import React from 'react';
import {
	Collapse,
	Navbar,
	NavbarBrand,
	NavLink,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Input,
} from 'reactstrap';
import './Navbar.css';
import './parts/Hamburger/Hamburger.css';
import MapIcon from './assets/img/Icons/map-icon.png';
import * as locationData from './assets/data/locations.json';
import { withRouter } from 'react-router-dom';
import Logo from './assets/img/Dexafit-tag.png';

export default withRouter(
	class Top extends React.Component {
		constructor(props) {
			super(props);

			this.toggle = this.toggle.bind(this);
			this.toggle2 = this.toggle2.bind(this);
			this.state = {
				isOpen: false,
				signModal: false,
				logModal: false,
				locationOpen: false,
			};
		}

		toggle() {
			this.setState({
				isOpen: !this.state.isOpen,
			});
		}
		toggle2() {
			this.setState({
				locationOpen: !this.state.locationOpen,
			});
		}
		toggle3 = () => {
			this.setState({
				signModal: !this.state.signModal,
				locationOpen: false,
			});
		};
		toggle4 = () => {
			this.setState({
				logModal: !this.state.logModal,
				locationOpen: false,
			});
		};
		handlePageChange = (page) => {
			this.props.history.push(page);
			if (this.props.show) {
				this.props.drawerClickHandler();
			}
		};
		handleLogoClick = () => {
			sessionStorage.setItem('Dexa-Customer-Type', 'Choices');
			this.handlePageChange('/');
			window.location.reload();
		};
		render() {
			let navClasses = 'Navbar-color ' + this.props.scrollStyle;
			if (this.props.show) {
				navClasses = 'Navbar-color open ' + this.props.scrollStyle;
			}
			let states = [];
			let locationSelect = 'location-select-div';
			if (this.state.locationOpen) {
				locationSelect = 'location-select-div open2';
			}
			let HideClass;
			if (
				(this.props.ChoicesSlide === 'Choices' || this.props.ChoicesSlide === null) &&
				this.props.location.pathname === '/'
			) {
				HideClass = ' d-none';
			}
			return (
				<div className={HideClass}>
					<Navbar className={navClasses} expand='lg'>
						<button
							className={this.props.animate}
							onClick={this.props.drawerClickHandler}
							type='button'>
							<span className='hamburger-box'>
								<span className='hamburger-inner'></span>
							</span>
						</button>
						<NavbarBrand onClick={this.handleLogoClick}>
							<img className='brand-icon' src={Logo} alt='dexa logo' />
						</NavbarBrand>
						<Collapse isOpen={this.props.show} className='Collapse-color' navbar>
							<div className='responsive-nav'>
								{/* <NavLink className='Navbar-element' onClick={(e) => this.handlePageChange('/')}>
									Learn
								</NavLink> */}
								<NavLink
									className='Navbar-element'
									onClick={(e) => this.handlePageChange('/Services')}>
									Services
								</NavLink>
								<NavLink
									className='Navbar-element'
									onClick={(e) => this.handlePageChange('/Solutions')}>
									Solutions
								</NavLink>
								{/* <NavLink className='Navbar-element' onClick={(e) => this.handlePageChange('/Map')}>
									Book
								</NavLink>
								<NavLink className='Navbar-element' onClick={(e) => this.handlePageChange('/Map')}>
									Pricing
								</NavLink> */}
								<NavLink
									className='Navbar-element'
									onClick={(e) => this.handlePageChange('/Blog/home')}>
									Blog
								</NavLink>
								<Button onClick={(e) => this.handlePageChange('/Map')} className='Navbar-button'>
									Get Started
								</Button>
							</div>
							<div className='button-wrap'>
								<div className='map-select-nav'>
									<p className='d-lg-none'>
										<i onClick={this.toggle2} className='down-arrow'></i>
									</p>
									<div onClick={this.toggle2} id='location-nav-text'>
										{this.props.CurrentLocation.label}
									</div>
									<img
										id='map-pin'
										onClick={(e) => this.handlePageChange('/Map')}
										src={MapIcon}
										alt='map pin icon'
									/>
								</div>
								{/* <Button onClick={this.toggle4} className='login-button' outline color='primary'>
									Log in
								</Button>
								<Button onClick={this.toggle3} className='sign-in' color='primary'>
									Sign up
								</Button> */}
							</div>
						</Collapse>
					</Navbar>
					<Modal isOpen={this.state.signModal} toggle={this.toggle3} className='test'>
						<ModalHeader toggle={this.toggle3}>Sign Up</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<div className='before-input'>Email:</div>
									<Input type='email' className='input-login' placeholder='Email'></Input>
									<div className='before-input'>Password:</div>
									<Input type='password' className='input-login' placeholder='Password'></Input>
									<div className='before-input'>Confirm Password:</div>
									<Input
										type='password'
										className='input-login'
										placeholder='Confirm Password'></Input>
								</FormGroup>
							</Form>
						</ModalBody>
						<ModalFooter>
							<Button color='primary' onClick={this.toggle3}>
								Sign Up
							</Button>
							<Button color='secondary' onClick={this.toggle3}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
					<Modal isOpen={this.state.logModal} toggle={this.toggle4} className='test'>
						<ModalHeader toggle={this.toggle4}>Log In</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<div className='before-input'>Email:</div>
									<Input type='email' className='input-login' placeholder='Email'></Input>
									<div className='before-input'>Password:</div>
									<Input type='password' className='input-login' placeholder='Password'></Input>
									<div className='forgot-password'>Forgot your password?</div>
								</FormGroup>
							</Form>
						</ModalBody>
						<ModalFooter>
							<Button color='primary' onClick={this.toggle4}>
								Log In
							</Button>
							<Button color='secondary' onClick={this.toggle4}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
					<div onClick={this.toggle2} className={locationSelect}>
						{this.state.locationOpen ? (
							<div className='flex-parent'>
								<div
									onClick={(e) => {
										this.props.LocationPass(40, -95, 'US', 'US');
										this.props.history.push('/Map');
									}}
									className='Col-parent'>
									United States
								</div>
								{locationData.default.map((location) => {
									if (states.includes(location.state)) {
										return null;
									} else {
										states.push(location.state);
										return location.country === 'US' ? (
											<div
												className='columns'
												onClick={(e) => {
													this.props.LocationPass2(
														location.coordinates[0],
														location.coordinates[1],
														location.state,
														location.label,
														location.country,
														true
													);
													this.props.history.push('/Map');
												}}
												key={location.label}>
												{location.label}
											</div>
										) : null;
									}
								})}
								<div
									onClick={(e) => {
										this.props.LocationPass(43.65, -79.38, 'CA', 'Canada');
										this.props.history.push('/Map');
									}}
									className='Col-parent'>
									Canada
								</div>
								{locationData.default.map((location) => {
									return location.country === 'CA' ? (
										<div
											className='columns'
											onClick={(e) => {
												this.props.LocationPass2(
													location.coordinates[0],
													location.coordinates[1],
													location.state,
													location.label,
													location.country,
													true
												);
												this.props.history.push('/Map');
											}}
											key={location.label}>
											{location.label}
										</div>
									) : null;
								})}
								<div
									onClick={(e) => {
										this.props.LocationPass(1.36, 103.78, 'AS', 'Asia');
										this.props.history.push('/Map');
									}}
									className='Col-parent'>
									Asia
								</div>
								{locationData.default.map((location) => {
									return location.country === 'AS' ? (
										<div
											className='columns'
											onClick={(e) => {
												this.props.LocationPass2(
													location.coordinates[0],
													location.coordinates[1],
													location.state,
													location.label,
													location.country,
													true
												);
												this.props.history.push('/Map');
											}}
											key={location.label}>
											{location.label}
										</div>
									) : null;
								})}
							</div>
						) : null}
					</div>
				</div>
			);
		}
	}
);
