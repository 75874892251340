import React, { Component } from 'react';
import LIT from '../../assets/img/blue-lightning.png';
import '../Book.css';

export default class ServNav extends Component {
	render() {
		return (
			<div className='scroll-icon location-scroll-icon'>
				<div
					id='Service-circle'
					onClick={this.props.click}
					className={`circle-border2 ${this.props.on}`}>
					<img className='small-button-icons2' src={LIT} alt='blue lightning icon' />
				</div>
				<h6 className='grey-label'>Services</h6>
			</div>
		);
	}
}
