import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Tim from '../../assets/img/Tim.jpg';
import Joanna from '../../assets/img/Joanna.jpg';
import Tory from '../../assets/img/Tory.jpg';
import Rod from '../../assets/img/Rod.jpg';
import Mark from '../../assets/img/Mark.png';
import Badou from '../../assets/img/Badou.jpg';
import arrow from '../../assets/img/right.png';
import '../Book.css';

export default class Book5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show1: true,
			show2: false,
			show3: false,
			show4: false,
			show5: false,
			show6: false,
			slide: 1,
			way: true
		};
	}
	handleSlideChangeForward = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 2,
					show2: true,
					show1: false,
					way: false
				});
				break;
			case 2:
				this.setState({
					slide: 3,
					show3: true,
					show2: false,
					way: false
				});
				break;
			case 3:
				this.setState({
					slide: 4,
					show4: true,
					show3: false,
					way: false
				});
				break;
			case 4:
				this.setState({
					slide: 5,
					show5: true,
					show4: false,
					way: false
				});
				break;
			case 5:
				this.setState({
					slide: 6,
					show6: true,
					show5: false,
					way: false
				});
				break;
			case 6:
				this.setState({
					slide: 1,
					show1: true,
					show6: false,
					way: false
				});
				break;
			default:
		}
	};
	handleSlideChangeBackwards = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 6,
					show6: true,
					show1: false,
					way: true
				});
				break;
			case 2:
				this.setState({
					slide: 1,
					show2: false,
					show1: true,
					way: true
				});
				break;
			case 3:
				this.setState({
					slide: 2,
					show2: true,
					show3: false,
					way: true
				});
				break;
			case 4:
				this.setState({
					slide: 3,
					show3: true,
					show4: false,
					way: true
				});
				break;
			case 5:
				this.setState({
					slide: 4,
					show4: true,
					show5: false,
					way: true
				});
				break;
			case 6:
				this.setState({
					slide: 5,
					show5: true,
					show6: false,
					way: true
				});
				break;
			default:
		}
	};
	render() {
		let direction = this.state.way;
		let left;
		let right;
		let slideDot1 = '';
		let slideDot2 = '';
		let slideDot3 = '';
		let slideDot4 = '';
		let slideDot5 = '';
		let slideDot6 = '';
		if (this.state.show1) {
			slideDot1 = 'circle-on';
		}
		if (this.state.show2) {
			slideDot2 = 'circle-on';
		}
		if (this.state.show3) {
			slideDot3 = 'circle-on';
		}
		if (this.state.show4) {
			slideDot4 = 'circle-on';
		}
		if (this.state.show5) {
			slideDot5 = 'circle-on';
		}
		if (this.state.show6) {
			slideDot6 = 'circle-on';
		}
		if (direction) {
			left = true;
			right = false;
		} else {
			left = false;
			right = true;
			direction = true;
		}
		return (
			<Row>
				<Col className='slide-container'>
					<img
						onClick={this.handleSlideChangeBackwards}
						className='left-arrow'
						src={arrow}
						alt='arrow pointing left'
					/>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Knowing your numbers with DexaFit takes you to the next level. Go check them out
									if you're serious about achieving your goals.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Mark} className='user-review-icon' id='mark-icon' alt='icon of user' />
								<div className='review-name'>Mark De La Rosa</div>
								<div>UFC Bantamweight Fighter</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									DEXA is my favorite option for measuring body fat percentage, as the results
									include information besides body composition.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tim} className='user-review-icon' id='Tim-icon' alt='icon of user' />
								<div className='review-name'>Tim Ferriss</div>
								<div>Author of the 4-hour body</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									I'm now in the best shape of my life at 48. MetLife even reduced my insurance
									premium after showing them my results!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Rod} className='user-review-icon' id='rod-icon' alt='icon of user' />
								<div className='review-name'>Rod T.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show4}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									So much information you get from these tests! You can use it to live happier and
									healthier without guessing!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tory} className='user-review-icon' alt='icon of user' />
								<div className='review-name'>Tory B.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show5}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Using DexaFit helped me create a plan to lose 34 lbs of fat and gain 4 lbs of
									muscle in 7 months! it is vital to any plan.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img
									src={Joanna}
									className='user-review-icon'
									id='joanna-icon'
									alt='icon of user'
								/>
								<div className='review-name'>Joanna Lee</div>
								<div>DexaFit Chicago</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show6}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Special thanks to my friends at Dexafit Las Vegas for always giving me scientific
									& accurate body measurements.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Badou} className='user-review-icon' id='badou-icon' alt='icon of user' />
								<div className='review-name'>Badou Jack</div>
								<div>Two-weight world boxing champion</div>
							</div>
						</Fade>
					</div>

					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									DEXA is my favorite option for measuring body fat percentage, as the results
									include information besides body composition.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tim} className='user-review-icon' id='Tim-icon' alt='icon of user' />
								<div className='review-name'>Tim Ferriss</div>
								<div>Author of the 4-hour body</div>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									I'm now in the best shape of my life at 48. MetLife even reduced my insurance
									premium after showing them my results!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Rod} className='user-review-icon' id='rod-icon' alt='icon of user' />
								<div className='review-name'>Rod T.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									So much information you get from these tests! You can use it to live happier and
									healthier without guessing!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tory} className='user-review-icon' alt='icon of user' />
								<div className='review-name'>Tory B.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show4}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Using DexaFit helped me create a plan to lose 34 lbs of fat and gain 4 lbs of
									muscle in 7 months! it is vital to any plan.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img
									src={Joanna}
									className='user-review-icon'
									id='joanna-icon'
									alt='icon of user'
								/>
								<div className='review-name'>Joanna Lee</div>
								<div>DexaFit Chicago</div>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show5}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Special thanks to my friends at Dexafit Las Vegas for always giving me scientific
									& accurate body measurements.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Badou} className='user-review-icon' id='badou-icon' alt='icon of user' />
								<div className='review-name'>Badou Jack</div>
								<div>Two-weight world boxing champion</div>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show6}>
							<div className='text-center'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Knowing your numbers with DexaFit takes you to the next level. Go check them out
									if you're serious about achieving your goals.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Mark} className='user-review-icon' id='mark-icon' alt='icon of user' />
								<div className='review-name'>Mark De La Rosa</div>
								<div>UFC Bantamweight Fighter</div>
							</div>
						</Fade>
					</div>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									I'm now in the best shape of my life at 48. MetLife even reduced my insurance
									premium after showing them my results!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Rod} className='user-review-icon' id='rod-icon' alt='icon of user' />
								<div className='review-name'>Rod T.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									So much information you get from these tests! You can use it to live happier and
									healthier without guessing!
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tory} className='user-review-icon' alt='icon of user' />
								<div className='review-name'>Tory B.</div>
								<div>DexaFit San Francisco</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Using DexaFit helped me create a plan to lose 34 lbs of fat and gain 4 lbs of
									muscle in 7 months! it is vital to any plan.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img
									src={Joanna}
									className='user-review-icon'
									id='joanna-icon'
									alt='icon of user'
								/>
								<div className='review-name'>Joanna Lee</div>
								<div>DexaFit Chicago</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show4}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Special thanks to my friends at Dexafit Las Vegas for always giving me scientific
									& accurate body measurements.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Badou} className='user-review-icon' id='badou-icon' alt='icon of user' />
								<div className='review-name'>Badou Jack</div>
								<div>Two-weight world boxing champion</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show5}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									Knowing your numbers with DexaFit takes you to the next level. Go check them out
									if you're serious about achieving your goals.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Mark} className='user-review-icon' id='mark-icon' alt='icon of user' />
								<div className='review-name'>Mark De La Rosa</div>
								<div>UFC Bantamweight Fighter</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show6}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<div className='review-title'>"</div>
								<div className='review-text'>
									DEXA is my favorite option for measuring body fat percentage, as the results
									include information besides body composition.
								</div>
								<div className='review-title'>"</div>
								<div className='grey-line'></div>
								<img src={Tim} className='user-review-icon' id='Tim-icon' alt='icon of user' />
								<div className='review-name'>Tim Ferriss</div>
								<div>Author of the 4-hour body</div>
							</div>
						</Fade>
					</div>

					<img
						onClick={this.handleSlideChangeForward}
						className='right-arrow'
						src={arrow}
						alt='arrow pointing right'
					/>
					<div id='dot-holder1'>
						<div className={`slide-circle ${slideDot1}`}></div>
						<div className={`slide-circle ${slideDot2}`}></div>
						<div className={`slide-circle ${slideDot3}`}></div>
						<div className={`slide-circle ${slideDot4}`}></div>
						<div className={`slide-circle ${slideDot5}`}></div>
						<div className={`slide-circle ${slideDot6}`}></div>
					</div>
				</Col>
			</Row>
		);
	}
}
