import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import IconNav2 from '../IconNav-2';
import Dumbbell from '../../assets/img/play-video@2x.png';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import '../HowItWorks.css';

export default class HowItWorks1 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack2(childData);
	};
	render() {
		return (
			<Row>
				<Col id='padding-1' lg={{ size: 6, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<IconNav2
								scrollStyle={this.props.scrollStyle}
								statePass2={this.props.statePass2}
								callBack2={this.secondCallBack}
							/>
							<Fade left>
								<h2 className='big-label'>Enhance Your Business or Practice with DexaFit</h2>
								<h4 className='small-lable'>Personalizing It Is Everything</h4>
								<p id='use-p-how'>
									Use DexaFit to grow your business or practice and inspire life-changing results.
								</p>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Innovate</h4>
								<p className='grey-p'>
									Get insights using the gold standard of health and fitness testing.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='Target icon' />
								</div>
								<h4 className='small-label-2'>Scale</h4>
								<p className='grey-p'>
									Keep more clients, attract new ones, and personalize plans with DexaFit.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='Internet icon' />
								</div>
								<h4 className='small-label-2'>Grow</h4>
								<p className='grey-p'>
									Motivate and inspire with elite-level services that create a scalable profit
									stream.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col lg='5'>
					<Fade right>
						<img
							className='Dumbbell-how'
							src={Dumbbell}
							alt='art of woman holding two dumbbells over her head app'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
