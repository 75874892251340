import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import IconNav2 from '../IconNav-2';
import Dumbbell from '../../assets/img/play-video@2x.png';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import '../HowItWorks.css';

export default class Medicine1 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack2(childData);
	};
	render() {
		return (
			<Row>
				<Col id='padding-1' lg={{ size: 6, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<IconNav2
								scrollStyle={this.props.scrollStyle}
								statePass2={this.props.statePass2}
								callBack2={this.secondCallBack}
							/>
							<Fade left>
								<h2 className='big-label' id='medicine-page-title'>
									Providing Clear Data & Clear Communication
								</h2>
								<h4 className='small-lable' id='medicine-page-small-title'>
									We build a detailed picture of health, so you can find the best ways to improve it
								</h4>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<p className='grey-p'>
									Get personalized insights using DexaFit's advanced technologies.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='Target icon' />
								</div>
								<p className='grey-p'>
									Optimize results with customized care and better compliance.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='Internet icon' />
								</div>
								<p className='grey-p'>
									Decrease treatment and recovery time. Reduce unnecessary care.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col lg='5'>
					<Fade right>
						<img
							className='Dumbbell-how'
							src={Dumbbell}
							alt='art of woman holding two dumbbells over her head app'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
