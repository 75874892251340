import React, { Component } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
//import Fade from 'react-reveal/Fade';
import '../Pricing.css';
import Card299 from './store-slides/299-card.jsx';
import Card99 from './store-slides/99-card.jsx';
import CardFree from './store-slides/Free-card.jsx';
import Card499 from './store-slides/499-card.jsx';
import CardOne from './store-slides/one-card.jsx';
import CardTwo from './store-slides/two-card.jsx';
import CardThree from './store-slides/three-card.jsx';
import CardFour from './store-slides/four-card.jsx';
import CardSix from './store-slides/six-card.jsx';
import CardEight from './store-slides/eight-card.jsx';
import CardTwelve from './store-slides/twelve-card.jsx';
import Card3d from './store-slides/3d-card.jsx';

import one from '../../assets/img/1.jpg';
import two from '../../assets/img/2.jpg';
import three from '../../assets/img/3.jpg';
import four from '../../assets/img/4.jpg';
import six from '../../assets/img/6.jpg';
import seven from '../../assets/img/7.jpg';
import eight from '../../assets/img/8.jpg';
import nine from '../../assets/img/9.jpg';
import ten from '../../assets/img/10.jpg';
import eleven from '../../assets/img/11.jpg';
import twelve from '../../assets/img/12.jpg';
import thirteen from '../../assets/img/13.jpg';
import fourteen from '../../assets/img/14.jpg';
import fifteen from '../../assets/img/15.jpg';
import sixteen from '../../assets/img/16.jpg';
import seventeen from '../../assets/img/17.jpg';
import eighteen from '../../assets/img/18.jpg';
import twenty from '../../assets/img/20.jpg';
import Care from '../../assets/img/Care.jpg';
import Joel from '../../assets/img/joelYakowitz.jpg';
import Karla from '../../assets/img/karlaRoach.jpg';
import Laura from '../../assets/img/LauraHamilton.jpg';
import Alene from '../../assets/img/AleneBaronian.jpg';
import twentyfive from '../../assets/img/25.png';
//import twentysix from '../../assets/img/26.png';
//import twentyseven from '../../assets/img/27.png';
//import twentyeight from '../../assets/img/28.png';
//import twentynine from '../../assets/img/29.png';

import Store1 from './store-slides/Store1.jsx';
import Store2 from './store-slides/Store2.jsx';
import Store3 from './store-slides/Store3.jsx';
import Store4 from './store-slides/Store4.jsx';
import Store5 from './store-slides/Store5.jsx';
import Store6 from './store-slides/Store6.jsx';
import Store7 from './store-slides/Store7.jsx';
import Store8 from './store-slides/Store8.jsx';
import Store9 from './store-slides/Store9.jsx';
import Store10 from './store-slides/Store10.jsx';
import Store11 from './store-slides/Store11.jsx';
import Store12 from './store-slides/Store12.jsx';
import Store13 from './store-slides/Store13.jsx';
import Store14 from './store-slides/Store14.jsx';
import Store15 from './store-slides/Store15.jsx';
import Store16 from './store-slides/Store16.jsx';
import Store17 from './store-slides/Store17.jsx';
import Store18 from './store-slides/Store18.jsx';
import Store19 from './store-slides/Store19.jsx';
import Store20 from './store-slides/Store20.jsx';
import Store21 from './store-slides/Store21.jsx';
import Store22 from './store-slides/Store22.jsx';
import Store23 from './store-slides/Store23.jsx';
import Store24 from './store-slides/Store24.jsx';
import Store25 from './store-slides/Store25.jsx';

export default class Pricing2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'plan',
			displayedComponent: '299',
			free: false,
			$99: false,
			$299: true,
			$499: false,
			$one: false,
			$two: false,
			$three: false,
			$3dpack: false,
			$four: false,
			$six: false,
			$eight: false,
			$twelve: false,
			cart: [],
			currentItem: '',
		};
	}
	renderComponent() {
		switch (this.state.displayedComponent) {
			case 'free':
				return <CardFree />;
			case '99':
				return <Card99 />;
			case '299':
				return <Card299 />;
			case '499':
				return <Card499 />;
			case 'one':
				return <CardOne page={this.state.page} prices={this.props.prices} />;
			case 'two':
				return <CardTwo page={this.state.page} prices={this.props.prices} />;
			case 'three':
				return <CardThree page={this.state.page} prices={this.props.prices} />;
			case '3dpack':
				return <Card3d page={this.state.page} prices={this.props.prices} />;
			case 'four':
				return <CardFour page={this.state.page} prices={this.props.prices} />;
			case 'six':
				return <CardSix page={this.state.page} prices={this.props.prices} />;
			case 'eight':
				return <CardEight page={this.state.page} prices={this.props.prices} />;
			case 'twelve':
				return <CardTwelve page={this.state.page} prices={this.props.prices} />;
			default:
				return <Card299 />;
		}
	}
	resetPageLab = () => {
		this.setState({
			page: 'Lab Testing',
			currentItem: '',
		});
	};
	addCart = (item) => {
		this.state.cart.push(item);
	};
	removeCart = (target) => {
		let newCart = this.state.cart;
		for (let i = 0; i < newCart.length; i++) {
			if (target === newCart[i].key) {
				newCart.splice(i, 1);
			}
		}
		this.setState({ cart: newCart });
	};
	renderPage() {
		let cart = this.state.cart;
		let total = 0;
		cart.map((item) => {
			total += item.price * item.quantity;
			return true;
		});
		let prices = this.props.prices;
		let amountOfDeals = 1;
		if (prices.twopack) {
			amountOfDeals++;
		}
		if (prices.threepack) {
			amountOfDeals++;
		}
		if (prices.fourpack) {
			amountOfDeals++;
		}
		if (prices.sixpack) {
			amountOfDeals++;
		}
		if (prices.eightpack) {
			amountOfDeals++;
		}
		if (prices.twelvepack) {
			amountOfDeals++;
		}
		let ThreeDSize = 12;
		if (prices.threeDpack) {
			ThreeDSize = ThreeDSize / 2;
		}
		let highPackToggle = false;
		if (amountOfDeals >= 6) {
			amountOfDeals = 4;
			highPackToggle = true;
		}
		let ColSize = 12 / amountOfDeals;
		switch (this.state.page) {
			case 'plan':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('free')}
									className={`pricing-group-button ${this.classReturn2('free')}`}>
									Free
								</Button>
								<Button
									onClick={() => this.clickSet('99')}
									className={`pricing-group-button ${this.classReturn2('99')}`}>
									Beginner
								</Button>
								<Button
									onClick={() => this.clickSet('299')}
									className={`pricing-group-button ${this.classReturn2('299')}`}>
									Starter
								</Button>
								<Button
									onClick={() => this.clickSet('499')}
									className={`pricing-group-button ${this.classReturn2('499')}`}>
									DexaFit+
								</Button>
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg='3' xl='3'>
									<h4 className='plan-titles'>Free</h4>

									<div
										onClick={() => this.clickSet('free')}
										className={`pricing-div ${this.classReturn2('free')}`}>
										<h4 id='prices-free' className='prices'>
											Free
										</h4>
										<h5 className='price-description'>Get started for free</h5>
									</div>

									<h5 className='included-title'>Fit by DexaFit App</h5>
									<h6 className='included-items'>
										All testing results and health data on your phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'free'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								<Col className='plan-spacer d-flex flex-column' lg='3' xl='3'>
									<h4 className='plan-titles'>Beginner</h4>

									<div
										onClick={() => this.clickSet('99')}
										className={`pricing-div ${this.classReturn2('99')}`}>
										<h4 className='prices'>99</h4>
										<h5 className='price-description'>Dip a toe in the water</h5>
									</div>

									<h5 className='included-title'>3D Body Scan</h5>
									<h6 className='included-items'>12 Scan Credits</h6>
									<h6 className='included-items'>HD 3D model of your body</h6>
									<h6 className='included-items'>Track Full Body circumference changes</h6>
									<h6 className='included-items'>Posture analysis</h6>

									<h5 className='included-title'>Discounted DXA, VO2, RMR</h5>
									<h6 className='included-items'>$95 discounted rate (compared to $149)</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2('99')}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								<Col className='plan-spacer d-flex flex-column' lg='3' xl='3'>
									<h4 className='plan-titles'>Starter</h4>
									<div
										onClick={() => this.clickSet('299')}
										className={`pricing-div ${this.classReturn2('299')}`}>
										<h4 className='prices'>299</h4>
										<h5 className='price-description'>Most Popular</h5>
									</div>

									<h5 className='included-title'>DXA, VO2, and/or RMR</h5>
									<h6 className='included-items'>
										4 Test Credits (save over 50% per test vs regular price)
									</h6>
									<h6 className='included-items'>Mix and match DXA, VO2, and/or RMR</h6>
									<h6 className='included-items'>Buy additional credits for only $75</h6>
									<h6 className='included-items'>Share a test credit with a friend</h6>

									<h5 className='included-title'>3D Body Scan</h5>
									<h6 className='included-items'>12 Scan Credits</h6>
									<h6 className='included-items'>HD 3D model of your body</h6>
									<h6 className='included-items'>Track Full Body circumference changes</h6>
									<h6 className='included-items'>Posture analysis</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'299'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								<Col className='plan-spacer d-flex flex-column' lg='3' xl='3'>
									<h4 className='plan-titles'>DexaFit +</h4>
									<div
										onClick={() => this.clickSet('499')}
										className={`pricing-div ${this.classReturn2('499')}`}>
										<h4 className='prices'>499</h4>
										<h5 className='price-description'>I’m all in baby!</h5>
									</div>
									<h5 className='included-title'>DXA, VO2, and/or RMR</h5>
									<h6 className='included-items'>
										8 Test Credits (save over 50% per test vs regular price)
									</h6>
									<h6 className='included-items'>Mix and match DXA, VO2, and/or RMR</h6>
									<h6 className='included-items'>Buy additional credits for only $75</h6>
									<h6 className='included-items'>Share a test credit with a friend</h6>

									<h5 className='included-title'>3D Body Scan</h5>
									<h6 className='included-items'>12 Scan Credits</h6>
									<h6 className='included-items'>HD 3D model of your body</h6>
									<h6 className='included-items'>Track Full Body circumference changes</h6>
									<h6 className='included-items'>Posture analysis</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'499'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			case 'Lab Testing':
				return (
					<div>
						<Row>
							{cart.length ? (
								<Col className='cart-checkout-card' xs={{ size: 10, offset: 1 }}>
									<div>Cart:</div>
									<div className='cart-outline'>
										{this.state.cart.map((item) => {
											return (
												<Row
													className='cart-row-space'
													key={Math.floor(Math.random() * Math.floor(10000))}>
													<Col className='cart-center cart-item-name'>{item.name}</Col>
													<Col className='cart-center cart-item-name'>{item.quantity}</Col>
													<Col className='cart-center cart-item-name'>
														${item.price * item.quantity}.00
													</Col>
													<Button
														className='remove-button'
														onClick={(e) => {
															this.removeCart(item.key);
														}}>
														X
													</Button>
												</Row>
											);
										})}
									</div>
									<div>
										<div className='total-text'>Total: ${total}.00</div>
										<Button
											onClick={(e) => {
												this.handlePageChange('book1');
											}}
											className='Learn-about-button'>
											Call To learn about purchasing options at your location!
										</Button>
									</div>
								</Col>
							) : null}
						</Row>
						<Row className='cart-row'>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '1',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={one} alt='DNA Analysis by FitnessGenes $199.00' />
									<div className='cart-card-text'>
										DNA Analysis Kit <br /> $199.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '2',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={two}
										alt='DNA Analysis + GET LEAN Starter System by FitnessGenes $259.00'
									/>
									<div className='cart-card-text'>
										DNA Analysis + GET LEAN Starter System <br /> $259.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '3',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={three}
										alt='FitnessGenes Muscle Build System $289.00'
									/>
									<div className='cart-card-text'>
										FitnessGenes Muscle Build System <br /> $289.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '4',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={four} alt='InsideTraker Ultimate Panel $574.00' />
									<div className='cart-card-text'>
										InsideTraker Ultimate Panel <br /> $574.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '5',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={fifteen}
										alt='Flore Microbiome Collection Kit & Personalized Probiotics $297.00'
									/>
									<div className='cart-card-text'>
										Flore Microbiome Collection Kit & Personalized Probiotics
										<br /> $297.00 <strike>$447.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '25',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={twentyfive}
										alt='PinnerTest Food Intolerance kit $299.00'
									/>
									<div className='cart-card-text'>
										PinnerTest Food Intolerance kit
										<br /> $299.00 <strike>$330.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '6',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={seven} alt='Performance Panel $349.00' />
									<div className='cart-card-text'>
										Performance Panel
										<br /> $349.00 <strike>$449.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '7',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={six} alt='Elite Blood Panel $1,199.00' />
									<div className='cart-card-text'>
										Elite Blood Panel
										<br /> $1,199.00 <strike>$1,299.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '8',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={eight} alt='Performance Panel $349.00' />
									<div className='cart-card-text'>
										Longevity Panel
										<br /> $249.00 <strike>$299.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '9',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={Care} alt='Care Consultation $299.00' />
									<div className='cart-card-text'>
										Care Consultation <br /> $299.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '10',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={nine} alt='Optimal Male Panel $529.00' />
									<div className='cart-card-text'>
										Optimal Male Panel
										<br /> $529.00 <strike>$599.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '11',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={fourteen}
										alt='Microbiome Collection Kit Only $149.00'
									/>
									<div className='cart-card-text'>
										Microbiome Collection Kit Only
										<br /> $149.00 <strike>$250.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '12',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={ten} alt='Optimal Female Panel $529.00' />
									<div className='cart-card-text'>
										Optimal Female Panel
										<br /> $529.00 <strike>$599.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '13',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={eleven}
										alt='Heart Health & Inflammation Panel $220.00 '
									/>
									<div className='cart-card-text'>
										Heart Health & Inflammation Panel
										<br /> $220.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '14',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={twelve} alt='Essential Thyroid $220.00' />
									<div className='cart-card-text'>
										Essential Thyroid
										<br /> $220.00
									</div>
								</div>
							</Col>
							{/* <Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '15',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={Joel} alt='Joel Yakowitz $0.00' />
									<div className='cart-card-text'>
										Joel Yakowitz
										<br /> $0.00
									</div>
								</div>
							</Col> */}
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '16',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={thirteen} alt='Immunity & Stress Panel $199.00' />
									<div className='cart-card-text'>
										Immunity & Stress Panel
										<br /> $199.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '17',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={seventeen}
										alt='QALYtude Metformin Longevity Bundle $349.00'
									/>
									<div className='cart-card-text'>
										QALYtude Metformin Longevity Bundle
										<br /> $349.00 <strike>$399.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '18',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={twenty} alt='The DexaDoc $0.00' />
									<div className='cart-card-text'>
										The DexaDoc
										<br /> $0.00
									</div>
								</div>
							</Col>
							{/* <Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '19',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={eighteen}
										alt='Team DANG Fitness & Nutrition $0.00'
									/>
									<div className='cart-card-text'>
										Team DANG Fitness & Nutrition
										<br /> $0.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '20',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={Alene} alt='Alene Baronian MS, RD, CSSD $0.00' />
									<div className='cart-card-text'>
										Alene Baronian MS, RD, CSSD
										<br /> $0.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '21',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img className='cart-img' src={Karla} alt='Karla Roach, CHHC $0.00' />
									<div className='cart-card-text'>
										Karla Roach, CHHC
										<br /> $0.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '22',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={Laura}
										alt='Laura Hamilton MA, RD, CSP, LD $0.00'
									/>
									<div className='cart-card-text'>
										Laura Hamilton MA, RD, CSP, LD
										<br /> $0.00
									</div>
								</div>
							</Col> */}
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '23',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={sixteen}
										alt='Clinical Health Prevention Program - The Champ Plan $0.00'
									/>
									<div className='cart-card-text'>
										Clinical Health Prevention Program - The Champ Plan
										<br /> $0.00
									</div>
								</div>
							</Col>
							<Col md='6' lg='4' xl='3'>
								<div
									onClick={(e) => {
										this.setState({
											page: 'item',
											currentItem: '24',
										});
										this.props.scroll();
									}}
									className='cart-card'>
									<img
										className='cart-img'
										src={seventeen}
										alt='QALYtude Longevity+ Bundle $499.00'
									/>
									<div className='cart-card-text'>
										QALYtude Longevity+ Bundle
										<br /> $499.00 <strike>$549.00</strike>
									</div>
									<div className='card-sale'>Sale</div>
								</div>
							</Col>
						</Row>
					</div>
				);
			case 'Vo2max':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('one')}
									className={`pricing-group-button${highPackToggle ? '-2' : ''} ${this.classReturn2(
										'one'
									)}`}>
									One Test
								</Button>
								{prices.twopack ? (
									<Button
										onClick={() => this.clickSet('two')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('two')}`}>
										Two Pack
									</Button>
								) : null}
								{prices.threepack ? (
									<Button
										onClick={() => this.clickSet('three')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('three')}`}>
										Three Pack
									</Button>
								) : null}
								{prices.fourpack ? (
									<Button
										onClick={() => this.clickSet('four')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('four')}`}>
										Four Pack
									</Button>
								) : null}
								{prices.sixpack ? (
									<Button
										onClick={() => this.clickSet('six')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('six')}`}>
										Six Pack
									</Button>
								) : null}
								{prices.eightpack ? (
									<Button
										onClick={() => this.clickSet('eight')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('eight')}`}>
										Eight Pack
									</Button>
								) : null}
								{prices.twelvepack ? (
									<Button
										onClick={() => this.clickSet('twelve')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('twelve')}`}>
										Twelve Pack
									</Button>
								) : null}
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
									<h4 className='plan-titles'>One Test</h4>
									<div
										onClick={() => this.clickSet('one')}
										className={`pricing-div ${this.classReturn2('one')}`}>
										<h4 className='prices'>{prices.vo2}</h4>
										<h5 className='price-description'>ONE VO2 max Test</h5>
									</div>

									<h5 className='included-title'>VO2 Max Fitness Test</h5>
									<h6 className='included-items'>ONE VO2 max Test</h6>
									<h6 className='included-items'>
										Pinpoint your precise fitness level, endurance potential, and cardiorespiratory
										degeneration.
									</h6>
									<h6 className='included-items'>
										Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
									</h6>
									<h6 className='included-items'>
										Considered the top predicator for ALL-CAUSE MORTALITY risk
									</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your iOS or Android phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'one'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								{prices.twopack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Two Pack</h4>
										<div
											onClick={() => this.clickSet('two')}
											className={`pricing-div ${this.classReturn2('two')}`}>
											<h4 className='prices'>{prices.twopack}</h4>
											<h5 className='price-description'>TWO VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											TWO VO2 max Tests (${Math.round((prices.twopack / 2) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>
										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'two'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.threepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Three Pack</h4>
										<div
											onClick={() => this.clickSet('three')}
											className={`pricing-div ${this.classReturn2('three')}`}>
											<h4 className='prices'>{prices.threepack}</h4>
											<h5 className='price-description'>THREE VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											THREE VO2 max Tests (${Math.round((prices.threepack / 3) * 100) / 100} per
											test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>
										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'three'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.fourpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Four Pack</h4>
										<div
											onClick={() => this.clickSet('four')}
											className={`pricing-div ${this.classReturn2('four')}`}>
											<h4 className='prices'>{prices.fourpack}</h4>
											<h5 className='price-description'>FOUR VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											FOUR VO2 max Tests (${Math.round((prices.fourpack / 4) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>
										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'four'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.sixpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Six Pack</h4>
										<div
											onClick={() => this.clickSet('six')}
											className={`pricing-div ${this.classReturn2('six')}`}>
											<h4 className='prices'>{prices.sixpack}</h4>
											<h5 className='price-description'>SIX VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											SIX VO2 max Tests (${Math.round((prices.sixpack / 6) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>
										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'six'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.eightpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Eight Pack</h4>
										<div
											onClick={() => this.clickSet('eight')}
											className={`pricing-div ${this.classReturn2('eight')}`}>
											<h4 className='prices'>{prices.eightpack}</h4>
											<h5 className='price-description'>EIGHT VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											EIGHT VO2 max Tests (${Math.round((prices.eightpack / 8) * 100) / 100} per
											test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'eight'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.twelvepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Twelve Pack</h4>
										<div
											onClick={() => this.clickSet('twelve')}
											className={`pricing-div ${this.classReturn2('twelve')}`}>
											<h4 className='prices'>{prices.twelvepack}</h4>
											<h5 className='price-description'>TWELVE VO2 max Tests</h5>
										</div>

										<h5 className='included-title'>VO2 Max Fitness Test</h5>
										<h6 className='included-items'>
											TWELVE VO2 max Tests (${Math.round((prices.twelvepack / 12) * 100) / 100} per
											test)
										</h6>
										<h6 className='included-items'>
											Pinpoint your precise fitness level, endurance potential, and
											cardiorespiratory degeneration.
										</h6>
										<h6 className='included-items'>
											Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
										</h6>
										<h6 className='included-items'>
											Considered the top predicator for ALL-CAUSE MORTALITY risk
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'twelve'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			case 'RMR':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('one')}
									className={`pricing-group-button${highPackToggle ? '-2' : ''} ${this.classReturn2(
										'one'
									)}`}>
									One Test
								</Button>
								{prices.twopack ? (
									<Button
										onClick={() => this.clickSet('two')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('two')}`}>
										Two Pack
									</Button>
								) : null}
								{prices.threepack ? (
									<Button
										onClick={() => this.clickSet('three')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('three')}`}>
										Three Pack
									</Button>
								) : null}
								{prices.fourpack ? (
									<Button
										onClick={() => this.clickSet('four')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('four')}`}>
										Four Pack
									</Button>
								) : null}
								{prices.sixpack ? (
									<Button
										onClick={() => this.clickSet('six')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('six')}`}>
										Six Pack
									</Button>
								) : null}
								{prices.eightpack ? (
									<Button
										onClick={() => this.clickSet('eight')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('eight')}`}>
										Eight Pack
									</Button>
								) : null}
								{prices.twelvepack ? (
									<Button
										onClick={() => this.clickSet('twelve')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('twelve')}`}>
										Twelve Pack
									</Button>
								) : null}
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
									<h4 className='plan-titles'>One Test</h4>
									<div
										onClick={() => this.clickSet('one')}
										className={`pricing-div ${this.classReturn2('one')}`}>
										<h4 className='prices'>{prices.rmr}</h4>
										<h5 className='price-description'>ONE RMR Test</h5>
									</div>

									<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
									<h6 className='included-items'>ONE RMR Test</h6>
									<h6 className='included-items'>
										Pinpoint the number of Calories you burn at rest
									</h6>
									<h6 className='included-items'>
										See how efficient your body is at converting food to energy and using fat or
										sugar as fuel
									</h6>
									<h6 className='included-items'>
										Learn if you have a slowed metabolism to breakthrough frustrating plateaus
									</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your iOS or Android phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'one'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								{prices.twopack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Two Pack</h4>
										<div
											onClick={() => this.clickSet('two')}
											className={`pricing-div ${this.classReturn2('two')}`}>
											<h4 className='prices'>{prices.twopack}</h4>
											<h5 className='price-description'>TWO RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											TWO RMR Tests ({Math.round((prices.twopack / 2) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'two'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.threepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Three Pack</h4>
										<div
											onClick={() => this.clickSet('three')}
											className={`pricing-div ${this.classReturn2('three')}`}>
											<h4 className='prices'>{prices.threepack}</h4>
											<h5 className='price-description'>THREE RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											THREE RMR Tests ({Math.round((prices.threepack / 3) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'three'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.fourpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Four Pack</h4>
										<div
											onClick={() => this.clickSet('four')}
											className={`pricing-div ${this.classReturn2('four')}`}>
											<h4 className='prices'>{prices.fourpack}</h4>
											<h5 className='price-description'>FOUR RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											FOUR RMR Tests ({Math.round((prices.fourpack / 4) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'four'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.sixpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Six Pack</h4>
										<div
											onClick={() => this.clickSet('six')}
											className={`pricing-div ${this.classReturn2('six')}`}>
											<h4 className='prices'>{prices.sixpack}</h4>
											<h5 className='price-description'>SIX RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											SIX RMR Tests ({Math.round((prices.sixpack / 6) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'six'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.eightpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Eight Pack</h4>
										<div
											onClick={() => this.clickSet('eight')}
											className={`pricing-div ${this.classReturn2('eight')}`}>
											<h4 className='prices'>{prices.eightpack}</h4>
											<h5 className='price-description'>EIGHT RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											EIGHT RMR Tests ({Math.round((prices.eightpack / 8) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'eight'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.twelvepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Twelve Pack</h4>
										<div
											onClick={() => this.clickSet('twelve')}
											className={`pricing-div ${this.classReturn2('twelve')}`}>
											<h4 className='prices'>{prices.twelvepack}</h4>
											<h5 className='price-description'>TWELVE RMR Tests</h5>
										</div>

										<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
										<h6 className='included-items'>
											TWELVE RMR Tests ({Math.round((prices.twelvepack / 12) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Pinpoint the number of Calories you burn at rest
										</h6>
										<h6 className='included-items'>
											See how efficient your body is at converting food to energy and using fat or
											sugar as fuel
										</h6>
										<h6 className='included-items'>
											Learn if you have a slowed metabolism to breakthrough frustrating plateaus
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'twelve'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			case '3D Scan':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('one')}
									className={`pricing-group-button ${this.classReturn2('one')}`}>
									One Scan
								</Button>
								{prices.threeDpack ? (
									<Button
										onClick={() => this.clickSet('3dpack')}
										className={`pricing-group-button ${this.classReturn2('3dpack')}`}>
										12pack
									</Button>
								) : null}
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg={ThreeDSize} xl={ThreeDSize}>
									<h4 className='plan-titles'>One Scan</h4>
									<div
										onClick={() => this.clickSet('one')}
										className={`pricing-div ${this.classReturn2('one')}`}>
										<h4 className='prices'>{prices.threeD}</h4>
										<h5 className='price-description'>ONE 3D Scan</h5>
									</div>

									<h5 className='included-title'>3D Body Scan</h5>
									<h6 className='included-items'>ONE 3D Scan</h6>
									<h6 className='included-items'>
										Map the surface of your body and get more than 400 results about your body in
										three dimensions
									</h6>
									<h6 className='included-items'>
										Reveal imbalances in muscle symmetry and strength, flexibility, and joint
										mechanics
									</h6>
									<h6 className='included-items'>
										Insights of key areas of your body, including circumferences, lengths, widths,
										and volumes
									</h6>
									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your iOS or Android phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'one'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								{prices.threeDpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ThreeDSize} xl={ThreeDSize}>
										<h4 className='plan-titles'>12 Pack</h4>
										<div
											onClick={() => this.clickSet('3dpack')}
											className={`pricing-div ${this.classReturn2('3dpack')}`}>
											<h4 className='prices'>{prices.threeDpack}</h4>
											<h5 className='price-description'>TWELVE 3D Scans</h5>
										</div>

										<h5 className='included-title'>3D Body Scan</h5>
										<h6 className='included-items'>
											TWELVE 3D Scans (${Math.round((prices.threeDpack / 12) * 100) / 100} per Scan)
										</h6>
										<h6 className='included-items'>
											Map the surface of your body and get more than 400 results about your body in
											three dimensions
										</h6>
										<h6 className='included-items'>
											Reveal imbalances in muscle symmetry and strength, flexibility, and joint
											mechanics
										</h6>
										<h6 className='included-items'>
											Insights of key areas of your body, including circumferences, lengths, widths,
											and volumes
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'3dpack'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			case 'DXA':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('one')}
									className={`pricing-group-button${highPackToggle ? '-2' : ''} ${this.classReturn2(
										'one'
									)}`}>
									One Test
								</Button>
								{prices.twopack ? (
									<Button
										onClick={() => this.clickSet('two')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('two')}`}>
										Two Pack
									</Button>
								) : null}
								{prices.threepack ? (
									<Button
										onClick={() => this.clickSet('three')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('three')}`}>
										Three Pack
									</Button>
								) : null}
								{prices.fourpack ? (
									<Button
										onClick={() => this.clickSet('four')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('four')}`}>
										Four Pack
									</Button>
								) : null}
								{prices.sixpack ? (
									<Button
										onClick={() => this.clickSet('six')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('six')}`}>
										Six Pack
									</Button>
								) : null}
								{prices.eightpack ? (
									<Button
										onClick={() => this.clickSet('eight')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('eight')}`}>
										Eight Pack
									</Button>
								) : null}
								{prices.twelvepack ? (
									<Button
										onClick={() => this.clickSet('twelve')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('twelve')}`}>
										Twelve Pack
									</Button>
								) : null}
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
									<h4 className='plan-titles'>One Test</h4>
									<div
										onClick={() => this.clickSet('one')}
										className={`pricing-div ${this.classReturn2('one')}`}>
										<h4 className='prices'>{prices.dxa}</h4>
										<h5 className='price-description'>ONE DEXA Scan</h5>
									</div>

									<h5 className='included-title'>DEXA Body Comp Scan</h5>
									<h6 className='included-items'>ONE DEXA Scan</h6>
									<h6 className='included-items'>
										Map and pinpoint your muscle gains and fat loss in specific areas of the body
									</h6>
									<h6 className='included-items'>
										Track your body's specific physiological response to your diet or fitness plan
									</h6>
									<h6 className='included-items'>
										Discover your skeletal health and risk for osteoporosis
									</h6>
									<h6 className='included-items'>
										Identify personal long-term health risks associated with visceral fat around
										your organs
									</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your iOS or Android phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'one'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								{prices.twopack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Two Pack</h4>
										<div
											onClick={() => this.clickSet('two')}
											className={`pricing-div ${this.classReturn2('two')}`}>
											<h4 className='prices'>{prices.twopack}</h4>
											<h5 className='price-description'>TWO DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											TWO DEXA Scans (${Math.round((prices.twopack / 2) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'two'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.threepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Three Pack</h4>
										<div
											onClick={() => this.clickSet('three')}
											className={`pricing-div ${this.classReturn2('three')}`}>
											<h4 className='prices'>{prices.threepack}</h4>
											<h5 className='price-description'>THREE DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											THREE DEXA Scans (${Math.round((prices.threepack / 3) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'three'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.fourpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Four Pack</h4>
										<div
											onClick={() => this.clickSet('four')}
											className={`pricing-div ${this.classReturn2('four')}`}>
											<h4 className='prices'>{prices.fourpack}</h4>
											<h5 className='price-description'>FOUR DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											FOUR DEXA Scans (${Math.round((prices.fourpack / 4) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'four'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.sixpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Six Pack</h4>
										<div
											onClick={() => this.clickSet('six')}
											className={`pricing-div ${this.classReturn2('six')}`}>
											<h4 className='prices'>{prices.sixpack}</h4>
											<h5 className='price-description'>SIX DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											SIX DEXA Scans (${Math.round((prices.sixpack / 6) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'six'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.eightpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Eight Pack</h4>
										<div
											onClick={() => this.clickSet('eight')}
											className={`pricing-div ${this.classReturn2('eight')}`}>
											<h4 className='prices'>{prices.eightpack}</h4>
											<h5 className='price-description'>EIGHT DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											EIGHT DEXA Scans (${Math.round((prices.eightpack / 8) * 100) / 100} per test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'eight'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.twelvepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Twelve Pack</h4>
										<div
											onClick={() => this.clickSet('twelve')}
											className={`pricing-div ${this.classReturn2('twelve')}`}>
											<h4 className='prices'>{prices.twelvepack}</h4>
											<h5 className='price-description'>TWELVE DEXA Scans</h5>
										</div>

										<h5 className='included-title'>DEXA Body Comp Scan</h5>
										<h6 className='included-items'>
											TWELVE DEXA Scans (${Math.round((prices.twelvepack / 12) * 100) / 100} per
											test)
										</h6>
										<h6 className='included-items'>
											Map and pinpoint your muscle gains and fat loss in specific areas of the body
										</h6>
										<h6 className='included-items'>
											Track your body's specific physiological response to your diet or fitness plan
										</h6>
										<h6 className='included-items'>
											Discover your skeletal health and risk for osteoporosis
										</h6>
										<h6 className='included-items'>
											Identify personal long-term health risks associated with visceral fat around
											your organs
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'twelve'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			case 'Bone':
				return (
					<Row>
						<Col className='d-none d-xl-block' xl='1'>
							<h4 className='sidebar-text-style'>Plan</h4>
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>Pricing</h4>
							<br />
							<br />
							<br />
							<br />
							<h4 className='sidebar-text-style'>What's included</h4>
						</Col>
						<Col className='d-block d-lg-none text-center' md='12'>
							<ButtonGroup className='pricing-group'>
								<Button
									onClick={() => this.clickSet('one')}
									className={`pricing-group-button${highPackToggle ? '-2' : ''} ${this.classReturn2(
										'one'
									)}`}>
									One Test
								</Button>
								{prices.twopack ? (
									<Button
										onClick={() => this.clickSet('two')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('two')}`}>
										Two Pack
									</Button>
								) : null}
								{prices.threepack ? (
									<Button
										onClick={() => this.clickSet('three')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('three')}`}>
										Three Pack
									</Button>
								) : null}
								{prices.fourpack ? (
									<Button
										onClick={() => this.clickSet('four')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('four')}`}>
										Four Pack
									</Button>
								) : null}
								{prices.sixpack ? (
									<Button
										onClick={() => this.clickSet('six')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('six')}`}>
										Six Pack
									</Button>
								) : null}
								{prices.eightpack ? (
									<Button
										onClick={() => this.clickSet('eight')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('eight')}`}>
										Eight Pack
									</Button>
								) : null}
								{prices.twelvepack ? (
									<Button
										onClick={() => this.clickSet('twelve')}
										className={`pricing-group-button${
											highPackToggle ? '-2' : ''
										} ${this.classReturn2('twelve')}`}>
										Twelve Pack
									</Button>
								) : null}
							</ButtonGroup>
						</Col>

						<Col className='d-block d-lg-none text-center box-shadow'>{this.renderComponent()}</Col>

						<Col className='d-none d-lg-block' lg='12' xl='11'>
							<Row>
								<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
									<h4 className='plan-titles'>One Test</h4>
									<div
										onClick={() => this.clickSet('one')}
										className={`pricing-div ${this.classReturn2('one')}`}>
										<h4 className='prices'>{prices.bone}</h4>
										<h5 className='price-description'>ONE Bone Density Scan</h5>
									</div>

									<h5 className='included-title'>Bone Density Testing</h5>
									<h6 className='included-items'>ONE Bone Density Scan</h6>

									<h5 className='included-title'>DexaFit ai</h5>
									<h6 className='included-items'>
										All testing results and health data on your iOS or Android phone
									</h6>
									<h6 className='included-items'>Apple Health and Google Fit integration</h6>
									<h6 className='included-items'>
										AI-powered personal health and fitness insights
									</h6>
									<h6 className='included-items'>Longevity Score & Body Score</h6>
									<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
									<h6 className='included-items'>Leaderboards and more!</h6>

									<Button
										className={`get-started-button-white align-self-end ${this.classReturn2(
											'one'
										)}`}
										onClick={(e) => this.handlePageChange('book4')}
										color='primary'>
										Get Started
									</Button>
								</Col>
								{prices.twopack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Two Pack</h4>
										<div
											onClick={() => this.clickSet('two')}
											className={`pricing-div ${this.classReturn2('two')}`}>
											<h4 className='prices'>{prices.twopack}</h4>
											<h5 className='price-description'>TWO Bone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											TWO Bone Density Scans (${Math.round((prices.twopack / 2) * 100) / 100} per
											test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'two'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.threepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Three Pack</h4>
										<div
											onClick={() => this.clickSet('three')}
											className={`pricing-div ${this.classReturn2('three')}`}>
											<h4 className='prices'>{prices.threepack}</h4>
											<h5 className='price-description'>THREE Bone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											THREE Bone Density Scans (${Math.round((prices.threepack / 3) * 100) / 100}{' '}
											per test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'three'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.fourpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Four Pack</h4>
										<div
											onClick={() => this.clickSet('four')}
											className={`pricing-div ${this.classReturn2('four')}`}>
											<h4 className='prices'>{prices.fourpack}</h4>
											<h5 className='price-description'>FOURBone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											FOUR Bone Density Scans (${Math.round((prices.fourpack / 4) * 100) / 100} per
											test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'four'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.sixpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Six Pack</h4>
										<div
											onClick={() => this.clickSet('six')}
											className={`pricing-div ${this.classReturn2('six')}`}>
											<h4 className='prices'>{prices.sixpack}</h4>
											<h5 className='price-description'>SIX Bone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											SIX Bone Density Scans (${Math.round((prices.sixpack / 6) * 100) / 100} per
											test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'six'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.eightpack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Eight Pack</h4>
										<div
											onClick={() => this.clickSet('eight')}
											className={`pricing-div ${this.classReturn2('eight')}`}>
											<h4 className='prices'>{prices.eightpack}</h4>
											<h5 className='price-description'>EIGHT Bone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											EIGHT Bone Density Scans (${Math.round((prices.eightpack / 8) * 100) / 100}{' '}
											per test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'eight'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
								{prices.twelvepack ? (
									<Col className='plan-spacer d-flex flex-column' lg={ColSize} xl={ColSize}>
										<h4 className='plan-titles'>Twelve Pack</h4>
										<div
											onClick={() => this.clickSet('twelve')}
											className={`pricing-div ${this.classReturn2('twelve')}`}>
											<h4 className='prices'>{prices.twelvepack}</h4>
											<h5 className='price-description'>TWELVE Bone Density Scan</h5>
										</div>

										<h5 className='included-title'>Bone Density Testing</h5>
										<h6 className='included-items'>
											TWELVE Bone Density Scans (${Math.round((prices.twelvepack / 12) * 100) / 100}{' '}
											per test)
										</h6>

										<h5 className='included-title'>DexaFit ai</h5>
										<h6 className='included-items'>
											All testing results and health data on your iOS or Android phone
										</h6>
										<h6 className='included-items'>Apple Health and Google Fit integration</h6>
										<h6 className='included-items'>
											AI-powered personal health and fitness insights
										</h6>
										<h6 className='included-items'>Longevity Score & Body Score</h6>
										<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
										<h6 className='included-items'>Leaderboards and more!</h6>

										<Button
											className={`get-started-button-white align-self-end ${this.classReturn2(
												'twelve'
											)}`}
											onClick={(e) => this.handlePageChange('book4')}
											color='primary'>
											Get Started
										</Button>
									</Col>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			case 'group':
				return (
					<Row>
						<Button className='group-spacer' onClick={(e) => this.handlePageChange('book1')}>
							Call your location to find out about group options!
						</Button>
					</Row>
				);
			case 'item':
				switch (this.state.currentItem) {
					case '1':
						return (
							<Store1
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={one}
								reset={this.resetPageLab}
							/>
						);
					case '2':
						return (
							<Store2
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={two}
								reset={this.resetPageLab}
							/>
						);
					case '3':
						return (
							<Store3
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={three}
								reset={this.resetPageLab}
							/>
						);
					case '4':
						return (
							<Store4
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={four}
								reset={this.resetPageLab}
							/>
						);
					case '5':
						return (
							<Store5
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={fifteen}
								reset={this.resetPageLab}
							/>
						);
					case '6':
						return (
							<Store6
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={seven}
								reset={this.resetPageLab}
							/>
						);
					case '7':
						return (
							<Store7
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={six}
								reset={this.resetPageLab}
							/>
						);
					case '8':
						return (
							<Store8
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={eight}
								reset={this.resetPageLab}
							/>
						);
					case '9':
						return (
							<Store9
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={Care}
								reset={this.resetPageLab}
							/>
						);
					case '10':
						return (
							<Store10
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={nine}
								reset={this.resetPageLab}
							/>
						);
					case '11':
						return (
							<Store11
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={fourteen}
								reset={this.resetPageLab}
							/>
						);
					case '12':
						return (
							<Store12
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={ten}
								reset={this.resetPageLab}
							/>
						);
					case '13':
						return (
							<Store13
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={eleven}
								reset={this.resetPageLab}
							/>
						);
					case '14':
						return (
							<Store14
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={twelve}
								reset={this.resetPageLab}
							/>
						);
					case '15':
						return (
							<Store15
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={Joel}
								reset={this.resetPageLab}
							/>
						);
					case '16':
						return (
							<Store16
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={thirteen}
								reset={this.resetPageLab}
							/>
						);
					case '17':
						return (
							<Store17
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={seventeen}
								reset={this.resetPageLab}
							/>
						);
					case '18':
						return (
							<Store18
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={twenty}
								reset={this.resetPageLab}
							/>
						);
					case '19':
						return (
							<Store19
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={eighteen}
								reset={this.resetPageLab}
							/>
						);
					case '20':
						return (
							<Store20
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={Alene}
								reset={this.resetPageLab}
							/>
						);
					case '21':
						return (
							<Store21
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={Karla}
								reset={this.resetPageLab}
							/>
						);
					case '22':
						return (
							<Store22
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={Laura}
								reset={this.resetPageLab}
							/>
						);
					case '23':
						return (
							<Store23
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={sixteen}
								reset={this.resetPageLab}
							/>
						);
					case '24':
						return (
							<Store24
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={seventeen}
								reset={this.resetPageLab}
							/>
						);
					case '25':
						return (
							<Store25
								scroll={this.props.scroll}
								addCart={this.addCart}
								img={twentyfive}
								reset={this.resetPageLab}
							/>
						);
					default:
						break;
				}
				break;
			default:
				break;
		}
	}
	clickSet(value) {
		switch (value) {
			case 'free':
				this.setState({
					displayedComponent: value,
					free: true,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case '99':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: true,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case '299':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: true,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case '499':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: true,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'one':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: true,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'two':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: true,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'three':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: true,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case '3dpack':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: true,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'four':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: true,
					$six: false,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'six':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: true,
					$eight: false,
					$twelve: false,
				});
				break;
			case 'eight':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: true,
					$twelve: false,
				});
				break;
			case 'twelve':
				this.setState({
					displayedComponent: value,
					free: false,
					$99: false,
					$299: false,
					$499: false,
					$one: false,
					$two: false,
					$three: false,
					$3dpack: false,
					$four: false,
					$six: false,
					$eight: false,
					$twelve: true,
				});
				break;
			default:
		}
	}
	classReturn2 = (statecheck) => {
		switch (statecheck) {
			case 'free':
				if (this.state.free === true) {
					return 'on';
				} else {
					return '';
				}
			case '99':
				if (this.state.$99 === true) {
					return 'on';
				} else {
					return '';
				}
			case '299':
				if (this.state.$299 === true) {
					return 'on';
				} else {
					return '';
				}
			case '499':
				if (this.state.$499 === true) {
					return 'on';
				} else {
					return '';
				}
			case 'one':
				if (this.state.$one === true) {
					return 'on';
				} else {
					return '';
				}
			case 'two':
				if (this.state.$two === true) {
					return 'on';
				} else {
					return '';
				}
			case 'three':
				if (this.state.$three === true) {
					return 'on';
				} else {
					return '';
				}
			case '3dpack':
				if (this.state.$3dpack === true) {
					return 'on';
				} else {
					return '';
				}
			case 'four':
				if (this.state.$four === true) {
					return 'on';
				} else {
					return '';
				}
			case 'six':
				if (this.state.$six === true) {
					return 'on';
				} else {
					return '';
				}
			case 'eight':
				if (this.state.$eight === true) {
					return 'on';
				} else {
					return '';
				}
			case 'twelve':
				if (this.state.$twelve === true) {
					return 'on';
				} else {
					return '';
				}
			default:
		}
	};

	handlePageChange = (page) => {
		this.props.scrollbutton1(page);
	};

	newLineText = (text) => {
		return text.split('\n').map((str) => (
			<div key={str} className='location-comment'>
				{str}
			</div>
		));
	};

	render() {
		return (
			<Row id='off-blue'>
				<Col xl='12' id='off-full-blue'></Col>
				<Col xs='11' sm='11' md='11' id='pricing-card'>
					<Row id='title-space'>
						<Col id='padding-remover' xl='8'>
							<h2 className='big-label-2'>Choose Your Plan</h2>
							<ButtonGroup className='plan-group'>
								<Button
									onClick={(e) => {
										this.setState({
											page: 'plan',
											displayedComponent: '299',
											free: false,
											$99: false,
											$299: true,
											$499: false,
											$one: false,
											$two: false,
											$three: false,
											$3dpack: false,
											$four: false,
											$six: false,
											$eight: false,
											$twelve: false,
											currentItem: '',
										});
									}}
									className='plan-group-button'>
									<span className={`${this.state.page === 'plan' ? 'target' : 'not-on-button'}`}>
										Premium Plans
									</span>
								</Button>
								{this.props.prices.dxa ? (
									<Button
										onClick={(e) => {
											this.setState({
												page: 'DXA',
												displayedComponent: 'one',
												free: false,
												$99: false,
												$299: false,
												$499: false,
												$one: true,
												$two: false,
												$three: false,
												$3dpack: false,
												$four: false,
												$six: false,
												$eight: false,
												$twelve: false,
												currentItem: '',
											});
										}}
										className='plan-group-button'>
										<span className={`${this.state.page === 'DXA' ? 'target' : 'not-on-button'}`}>
											DXA
										</span>
									</Button>
								) : null}
								{this.props.prices.bone ? (
									<Button
										onClick={(e) => {
											this.setState({
												page: 'Bone',
												displayedComponent: 'one',
												free: false,
												$99: false,
												$299: false,
												$499: false,
												$one: true,
												$two: false,
												$three: false,
												$3dpack: false,
												$four: false,
												$six: false,
												$eight: false,
												$twelve: false,
												currentItem: '',
											});
										}}
										className='plan-group-button'>
										<span className={`${this.state.page === 'Bone' ? 'target' : 'not-on-button'}`}>
											Bone Density
										</span>
									</Button>
								) : null}
								{this.props.prices.vo2 ? (
									<Button
										onClick={(e) => {
											this.setState({
												page: 'Vo2max',
												displayedComponent: 'one',
												free: false,
												$99: false,
												$299: false,
												$499: false,
												$one: true,
												$two: false,
												$three: false,
												$3dpack: false,
												$four: false,
												$six: false,
												$eight: false,
												$twelve: false,
												currentItem: '',
											});
										}}
										className='plan-group-button'>
										<span
											className={`${this.state.page === 'Vo2max' ? 'target' : 'not-on-button'}`}>
											Vo2max
										</span>
									</Button>
								) : null}
								{this.props.prices.rmr ? (
									<Button
										onClick={(e) => {
											this.setState({
												page: 'RMR',
												displayedComponent: 'one',
												free: false,
												$99: false,
												$299: false,
												$499: false,
												$one: true,
												$two: false,
												$three: false,
												$3dpack: false,
												$four: false,
												$six: false,
												$eight: false,
												$twelve: false,
												currentItem: '',
											});
										}}
										className='plan-group-button'>
										<span className={`${this.state.page === 'RMR' ? 'target' : 'not-on-button'}`}>
											RMR
										</span>
									</Button>
								) : null}
								{this.props.prices.threeD ? (
									<Button
										onClick={(e) => {
											this.setState({
												page: '3D Scan',
												displayedComponent: 'one',
												free: false,
												$99: false,
												$299: false,
												$499: false,
												$one: true,
												$two: false,
												$three: false,
												$3dpack: false,
												$four: false,
												$six: false,
												$eight: false,
												$twelve: false,
												currentItem: '',
											});
										}}
										className='plan-group-button'>
										<span
											className={`${this.state.page === '3D Scan' ? 'target' : 'not-on-button'}`}>
											3D Scan
										</span>
									</Button>
								) : null}
								<Button
									onClick={(e) => {
										this.setState({ page: 'Lab Testing', currentItem: '' });
									}}
									className='plan-group-button'>
									<span
										className={`${this.state.page === 'Lab Testing' ? 'target' : 'not-on-button'}`}>
										Lab Testing
									</span>
								</Button>
								<Button
									onClick={(e) => {
										this.setState({ page: 'group', currentItem: '' });
									}}
									className='plan-group-button'>
									<span className={`${this.state.page === 'group' ? 'target' : 'not-on-button'}`}>
										Group
									</span>
								</Button>
							</ButtonGroup>
						</Col>
						<Col xl='4'>
							<p id='side-border' className='small-label-4'>
								Choose a premium plan and save 50% off our regular pricing.
							</p>
						</Col>
					</Row>
					{this.renderPage()}
					{this.props.prices.comment ? (
						<Row>
							<Col className='text-center' md='12'>
								<div className='location-comment-box'>
									<div className='location-comment-title'>Location Specific Deals:</div>
									<br />
									{this.newLineText(this.props.prices.comment)}
								</div>
							</Col>
						</Row>
					) : null}
				</Col>
			</Row>
		);
	}
}
