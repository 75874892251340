import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Phone from '../../assets/img/tablet-phone.png';
import Tablet from '../../assets/img/tablet-analytics.png';
import Insights from '../../assets/img/insights-1.png';
import Connection from '../../assets/img/Icon-connection.png';
import Clock from '../../assets/img/Icon-clock.png';
import Graph from '../../assets/img/Icon-graph.png';
import '../BusinessHome.css';

export default class Business3 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row className='off-color-2'>
				<Col>
					<Row>
						<Col>
							<h4 className='Business-slide-3-top-text-1'>Featured Apps</h4>
							<p className='Business-slide-3-top-text-2'>
								Integrate all of the health data you need to make smarter decisions and achieve
								healthier outcomes.
							</p>
						</Col>
					</Row>
					<Row>
						<Col className='Business-slide-3-left-spacing' lg='7'>
							<Row>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-3-left-img-holder' xs='3'>
											<img
												className='Business-slide-3-left-img'
												src={Insights}
												alt='a cartoon head with electronic lines coming out the back'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-3-left-text-top'>Operator ai</div>
											<div className='Business-slide-3-left-text-bottom'>
												Lead generation, scheduling, payments, and analytics
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-3-left-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-3-left-img-holder' xs='3'>
											<img
												className='Business-slide-3-left-img'
												src={Graph}
												alt='3 cartoon people infront of a graph'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-3-left-text-top'>Teams ai</div>
											<div className='Business-slide-3-left-text-bottom'>
												AI analytics and insights for sports, research, and more
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-3-left-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-3-left-img-holder' xs='3'>
											<img
												className='Business-slide-3-left-img'
												src={Connection}
												alt='a cartoon person with lines and dots coming from them'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-3-left-text-top'>Pros ai</div>
											<div className='Business-slide-3-left-text-bottom'>
												Tools and outreach for health and wellness professionals
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-3-left-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-3-left-img-holder' xs='3'>
											<img
												className='Business-slide-3-left-img'
												src={Clock}
												alt='a cartoon clock'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-3-left-text-top'>Life ai</div>
											<div className='Business-slide-3-left-text-bottom'>
												Better models to predict chronic disease and longevity
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-3-left-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col lg='5'>
							<Fade right>
								<img
									className='Business-slide-3-phone'
									src={Phone}
									alt='Body score screen of the dexafit app'
								/>
								<img
									className='Business-slide-3-tablet'
									src={Tablet}
									alt='Body score screen of the dexafit app on the tablet'
								/>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
