import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store11 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Microbiome Collection Kit Only',
			price: 149.99,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>Microbiome Collection Kit Only</div>
							<br />
							<br />
							<div>$149.99</div>
							<strike>$250.00</strike> <br />
							<br />
							<strong>Full Microbiome Analysis</strong> <br />
							<br />
							<div>
								Say hi to your 39 trillion bacteria and track your progress to learn about your
								personal gut health. Gutbuster™ technology allows for the interpretation of the
								trillions of microbes inside your gut. This analysis is available in digital format
								on your online Customer Portal.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>Why analyze your microbiome using Flore?</div>
					<br />
					<br />
					<div>
						Your body is full of trillions of bacteria, viruses, and fungi. They are collectively
						known as the microbic. Over the last several years, the microbiome has been linked to
						numerous diseases and conditions that may have a profound impact on overall health and
						wellness.
						<br />
						<br />
						Our patented methodology using whole genome sequencing allows you to dive deeper into
						your microbiome, going beyond simple genus calls allowing you to uncover viruses,
						phages, fungi, etc… The Flore Microbiome Analysis allows you to discover exactly what
						microbes you have living in your gut. The Analysis includes:
					</div>
					<br />
					<br />
					<ol>
						<li>Microbiome Score (how healthy is your microbiome out of 100)</li>
						<br />
						<li>Longitudinal tracking based on the number of timepoints</li>
						<br />
						<li>Comments written by one of our Ph.D. scientists</li>
						<br />
						<li>Scientific reference information</li>
						<br />
						<li>
							Percent breakdown of your gut profile i.e. probiotics, neutral, positive, variable
						</li>
						<br />
						<li>Health and Diet recommendations on a macronutrient level</li>
						<br />
						<li>
							Breakdown of microbiome profile by Top 10, Probiotics, Neutral, Variable, Beneficial
							and all microbes
						</li>
						<br />
						<li>
							Breakdown of microbiome profile includes: abundance data, comparison to other Sun
							Genomics customers
						</li>
						<br />
						<li>Microbe definitions and accompanying reference information</li>
						<br />
					</ol>
					<div>
						More than that, Sun Genomics provides the “action” on top of the “information”.{' '}
					</div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<div className='product-title text-center'>How it works?</div>
							<br />
							<br />
							<strong>1. Sample Your Microbiomes</strong>
							<br />
							<div>
								Provide a stool sample using the at-home Microbiome Collection Kit and ship your
								sample to us in the prepaid envelope.
							</div>
							<br />
							<br />
							<strong>2. DNA Tested and Analyzed</strong>
							<br />
							<div>
								Using our patented mythology, our scientists extract the DNA from your sample,
								sequence, and interpret the data key to creating the right probiotic formula for you
								and your gut..
							</div>
							<br />
							<br />
							<strong>3. Receive Your Report </strong>
							<br />
							<div>
								Receive a full microbiome analysis report, available in digital format on the Sun
								Genomics’ Customer Portal.
							</div>
							<br />
						</Col>
						<Col md='6'>
							<div className='product-title text-center'>What’s Included?</div>
							<br />
							<br />
							<strong>Microbiome Collection Kit</strong>
							<br />
							<div>
								Includes both snapshot and long-term sampling methods allowing for better analysis
								of your gut microbiome.{' '}
							</div>
							<br />
							<br />
							<strong>Microbiome Profile Analysis</strong>
							<br />
							<div>
								What’s in your microbiome? How does your microbiome compare to others? What your
								microbiome data means and how to understand it.
							</div>
							<br />
							<br />
							<strong>Scientific Phone Consultation & Survey</strong>
							<br />
							<div>
								Chat with scientist about your health objective and how your microbiome impacts your
								overall health and wellness.
							</div>
							<br />
						</Col>
					</Row>
					<br />
					<br />
					<div className='product-title text-center'>Highlights</div>
					<br />
					<br />
					<ul>
						<li>
							Analysis can identify over 23,000 different species of bacteria including: Escherichia
							coli (e-coli); Clostridioides duffle (c-diff): Salmonella enterica
						</li>
						<br />
						<li>Proven Gastric Survivability</li>
						<br />
						<li>DNA Verified</li>
						<br />
						<li>Cold-shipped</li>
						<br />
						<li>Lab fee included</li>
						<br />
					</ul>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
