import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../../Pricing.css';

export default class CardFree extends Component {
	render() {
		return (
			<div>
				<h2 className='small-card-title'>Free</h2>
				<h3 className='flavor-text'>Get started for free</h3>
				<h4 id='prices-free' className='prices'>
					Free
				</h4>
				<div className='small-card-wraper'>
					<h5 className='included-title'>Fit by DexaFit App</h5>
					<h6 className='included-items'>All testing results and health data on your phone</h6>
					<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
					<h6 className='included-items'>AI-powered personal health and fitness insights</h6>
					<h6 className='included-items'>Longevity Score & Body Score</h6>
					<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
					<h6 className='included-items'>Leaderboards and more!</h6>
				</div>

				<Button
					id='selected-button'
					className='get-started-button-white align-self-end'
					color='primary'>
					Get Started
				</Button>
			</div>
		);
	}
}
