import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Mar112020 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Are Your Hormones Impacting Your Sleep?</div>
						<br />
						<div className='blog-date-page'>MAR 11, 2020</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							You stopped drinking caffeine past 1pm. <br />
							<br />
							You invested in blue light blockers.
							<br />
							<br />
							Yet, you still can’t seem to get a good nights sleep
							<br />
							<br />
							Not only do your hormones affect your mood, metabolic function, production, and more,
							they also play a huge role in{' '}
							<a href='https://www.sciencealert.com/chemical-messengers-how-hormones-help-us-sleep'>
								the amount of sleep
							</a>{' '}
							you manage to get each night. <br />
							<br />
							Balancing your hormones is vital to getting the sleep your body needs each night, but
							that’s much easier said than done. So, what can you do? <br />
							<br />
							First, let’s take a look at how your hormones impact sleep. <br />
							<br />
							<strong>Hormones that Impact Sleep</strong>
							<br />
							<br />
							The human body secretes and circulates approximately 50 different hormones, most of
							which are produced by the endocrine system. The endocrine system includes a series of
							glands that produce and secrete your hormones. And just like there are many different
							types of hormones, there are also different glands that secrete them. Each gland
							produces a different hormone, likewise, each hormone contributes to a different
							function.
							<br />
							<br />
							How does this relate to sleep? Specific hormones, like cortisol and melatonin, affect
							your body in specific ways that affect your ability to sleep. Whether it be a feeling
							of stress, anxiety, anger, or sexual stimulation, there are a{' '}
							<a href='https://www.health.harvard.edu/sleep/8-reasons-why-youre-not-sleeping'>
								host of reasons{' '}
							</a>{' '}
							as to why your hormones can prevent you from nodding off. <br />
							<br />
							Here are the five key hormones associated with sleep: <br />
							<br />
							<ul>
								<li>
									<strong>Oestrogen </strong>- Largely associated with women’s reproductive health,
									oestrogen plays a key role in the menstrual cycle and your body’s ability to
									utilise serotonin – an important feel-good neurotransmitter. Levels of this
									hormone fluctuate wildly throughout your life and, at lower levels, can cause a
									wide range of sleep-related symptoms.
								</li>
								<li>
									<strong>Progesterone </strong>- Better known as the female sex hormone,
									progesterone is one of the{' '}
									<a href='https://thesleepdoctor.com/2018/01/05/menopause-affects-sleep/'>
										{' '}
										most linked
									</a>{' '}
									hormones to sleep quality. At higher concentrations, progesterone provides an
									anti-anxiety effect, enabling you to fall asleep more quickly. However, at lower
									concentrations, mood swings and cramping are two commonly encountered symptoms.{' '}
								</li>
								<li>
									<strong>Testosterone </strong>- Levels of testosterone don’t fluctuate in the same
									sporadic way as oestrogen or progesterone. Instead, testosterone levels often
									decline gradually over a longer period of time, meaning there are much lower
									concentrations in older people. These lower levels are often linked to a poorer
									quality of sleep and can even lead to insomnia.{' '}
								</li>
								<li>
									<strong>Insulin </strong>- This hormone controls your blood glucose levels. Sleep
									deprivation can raise your blood sugar level and encourage unhealthy late-night
									eating habits. This, in turn, triggers an insulin release, causing blood glucose
									levels to fluctuate throughout the night. When the level of blood glucose is
									higher, there is a greater chance that your night’s sleep will be a lot more
									restless than normal.
								</li>
								<li>
									<strong>Cortisol </strong>- Known as the stress hormone, is a steroid made in the
									adrenal glands. It is responsible for controlling blood sugar levels, regulating
									metabolism, reducing inflammation improve memory. But when cortisol gets too high,
									it can result in high blood pressure, mood swings, anxiety, depression, rapid
									weight gain, and you guessed it,{' '}
									<a href='https://www.naturalmedicinejournal.com/journal/2010-06/role-cortisol-sleep'>
										disrupted sleep.
									</a>
								</li>
								<li>
									<strong>Melatonin </strong>- The main role of melatonin is to regulate sleep
									cycles. Exposure to light stimulates a nerve pathway in the to the part of the
									brain that controls hormone production, called the suprachiasmatic nucleus (SCN).
									When exposed to light, the SCN begins releasing stimulating hormones like
									cortisol, and suppressing hormones like melatonin. Meanwhile, when darkness
									arrives, the SCN signals to start{' '}
									<a href='https://www.sleepfoundation.org/articles/melatonin-and-sleep'>
										{' '}
										producing more melatonin
									</a>{' '}
									and suppressing cortisol. Too much stress during the day can raise cortisol
									levels, while binging on Netflix or playing on your smartphone before bed can
									suppress melatonin.{' '}
								</li>
							</ul>
							<strong>Control Your Hormones</strong>
							<br />
							<br />
							Now that you have a better understanding of the hormones that affect your sleep, it’s
							time to use this information and get a good nights rest. <br />
							<br />
							Here are some thing you can do to help balance your hormones: <br />
							<br />
							<strong>Reduce using screens before bed.</strong> Watching the TV or using your phone
							before bed can suppress your melatonin level, which can significantly affect your
							ability to fall asleep. Instead, try reading a book or playing music.
							<br />
							<br />
							<strong>Destress.</strong> It may sound fairly obvious but doing{' '}
							<a href='https://www.sleep.org/articles/sleep-better-when-stressed/'>
								{' '}
								things to destress
							</a>
							yourself before bed can make a big difference to your quality of sleep. Whether it be
							meditation, ASMR, yoga, massage, sport, candles, lavender oils, or knitting – whatever
							makes you feel nice and relaxed, make the most of it and you’ll be asleep in no time.{' '}
							<br />
							<br />
							<strong>Set a routine.</strong> The human body loves a set routine that follows their
							<a href='https://www.hormone.org/your-health-and-hormones/sleep-and-circadian-rhythm'>
								{' '}
								circadian rhythm.
							</a>{' '}
							Try to always fall asleep and wake up at the same time each day. While it may be
							tempting to sleep in, oversleeping can actually make you feel a lot worse in the long
							run and could cause arguably as many problems as not getting enough sleep. <br />
							<br />
							<strong>Watch when you eat.</strong> Eating before bed isn’t a good idea, since it
							raises both your insulin and cortisol level. If you do eat before bed and manage to
							get to sleep, deep REM sleep will be more difficult to come by, and the quality and
							duration of your sleep will suffer.
							<br />
							<br />
							Understanding the hormones that affect your sleep is the first step in getting in
							touch with your body and make the changes necessary to get quality sleep. Take the
							time to get to know your body’s natural rhythms and develop a sleeping routine that
							supports balanced sleep cycles. Once you do, you’ll be able to rest easy.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
