import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec202015 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Types of Body Fat and the Dangers of Visceral Fat</div>
						<br />
						<div className='blog-date-page'>DEC 20, 2015</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							<strong className='text-center'>Why all body fat is NOT created equal...</strong>
							<br />
							<br />
							Anyone trying to lose weight knows it's all about burning off the unhealthy excess
							fat. But as it turns out, not all fat is created equal—and not all people who need to
							lose fat even know about it.
							<br />
							<br />
							When your <a href='/Service'>body composition</a> is tested using a{' '}
							<a href='/Service'> DXA scan</a>, you’re not only guaranteed one of the most accurate
							measures of body fat available, you gain valuable information that can be applied to
							improving your overall health and optimizing training and nutrition plans.
							<br />
							<br />
							This has been established by researchers, medicine, and sports performance labs around
							the world. But its accuracy is not the main reason for DXA’s adoption by professionals
							as the gold standard.
							<br />
							<br />
							Discovering exactly how much of your body mass is made up of fat—and where that fat is
							stored on your body—is one of the main reasons. It’s the complete picture of your body
							composition distribution (including lean mass, fat mass, and bone mass) that gets the
							medicine and research geeks so jazzed.
							<br />
							<br />
							This information empowers you to take the necessary steps to avoid many of the
							diseases plaguing so many of us today.
							<br />
							<br />
							This knowledge of your body’s specific fat distribution tips you off to potentially
							serious health challenges in your future. To illustrate this point better, let’s
							examine two common body shapes found on the street today: the “apple” and the “pear.”
							<br />
							<br />
							First up, Apple Shapes:
							<br />
							<br />
							Android fat, the leading cause of ‘apple bellies’ and popped pant buttons, is the fat
							stored in the midsection of the body: predominantly in the abdomen, but also the fat
							stored in the chest and upper arms.
							<br />
							<br />
							This is a common fat-storage location among men and is associated with an “apple
							shape.” It’s consistently associated with increased risk of heart disease and
							diabetes, hormonal imbalances, some cancers, sleep apnea, and more.
							<br />
							<br />
							Why is this?
							<br />
							<br />
							Because of the nature of the fat in this region of your body. It’s your visceral fat.
							<br />
							<br />
							Some are surprised to learn body fat comes in different varieties…
							<br />
							<br />
							Subcutaneous fat: The noticeable layer of fat just below our skin. The kind that
							jiggles, dimples, causes cellulite, and preferred fat of mosquitoes, no-seeums, and
							horse flies.
							<br />
							<br />
							Visceral fat: Found deeper inside our abs, under our six packs and around our organs
							like the liver, pancreas, and intestines, our visceral fat is linked to all those
							diseases listed above.
							<br />
							<br />
							The danger of visceral fat is related to the release of proteins and hormones that
							trigger inflammation in our bodies, which in turn damages arteries, invades our
							organs, and affects all the vital processes they carry out every minute of the day.
							<br />
							<br />
							So yes. This is a region we want to track regularly and make sure we’re giving it some
							love and keeping it happy.
							<br />
							<br />
							Another important marker measured by DXA is Gynoid fat:
							<br />
							<br />
							Gynoid fat storage (i.e. the “pear shape”) is more common among women. This fat is
							stored primarily around the hips and thighs, and its storage is considered
							healthier—although some men with gynoid shapes may have hormonal imbalances.
							<br />
							<br />
							Overweight pear-shaped people tend to suffer from mechanical problems such as hip,
							knee and other joint issues due to an excess of fat (weight stress) on the lower half
							of their body.
							<br />
							<br />
							There is also a third type of body shape, the Ovoid. It’s less known by folks, and
							there is no differentiation between men and women. Its fat storage promotes an ‘egg
							shape’ and means general fat coverage.
							<br />
							<br />
							Too much fat anywhere is not good, so knowing your body’s distribution is critical to
							monitoring your health risks and avoiding disease later in life.
							<br />
							<br />
							Since a DXA scan directly measures the different types of fat stored in every region
							of your body, we will know how to take action that limits and loses your fat, and
							reduces your risk for disease.
							<br />
							<br />
							Determining the ratio of android to gynoid fat (the A/G ratio)—and monitoring that
							ratio (you want it below 1.0 btw)—is critical because it’s directly correlated to the
							prevalence of visceral fat.
							<br />
							<br />
							And remember, this fat storage is a result of organs insulating themselves from toxins
							by encapsulating the toxins in fat. Ironically, it’s a survival strategy that may
							impede organ function and ultimately increase your risk for disease. Ideally your
							android fat will always be lower than your gynoid fat.
							<br />
							<br />
							If you don’t have access to DXA or a nearby DexaFit, a common way to measure the A/G
							ratio is using the waist-to-hip ratio where the waist measurement is divided by the
							hip measurement.
							<br />
							<br />
							However, this method won’t tell the whole story, since it’s limited to ‘topical’
							measurements only and can’t take a look ‘under the hood’ so to speak.
							<br />
							<br />
							Men, specifically, may be carrying more than the ‘usual’ amount of fat on the hips,
							thus making them appear OK using the waist-hip ratio; yet they may be carrying more
							than a healthy amount of fat on the abdomen.
							<br />
							<br />
							A DXA scan eliminates this chance for error by directly measuring your android and
							gynoid fat percentages and displaying the A/G ratio on your report. This way, it’s
							easy for you and your doctor to evaluate your risk for health problems associated with
							a high A/G ratio.
							<br />
							<br />
							What’s even more powerful now is the direct measurement of your visceral fat, using
							the VAT software available at all DexaFit locations.
							<br />
							<br />
							Not only do you get the Android and Gynoid measurements, you have a straightforward
							number and overview of how much fat you store in this vital chamber of health in your
							body!
							<br />
							<br />
							The factors that influence fat distribution are partially controllable by you, and
							even the genetic factors can be mitigated to some extent. It’s important to remember
							that fat distribution is influenced by hormones; genetics; race; presence of toxins;
							physical activity (type and amount); malnutrition; and stress.
							<br />
							<br />
							Of these, you have complete control over the last four—toxins, physical activity,
							nutrition and stress management.
							<br />
							<br />
							If you possess the relevant information; if you know where you want to go; and if you
							command the knowledge of the means to get there; the problem which remains is one of
							execution.
							<br />
							<br />
							But knowledge in the realm of fitness, nutrition, and health never exists in
							concentrated or integrated forms but as dispersed bits of incomplete and frequently
							subjective knowledge (a.k.a., educated guessing).
							<br />
							<br />
							The problem of body transformations in particular is not merely deciding what to eat
							and how to workout; it’s a problem of how to secure the best use of information that
							provides a framework of knowledge you can use to provide honest and outcome-based
							feedback while following a plan.
							<br />
							<br />
							Time is the scarcest of all resources; don’t waste it by failing to assess if your
							program actually delivers the results you want!
							<br />
							<br />
							A DexaFit DXA scan empowers you with accurate and outcome-based feedback. It helps you
							discover where you store fat, so you can use the information along with other markers
							to tailor a training and nutrition program that’s appropriate for your body type,
							physiology, and goals.
							<br />
							<br />
							If you want more help or support along your fitness path, we’re always here with a
							map, compass, and supplies to help you get to your destination sooner.
							<br />
							<br />
							Now all you need to do is decide where you want to go; figure out the means to get
							there; and, finally, execute!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
