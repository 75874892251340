import React, { Component } from 'react';
import { Row } from 'reactstrap';
import DexaNav from './IconNav/DexaNav';
import Vo2Nav from './IconNav/Vo2Nav';
import RmrNav from './IconNav/RmrNav';
import ThreeNav from './IconNav/ThreeNav';
import LabNav from './IconNav/LabNav';
import FitNav from './IconNav/FitNav';
import './Service.css';

export default class Service1 extends Component {
	state = {
		dexa: false,
		vo2: false,
		rmr: false,
		threed: false,
		lab: false,
		fit: true,
	};

	componentDidMount() {
		this.stateHandler();
	}

	componentDidUpdate(prevProps, prevState) {
		this.stateHandler(prevProps, prevState);
	}
	stateHandler(prevProps) {
		if (prevProps !== this.props) {
			switch (this.props.statePass2.page) {
				case 'dexa':
					this.setState({
						dexa: true,
						vo2: false,
						rmr: false,
						threed: false,
						lab: false,
						fit: false,
					});
					break;
				case 'vo2':
					this.setState({
						dexa: false,
						vo2: true,
						rmr: false,
						threed: false,
						lab: false,
						fit: false,
					});
					break;
				case 'rmr':
					this.setState({
						dexa: false,
						vo2: false,
						rmr: true,
						threed: false,
						lab: false,
						fit: false,
					});
					break;
				case '3d':
					this.setState({
						dexa: false,
						vo2: false,
						rmr: false,
						threed: true,
						lab: false,
						fit: false,
					});
					break;
				case 'lab':
					this.setState({
						dexa: false,
						vo2: false,
						rmr: false,
						threed: false,
						lab: true,
						fit: false,
					});
					break;
				case 'fit':
					this.setState({
						dexa: false,
						vo2: false,
						rmr: false,
						threed: false,
						lab: false,
						fit: true,
					});
					break;
				default:
			}
		}
	}

	classReturn = (statecheck) => {
		switch (statecheck) {
			case 'dexa':
				if (this.state.dexa === true) {
					return 'on';
				} else {
					return '';
				}
			case 'vo2':
				if (this.state.vo2 === true) {
					return 'on';
				} else {
					return '';
				}
			case 'rmr':
				if (this.state.rmr === true) {
					return 'on';
				} else {
					return '';
				}
			case '3d':
				if (this.state.threed === true) {
					return 'on';
				} else {
					return '';
				}
			case 'lab':
				if (this.state.lab === true) {
					return 'on';
				} else {
					return '';
				}
			case 'fit':
				if (this.state.fit === true) {
					return 'on';
				} else {
					return '';
				}
			default:
		}
	};

	buttonClick = (value) => {
		this.props.callBack2(value);
	};

	render() {
		return (
			<div>
				<Row className={`scroll-nav-spacer ${this.props.scrollStyle}`}>
					<div className='scroll-bar'>
						<FitNav click={() => this.buttonClick('fit')} on={this.classReturn('fit')} />
						<DexaNav click={() => this.buttonClick('dexa')} on={this.classReturn('dexa')} />
						<Vo2Nav click={() => this.buttonClick('vo2')} on={this.classReturn('vo2')} />
						<RmrNav click={() => this.buttonClick('rmr')} on={this.classReturn('rmr')} />
						<ThreeNav click={() => this.buttonClick('3d')} on={this.classReturn('3d')} />
						<LabNav click={() => this.buttonClick('lab')} on={this.classReturn('lab')} />
					</div>
				</Row>
			</div>
		);
	}
}
