import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledCarousel } from 'reactstrap';
import Fade from 'react-reveal/Fade';
//import Tread from '../../assets/img/Dexafit_Treadmill.png';
import Review from '../../assets/img/Dexafit_Review.png';
import Weight from '../../assets/img/Dexafit_Weight_Loss.png';
import Scan from '../../assets/img/Dexafit_DXA_Scan.png';
import ThreeD from '../../assets/img/Dexafit_Styku_3D.png';
import Rmr from '../../assets/img/Dexafit_RMR_Test.png';
import Vo2 from '../../assets/img/Dexafit_Treadmill.png';
import '../Home.css';

export default class Home4 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		const items = [
			{
				src: Vo2,
				altText: '',
				caption: '',
			},
			{
				src: ThreeD,
				altText: '',
				caption: '',
			},
			{
				src: Rmr,
				altText: '',
				caption: '',
			},
			{
				src: Scan,
				altText: '',
				caption: '',
			},
		];
		return (
			<Row className='spacer-15'>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col md='9'>
							<Fade left>
								<h2 className='A-simpler-way-to-ach'>
									Take a smarter approach to achieving your goals.
								</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' className='d-none picture-switch-on'>
							<Fade right>
								{/* <img className='img-3 small-space' src={Tread} alt='treadmill vo2 max running' /> */}
								<UncontrolledCarousel items={items} />
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2'>
							<Fade left>
								<div className='number-cir'>1</div>
								<h5 className='steps-label'>Measure</h5>
								<p className='grey-text-2'>
									Measure your body composition, cardio fitness, metabolic health, and more with
									DexaFit.
								</p>
							</Fade>
						</Col>
						<Col className='picture-switch-off' xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade right>
								{/* <img className='img-3' src={Tread} alt='treadmill vo2 max running' /> */}
								<UncontrolledCarousel items={items} />
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade left>
								<img
									className='img-3'
									src={Review}
									alt='two people sitting down and reviewing data'
								/>
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2' id='card-2-left'>
							<Fade right>
								<div className='number-cir' id='number-cir-right'>
									2
								</div>
								<h5 className='steps-label' id='steps-label-right'>
									Insights
								</h5>
								<p className='grey-text-2'>
									Learn where you are, how you compare, and where you need to be.
								</p>
							</Fade>
						</Col>
						<Col md='12' className='d-none picture-switch-on'>
							<Fade right>
								<img
									className='img-3'
									src={Weight}
									alt='woman standing infront of mirror holding pant line and showing how it has srunk'
								/>
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2'>
							<Fade left>
								<div className='number-cir'>3</div>
								<h5 className='steps-label'>Better Decisions</h5>
								<p className='grey-text-2'>
									Get personalized insights and recommendations for ways to achieve optimal health.
								</p>
							</Fade>
						</Col>
						<Col className='picture-switch-off' xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade right>
								<img
									className='img-3'
									src={Weight}
									alt='woman standing infront of mirror holding pant line and showing how it has srunk'
								/>
							</Fade>
						</Col>
					</Row>
					<div className='center'>
						<Fade right>
							<Button
								className='GetStarted-button-2'
								onClick={(e) => this.handlePageChange('/Map')}>
								Get Started
							</Button>
						</Fade>
					</div>
				</Col>
			</Row>
		);
	}
}
