import React, { Component } from 'react';
import HOW from '../../assets/img/insights-1.png';
import '../Service.css';

export default class HowNav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img className='small-button-icons' src={HOW} alt='How icon' />
				</div>
				<h6 className='grey-label'>Business</h6>
			</div>
		);
	}
}
