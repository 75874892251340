import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import Phone from '../../assets/img/phone@3x.png';
import '../Service.css';

export default class RmR2 extends Component {
  render() {
    return (
      <Row>
        <Col
          className='spacer-1'
          sm={{ size: 10, offset: 1 }}
          md={{ size: 5, offset: 1 }}
          lg={{ size: 7, offset: 1 }}
        >
          <Row>
            <Col className='spacer-7' md='12'>
              <Fade left>
                <h2 className='big-label'>
                  Pinpoint the exact number of Calories you burn at rest
                </h2>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col md='12' lg='4'>
              <Fade left>
                <div>
                  <img className='small-icon' src={Wrench} alt='wrench icon' />
                </div>
                <h4 className='small-label-2'>Upgrade your metabolism</h4>
                <p className='grey-p'>
                  See how efficient your body is at converting food to energy
                  and using fat or sugar as fuel
                </p>
              </Fade>
            </Col>
            <Col md='12' lg='4'>
              <Fade left>
                <div>
                  <img className='small-icon' src={Target} alt='wrench icon' />
                </div>
                <h4 className='small-label-2'>Breakthrough plateaus</h4>
                <p className='grey-p'>
                  Learn if you have a slowed metabolism to understand weight
                  loss plateaus.
                </p>
              </Fade>
            </Col>
            <Col md='12' lg='4'>
              <Fade left>
                <div>
                  <img
                    className='small-icon'
                    src={Internet}
                    alt='wrench icon'
                  />
                </div>
                <h4 className='small-label-2'>Optimize your body</h4>
                <p className='grey-p'>
                  Understand how the food you eat affects your body fat, lean
                  mass, and skeletal health.
                </p>
              </Fade>
            </Col>
          </Row>
        </Col>
        <Col className='d-flex align-items-end' md='6' lg='4'>
          <Fade right>
            <img className='phone' src={Phone} alt='phone app' />
          </Fade>
        </Col>
      </Row>
    );
  }
}
