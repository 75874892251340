import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec122016 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Benefits of a Body Composition Analysis</div>
						<br />
						<div className='blog-date-page'>Dec 12, 2016</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							A BCA (
							<a href='https://naturkurwellness.com/2016/09/17/benefits-body-composition-analysis-testing/'>
								body composition analysis
							</a>
							, for anyone unfamiliar with the term) is the “process of examining the human body to
							determine the percentage of body fat compared to muscle tissue.” A BCA is capable of
							revealing how healthy an individual’s body is, and is a popular resource for trainers,
							coaches, medical professionals, and researchers alike.
							<br />
							<br />
							There are a ton of benefits to doing a BCA, now that it’s surpassed the outdated Body
							Mass Index (BMI). After all, there’s more to being healthy than losing weight—there
							are also important factors like increasing physical activity and improving your ratio
							of muscle to fat. The BCA helps people do just that.
							<br />
							<br />
							<strong>Benefits of body composition analysis</strong>
							<br />
							<br />
							After doing a BCA, you’ll be able to…
							<br />
							<br />
							<strong>
								1. Devise a precise plan for muscle gain and fat loss in specific areas
							</strong>
							<br />
							<br />
							By directly assessing your ratio of body fat and muscle, a BCA can help you customize
							your training/diet to suit your needs. As many people seeking to improve their health
							know, there may come a time when your body weight will stop decreasing, despite having
							a healthier diet and increased physical activity. This is likely because you’re
							building muscle, and muscle weighs more than fat.
							<br />
							<br />
							If you’ve been getting regular BCA checkups, this revelation won’t astound you, and it
							may even inspire you to work even harder toward a healthy body!
							<br />
							<br />
							<strong>2. Learn your risk for osteoporosis</strong>
							<br />
							<br />
							A BCA can measure bone health and determine your chances of getting osteoporosis. This
							is doable because the BCA measures body density, and someone with osteoporosis is
							likely to have a lower body density due to how fragile their bones are.
							<br />
							<br />
							<strong>3. Identify personal long-term health risks</strong>
							<br />
							<br />A BCA can reveal serious risks to your health like diabetes, cardiovascular
							disease, hormonal imbalances, etc. All of these diseases, after all, are{' '}
							<a href='https://healthyliving.azcentral.com/advantages-healthy-body-composition-17706.html'>
								affected by and possibly caused by high fat stores and low muscle mass
							</a>
							. If you think you’re at risk for any of these, it is recommended that you get a BCA
							(and see a doctor).
							<br />
							<br />
							<strong>How to measure body composition</strong>
							<br />
							<br />
							The first step to measuring your body composition is to forget the scale. The only
							thing a weight scale can tell you is how much you weigh, not how much fat you need to
							lose/keep. A BCA will be able to tell you your body fat percentage, muscle mass, fat
							mass, body water, etc.
							<br />
							<br />
							Check out our <a href='/Services'>DXA scan website</a> for more information on what
							doing a BCA looks like.
							<br />
							<br />
							<strong>How to improve body composition</strong>
							<br />
							<br />
							There are{' '}
							<a href='https://inbodyusa.com/blogs/inbodyblog/how-to-start-changing-your-body-composition-today/'>
								two different goals
							</a>{' '}
							you should pursue, depending on your BCA results. If you have a high body fat
							percentage and high overall weight, you should consider losing fat first. The key?
							Burn more calories in a day than you take in. You can also look into resistance
							training and cardiovascular exercise!
							<br />
							<br />
							If you have skinny fat (not overweight, but have low amounts of lean body mass and
							high amounts of fat mass), you may want to develop lean body mass first. Lean body
							mass is your total weight minus your fat, and the best way to improve your lean body
							mass is through a resistance training program designed to strengthen your muscles.
							<br />
							<br />
							Good luck!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
