import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../../Pricing.css';

export default class Card299 extends Component {
	render() {
		return (
			<div>
				<h2 className='small-card-title'>Starter</h2>
				<h3 className='flavor-text'>Most Popular</h3>
				<h4 className='prices'>299</h4>
				<div className='small-card-wraper'>
					<h5 className='included-title'>DXA, VO2, and/or RMR</h5>
					<h6 className='included-items'>
						4 Test Credits (save over 50% per test vs regular price)
					</h6>
					<h6 className='included-items'>Mix and match DXA, VO2, and/or RMR</h6>
					<h6 className='included-items'>Buy additional credits for only $75</h6>
					<h6 className='included-items'>Share a test credit with a friend</h6>

					<h5 className='included-title'>3D Body Scan</h5>
					<h6 className='included-items'>12 Scan Credits</h6>
					<h6 className='included-items'>HD 3D model of your body</h6>
					<h6 className='included-items'>Track Full Body circumference changes</h6>
					<h6 className='included-items'>Posture analysis</h6>

					<h5 className='included-title'>DexaFit ai</h5>
					<h6 className='included-items'>All testing results and health data on your phone</h6>
					<h6 className='included-items'>Apple Health and Google Fit Integration</h6>
					<h6 className='included-items'>AI-powered personal health and fitness insights</h6>
					<h6 className='included-items'>Longevity Score & Body Score</h6>
					<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
					<h6 className='included-items'>Leaderboards and more!</h6>
				</div>

				<Button
					id='selected-button'
					className='get-started-button-white align-self-end'
					color='primary'>
					Get Started
				</Button>
			</div>
		);
	}
}
