import React, { Component } from 'react';
import { Container } from 'reactstrap';
import HowItWorks1 from './slides/HowitWorks1';
import HowItWorks2 from './slides/HowitWorks2';
import HowItWorks3 from './slides/HowitWorks3';
import HowItWorks4 from './slides/HowitWorks4';
import HowItWorks5 from './slides/HowitWorks5';
import HowItWorks6 from './slides/HowitWorks6';
import HowItWorks7 from './slides/HowitWorks7';
import Footer from '../Footer';

export default class HowitWorks extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Container fluid>
				<HowItWorks1
					scrollStyle={this.props.scrollStyle}
					statePass2={this.props.statePass}
					callBack2={this.secondCallBack}
				/>
				<HowItWorks2 />
				<HowItWorks3 />
				<HowItWorks4 />
				<HowItWorks5 />
				<HowItWorks6 />
				<HowItWorks7 history={this.props.history} />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
