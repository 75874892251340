import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Sep172019 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>How One Simple Number Ignites the Change You Need</div>
						<br />
						<div className='blog-date-page'>SEP 17, 2019</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							Have you ever stopped and wondered how long you might live? We did.
							<br />
							<br />
							It’s why we created a solution that helps people get a leg up on optimizing their
							longevity. Aging affects us all, and we all want to know how to live a longer, more
							optimal life.
							<br />
							<br />
							<strong> Meet the DexaFit Longevity Score</strong>
							<br />
							<br />
							Our idea was simple. We already leverage specific, medical-grade diagnostic testing
							backed by science and trusted as the gold standard. The data we collect with it
							provides detailed insights about someone's current health status. This data is more
							precise than what your birth date, height, weight, step count, can predict. So what if
							we could figure out a way to discover what is truly going on inside of your body and
							create a “Longevity Score”? A metric that reveals your overall state of health and
							helps you live a happier, healthier, stronger and longer life?
							<br />
							<br />
							After hours of research and meticulous data analysis, we created an algorithm to do
							just that.
							<br />
							<br />
							<strong>How does the Longevity Score work?</strong>
							<br />
							<br />
							First, we only use data backed by decades of peer-reviewed medical research. Second,
							we collect the primary data we need in a non-invasive way. And third, we make the
							metric smarter when we input more data to the algorithm.
							<br />
							<br />
							By leveraging the power of DXA body composition analysis, VO2max testing, and
							metabolic health and biomarker analysis, we can discover “biological clues” that help
							us understand your risk for preventable diseases and provide a snapshot of your total
							health.
							<br />
							<br />
							We combine these clues to create your Longevity Score. It shows you exactly where you
							are, how you compare to others like you, and where you need to be in order to achieve
							optimal wellness.
							<br />
							<br />
							<strong>How to leverage your Longevity Score?</strong>
							<br />
							<br />
							We all want to turn back the hands of father time and feel like we did in our youth.
							This essential human quest and aspiration is what we want to tap into with the DexaFit
							Longevity Score.
							<br />
							<br />
							We see it as your alarm clock, your congratulatory applause, your scoreboard. It is
							the clarity and motivation you need to drive the change in your lifestyle.
							<br />
							<br />
							And because your body is always changing both inside and out, your Longevity Score is
							dynamic. As you re-test with DexaFit, your score will change. It will show you how
							your daily choices increase or decrease your score. We recommend that you treat your
							body like an exotic sports car and check your oil a few times a year.
							<br />
							<br />
							Through this personalized approach, you will identify the lifestyle interventions that
							prolong your longevity, hold you accountable, and motivate you on your journey to
							optimal health.
							<br />
							<br />
							Get started today by scheduling a visit to a DexaFit near you.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
