import React, { Component } from 'react';
import './Footer.css';
import { Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import Phone from './parts/Phonenumber.jsx';
import FullLogo from './assets/img/Dexafit-tag.png';
import FacebookLogo from './assets/img/facebook@3x.png';
import TwitterLogo from './assets/img/twitter@3x.png';
import InstagramLogo from './assets/img/instagram@3x.png';
import GoogleLogo from './assets/img/google-plus@3x.png';

export default class Home extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	handleSubmit = (number) => {
		if (number.length >= 10) {
			axios
				.post(`/api/text`, { number: number })
				.then((res) => {
					console.log('message sent');
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	render() {
		let CustomerToggle = sessionStorage.getItem('Dexa-Customer-Type');
		let FooterBarSwitch;
		switch (CustomerToggle) {
			case 'Customer':
				FooterBarSwitch = (
					<Row className='Footer-image'>
						<Col className='card-6' sm='6' md='6' lg={{ size: 6, offset: 1 }}>
							<h2 className='Let-us-build-a-plan'>Let us build a plan for you</h2>
						</Col>
						<Col className='card-6' xs='10' sm='3' md='3' lg='2'>
							<Button className='learn-more' onClick={(e) => this.handlePageChange('/Services')}>
								Learn more
							</Button>
						</Col>
						<Col className='card-6' xs='10' sm='3' md='3' lg='2'>
							<Button className='Get-started-footer' onClick={(e) => this.handlePageChange('/Map')}>
								Get started
							</Button>
						</Col>
					</Row>
				);
				break;
			case 'Business':
				FooterBarSwitch = (
					<Row className='Footer-image'>
						<Col className='card-6' sm='6' md='6' lg={{ size: 6, offset: 1 }}>
							<h2 className='Let-us-build-a-plan'>Let us build a plan for you</h2>
						</Col>
						<Col className='card-6' xs='10' sm='3' md='3' lg='2'>
							<Button className='learn-more' onClick={(e) => this.handlePageChange('/Services')}>
								Learn more
							</Button>
						</Col>
						<Col className='card-6' xs='10' sm='3' md='3' lg='2'>
							<Button className='Get-started-footer' onClick={(e) => this.handlePageChange('/Map')}>
								Get started
							</Button>
						</Col>
					</Row>
				);
				break;
			case null:
				break;
			default:
				break;
		}
		return (
			<div>
				{FooterBarSwitch}
				<Row className='off-color-2'>
					<Col className='card-5' sm='6' md='3'>
						<h4 className='learn'>PRODUCTS & SERVICES</h4>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							DEXA Body Composition Scan
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							3D Body Scan
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							VO2 Max Cardiorespiratory Fitness Test
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							RMR Metabolic Health Analysis
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Lab Testing (Blood, DNA & More)
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Coaching (Exercise, Nutrition & More)
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Care (Chronic Disease and Preventative Health)
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Health Informatics
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Virtual Care Marketplace
						</h5>
					</Col>
					<Col className='card-5' sm='6' md='3'>
						<h4 className='learn'>NOTABLE LINKS</h4>
						<h5 onClick={(e) => this.handlePageChange('/')} className='footer-item'>
							DexaFit for beginners
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							What is a good Longevity Score?
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Why is my Body Score more important than body fat percentage?
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Free Sleep Score & Analysis
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Free Recovery Score
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							Free Activity Readiness Score
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Services')} className='footer-item'>
							DexaFit and COVID-19
						</h5>
					</Col>
					<Col className='card-5' sm='6' md='3'>
						<h4 className='learn'>FOR BUSINESS & PROS</h4>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Trainers, Coaches, & Wellness Professionals
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Physicians & Healthcare Professionals
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Corporate Wellness
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Athletes & Sports
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Insurance
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Solutions')} className='footer-item'>
							For Entrepreneurs & Business Owners
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Map')} className='footer-item'>
							Join the DexaFit Marketplace as a wellness Pro or Provider
						</h5>
					</Col>
					<Col className='card-5' sm='6' md='3'>
						<h4 className='learn'>COMPANY</h4>
						{/* <h5
							onClick={() => {
								sessionStorage.setItem('Dexa-Customer-Type', 'Business');
								window.location.reload();
							}}
							className='footer-item'>
							Become a Partner Homepage
						</h5>
						<h5
							onClick={() => {
								sessionStorage.setItem('Dexa-Customer-Type', 'Customer');
								window.location.reload();
							}}
							className='footer-item'>
							Customer Homepage
						</h5> */}
						<h5 onClick={(e) => this.handlePageChange('/Blog/home')} className='footer-item'>
							Read Our Blog & Articles
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/MeetTheTeam')} className='footer-item'>
							Learn More About Us
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Jobs')} className='footer-item'>
							Jobs At DexaFit
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/partner')} className='footer-item'>
							Press
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Map')} className='footer-item'>
							Email Us
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/FAQ')} className='footer-item'>
							Frequently Asked Questions
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Partner')} className='footer-item'>
							Partner With Us
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Terms')} className='footer-item'>
							Terms of Use
						</h5>
						<h5 onClick={(e) => this.handlePageChange('/Privacy')} className='footer-item'>
							Our Privacy Policy
						</h5>
					</Col>
				</Row>
				<Row>
					<Col md='4'>
						<Phone handleSubmit={this.handleSubmit} />
					</Col>
					<Col md='4'>
						<div className='wrap'>
							<img className='full-logo' src={FullLogo} alt='dexa logo' />
							<div className='text-center'>© 2020 DexaFit All Rights Reserved</div>
						</div>
					</Col>
					<Col md='4'>
						<div className='Social-Wraper'>
							<h5 className='Follow-Us'>Follow Us</h5>
							<div className='wraper-2'>
								<div className='Footer-icon-wraper'>
									<a href='https://www.facebook.com/dexafit/'>
										<img className='social-icon-1' src={FacebookLogo} alt='facebook logo' />
									</a>
									<a href='https://twitter.com/dexafit?lang=en'>
										<img className='social-icon-1' src={TwitterLogo} alt='twitter logo' />
									</a>
									<a href='https://www.instagram.com/dexafit/?hl=en'>
										<img className='social-icon-1' src={InstagramLogo} alt='instagram logo' />
									</a>
									<a href='https://www.google.com/search?sxsrf=ACYBGNTiTdzGwqlfo6t0uTttjblrUrbLXA%3A1580502748885&source=hp&ei=3I40XsvgM8zQtQaIubb4Bw&q=dexafit&oq=de&gs_l=psy-ab.1.0.35i39l3j0i67j0j0i67l4j0i131.2035.2218..2721...2.0..0.96.181.2......0....1..gws-wiz.....10..35i362i39.Txo_cMn8umc'>
										<img
											id='google-plus-icon'
											className='social-icon-1'
											src={GoogleLogo}
											alt='google logo'
										/>
									</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className='divider' md={{ size: 10, offset: 1 }}>
						<br />
					</Col>
					<Col xs='9' md={{ size: 3, offset: 1 }}>
						<p className='trade-mark'>© 2018 DexaFit All Rights Reserved</p>
					</Col>
					<Col xs='6' md={{ size: 2, offset: 2 }}>
						<p className='Privacy-Policy'>Privacy Policy</p>
					</Col>
					<Col xs='6' md='2'>
						<p className='Terms-Conditions'>Terms & Conditions</p>
					</Col>
					<Col xs='6' md='2'>
						<p className='Code-of-Conduct'>Code of Conduct</p>
					</Col>
				</Row>
			</div>
		);
	}
}
