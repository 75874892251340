import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import LeanMass from '../../assets/img/lean_mass(1).png';
import CardioFit from '../../assets/img/Activity(1).png';
import Metabolism from '../../assets/img/RMR(2).png';
import LabTesting from '../../assets/img/LabResults(1).png';
import Longevity from '../../assets/img/icons8-checklist(1).png';
import Marketplace from '../../assets/img/fat_mass(1).png';
import '../Home.css';

export default class Home3 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<div>
				<Row className='off-color spacer-14'>
					<Col md={{ size: 8, offset: 2 }}>
						<Fade left>
							<br />
							<br />
							<h2 className='A-smarter-way-to-tak'>Stop guessing. Get answers.</h2>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col lg='4' md='6' className='Cards'>
								<Fade right>
									<img className='mid-icons-2' src={LeanMass} alt='leanmass min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>Body Composition</h3>
									<p className='midsection-para'>
										DEXA scans and 3D body analysis shows you exactly how your lean mass, fat mass,
										and skeletal health is changing and where it should be.
									</p>
								</Fade>
							</Col>
							<Col lg='4' md='6' className='Cards'>
								<Fade right>
									<img className='mid-icons-2' src={CardioFit} alt='cardio fit min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>Cardio Fitness</h3>
									<p className='midsection-para'>
										VO2max is the top predictor of all-cause mortality, and knowing it can help you
										optimize your cardiovascular health and fitness.
									</p>
								</Fade>
							</Col>
							<Col lg='4' md='6' className='Cards'>
								<Fade right>
									<img className='mid-icons-2' src={Metabolism} alt='metabolism min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>Metabolism</h3>
									<p className='midsection-para'>
										RMR testing pinpoints the precise number of Calories you burn at rest and shows
										you how your metabolism impacts your health.
									</p>
								</Fade>
							</Col>

							<Col lg='4' md='6' className='Cards'>
								<Fade left>
									<img className='mid-icons-2' src={LabTesting} alt='labTesting min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>At-Home Lab Testing</h3>
									<p className='midsection-para'>
										Blood, DNA and microbiome testing reveals key insights about your hormones,
										micronutrients, gut health, genetics, and more.{' '}
									</p>
								</Fade>
							</Col>
							<Col lg='4' md='6' className='Cards'>
								<Fade left>
									<img className='mid-icons-2' src={Longevity} alt='Longevity min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>Longevity & AI insights</h3>
									<p className='midsection-para'>
										Our artificial intelligence engine analyzes your health data to generate a
										longevity score, preventative health risk profile, and actionable guidance.
									</p>
								</Fade>
							</Col>
							<Col lg='4' md='6' className='Cards'>
								<Fade left>
									<img className='mid-icons-2' src={Marketplace} alt='Marketplace min' />
									<br />
									<br />
									<h3 className='midsecion-labels'>Marketplace</h3>
									<p className='midsection-para'>
										Find wellness pros, physicians, and products that help you achieve an optimal
										state of health and performance.
									</p>
								</Fade>
							</Col>
						</Row>
						<Row>
							<Col className='center-button'>
								<Button
									onClick={(e) => this.handlePageChange('/Services')}
									className='learn-button '>
									Learn More
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}
