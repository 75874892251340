import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import ParterSlide from './slideshows/PartnerSlide';
import Heart from '../assets/img/Icons/heartbeat.png';
import Lightning from '../assets/img/Icons/lightning-bubble.png';
import Vandiagram from '../assets/img/Icons/vandiagram.png';
import House from '../assets/img/house.png';
import Bone from '../assets/img/bone_1 (1).png';
import Insight from '../assets/img/insights-1.png';
import Lab from '../assets/img/LabResults(1).png';
import Checklist from '../assets/img/icons8-checklist(1).png';
import Megaphone from '../assets/img/Icons/megaphone-3-64.png';
import Teacher from '../assets/img/Icons/teacher-64.png';
import Shield from '../assets/img/Icons/refresh-shield-64.png';
import Gear from '../assets/img/Icons/services-64.png';
import Clipboard2 from '../assets/img/Icons/clipboard-2-64.png';
import Handshake from '../assets/img/Icons/handshake-64.png';
import Footer from '../Footer';
import './Partner.css';

export default class Partner extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Container fluid>
				<Row>
					<Col className='text-center Transform-lives'>
						<Fade left>
							<h1 className='Transfrom-lives-text-2'>Powered By DexaFit</h1>
							<div className='Get-medical-grade-text'>
								Get medical-grade technology, testing, and support for your business.
							</div>
						</Fade>
					</Col>
				</Row>
				<Row>
					<Col className='text-center' md='12'>
						<Fade left>
							<div className='Make-health'>
								<strong>Teaching </strong> people how to take care of themselves is the easy part.
							</div>
							<br />
							<br />
							<div className='Make-health-text'>
								Showing them how to stay motivated, getting them to stick to your plan, and erasing
								any doubt of of your value to them? That's what matters.
							</div>
							<div className='Make-health-text'>Introducing Powered By DexaFit.</div>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Lightning} alt='icon of a white lightning bolt in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Engaging</div>
									<div className='Partner-normal-text'>
										Offer transformative technology trusted as the gold standard by science.
									</div>
								</Fade>
							</Col>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Heart} alt='icon of a heartrate monitor in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Meaningful</div>
									<div className='Partner-normal-text'>
										Deliver personalized health and fitness insights to optimize planning.
									</div>
								</Fade>
							</Col>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Vandiagram} alt='icon of a three circle Vandiagram in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Actionable</div>
									<div className='Partner-normal-text'>
										Improve feedback loops and give people the best possible chance of improving
										health.
									</div>
								</Fade>
							</Col>
						</Row>
						<br />
						<br />
					</Col>
				</Row>
				<Row>
					<Col md='12' className='How-Do'>
						<Fade left>
							<div className='How-Do-text'>Customized To Your Needs</div>
							<div id='Customized-text'>
								Powered By DexaFit means you get the flexibility of picking which testing, services,
								and support you want from us.
							</div>
						</Fade>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Body-Composition-Analysis-Title'>Body Composition Analysis</div>
									<br />
									<div>
										with <strong>DXA Body Composition Analysis</strong>
									</div>
									<br />
									<ul>
										<li>
											<strong> Trusted as one of the most proven</strong> DXA systems available
											<br />
											<br />
										</li>
										<li>
											<strong>FDA-approved and refined </strong>over 30 years
											<br />
											<br />
										</li>
										<li>
											<strong>Hundreds of peer-reviewed articles</strong> in leading journals
											confirming DXA as the most accurate body composition measurement
											<br />
										</li>
									</ul>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='white-background'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Partner-why'>360° Of Insights</div>
									<br />
									<div>
										with <strong>3D Body Scanning</strong>
									</div>
									<br />
									<ul>
										<li>
											Offer hundreds of thousands of
											<strong> body shape and posture assessments </strong>with 3D scanning
											<br />
											<br />
										</li>
										<li>
											Used worldwide in more than 20 countries
											<br />
											<br />
										</li>
										<li>
											Receive an initial scan report which contains your
											<strong> outer body measurements and 3D avatar</strong>
										</li>
									</ul>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='Body-Composition-Analysis-Row'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Body-Composition-Analysis-Title'>Metabolic Stress System</div>
									<br />
									<div>
										For <strong>Cardiovascular and Metabolic Health Assessments </strong>
									</div>
									<br />
									<ul>
										<li>
											Show people how to exercise more effectively, and personalize diets to boost
											sluggish metabolisms
											<br />
											<br />
										</li>
										<li>
											<strong>VO2max, RMR,</strong> Anaerobic Threshold, Aerobic Base, Lactate
											Threshold, and Fat Burning and Heart Rate Zones, and ECG/EKG testing
											<br />
											<br />
										</li>
										<li>
											<strong>
												Powerful predictor of both cardiovascular disease and all cause mortality{' '}
											</strong>
											in both men and women
										</li>
									</ul>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track-2'>
								<div>
									<strong>AI Powered </strong>Insights
								</div>
								<div>
									The Fit by DexaFit app tracks everything from your body composition health, cardio
									fitness, metabolic and physiological health, and more.
								</div>
							</div>
						</Fade>
					</Col>
					<Col md='12'>
						<ParterSlide />
					</Col>
				</Row>
				<Row className='white-background'>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<Fade left>
							<div className='Partner-why'>
								<strong>Modern</strong> Lab Testing
							</div>
							<br />
							<div className='Body-Composition-Analysis-Text'>
								Get access to priority pricing, support, and availability of DexaFit's lab tests.
							</div>
						</Fade>
					</Col>

					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<br />
						<Row>
							<Col md='6'>
								<Fade left>
									<br />
									<img src={Lab} alt='lab testing icon' />
									<div className='Lab-testing-blocks'>Blood Panel Testing</div>
									<div>
										Offer custom blood panel analysis, onsite group phlebotomy testing, and takehome
										kits
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<br />
									<img src={Bone} alt='bone icon' />
									<div className='Lab-testing-blocks'>Microbiome Testing Kits</div>
									<div>
										Sequencing-based microbiome screening kits provides detailed gut health analysis
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade left>
									<br />
									<img src={Insight} alt='mind with links icon' />
									<div className='Lab-testing-blocks'>Food Intolerance Kits</div>
									<div>
										Help clients customize diets and avoid foods that make them sick, bloated, and
										fat
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<br />
									<img src={Checklist} alt='blue lightning icon' />
									<div className='Lab-testing-blocks'>DNA Screening Kits</div>
									<div>
										DNA takehome kits check 40+ genes related to fitness, health, and performance
									</div>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='Body-Composition-Analysis-Row'>
					<Col className='text-center' md='12'>
						<Fade left>
							<div className='Body-Composition-Analysis-Title'>
								<strong>Additional Services </strong> and Support
							</div>
							<br />
							<div className='Make-health-text'>
								Once your order is delivered and installed,{' '}
								<strong>
									we make sure you know how to use everything, explain the test results to others,
									and help you spread the word in your market.
								</strong>
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<br />
						<Row>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Megaphone} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Marketing Support</strong>
									</div>
									<div className='icon-text'>
										Digital marketing support and in-house design team for custom marketing
										materials
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Teacher} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Training Support</strong>
									</div>
									<div className='icon-text'>
										Training with equipment and access to DexaFit University online training portal
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Shield} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Compliance Support</strong>
									</div>
									<div className='icon-text'>
										Safety guides and training to ensure state compliance. Regulatory support &
										updates
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Gear} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Technology Support</strong>
									</div>
									<div className='icon-text'>
										Ongoing technology support and troubleshooting for testing equipment
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Clipboard2} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Testing Protocols</strong>
									</div>
									<div className='icon-text'>
										DexaFit testing protocols deliver consistent, high-quality service and data
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Handshake} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Strategic Partnerships</strong>
									</div>
									<div className='icon-text'>
										Benefit from DexaFit's national ad campaigns, partnerships, and pilot trials
									</div>
									<br />
									<br />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='white-background'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track'>
								<div className='Partner-why'>
									<strong>Schedule </strong> a call to learn more and get started.
								</div>
							</div>
						</Fade>
					</Col>

					<Col className='text-center' md='12'>
						<Fade left>
							<br />
							<br />
							<Button href='https://dexafit1.typeform.com/to/Oza65d' className='Contact-us-button'>
								Contact Us
							</Button>
						</Fade>
					</Col>
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
