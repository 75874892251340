import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store4 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'InsideTracker Ultimate Panel',
			price: 574.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>InsideTracker Ultimate Panel</div>
							<br />
							<br />
							<div>$574.00</div> <br />
							<br />
							<div>
								A detailed analysis of 41 key biomarkers and personalized recommendations for
								nutrition, supplementation & lifestyle. <br />
								<br />
								*Please note: this test is not yet available if you’re in MD, NJ, NY, RI, ND, SD, or
								HI. We recommend trying one of our at-home testing kits.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>How healthy am I?</div>
					<br />
					<br />
					<div className='text-center'>
						See where you stand on key biomarkers and learn how to optimize them.
					</div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<ul>
								<li>
									<strong>Identify your optimal zones</strong> - Based on your age, gender,
									ethnicity, activity levels and goals
								</li>
								<li>
									<strong>Personalized recommendations</strong> - optimize your out-of-range
									biomarkers with a nutrition database providing over 7,500 food items
									scientifically proven to improve specific biomarker levels.{' '}
								</li>
								<li>
									<strong>Expert analysis and advice</strong> - Get advice on hundreds of
									supplements as well as lifestyle and exercise suggestions to help you reach your
									optimal zones
								</li>
							</ul>
						</Col>
						<Col md='6'>
							<ul>
								<li>
									<strong>Science-based analysis </strong> - Each recommendation is backed by
									science and directly linked to a published, peer-reviewed scientific research
									publication
								</li>
								<li>
									<strong>Track your progress</strong> - InsideTracker’s cutting-edge platform helps
									you track your progress over time and measure the effectiveness of actions you are
									taking to improve your health
								</li>
								<li>
									<strong>Upload your historical data</strong> - You can add your data from past
									blood tests to see a more complete picture over time
								</li>
							</ul>
						</Col>
					</Row>
					<br />
					<br />
					<div className='product-title text-center'>How it works?</div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<strong>1) Purchase your test</strong>
							<br />
							<div>Select a plan tailored exactly to your needs and goals.</div>
							<br />
							<strong>Select a plan tailored exactly to your needs and goals.</strong>
							<br />
							<div>
								Choose from 2,000 locations around the US or choose 'white glove' and have your
								blood drawn at home!
							</div>
						</Col>
						<Col md='6'>
							<strong>3) See your results</strong> <br />
							<div>Find out where you stand on essential biomarkers.</div>
							<br />
							<strong>4) Get your recommendations</strong>
							<br />
							<div>
								Optimize your diet, health, and fitness with personalized science-based
								recommendations
							</div>
						</Col>
					</Row>
					<br />
					<br />
					<div className='product-title text-center'>
						Ultimate Panel (includes 42 total markers)
					</div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<div className='product-title'>Energy & Metabolism</div>
							<br />
							<ul>
								<li>Glucose</li>
								<li>Cholesterol</li>
								<li>Low-density Lipoprotein (LDL)</li>
								<li>High-density Lipoprotein (HDL)</li>
								<li>Triglycerides</li>
							</ul>
							<br />
							<div className='product-title'>Strength & Endurance</div>
							<br />
							<ul>
								<li>Creatine Kinase•</li>
								<li>Testosterone</li>
								<li>Free Testosterone</li>
								<li>DHEAS</li>
								<li>Cortisol</li>
								<li>Sex-Hormone Binding Globulin</li>
								<li>Albumin</li>
								<li>Sodium</li>
							</ul>
							<br />
							<div className='product-title'>Bone & Muscle Health</div>
							<br />
							<ul>
								<li>Calcium</li>
								<li>Vitamin D</li>
							</ul>
						</Col>
						<Col md='6'>
							<div className='product-title'>Brain & Body</div>
							<br />
							<ul>
								<li>Magnesium</li>
								<li>Folate</li>
								<li>Vitamin B12</li>
								<li>Potassium</li>
							</ul>
							<br />
							<div className='product-title'>Oxygen & Performance</div>
							<br />
							<ul>
								<li>Hemoglobin</li>
								<li>Ferritin</li>
								<li>Total Iron Binding Capacity</li>
								<li>Iron</li>
								<li>Transferrin Saturation</li>
							</ul>
							<br />
							<div className='product-title'>Inflammation</div>
							<br />
							<ul>
								<li>White Blood Cell Count</li>
								<li>High Sensitivity C-Reactive Protein</li>
							</ul>
							<br />
							<div className='product-title'>Liver Health</div>
							<br />
							<ul>
								<li>Alanine Aminotransferase</li>
								<li>Aspartate Aminotransferase</li>
								<li>Gamma-glutamyl Transpeptidase</li>
							</ul>
							<br />
							<div className='product-title'>Complete Blood Count</div>
							<br />
							<br />
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
