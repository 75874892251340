import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store20 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Alene Baronian MS, RD, CSSD',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Alene Baronian MS, RD, CSSD</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>
								Hi, I'm Alene. I work with active individuals and athletes to teach them how to
								properly fuel themselves to meet their body and performance goals.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						I am a Registered Dietitian Nutritionist who looks at your body composition to assess
						accurate body weight goals. and build a personalized program.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
