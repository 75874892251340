import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../../Pricing.css';

export default class Card499 extends Component {
	render() {
		return (
			<div>
				<h2 className='small-card-title'>DexaFit +</h2>
				<h3 className='flavor-text'>I'm all in baby!</h3>
				<h4 className='prices'>499</h4>
				<div className='small-card-wraper'>
					<h5 className='included-title'>Fit by DexaFit App</h5>
					<h6 className='included-items'>All testing results in the app</h6>
					<h6 className='included-items'>Apple Health, FitBit and Google Fit Integration</h6>
					<h6 className='included-items'>Leaderboards</h6>
					<h6 className='included-items'>and more!</h6>

					<h5 className='included-title'>3D Body Scanning</h5>
					<h6 className='included-items'>24 Credits</h6>
					<h6 className='included-items'>HD 3D model of your body</h6>
					<h6 className='included-items'>Track fully body circumference changes </h6>
					<h6 className='included-items'>Posture</h6>

					<h5 className='included-title'>DXA, RMR, and/or VO2</h5>
					<h6 className='included-items'>8 Credits</h6>
					<h6 className='included-items'>DXA (Link to service)</h6>
					<h6 className='included-items'>RMR (Link to service)</h6>
					<h6 className='included-items'>VO2 (Link to service)</h6>
					<h6 className='included-items'>
						Buy additional credits for $65 within the next 12 months
					</h6>
					<h6 className='included-items'>Share on of your test credits</h6>

					<h5 className='included-title'>Sharing/ Referral Code</h5>
					<h6 className='included-items'>
						Share your referral code to give a $50 scan and get a $50 scan
					</h6>
				</div>

				<Button
					id='selected-button'
					className='get-started-button-white align-self-end'
					color='primary'>
					Get Started
				</Button>
			</div>
		);
	}
}
