import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import map from '../../assets/img/Icons/map-pin.png';
import text from '../../assets/img/Icons/text-icon.png';
import bell from '../../assets/img/Icons/bell-icon.png';
import Heart from '../../assets/img/Icons/heartbeat.png';
import Van from '../../assets/img/Icons/vandiagram.png';
import Light from '../../assets/img/Icons/lightning-bubble.png';
import '../Book.css';

export default class Book2 extends Component {
	render() {
		return (
			<Row>
				<Col className='text-center off-color-4' lg='12'>
					<Fade left>
						<Row>
							<Col className='text-center' lg='4'>
								<div className='contact-divs'>
									<img id='map-icon' className='contact-icons' src={map} alt='map pin icon' />
									<div className='contact-text'>{this.props.address}</div>
								</div>
							</Col>
							<Col className='text-center' lg='4'>
								<div className='contact-divs'>
									<img className='contact-icons' src={text} alt='text icon' />
									<div className='contact-text'>{this.props.phone}</div>
									<div className='contact-text'>{this.props.email}</div>
								</div>
							</Col>
							<Col className='text-center' lg='4'>
								<div className='contact-divs'>
									<img className='contact-icons' src={bell} alt='bell icon' />
									<div className='contact-text'>Instagram</div>
									<div className='contact-text'>Facebook</div>
								</div>
							</Col>
						</Row>
					</Fade>
				</Col>
				<Col className='text-center padding-increase' lg='12'>
					<Fade right>
						<Row>
							<Col className='book2-text-align' lg={{ size: 6, offset: 1 }}>
								<div className='big-label-4'>Start Your Healthiesr Future Today</div>
								<div className='deal-text'>
									Know where you are, discover where you can go, and confirm what’s working.
								</div>
							</Col>
							<Col>
								<img className='blue-bubble-icons' src={Heart} alt='hearthbeat bubble icon' />
								<img className='blue-bubble-icons' src={Van} alt='Vandiagram bubble icon' />
								<img
									className='blue-bubble-icons'
									id='lightning-bubble'
									src={Light}
									alt='Lightning bubble icon'
								/>
							</Col>
						</Row>
					</Fade>
				</Col>
			</Row>
		);
	}
}
