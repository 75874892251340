import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store2 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'DNA Analysis + GET LEAN Starter System',
			price: 259.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>DNA Analysis + GET LEAN Starter System</div>
							<br />
							<br />
							<div>$259.00</div> <br />
							<br />
							<strong>FitnessGenes DNA Analysis + GET LEAN Starter System</strong> <br />
							<br />
							<div>
								A new you in 8 weeks. This DNA analysis and 8-week GET LEAN plan gives you unique
								insights into your fitness related genes and physiology. Your fat loss plan helps
								BOOST your metabolism, RESHAPE your physique, and OPTIMIZE your fat-loss.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>Fitness Genes GET LEAN Starter System</div>
					<br />
					<br />
					<div className='text-center'>
						Genetically enhanced workout system designed to help you lose weight.
					</div>
					<br />
					<br />
					<strong>STUBBORN FAT</strong>
					<br />
					<br />
					<div>
						Genetics play a part with how we store body fat. Learn if you have a tendency to store
						stubborn fat and what the risks are.
					</div>
					<br />
					<br />
					<strong>HIIT VS CARDIO</strong>
					<br />
					<br />
					<div>
						Different exercises affect how you burn fat depending on your genetics. Learn what
						exercises work best for you.
					</div>
					<br />
					<br />
					<strong>NUTRITION PLAN</strong>
					<br />
					<br />
					<div>
						Get a macronutrient nutrition plan that helps you lose weight. Find you how much
						protein, fat and carbohydrate you should eat daily.
					</div>
					<br />
					<br />
					<strong>APPETITE AND CRAVINGS</strong>
					<br />
					<br />
					<div>
						The FTO Gene aka ‘The Fat Gene” indicates that some people have an increased appetite
						who tend to hold extra body fat.{' '}
					</div>
					<br />
					<br />
					<strong>FAT BURNING</strong>
					<br />
					<br />
					<div>
						Find out if you have the same fat burning capacity as an endurance athlete, power/speed
						athletes or both.
					</div>
					<br />
					<br />
					<strong>METABOLISM EFFICIENCY</strong>
					<br />
					<br />
					<div>
						One mechanism of fat loss is through the heat generated by your body. Learn if you have
						a fast or slow metabolism.
					</div>
					<br />
					<br />
					<div className='product-title text-center'>How does it work?</div>
					<br />
					<br />
					<div className='text-center'>
						Knowing how your body responds to exercise and diet is just the start. Find out what
						exercise and nutrition gets your body, fitness, and metabolism to optimal.
					</div>
					<br />
					<br />
					<div className='product-title'>DNA ANALYSIS</div>
					<br />
					<ul>
						<li>DNA Test Kit</li>
						<br />
						<li>DNA Analysis</li>
						<br />
						<li>40+ Gene Results</li>
						<br />
						<li>Detailed FitnessGenes Gene report</li>
						<br />
					</ul>
					<br />
					<div className='product-title'>MEMBERS AREA</div>
					<br />
					<ul>
						<li>Online members portal</li>
						<br />
						<li>Training action blueprint</li>
						<br />
						<li>Nutrition action blueprint with calculator</li>
						<br />
						<li>FitnessGenes eBooks</li>
						<br />
						<li>Exercise library</li>
						<br />
						<li>Glossary</li>
						<br />
						<li>Ongoing Gene And Scientific Updates</li>
						<br />
					</ul>
					<br />
					<div className='product-title'>FITNESSGENES FAT-LOSS SYSTEM</div>
					<br />
					<ul>
						<li>8-Week Periodized Fat Loss Plan</li>
						<br />
						<li>Exercise descriptions, photos and videos</li>
						<br />
						<li>Macronutrient Plan</li>
						<br />
						<li>Online Support Through Our Team Of Experts</li>
						<br />
						<li>FREE HIIT workouts</li>
						<br />
						<li>FREE ABS AND CORE WORKOUTS</li>
						<br />
					</ul>
				</Col>
			</Row>
		);
	}
}
