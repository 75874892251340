import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class Book6 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false,
		item4: false,
		item5: false,
		item6: false,
	};

	clickToggle = (item) => {
		switch (item) {
			case '1':
				this.setState((prevState) => ({
					item1: !prevState.item1,
				}));
				break;
			case '2':
				this.setState((prevState) => ({
					item2: !prevState.item2,
				}));
				break;
			case '3':
				this.setState((prevState) => ({
					item3: !prevState.item3,
				}));
				break;
			case '4':
				this.setState((prevState) => ({
					item4: !prevState.item4,
				}));
				break;
			case '5':
				this.setState((prevState) => ({
					item5: !prevState.item5,
				}));
				break;
			case '6':
				this.setState((prevState) => ({
					item6: !prevState.item6,
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row className='offcolor-6'>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<Fade left>
								<h2 className='big-label spacer-5'>FAQs</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											What if I want to cancel my plan?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<div>
													No problem. Cancel, downgrade, or upgrade your plan anytime. If you
													cancel, you will only pay the regular price balance of the services used.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											How long is each appointment?
										</div>
										{this.state.item2 ? (
											<Fade top>
												<div>
													Plan on a 30 to 60 minute appointment, depending on what testing is need.
													One test only will be about 30 minutes. More than one can take up to an
													hour.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											How do I pay?
										</div>
										{this.state.item3 ? (
											<Fade top>
												<div>
													You can pay monthly or annually if you sign up for a premium membership.
													Or pay-as-you-go. <br />
													<br />
													If you choose a premium plan, you can save 10% by pre-paying for the
													package.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item4 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('4')}
											className={`switch ${this.state.item4 ? 'switch-select' : null}`}>
											How do I prepare for my tests?
										</div>
										{this.state.item4 ? (
											<Fade top>
												<div>You can read the Pre-Test Protocols Here</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item5 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('5')}
											className={`switch ${this.state.item5 ? 'switch-select' : null}`}>
											Do you offer a diet or training plan for me after I get my test?
										</div>
										{this.state.item5 ? (
											<Fade top>
												<div>
													Our techs at the testing centers do not, but we have a network of wellness
													professionals and medical practitioners available to review your results
													and personalize a plan for you. We can also share your results with your
													own referring provider. He or she may provide you with specific nutrition
													or exercise interventions, based on your results.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item6 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('6')}
											className={`switch ${this.state.item6 ? 'switch-select' : null}`}>
											Can I use my HSA/FSA card to pay for this?
										</div>
										{this.state.item6 ? (
											<Fade top>
												<div>
													Absolutely! In fact, we encourage it. You might be able to get reimbursed
													from your insurance provider too. Don’t forget to ask them.
												</div>
											</Fade>
										) : null}
									</div>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
