import React, { Component, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';
import Footer from '../Footer';
import * as locationData from '../assets/data/locations.json';
import './Map.css';
import './Hamburger/Hamburger.css';

var Icon = require('../assets/img/Dexifit.png');
const stylingColor = [
	{
		elementType: 'geometry',
		stylers: [
			{
				color: '#f5f5f5',
			},
		],
	},
	{
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#616161',
			},
		],
	},
	{
		elementType: 'labels.text.stroke',
		stylers: [
			{
				color: '#f5f5f5',
			},
		],
	},
	{
		featureType: 'administrative.land_parcel',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#bdbdbd',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [
			{
				color: '#eeeeee',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#757575',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				color: '#e5e5e5',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				color: '#ffffff',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#757575',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry',
		stylers: [
			{
				color: '#dadada',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#616161',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
	{
		featureType: 'transit.line',
		elementType: 'geometry',
		stylers: [
			{
				color: '#e5e5e5',
			},
		],
	},
	{
		featureType: 'transit.station',
		elementType: 'geometry',
		stylers: [
			{
				color: '#eeeeee',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				color: '#c9c9c9',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#9e9e9e',
			},
		],
	},
];
export default class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultCenter: { lat: this.props.currentLocation.lat, lng: this.props.currentLocation.lon },
			defaultZoom: this.props.currentLocation.defaultZoom,
			selectedOption: {
				value: this.props.currentLocation.value,
				label: this.props.currentLocation.label,
				defaultZoom: this.props.currentLocation.defaultZoom,
			},
			selectedOption2: {
				value: this.props.currentLocation.country,
				country: this.props.currentLocation.country,
				defaultZoom: this.props.currentLocation.defaultZoom,
			},
			isOpen: false,
		};
		//this.getLocation = this.getLocation.bind(this);
		//this.getCoordinates = this.getCoordinates.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChange2 = this.handleChange2.bind(this);
		//this.handleLocationError = this.handleLocationError.bind(this);
		this.toggle = this.toggle.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	// getLocation() {
	// 	if (navigator.geolocation) {
	// 		navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
	// 	} else {
	// 		console.log('Geolocation is not supported by this browser.');
	// 	}
	// }

	// getCoordinates(position) {
	// 	let geoCoords = { lat: position.coords.latitude, lng: position.coords.longitude };
	// 	this.setState({
	// 		defaultCenter: geoCoords,
	// 		defaultZoom: 6,
	// 		selectedOption: { value: 'All', label: '', defaultZoom: 4 },
	// 		selectedOption2: { value: 'All', country: 'All', defaultZoom: 4 }
	// 	});
	// }

	// handleLocationError(error) {
	// 	switch (error.code) {
	// 		case error.PERMISSION_DENIED:
	// 			console.log('Location request denied by user.');
	// 			break;
	// 		case error.POSITION_UNAVAILABLE:
	// 			console.log('Location information is unavailable.');
	// 			break;
	// 		case error.TIMEOUT:
	// 			console.log('The Request to get user location timed out');
	// 			break;
	// 		case error.UNKOWN_ERROR:
	// 			console.log('An unknown error occurred.');
	// 			break;
	// 		default:
	// 			console.log('An unknown error occurred.');
	// 	}
	// }

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props !== prevProps) {
			this.setState({
				defaultCenter: { lat: this.props.currentLocation.lat, lng: this.props.currentLocation.lon },
				defaultZoom: this.props.currentLocation.defaultZoom,
				selectedOption: {
					value: this.props.currentLocation.value,
					label: this.props.currentLocation.label,
					defaultZoom: this.props.currentLocation.defaultZoom,
				},
				selectedOption2: {
					value: this.props.currentLocation.country,
					country: this.props.currentLocation.country,
					defaultZoom: this.props.currentLocation.defaultZoom,
				},
			});
		}
	}

	handleChange = (event, lat, lon, state, label, country) => {
		this.setState({
			defaultCenter: { lat: lat, lng: lon },
			defaultZoom: 6,
			selectedOption: { value: state, label: label, lat: lat, lon: lon, defualtZoom: 6 },
			selectedOption2: { value: country, country: country, defaultZoom: 4 },
		});
	};
	handleChange2 = (event, lat, lon, country) => {
		this.setState({
			defaultCenter: { lat: lat, lng: lon },
			defaultZoom: 4,
			selectedOption: { value: 'All', label: '', defaultZoom: 4 },
			selectedOption2: { value: country, country: country, defaultZoom: 4 },
		});
	};
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};

	render() {
		let states = [];
		let selectClasses = 'select-div text-center';
		if (this.state.isOpen) {
			selectClasses = 'select-div open text-center';
		}
		let animateClass = 'hamburger2 hamburger2--collapse';
		if (this.state.isOpen) {
			animateClass = 'hamburger2 hamburger2--collapse is-active';
		}
		//this.getLocation();
		const MapDiv = withScriptjs(
			withGoogleMap((props) => {
				const [selectedLocation, setSelectedLocation] = useState(null);
				return (
					<GoogleMap
						defaultOptions={{ styles: stylingColor }}
						defaultZoom={props.defaultZoom}
						defaultCenter={props.defaultCenter}>
						{locationData.default.map((location) => {
							return props.selectedOption2.value === location.country ||
								props.selectedOption2.value === 'All' ? (
								props.selectedOption.value === location.state ||
								props.selectedOption2.value === 'All' ||
								props.selectedOption.value === 'All' ? (
									<Marker
										key={location.name}
										position={{
											lat: location.coordinates[0],
											lng: location.coordinates[1],
										}}
										onClick={() => {
											setSelectedLocation(location);
										}}
										icon={{
											url: Icon,
											scaledSize: new window.google.maps.Size(25, 30),
										}}
									/>
								) : props.selectedOption2.value !== 'US' ? (
									<Marker
										key={location.name}
										position={{
											lat: location.coordinates[0],
											lng: location.coordinates[1],
										}}
										onClick={() => {
											setSelectedLocation(location);
										}}
										icon={{
											url: Icon,
											scaledSize: new window.google.maps.Size(25, 30),
										}}
									/>
								) : null
							) : null;
						})}
						{selectedLocation && (
							<InfoWindow
								position={{
									lat: selectedLocation.coordinates[0],
									lng: selectedLocation.coordinates[1],
								}}
								onCloseClick={() => {
									setSelectedLocation(null);
								}}>
								<div className='text-center'>
									<strong>{selectedLocation.name}</strong>
									<div>{selectedLocation.address}</div>
									<div>{selectedLocation.phone}</div>
									<a href={selectedLocation.website}>{selectedLocation.email}</a>
									<br />
									{!selectedLocation.phone && !selectedLocation.email ? (
										''
									) : (
										<div>
											<a href={`https://www.google.com/maps/place/${selectedLocation.address}`}>
												Directions
											</a>
											<br />
											<div
												className='view-location-page'
												onClick={(event) =>
													(window.location.href = '/Book/' + selectedLocation.name)
												}>
												View Location Page
											</div>
											<button
												className='map-book'
												onClick={(event) =>
													(window.location.href = '/Book/' + selectedLocation.name)
												}>
												Book Now
											</button>
											<button
												className='map-view-prices'
												onClick={(event) =>
													this.handlePageChange('/Pricing/' + selectedLocation.name)
												}>
												View Pricing
											</button>
										</div>
									)}
								</div>
							</InfoWindow>
						)}
					</GoogleMap>
				);
			})
		);

		return (
			<Container fluid id='map-container'>
				<Row>
					<h1 id='find-dexafit'>
						<strong>Find DexaFit</strong> Near You
					</h1>
					<h4 id='find-location'>
						DexaFit has over two dozen locations across the US and is expanding to Canada and Asia
						soon!
					</h4>
					<div className={selectClasses} onClick={this.toggle}>
						<button className={animateClass} onClick={this.toggle} type='button'>
							<span className='hamburger2-box'>
								<span className='hamburger2-inner'></span>
							</span>
						</button>
						{this.state.isOpen ? (
							<div className='flex-parent'>
								<div onClick={(e) => this.handleChange2(e, 40, -95, 'US')} className='Col-parent'>
									United States
								</div>
								{locationData.default.map((location) => {
									if (states.includes(location.state)) {
										return null;
									} else {
										states.push(location.state);
										return location.country === 'US' ? (
											<div
												className='columns'
												onClick={(e) =>
													this.handleChange(
														e,
														location.coordinates[0],
														location.coordinates[1],
														location.state,
														location.label,
														location.country
													)
												}
												key={location.label}>
												{location.label}
											</div>
										) : null;
									}
								})}
								<div
									onClick={(e) => this.handleChange2(e, 43.65, -79.38, 'CA')}
									className='Col-parent'>
									Canada
								</div>
								{locationData.default.map((location) => {
									return location.country === 'CA' ? (
										<div
											className='columns'
											onClick={(e) =>
												this.handleChange(
													e,
													location.coordinates[0],
													location.coordinates[1],
													location.state,
													location.label,
													location.country
												)
											}
											key={location.label}>
											{location.label}
										</div>
									) : null;
								})}
								<div
									onClick={(e) => this.handleChange2(e, 1.36, 103.78, 'AS')}
									className='Col-parent'>
									Asia
								</div>
								{locationData.default.map((location) => {
									return location.country === 'AS' ? (
										<div
											className='columns'
											onClick={(e) =>
												this.handleChange(
													e,
													location.coordinates[0],
													location.coordinates[1],
													location.state,
													location.label,
													location.country
												)
											}
											key={location.label}>
											{location.label}
										</div>
									) : null;
								})}
							</div>
						) : null}
					</div>
					<div id='map-wrapper'>
						<MapDiv
							defaultCenter={this.state.defaultCenter}
							defaultZoom={this.state.defaultZoom}
							selectedOption={this.state.selectedOption}
							selectedOption2={this.state.selectedOption2}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
							loadingElement={<div style={{ height: '100%' }} />}
							containerElement={<div style={{ height: '100%' }} />}
							mapElement={<div style={{ height: '100%' }} />}
						/>
					</div>
				</Row>
				<Row id='map-seperator'>
					{locationData.default.map((location, i) => {
						return this.state.selectedOption2.value === location.country ||
							this.state.selectedOption2.value === 'All' ? (
							this.state.selectedOption.value === location.state ||
							this.state.selectedOption2.value === 'All' ||
							this.state.selectedOption.value === 'All' ? (
								<Col md='6' key={i}>
									<div className='location-div'>
										{location.country === 'AS' ? (
											<div>
												<h4>{location.name}</h4>

												<div>
													<Button className='map-book' href='https://www.dexafitasia.com/'>
														View Location Page
													</Button>
												</div>
												<br />
												<br />
												<br />
												<br />
											</div>
										) : (
											<div>
												<h4>{location.name}</h4>
												{location.open ? (
													<div>
														<div>{location.phone}</div>
														<a href={`https://www.google.com/maps/place/${location.address}`}>
															{location.address}
														</a>
														<br />
														<a className='location-email' href={location.email}>
															{location.email}
														</a>
														<br />
														<div
															className='view-location-page'
															onClick={(event) =>
																(window.location.href = '/Book/' + location.name)
															}>
															View Location Page
														</div>
														<button
															className='map-book'
															onClick={(event) =>
																(window.location.href = '/Book/' + location.name)
															}>
															Book Now
														</button>
														<button
															className='map-view-prices'
															onClick={(event) =>
																this.handlePageChange('/Pricing/' + location.name)
															}>
															View Pricing
														</button>
													</div>
												) : (
													<div className='coming-soon-spacer'>coming soon</div>
												)}
											</div>
										)}
									</div>
								</Col>
							) : this.state.selectedOption2.value !== 'US' ? (
								<Col md='6' key={i}>
									<div className='location-div'>
										{this.state.selectedOption2.value === 'AS' ? (
											<div>
												<h4>{location.name}</h4>

												<div>
													<button className='map-book' href='https://www.dexafitasia.com/'>
														View Location Page
													</button>
												</div>
											</div>
										) : (
											<div>
												<h4>{location.name}</h4>
												{location.open ? (
													<div>
														<div>{location.phone}</div>
														<a href={`https://www.google.com/maps/place/${location.address}`}>
															{location.address}
														</a>
														<br />
														<a className='location-email' href={location.email}>
															{location.email}
														</a>
														<br />
														<div
															className='view-location-page'
															onClick={(event) =>
																(window.location.href = '/Book/' + location.name)
															}>
															View Location Page
														</div>
														<button
															className='map-book'
															onClick={(event) =>
																(window.location.href = '/Book/' + location.name)
															}>
															Book Now
														</button>
														<button
															className='map-view-prices'
															onClick={(event) =>
																this.handlePageChange('/Pricing/' + location.name)
															}>
															View Pricing
														</button>
													</div>
												) : (
													<div className='coming-soon-spacer'>coming soon</div>
												)}
											</div>
										)}
									</div>
								</Col>
							) : null
						) : null;
					})}
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
