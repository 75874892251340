import React, { Component } from 'react';
import { Container, Row, Col, UncontrolledCarousel, Button } from 'reactstrap';
import Footer from '../Footer';
import Fade from 'react-reveal/Fade';
import ParterSlide from './slideshows/PartnerSlide';
import Heart from '../assets/img/Icons/heartbeat.png';
import Lightning from '../assets/img/Icons/lightning-bubble.png';
import Vandiagram from '../assets/img/Icons/vandiagram.png';
import service1 from '../assets/img/1.png';
import service2 from '../assets/img/2.png';
import service3 from '../assets/img/3.png';
import service4 from '../assets/img/4.png';
import service6 from '../assets/img/6.png';
import service7 from '../assets/img/7.png';
import service8 from '../assets/img/8.png';
import service10 from '../assets/img/10.png';
import BellyFat from '../assets/img/belly_fat-women (1).png';
import Bone from '../assets/img/bone_1 (1).png';
import Insight from '../assets/img/insights-1.png';
import House from '../assets/img/house.png';
import Lab from '../assets/img/LabResults(1).png';
import Checklist from '../assets/img/icons8-checklist(1).png';
import Clipboard1 from '../assets/img/Icons/clipboard-8-64.png';
import Book2 from '../assets/img/Icons/book-16-64.png';
import PiggyBank from '../assets/img/Icons/piggy-bank-64.png';
import Megaphone from '../assets/img/Icons/megaphone-3-64.png';
import Teacher from '../assets/img/Icons/teacher-64.png';
import Shield from '../assets/img/Icons/refresh-shield-64.png';
import Gear from '../assets/img/Icons/services-64.png';
import Clipboard2 from '../assets/img/Icons/clipboard-2-64.png';
import Handshake from '../assets/img/Icons/handshake-64.png';
import './Partner.css';

export default class Partner2 extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		const items = [
			{
				src: service1,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service2,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service3,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service4,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service6,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service7,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service8,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service10,
				altText: '',
				caption: '',
				header: '',
			},
		];
		return (
			<Container fluid>
				<Row>
					<Col className='text-center Transform-lives'>
						<Fade left>
							<h1 className='Transfrom-lives-text'>Transform Lives.</h1>
						</Fade>
					</Col>
				</Row>
				<Row>
					<Col className='text-center' md='12'>
						<Fade left>
							<div className='Make-health'>
								<strong>Make Health And Fitness</strong> A Lifelong Passion
							</div>
							<br />
							<br />
							<div className='Make-health-text'>
								DexaFit is rapidly expanding and looking to scale our vision to make
								state-of-the-art health and fitness testing accessible, efficient, and effective.
							</div>
							<div className='Make-health-text'>
								Join the DexaFit Partner Network and change the way people take care of themselves.
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Lightning} alt='icon of a white lightning bolt in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Engaging</div>
									<div className='Partner-normal-text'>
										Offer transformative technology trusted as the gold standard by science.
									</div>
								</Fade>
							</Col>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Heart} alt='icon of a heartrate monitor in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Meaningful</div>
									<div className='Partner-normal-text'>
										Deliver personalized health and fitness insights to optimize planning.
									</div>
								</Fade>
							</Col>
							<Col className='text-center' md='4'>
								<Fade left>
									<br />
									<br />
									<img src={Vandiagram} alt='icon of a three circle Vandiagram in a blue circle' />
									<br />
									<br />
									<div className='Partner-blue-text'>Actionable</div>
									<div className='Partner-normal-text'>
										Improve feedback loops and give people the best possible chance of improving
										health.
									</div>
								</Fade>
							</Col>
						</Row>
						<br />
						<br />
					</Col>
				</Row>
				<Row>
					<Col md='12' className='How-Do'>
						<Fade left>
							<div className='How-Do-text'>
								How Do DexaFit Partners Help Others Take Better Care Of Themselves?
							</div>
						</Fade>
					</Col>
					<Col md='12'>
						<Fade left>
							<div className='Partner-first'>
								<div>First,</div>
								<div>
									They show people their baseline health and fitness with the most advanced testing
									available today.
								</div>
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 8, offset: 2 }}>
						<Fade left>
							<UncontrolledCarousel className='carousel-book' items={items} />
							<br />
							<br />
						</Fade>
					</Col>
				</Row>
				<Row className='text-center'>
					<Col md='12'>
						<Fade left>
							<br />
							<br />
							<div className='Partner-why'>Why?</div>
							<br />
							<br />
						</Fade>
					</Col>
					<Col md={{ size: 3, offset: 2 }}>
						<Fade left>
							<div className='Why-text'>
								Millions of people waste time on workouts they’ll never stick to or diets that are
								impossible to keep up.
								<strong>
									{' '}
									They're tired of guessing and estimates when they measure their progress and try
									to plan.
								</strong>
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 3, offset: 2 }}>
						<Fade left>
							<div className='Why-text'>
								DexaFit opened to offer people access to
								<strong>
									{' '}
									transformative technologies that help them discover what diets and workout
									programs are the best
								</strong>{' '}
								for their body type, metabolism, and physiology.
							</div>
						</Fade>
					</Col>
					<Col md='12'>
						<Fade left>
							<br />
							<br />
							<div className='Partners-Mark'>“</div>
							<div className='Mark-text'>
								I learned more about my body and health in 30 minutes at DexaFit than I have in 20
								years. These are amazing technologies and give accurate information that you can not
								easily get any other way. “
							</div>
							<br />
							<div>— - Rick, ONGO Fitness</div>
							<br />
							<br />
							<br />
						</Fade>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track'>
								<div id='Then-text'>
									<strong>Then, </strong>
								</div>
								<div>
									<strong>They track and and analyze</strong>
									the biometrics needed to optimize your diet, fitness, or treatment plan and
									confirm it gets results.
								</div>
							</div>
						</Fade>
					</Col>
					<Col md='12'>
						<ParterSlide />
					</Col>
					<Col md='12'>
						<br />
						<br />
						<Row className='Below-slideshow'>
							<Col md={{ size: 3, offset: 2 }}>
								<Fade left>
									<div className='Why-text'>
										<strong>
											Most people don't have an 'army of analysts and sports scientists'
										</strong>{' '}
										measuring and tracking their progress. DexaFit helps them uncover the fads,
										frauds, and hardcore programs that don't lead to sustainable body
										transformations.{' '}
									</div>
								</Fade>
							</Col>
							<Col md={{ size: 3, offset: 2 }}>
								<Fade left>
									<div className='Why-text'>
										Our testing makes it simple for them to{' '}
										<strong>
											{' '}
											track their progress on a plan, stay motivated, and use predictive fitness and
											health analytics to visualize how their body is changing
										</strong>{' '}
										and modify a plan to guarantee the best results.
									</div>
								</Fade>
							</Col>
							<Col className='text-center' md='12'>
								<Fade left>
									<br />
									<br />
									<div className='Partners-Mark'>“</div>
									<div className='Mark-text'>
										“Low carb, high carbs, low calories, high fat, low fat.... Every day it’s
										something new. DexaFit initially helped me realize that I wasn’t eating ENOUGH.
										I have been steadily losing fat and gaining muscle ever since my last two
										visits.”
									</div>
									<br />
									<div>— Meghan R. Yelp</div>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='off-color-7'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track'>
								<div id='Finally-text'>
									<strong>Finally,</strong>
								</div>
								<div>
									<strong>Many health and fitness claims believed</strong> by people these days
									cannot be substantiated with fact.
								</div>
							</div>
							<br />
							<div className='Dexafit-is-changing'>DexaFit is changing this...</div>
							<br />
							<div className='Since-2011'>
								Since 2011, we've watched clients try everything from eating Paleo, Vegetarian, and
								Ketogenic... to getting in shape with CrossFit, Triathlons, and ARX...
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<br />
						<br />
						<br />
						<Row className='text-center'>
							<Col md='4'>
								<Fade left>
									<img src={BellyFat} alt='the cartoon belly of a woman' />
									<br />
									<br />
									<div>
										DexaFit useres <strong>lose an average of 4.3%</strong> body fat in 3 months
									</div>
									<br />
								</Fade>
							</Col>
							<Col md='4'>
								<Fade left>
									<img src={Bone} alt='the cartoon belly of a woman' />
									<br />
									<br />
									<div>Chicago is more 'dense' than San Francisco (DXA bone density results)</div>
									<br />
								</Fade>
							</Col>
							<Col md='4'>
								<Fade left>
									<img src={Insight} alt='the cartoon belly of a woman' />
									<br />
									<br />
									<div>
										31% of DexaFit users discovered <strong>cognition risks factors</strong> after
										testing
									</div>
									<br />
								</Fade>
							</Col>
						</Row>
					</Col>
					<Col className='text-center' md='12'>
						<Fade left>
							<br />
							<br />
							<br />
							<div className='Make-health-text'>
								This combination of data and insights gathered at our locations allows us to keep
								our fingers on the pulse of{' '}
								<strong> what’s happening, why it's happening, and what's working. </strong>
							</div>
						</Fade>
					</Col>
					<Col className='text-center' md='12'>
						<Fade left>
							<br />
							<br />
							<div className='Partners-Mark'>“</div>
							<div className='Mark-text'>
								“I’m in the best shape of my life at age 48 with 10% body fat. MetLife reduced my
								insurance premiums after reviewing my DexaFit report!”
							</div>
							<br />
							<div>— Rod, DexaFit Bay Area</div>
						</Fade>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track'>
								<div>
									<strong>Get</strong> Involved
								</div>
								<div>
									If you're entrepreneural, intuitive and dedicated to helping others feel and look
									better, join our DexaFit partnerhsip network.
								</div>
							</div>
						</Fade>
					</Col>

					<Col className='text-center' md={{ size: 4, offset: 2 }}>
						<Fade left>
							<br />
							<br />
							<strong>Open your own DexaFit in the US. </strong>
							<br />
							<br />
							<Button
								href='https://dexafit1.typeform.com/to/HCtUfo'
								className='Get-involved-button'>
								Learn More
							</Button>
						</Fade>
					</Col>
					<Col className='text-center' md={{ size: 4, offset: 0 }}>
						<Fade left>
							<br />
							<br />
							<strong>Interested in taking DexaFit international?</strong>
							<br />
							<br />
							<Button
								href='https://dexafit1.typeform.com/to/ONzcXS'
								className='Get-involved-button'>
								Get Started
							</Button>
						</Fade>
					</Col>
				</Row>
				<Row>
					<Col className='text-center' md='12'>
						<Fade left>
							<br />
							<br />
							<div className='Partner-why'>
								<strong>Let's</strong> Take A Tour
							</div>
							<br />
							<br />
							<br />
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col md='6'>
								<Fade left>
									<div className='push-right'>
										<div className='Dexafit-is-changing'>
											<strong>Designed</strong> for Success
										</div>
										<br />
										<div>
											We help you <strong> pick the ideal location and design a layout</strong> to
											produce optimal results.
										</div>
										<br />
										<div>
											<strong>Space</strong> Overview
										</div>
										<br />
										<ul>
											<li>675 to 1,500 square feet recommended</li>
											<li>Freedom to divide space into separate rooms for services</li>
										</ul>
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<br />
									<UncontrolledCarousel className='carousel-book' items={items} />
								</Fade>
							</Col>
						</Row>
						<br />
						<br />
						<br />
					</Col>
				</Row>
				<Row className='Body-Composition-Analysis-Row'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Body-Composition-Analysis-Title'>Body Composition Analysis</div>
									<br />
									<div>
										with <strong>DXA Body Composition Analysis</strong>
									</div>
									<br />
									<div>
										Help others{' '}
										<strong>
											know the exact location & percentage of fat, bone & lean muscle throughout
											their body.
										</strong>
									</div>
									<br />
									<ul>
										<li>
											<strong> Trusted as one of the most proven</strong> DXA systems available
										</li>
										<li>
											Third generation DXA technology <strong>refined over 30 years</strong>{' '}
										</li>
										<li>
											<strong>Hundreds of peer-reviewed articles</strong> in leading journals
											confirming DXA as the most accurate body composition measurement
										</li>
									</ul>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Body-Composition-Analysis-Title'>3D Body Scanning</div>
									<br />
									<div>
										with <strong>Fit3D Proscanner</strong>
									</div>
									<br />
									<div>
										<strong>
											In 40 seconds, the Fit3D ProScanner takes a complete 3D, 360° body scan
										</strong>{' '}
										and extracts the most commonly used benchmark fitness measurements
									</div>
									<br />
									<ul>
										<li>
											Offer hundreds of thousands of{' '}
											<strong>body shape and posture assessments</strong>
										</li>
										<li>Used worldwide in more than 20 countries</li>
										<li>
											Receive an initial scan report which contains your{' '}
											<strong>outer body measurements and 3D avatar</strong>
										</li>
									</ul>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='white-background'>
					<Col md={{ size: 10, offset: 1 }}>
						<Row>
							<Col className='Body-Composition-Analysis-Text' md='6'>
								<Fade left>
									<div className='Partner-why'>Metabolic Stress System</div>
									<br />
									<div>
										For <strong>Cardiovascular and Metabolic Health</strong>
									</div>
									<br />
									<div>
										<strong>Pinpoint precise cardio fitness levels</strong>, how to exercise more
										effectively, and personalize diets to boost sluggish metabolisms.
									</div>
									<br />
									<ul>
										<li>
											<strong>VO2max, </strong>Anaerobic Threshold, Aerobic Base, Lactate Threshold,
											and Fat Burning and Heart Rate Zones, and ECG/EKG testing
										</li>
										<li>
											<strong>
												Powerful predictor of both cardiovascular disease and all cause mortality
											</strong>{' '}
											in both men and women
										</li>
										<li>
											<strong>RMR and RER </strong>analysis to monitor metabolic health
										</li>
									</ul>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<img className='house-placeholder-2' src={House} alt='placeholder' />
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='Body-Composition-Analysis-Row Body-Composition-Analysis-Text'>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<Fade left>
							<div className='Body-Composition-Analysis-Title'>
								<strong>Modern</strong> Lab Testing
							</div>
							<br />
							<div>
								Get access to priority pricing, support, and availability of DexaFit's lab tests.
							</div>
						</Fade>
					</Col>

					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<br />
						<Row>
							<Col md='6'>
								<Fade left>
									<br />
									<img src={Lab} alt='lab testing icon' />
									<div className='Lab-testing-blocks'>Blood Panel Testing</div>
									<div>
										Offer custom blood panel analysis, onsite group phlebotomy testing, and takehome
										kits
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<br />
									<img src={Bone} alt='bone icon' />
									<div className='Lab-testing-blocks'>Microbiome Testing Kits</div>
									<div>
										Sequencing-based microbiome screening kits provides detailed gut health analysis
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade left>
									<br />
									<img src={Insight} alt='mind with links icon' />
									<div className='Lab-testing-blocks'>Food Intolerance Kits</div>
									<div>
										Help clients customize diets and avoid foods that make them sick, bloated, and
										fat
									</div>
								</Fade>
							</Col>
							<Col md='6'>
								<Fade right>
									<br />
									<img src={Checklist} alt='blue lightning icon' />
									<div className='Lab-testing-blocks'>DNA Screening Kits</div>
									<div>
										FitnessGenes takehome kits check 40+ genes related to fitness, health, and
										performance
									</div>
								</Fade>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='blue-background'>
					<Col className='text-center' md='12'>
						<Fade left>
							<div className='Body-Composition-Analysis-Title'>
								<strong>All-In-One</strong> Business Solutions
							</div>
							<br />
							<div className='Make-health-text'>
								Owning your own business can seem both daunting and exciting at the same time. By
								partnering with DexaFit, we make sure you{' '}
								<strong>
									avoid starting from scratch during your startup, shorten your operations learning
									curve, and provide you with ongoing support and resources.
								</strong>
							</div>
						</Fade>
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<br />
						<Row>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Clipboard1} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Pre-Launch Playbook</strong>
									</div>
									<div className='icon-text'>
										Avoid missing small details starting up. Your roadmap to launching your new
										business.
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Book2} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Operations Playbook</strong>
									</div>
									<div className='icon-text'>
										5+ years of operation. Get DexaFit workflows, processes, and protocols.{' '}
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={PiggyBank} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Low Overhead</strong>
									</div>
									<div className='icon-text'>
										Preferred pricing for all of your testing equipment, technology, supplies, and
										more.
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Megaphone} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Marketing Support</strong>
									</div>
									<div className='icon-text'>
										Print and digital marketing support. In-house design team for custom marketing
										materials
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Teacher} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Training Support</strong>
									</div>
									<div className='icon-text'>
										Training with equipment and access to DexaFit University online training portal
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Shield} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Compliance Support</strong>
									</div>
									<div className='icon-text'>
										Safety guides and training to ensure state compliance. Regulatory support &
										updates
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Gear} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Technology Support</strong>
									</div>
									<div className='icon-text'>
										Ongoing technology support and troubleshooting for testing equipment
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Clipboard2} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Testing Protocols</strong>
									</div>
									<div className='icon-text'>
										DexaFit testing protocols deliver consistent, high-quality service and data
									</div>
									<br />
									<br />
								</Fade>
							</Col>
							<Col className='icon-center' md='4'>
								<Fade left>
									<img className='icon-partner-page' src={Handshake} alt='clipboard icon' />
									<div className='Body-Composition-Analysis-Text'>
										<strong>Strategic Partnerships</strong>
									</div>
									<div className='icon-text'>
										Benefit from DexaFit's national ad campaigns, partnerships, and pilot trials
									</div>
									<br />
									<br />
								</Fade>
							</Col>
						</Row>
					</Col>
					<Col className='text-center' md='12'>
						<Fade left>
							<div>and much more.</div>
						</Fade>
					</Col>
				</Row>
				<Row className='white-background'>
					<Col md='12'>
						<div className='About-Dexafit-text'>
							<Fade left>
								<div className='Partner-why'>
									<strong>About</strong> DexaFit
								</div>
								<br />
								<div>
									Technology innovation is driving the movement toward more personalized health and
									fitness{' '}
									<strong>
										Objective, data-driven feedback is more critical than ever if you want to know
										the merits of a fitness plan and track progress with confidence.
									</strong>
								</div>
								<br />
								<div>
									Access to the latest developments in health, fitness, and nutrition science is{' '}
									<strong>
										essential if you want to understand where you are, where you should be, and if
										what you do works.
									</strong>
								</div>
								<br />
								<div>
									DexaFit opened in 2011 to provide this access to clients. Our locations offer the
									most strategic, results-driven health and fitness testing system that makes sure a
									diet and fitness plan is the right one for someone and actually gets results.
								</div>
								<br />
								<div>
									Our mission is to go beyond the frenzy of fads and take a deeper look at the state
									of science around the most pressing health and fitness questions of the day.
								</div>
								<br />
								<br />
								<br />
							</Fade>
							<Fade left>
								<div className='Partner-why'>
									<strong>Most Popular</strong> Questions
								</div>
								<br />
								<div className='Lab-testing-blocks'>
									Q: What kind of support do I get from DexFit?
								</div>
								<div>
									You will get access to a robust support system, designed and tested during the
									early stages of our growth. Support ranges from…
									<br />
									<br />
									<ul>
										<li>
											Initial space build-out support, including equipment, furniture, technology,
											and supplies
										</li>
										<br />
										<li>Regulatory state compliance support, medical oversight support</li>
										<br />
										<li>
											Formal training and on-going education (DexaFit University resource hub &
											expert oversight)
										</li>
										<br />
										<li>
											Website, social media, digital marketing and technology development and
											support
										</li>
										<br />
										<li>
											Relationships with our strategic partnerships, including Research, Pilot
											Studies, Military, Gyms, Hospitals, & Corporations
										</li>
										<br />
										<li>And much more (outlined on your introductory call)</li>
									</ul>
								</div>
								<br />
								<div className='Lab-testing-blocks'>Q: How large of a space do I need?</div>
								<div>675 to 1,500 sq/ft is the minimum space you will need. </div>
								<br />
								<div className='Lab-testing-blocks'>
									Q: What are the requirements to operate a DexaFit in my state?
								</div>
								<div>
									Upon completion of your application below, we will forward any requirements or
									additional information you will need to know about your state.
								</div>
								<br />
								<div className='Lab-testing-blocks'>
									Q: What is an estimate of my initial startup investment?
								</div>
								<div>
									<strong>Initial Capital Investment:</strong> ~$147,000 to ~$188,000
									<br />
									<br />
									Includes all Testing Equipment, Office & Technology requirements, Territory
									Rights, and Professional Services.
									<br />
									<br />
									*DexaFit has relationships with third-party sources to offer preferred financing
									options
									<br />
									<br />
									*A more detailed Financial Model with a breakdown of costs, revenue, and income
									estimates will be provided upon the completion of the application below.
								</div>
							</Fade>
						</div>
					</Col>
				</Row>
				<Row className='Body-Composition-Analysis-Row'>
					<Col md='12'>
						<Fade left>
							<div className='Then-track'>
								<div>
									<strong>Get</strong> Involved
								</div>
							</div>
						</Fade>
					</Col>

					<Col className='text-center' md={{ size: 4, offset: 2 }}>
						<Fade left>
							<br />
							<br />
							<strong>Ready to get started with DexaFit in the US?</strong>
							<br />
							<br />
							<Button
								href='https://dexafit1.typeform.com/to/HCtUfo'
								className='Get-involved-button'>
								DexaFit USA
							</Button>
						</Fade>
					</Col>
					<Col className='text-center' md={{ size: 4, offset: 0 }}>
						<Fade left>
							<br />
							<br />
							<strong>Learn more about international opportunities.</strong>
							<br />
							<br />
							<Button
								href='https://dexafit1.typeform.com/to/ONzcXS'
								className='Get-involved-button'>
								DexaFit International
							</Button>
						</Fade>
					</Col>
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
