import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store24 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'QALYtude Longevity+ Bundle',
			price: 499.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>QALYtude Longevity+ Bundle</div>
							<br />
							<br />
							<div>$499.00</div>
							<strike>$549.00</strike> <br />
							<br />
							<div>
								Reverse aging and optimize how you look, feel, and perform with the QALYtude
								Longevity+ Bundle. Get a baseline biomarker analysis (18 markers), physician
								consultation, 3-month supply of Metformin, and 6-week supply of NAD+ patches.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<br />
					<div className='product-title'>About QALYtude: </div>
					<br />
					<br />
					<div>
						QALYtude’s mission is to provide easy access to longevity and healthspan therapies. The
						QALYtude Longevity+ bundle is a simple way to discover the best way to reverse aging and
						take a proactive approach with your health.
					</div>
					<br />
					<br />
					<div className='product-title'>What’s included:</div>
					<br />
					<br />
					<ul>
						<li>Baseline biomarker analysis (18 markers) </li>
						<br />
						<li>Physician consultation and recommendations </li>
						<br />
						<li>*Metformin prescription (pending Physician review)</li>
						<br />
						<li>*NAD+ patches</li>
						<br />
					</ul>
					<div>
						*QALYtude will assist in setting up a visit for you with an independent physician who
						will evaluate whether or not you are an appropriate candidate for the prescription
						product and if appropriate, may write you a prescription for the product which you can
						fill at the pharmacy of your choice.{' '}
					</div>
					<br />
					<br />
					<div className='product-title'>About Metformin:</div>
					<br />
					<br />
					<div>
						Metformin, an effective first-line agent for the treatment of type 2 diabetes, has come
						a long way since the FDA approved the drug. Today, metformin is inexpensive, generic,
						and widely available. More importantly, it now has a new reputation as a possible
						anti-aging wonder pill that influences a host of metabolic and cellular processes
						closely associated with aging.
						<br />
						<br />
						The science behind Metformin:
					</div>
					<br />
					<ul>
						<li>
							<a href='https://link.springer.com/article/10.1007/s00125-009-1440-6'>
								A retrospective study of 62k patients in UK: “Metformin use was associated with
								lower risk of cancer of the colon or pancreas..."
							</a>
						</li>
						<br />
						<li>
							<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3031263/'>
								A representative population study of 800k individuals: "Metformin can reduce the
								incidences of several gastroenterological cancers..."
							</a>
						</li>
						<br />
						<li>
							<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2809274/'>
								A study involving 1,300 patients in Netherlands: "Metformin use was associated with
								lower cancer mortality compared with non users"
							</a>
						</li>
						<br />
						<li>
							<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2735093/'>
								A study conducted at M.D. Anderson Cancer Center involving 973 patients and 863
								controls: "Metformin use was associated with reduced risk of pancreatic cancer..."
							</a>
						</li>
						<br />
						<li>
							<a href='https://www.ncbi.nlm.nih.gov/pubmed/24577463/'>
								A study of 365 people aged 55 and over were followed for 4 years: "Controlling for
								age, education, diabetes duration, fasting blood glucose, vascular and non-vascular
								risk factors, Metformin use showed a significant inverse association with cognitive
								impairment"
							</a>
						</li>
						<br />
						<li>
							<a href='https://academic.oup.com/biomedgerontology/article/69/10/1299/2947791'>
								A study of 67,731 participants who were non demented, non diabetic, aged 65 or over
								at baseline from January 2004 to December 2009: "Diabetes is associated with an
								increased risk of dementia. The risk effect becomes weaker provided that
								participants take sulfonylureas or Metformin rather than thiazolidinediones for a
								longer period"
							</a>
						</li>
						<br />
					</ul>
					<br />
					<br />
					<div className='product-title'>About NAD+:</div>
					<br />
					<br />
					<div>
						Nicotinamide adenine dinucleotide (NAD+) is an essential cofactor in all living cells
						that is involved in fundamental biological processes. Emerging evidence implicates that
						elevating NAD+ levels may slow or even reverse aging. By increasing intracellular levels
						of NAD+, age-related mitochondrial dysfunction can be reversed. NAD+ combats aging by
						activating key anti-aging enzymes called sirtuins, specifically SIRT1 and SIRT3.
						Sirtuins help optimize your longevity by favorably controlling gene expression.
					</div>
					<br />
					<br />
					<div className='product-title'>Biomarkers measured: </div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<ul>
								<li>Vitamin D </li>
								<br />
								<li>HS-CRP</li>
								<br />
								<li>ALT</li>
								<br />
								<li>Bun/Creatine Ratio </li>
								<br />
								<li>DHEA </li>
								<br />
								<li>Total Testosterone + Free Testosterone </li>
								<br />
								<li>Free T3 </li>
								<br />
								<li>TSH</li>
								<br />
								<li>DHEA-S</li>
								<br />
							</ul>
						</Col>
						<Col md='6'>
							<ul>
								<li>Cortisol </li>
								<br />
								<li>Glucose </li>
								<br />
								<li>A1C </li>
								<br />
								<li>Progesterone </li>
								<br />
								<li>Lipid Panel </li>
								<br />
								<li>Cortisol </li>
								<br />
								<li>Homocysteine </li>
								<br />
								<li>E2</li>
								<br />
							</ul>
						</Col>
					</Row>
					<br />
					<div className='product-title'>How it works:</div>
					<br />
					<br />
					<ol>
						<li>Sign up for the QALYtude Anti-Aging Bundle</li>
						<br />
						<li>Visit a local Quest lab for a blood draw</li>
						<br />
						<li>Once you receive your lab results, schedule your physician consultation</li>
						<br />
						<li>
							During the consultation, you will review your health history and any relevant DexaFit
							or lab results with the doctor
						</li>
						<br />
						<li>
							After your consultation, your doctor will provide lifestyle recommendations and
							prescribe any relevant longevity therapies, including Metforin or NAD+ therapy
						</li>
						<br />
					</ol>
				</Col>
			</Row>
		);
	}
}
