import React, { Component } from 'react';
//import { Link } from 'react';
import { Container } from 'reactstrap';
import ChoiceSlide from './slides/ChoiceSlide';
import Footer from '../Footer';

export default class CustomerTypeChoice extends Component {
	constructor(props) {
		super(props);
		this.PaddingSwitch = this.PaddingSwitch.bind(this);
		this.state = {
			Padding: 0,
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	PaddingSwitch() {
		this.setState({ Padding: 100 });
	}
	render() {
		return (
			<Container fluid style={{ paddingTop: this.state.Padding }}>
				<ChoiceSlide history={this.props.history} PaddingSwitch={this.PaddingSwitch} />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
