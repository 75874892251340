import React, { Component } from 'react';
import lab from '../../assets/img/LabResults(1).png';
import '../Service.css';

export default class LabNav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img className='small-button-icons' src={lab} alt='lab icon' />
				</div>
				<h6 className='grey-label'>Lab Testing</h6>
			</div>
		);
	}
}
