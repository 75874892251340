import React, { Component } from 'react';
import { Container, Row, Col, UncontrolledCarousel } from 'reactstrap';
import service1 from '../assets/img/1.png';
import service2 from '../assets/img/2.png';
import service3 from '../assets/img/3.png';
import service4 from '../assets/img/4.png';
import service6 from '../assets/img/6.png';
import service7 from '../assets/img/7.png';
import service8 from '../assets/img/8.png';
import service10 from '../assets/img/10.png';
import JobApplications from './slides/Job-applications';
import Footer from '../Footer';

export default class Jobs extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const items = [
			{
				src: service1,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service2,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service3,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service4,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service6,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service7,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service8,
				altText: '',
				caption: '',
				header: ''
			},
			{
				src: service10,
				altText: '',
				caption: '',
				header: ''
			}
		];
		return (
			<Container fluid>
				<Row>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<h1 className='Privacy-policy-title'>
							<strong>Work</strong> With Us
						</h1>
						<div id='Job-second-title'>
							Want to help make your community leaner, stronger, happier, and healthier? We're
							hiring!
						</div>
						<br />
					</Col>
					<Col>
						<Row className='off-color'>
							<Col md={{ size: 5, offset: 1 }}>
								<br />
								<br />
								<div className='Privacy-policy'>
									<strong className='bolded-terms'>How We Work</strong>
								</div>
								<div className='Privacy-policy'>
									<strong className='bolded-terms'>Choose your own adventure</strong>
								</div>
								<div className='Privacy-policy'>
									Some of our team works remotely. Others are location-dependent at one of our 11
									(soon to be 15 locations nationwide). <br />
									<br />
									We care about the quality of the work you produce and how well you execute. <br />
									<br />
									Execution > Idea <br />
									<br />
									Team > Idea
								</div>
							</Col>
							<Col md='5'>
								<br />
								<br />
								<UncontrolledCarousel className='carousel-book' items={items} />
							</Col>
							<Col md={{ size: 10, offset: 1 }}>
								<div className='Privacy-policy'>
									<br /> <br />
									<strong className='bolded-terms'>Coming aboard</strong>
									<br />
									<br />
									To empower, inspire, and support people to transform their health and fitness.{' '}
									<br />
									<br />
									<strong className='bolded-terms'>Company vision </strong> <br />
									<br />
									To empower, inspire, and support people to transform their health and fitness.{' '}
									<br />
									<br />
									<strong className='bolded-terms'>What do we look for?</strong> <br />
									<br />
									You should be curious, driven, compassionate, tenacious, independent,
									collaborative, communicative, supportive, self-motivated, and able to execute.{' '}
									<br />
									<br />
									We want to work with people interested in helping others get stronger, leaner,
									happier, and healthier. If this sounds good to you? Apply today.
								</div>
								<br />
								<br />
							</Col>
						</Row>
					</Col>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<br />
						<br />
						<div className='Privacy-policy'>
							<strong className='bolded-terms'>Open</strong> Positions
						</div>
						<br />
						<br />
						<JobApplications />
					</Col>
					<Col md={{ size: 10, offset: 1 }}>
						<h1 className='Privacy-policy-title text-center'>
							<strong>More</strong> About Us
						</h1>
						<div className='Privacy-policy'>
							<strong className='bolded-terms'>Why we do what we do</strong> <br />
							<br />
							Millions of people waste time on workout programs they’ll never stick to, diets that
							are impossible to keep up, and fitness gimmicks that never really do anything.
							<br />
							<br />
							DexaFit opened to offer clients the most strategic, results-driven health and fitness
							testing system that makes sure a diet and fitness plan is the right one for someone
							and actually gets results.
							<br />
							<br />
							<strong className='bolded-terms'>How DexaFit works</strong>
							<br />
							<br />
							We only use medical-grade technology to perform our fitness testing. <br />
							<br />
							What we offer our clients is unique and rare because it gives them access to
							technology and feedback usually only available at hospitals and professional athletic
							facilities.
							<br />
							<br />
							<strong className='bolded-terms'>Our team</strong>
							<br />
							<br />
							Everyone at DexaFit is committed to learning and knowing exactly what our clients
							truly need to transform their health, fitness, and bodies.
							<br />
							<br />
							During every client engagement, we help them:
							<br />
							<br />
							<ul>
								<li>
									<strong className='bolded-terms'>Figure out </strong>what they need and what they
									want to accomplish
								</li>
								<li>
									<strong className='bolded-terms'>Pay attention </strong> to the trends that help
									them learn how to achieve their optimal
								</li>
								<li>
									<strong className='bolded-terms'>Ask questions </strong>to discover what's
									important to them and why.
								</li>
								<li>
									<strong className='bolded-terms'>Stay motivated </strong>and remove roadblocks to
									success.
								</li>
							</ul>
						</div>
					</Col>
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
