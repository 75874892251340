import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class Dexa7 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false,
		item4: false,
		item5: false,
	};

	clickToggle = (item) => {
		switch (item) {
			case '1':
				this.setState((prevState) => ({
					item1: !prevState.item1,
				}));
				break;
			case '2':
				this.setState((prevState) => ({
					item2: !prevState.item2,
				}));
				break;
			case '3':
				this.setState((prevState) => ({
					item3: !prevState.item3,
				}));
				break;
			case '4':
				this.setState((prevState) => ({
					item4: !prevState.item4,
				}));
				break;
			case '5':
				this.setState((prevState) => ({
					item5: !prevState.item5,
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<Fade left>
								<h2 className='big-label spacer-5'>Learn more about DEXA</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											What do I have to do before my visit?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<br />
												<div>
													The most important thing to note is we’re appointment only, so please make
													sure you schedule online or via our mobile app!
													<br />
													<br />
													On the day of your visit, remember to wear comfortable clothes (no metal),
													and make sure you’re not dehydrated or over-hydrated. We recommend fasting
													for two hours before your appointment.
													<br />
													<br />
													If you’re planning to do a 3D body scan the same day, wear compression
													shorts or tight boxers (guys) or tight leggings and a sports bra (ladies).
													Please also remember to download our Fit app prior to your visit.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											How is my body score calculated and what does it mean?
										</div>
										{this.state.item2 ? (
											<Fade top>
												<br />
												<div>
													Because body fat and BMI alone are poor indicators of health, we developed
													the body score metric. Based on an algorithm that utilizes data points
													collected from the DXA scan, including your body fat, lean mass, skeletal
													health, ad visceral fat, your body score is a simple way to track the
													health of your body composition distribution. Similar to your grade school
													report card, your body score is a letter grade.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											What do I have to wear during my visit?
										</div>
										{this.state.item3 ? (
											<Fade top>
												<br />
												<div>
													Please wear workout clothes without any metal. Small buttons or zippers
													that cannot be removed from clothing are fine.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item4 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('4')}
											className={`switch ${this.state.item4 ? 'switch-select' : null}`}>
											How long does a DXA scan take?
										</div>
										{this.state.item4 ? (
											<Fade top>
												<br />
												<div>
													The DXA scan is incredibly simple and efficient. You will only have to lie
													still on the scanning table for six to eight minutes. Once the scan is
													complete, your results will be available immediately.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item5 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('5')}
											className={`switch ${this.state.item5 ? 'switch-select' : null}`}>
											What is visceral fat exactly?
										</div>

										{this.state.item5 ? (
											<Fade top>
												<br />
												<div>
													Unlike subcutaneous fat, which has the primary function of being a depot
													of stored energy, visceral fat is far more nefarious. It is found within
													the envelope formed by the abdominal muscles, and it can infiltrate
													organs; thus, disrupting their function. VAT cells are dangerous and
													pathogenic because they are metabolically active; the cells release
													inflammatory proteins that are highly correlated with risk factors for
													heart disease (such as elevated blood sugar, triglycerides, and
													cholesterol).
												</div>
												<br />
											</Fade>
										) : null}
									</div>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
