import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Karla from '../../../assets/img/Karla_and_Angie.jpg';

export default class Store21 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Karla Roach, CHHC',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Karla Roach, CHHC</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>
								If you are ready to make some changes and don’t know where to start, I encourage you
								to schedule a time to meet with me. Your small changes today can make for a
								healthier tomorrow!
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						Change can feel scary. I understand it completely because I have been there!
						<br />
						<br />
						My name is Karla Roach and I am a Certified Holistic Health Coach. I am married to my
						high school sweetheart and we have five children, ages 20, 18, 16, 13 and 11. My journey
						toward wellness began over 11 years ago. Our kids were continuously sick and the medical
						bills were “out of control” as a result. We decided to become “proactive” and take
						responsibility for our health.
						<br />
						<br />
						We did a pantry and refrigerator overhaul by replacing our food with better choices.
						Before long, our need for doctor appointments greatly decreased and I also discovered a
						love for health coaching in the process! Working as a health coach, I have a passion for
						helping individuals set goals and teaching them to make changes one step at a time.
						Holding my clients accountable to their goals is an important part of my coaching
						process. Knowing that I am listening and cheering for them along the way makes my
						clients feel more inspired to keep working toward better health.
						<br />
						<br />
						<img className='product-page-img' src={Karla} alt='Karla at dexafit' />
						<br />
						<br />I was introduced to DexaFit Madison in 2019 and realized having scientific data to
						help individuals learn more about their bodies is a great component to add to a health
						coaching practice. So, on January 3, 2020, my sister, Angie, and I opened DexaFit Eau
						Claire. With the technology that DexaFit provides along with the expertise that my
						coaching brings, we are helping people to “Know their Body and Transform their Life”.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
