import React, { Component } from 'react';
import ThreeD from '../../assets/img/3DView(1).png';
import '../Service.css';

export default class ThreeNav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img className='small-button-icons' src={ThreeD} alt='3D icon' />
				</div>
				<h6 className='grey-label'>3D</h6>
			</div>
		);
	}
}
