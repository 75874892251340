import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../Footer';

export default class Terms extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={{ size: 10, offset: 1 }}>
						<h1 id='Privacy-policy-title'>
							TERMS OF SERVICE <br />
							DexaFit Terms of Service (“Agreement”)
						</h1>
						<div className='Privacy-policy'>
							Last modified on February 22, 2017. <br />
							<br />
							Please read these Terms of Service (“Agreement”, “Terms of Service”) carefully before
							using http://dexafit.com (“the Site”) operated by DexaFit, LLC (“us”, “we”, or “our”).
							This Agreement sets forth the legally binding terms and conditions for your use of the
							Site at http://dexafit.com. <br />
							<br />
							By accessing or using the Site in any manner, including, but not limited to, visiting
							or browsing the Site or contributing content or other materials to the Site, you agree
							to be bound by these Terms of Service. Capitalized terms are defined in this
							Agreement. <br />
							<br />
							<strong className='bolded-terms'>Intellectual Property</strong> <br />
							The Site and its original content, features and functionality are owned by DexaFit,
							LLC and are protected by international copyright, trademark, patent, trade secret and
							other intellectual property or proprietary rights laws.
							<br />
							<br />
							<strong className='bolded-terms'>Termination</strong> <br />
							We may terminate your access to the Site, without cause or notice, which may result in
							the forfeiture and destruction of all information associated with you. All provisions
							of this Agreement that by their nature should survive termination shall survive
							termination, including, without limitation, ownership provisions, warranty
							disclaimers, indemnity, and limitations of liability.
							<br />
							<br />
							<strong className='bolded-terms'>Links To Other Sites</strong> <br />
							Our Site may contain links to third-party sites that are not owned or controlled by
							DexaFit, LLC.
							<br />
							<br />
							DexaFit, LLC has no control over, and assumes no responsibility for, the content,
							privacy policies, or practices of any third party sites or services. We strongly
							advise you to read the terms and conditions and privacy policy of any third-party site
							that you visit.
							<br />
							<br />
							<strong className='bolded-terms'>Governing Law</strong>
							<br />
							This Agreement (and any further rules, polices, or guidelines incorporated by
							reference) shall be governed and construed in accordance with the laws of Illinois,
							United States, without giving effect to any principles of conflicts of law.
							<br />
							<br />
							<strong className='bolded-terms'>Changes To This Agreement</strong> <br />
							We reserve the right, at our sole discretion, to modify or replace these Terms of
							Service by posting the updated terms on the Site. Your continued use of the Site after
							any such changes constitutes your acceptance of the new Terms of Service.
							<br />
							<br />
							Please review this Agreement periodically for changes. If you do not agree to any of
							this Agreement or any changes to this Agreement, do not use, access or continue to
							access the Site or discontinue any use of the Site immediately.
							<br />
							<br />
							<strong className='bolded-terms'>Contacting Us</strong>
							<br />
							<br />
							If there are any questions regarding these terms of service you may contact us using
							the information below.
							<br />
							<br />
							http://dexafit.com
							<br />
							3727 North Broadway Street
							<br />
							Chicago, IL 60613
							<br />
							United States
							<br />
						</div>
						<br />
						<br />
					</Col>
				</Row>

				<Footer history={this.props.history} />
			</Container>
		);
	}
}
