import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store18 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'The DexaDoc',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>The DexaDoc</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>
								The DexaDoc program involves using data derived through body composition and
								metabolic analysis to reconfigure your body specific to your goals.{' '}
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						Hi, I'm Dr. Dan Dodd, the DexaDoc, and I provide nutritional and fitness coaching for
						all ages and goals. I use specific data derived through testing to identify a blueprint
						to expedite your journey to achieving your goals. <br />
						<br />I specifically offer monthly fitness and nutritional guidance to help you stay on
						track to achieving your goal. This option is designed for those individuals that are
						looking for general guidance and nutritional support, as well as workout programs.
					</div>{' '}
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
