import React, { Component } from 'react';

import HowitWorks from './HowItWorks';
import Athletes from './Athletes';
import Medicine from './Medicine';

export default class Services extends Component {
	state = {
		page: 'how',
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	renderPage() {
		switch (this.state.page) {
			case 'how':
				return (
					<HowitWorks
						scrollStyle={this.props.scrollStyle}
						history={this.props.history}
						statePass={this.state}
						callBack={this.myCallback}
					/>
				);
			case 'med':
				return (
					<Medicine
						scrollStyle={this.props.scrollStyle}
						history={this.props.history}
						statePass={this.state}
						callBack={this.myCallback}
					/>
				);
			case 'ath':
				return (
					<Athletes
						scrollStyle={this.props.scrollStyle}
						history={this.props.history}
						statePass={this.state}
						callBack={this.myCallback}
					/>
				);
			default:
		}
	}

	myCallback = (dataFromChild) => {
		this.setState({
			page: dataFromChild,
		});
	};
	render() {
		return this.renderPage();
	}
}
