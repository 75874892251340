import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Mar272019 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Harness the Power of VO2 Max Testing</div>
						<br />
						<div className='blog-date-page'>Mar 27, 2019</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							If you track your carbs, time your laps and rarely forget your FitBit at home, you
							already understand the importance of collecting health data. But a test once utilized
							only in labs for elite athletes is now bringing more powerful fitness data to anyone,
							whether you’re a badminton buff or serial marathoner. The benefits of VO2 max testing
							reach far outside the realm of exercise—you can expect valuable insight into your risk
							for disease, energy level and even nutrition requirements.
							<br />
							<br />
							Here are a few reasons why you should test yours:
							<br />
							<br />
							<strong>Identify Disease Risk </strong>
							<br />
							<br />
							A lower VO2 max is directly associated with an increased risk of multiple chronic
							illnesses, like Type 2 Diabetes. By taking a VO2 max test, you hold another powerful
							indicator of your health and physical fitness in the palm of your hand. Use the
							information and work diligently to keep your heart and lungs strong, and you can
							decrease your risk of contracting chronic illnesses.
							<br />
							<br />
							<strong>Track Your Progress Objectively</strong>
							<br />
							<br />
							Watching that number on the scale? Looking at your mile pace over time? Shooting to
							lift more than you did last week? You’re tracking some factors that contribute to your
							health, but not your overall cardiovascular fitness level. VO2 max is the most
							precise, scientific way to measure your current fitness level. After implementing
							changes, you can test again and track your degree of change. And you can{' '}
							<a href='https://www.worldfitnesslevel.org/#/'>compare your stats</a> to those of
							other people your age.
							<br />
							<br />
							<strong>Exercise Efficiently and Avoid Overtraining</strong>
							<br />
							<br />
							You’ll learn about your custom training zones based on your VO2 max. If you’re looking
							to improve your speed and fitness, there’s a precise zone for just that. A
							professional can help break down your data to recommend the amount of time spent in
							each zone, preventing injury and overtraining. Check out some examples of VO2 max
							workout formats in this{' '}
							<a href='https://www.active.com/running/articles/how-to-maximize-your-vo2max-training'>
								Active.com
							</a>{' '}
							article. The goal? To spend the right amount of time training and the right amount
							recovering, in order see real, data-driven results in your performance.
							<br />
							<br />
							With the information VO2 max testing returns, you gain a serious advantage and control
							over your health and wellness.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
