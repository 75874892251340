import React, { Component } from 'react';
import BOK from '../../assets/img/calender.png';
import '../Book.css';

export default class BookNav extends Component {
	render() {
		return (
			<div className='scroll-icon location-scroll-icon'>
				<div onClick={this.props.click} className={`circle-border2 ${this.props.on}`}>
					<img className='small-button-icons2' src={BOK} alt='calendar icon' />
				</div>
				<h6 className='grey-label'>Book</h6>
			</div>
		);
	}
}
