import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec202015 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>
							DexaFit Services: 3D Body Scanner + Metabolic Testing
						</div>
						<br />
						<div className='blog-date-page'>May 10, 2018</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							Health and wellness are an important part of life, and everyone is always trying to
							figure out how to reach peak health through fitness plans, diets, workout routines and
							more. While there are many ways to stay in shape and maintain both physical and
							emotional wellness, you usually cannot do it all alone. When you seek out help from
							other people, spaces, and services, you open yourself to a wide range of opportunities
							for your best health, best body, and best level of wellness. DexaFit is one such space
							where health and wellness services for optimal health are the top priority. This
							company offers many resources that anyone can use and benefit from, making them an
							invaluable part of any fitness journey. So, what does DexaFit do, exactly? Below we
							have a run down of everything DexaFit does and how we do it.
							<br />
							<br />
							<strong>What Does DexaFit Do?</strong>
							<br />
							<br />
							Whether you're trying to get in shape, gain muscle, lose fat, figure out your future
							health risks, or learn how to care for yourself better, we offer a service that can
							help you reach your goal. At DexaFit, we understand that physical and mental wellness
							amount to more than just looking good on the outside. DexaFit is an integral part of
							creating goodness on the inside, so you could call us your step one. We are a company
							that wants to promote health in a way that is doable, accessible, and exciting. To do
							this, we offer solutions for a wide range of customers: there are gyms, studios, and
							trainers for both individuals and groups, solutions for corporate wellness, options
							for athletes and teams, and even services for physicians and healthcare professionals.
							Basically, wherever you are and whoever you are, we have a solution for you.
							<br />
							<br />
							<strong>DexaFit Main Services: Help You Understand Your Body</strong>
							<br />
							<br />
							One of the main things we do at DexaFit is helping our customers glean a better
							understanding of their unique bodies. We believe that in order to live well, you have
							to know what you're dealing with internally. With this in mind, we have developed a
							series of scans and tests that return data on your internal health and wellness. These
							scans and tests are a non-intrusive way to measure your body composition, body fat
							percentage, the risk of fractures and muscle pull, weak spots, and more. Opting for
							just one of our scans or tests is a great decision, but you can also do all four!
							Check them out:
							<br />
							<br />
							<strong>Body Scans</strong>
							<br />
							<br />
							<ul>
								<li>
									Dexa Scan: The Dexa Scan is known as the" Gold Standard of Body Composition
									Analysis." It is trusted by research and elite sports labs, uses FDA-approved
									technology, and is one of the simplest ways to get a precise reading on a number
									of health factors like your body mass index and more. The scan is ultimately used
									to quantify your lean, fat, and bone mass, which is used to identify your risk for
									osteoporosis. It also measures your levels of visceral fat, which directly
									correlates to your risk for certain diseases like diabetes and cardiovascular
									disease and hormone imbalances. To get started, you need to schedule an
									appointment with the DexaFit nearest you. Once you have a day, time, and place, be
									sure to show up workout clothes without any metal to your appointment. You will
									then lie down on the scanner for about 6-10 minutes. The test results are
									available immediately after your test and DexaFit tech will go over your results
									with you and answer any questions you may have. The results will offer prime diets
									for your body and health type, workout suggestions, fitness goals, and also
									references to a trainer and other Dexa services. With these results, you can
									create a plan that is tailored to your body and its needs.{' '}
								</li>
								<br />
								<li>
									3D Body Scan: The 3D body scan is used by leading researchers and performance
									centers around the world and is regarded as the optimal way to track your body
									composition health, posture, your internal wellness score, and much more. With
									this particular scan, you can learn 400+ precise measurements such as lengths,
									widths, volumes, and circumferences. It tracks different areas of health such as
									nutrition, psychology, and morbidity as well as reveals imbalances in muscle
									symmetry and strength, flexibility, and joint mechanics. This data allows you to
									identify which exercises work well for your body and which can disrupt training or
									cause pain. The 3D scan also provides an amazing visual of your results which
									helps you scan problem areas, fitness progress, and more. To get started, you must
									first schedule an appointment and then head over to your Dexa location to complete
									the test. All you have to do is stand on a platform, grab the handles, and stay
									completely still for about 40 seconds. The machine will take a complete 360 degree
									3D body scan and return that data to the FIt3d software. When done, you can go
									over the results with your Dexa technician, check out your Fit3D dashboard, and
									more.
								</li>
							</ul>
							<br />
							<br />
							<strong>Tests</strong>
							<br />
							<br />
							<ul>
								<li>
									Metabolic Test: You already know that your metabolism plays a major part in your
									body's weight management, but is that all you know? Learning more about your
									metabolism is a serious step in learning more about your health and wellness, and
									DexaFit offers a way to learn more than you ever could with the help of the
									Metabolic test. It shows you how well your body creates energy, what foods are
									best for you body, and how to work with the metabolism to lose weight and
									understand your personal fitness. To take the test, avoid exercise for at least 12
									hours prior and food, alcohol, tobacco, and caffeine for at least 5 hours prior.
									When you come in, you will sit in a chair and breath into a mask for about 10
									minutes. The test will calculate the amount of oxygen you consume during this time
									and afterward, a tech will go over your results with you.{' '}
								</li>
								<br />
								<li>
									VO2 Max Test: The VO2 Max test is meant to pinpoint your fitness level, pure
									endurance potential, and cardiovascular degeneration. With this data, you can
									exercise smarter, detect early signs of disease, and avoid overtraining yourself.
									For this test, you will wear a heart rate strap and a face mask. The mask is
									connected to Dexa's medical-grade metabolic cart. From here, you will start
									walking on a treadmill or bike for your warmup and then gradually increase your
									intensity for 10-20 minutes. Once you're done, you will receive your results and a
									tech will go over them with you.
								</li>
							</ul>
							<br />
							<br />
							<strong>DexaFit Analyses: Help You Identify and Avoid Health Risks</strong>
							<br />
							<br />
							Finally, Dexafit offers a number of additional services such as Blood Panel testing,
							Microbiome Testing, Food Intolerance testing, and more. Of these tests, two of the
							most useful analyses are the Bio Marker Analysis and the Genetic Fitness Analysis.
							<br />
							<br />
							The Bio Marker Analysis gauges the levels of key chemicals in your body, including
							micronutrients, hormones, and heavy metals, rating them on a scale to see if you are
							at, above, or below standard levels. With these results, you can track how your body
							changes when you try a new diet and fitness program. For the bio marker analysis, all
							you have to do is a select a panel or at-home kit that has been tailored to your needs
							and personal goals. Then, get your blood drawn and have the sample analyzed at one of
							the nearby DexaFit labs. Shortly afterward, the results will be sent to you via email.
							<br />
							<br />
							The Genetic Fitness Test entails looks at your DNA to glean an understanding of how
							you're built and what your body needs best on genetic markers. By better understanding
							your genes, you can know how to modify them to fit your environment and lifestyle. You
							can purchase the genetic fitness test that fits your needs directly from Dexa.
							<br />
							<br />
							To learn more about DexaFit and what we do, visit our wider site and look for a store
							near you. We can't wait to begin working with you!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
