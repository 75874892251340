import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec122019 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Are your hormones stalling your progress?</div>
						<br />
						<div className='blog-date-page'>DEC 12, 2019</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							You committed to an exercise program. <br />
							<br />
							You're following a diet that a friend or coach recommended.
							<br />
							<br />
							And you’re doing everything conventional wisdom claims to do to lose body fat.
							<br />
							<br />
							Yet still... no change. <br />
							<br />
							<strong>But have you checked your hormones?</strong> <br />
							<br />
							Every moment of every day, your body is performing a delicate dance of converting,
							storing and burning energy. How well it does this dance determines how you look, feel,
							and perform in your daily life. <br />
							<br />
							This process is a known as your metabolism.
							<br />
							<br />
							It’s when your body converts protein, fats, and carbohydrates into usable energy
							sources like amino acids, fatty acids, glucose, and at a fundamental level, electrons.
							Once it breaks these down into usable components, it transports them into your cells
							to grow, heal, build muscle, and much more. <br />
							<br />
							And because there are 100,000 biochemical reactions going inside of your cells in any
							given second, how well your cells communicate is critical to health. Your hormones
							play a central role in this communication process.
							<br />
							<br />
							If your hormones are out of whack, your metabolism will get wonky and weight gain,
							disease, and frustration will follow. <br />
							<br />
							<strong>Hormones 101</strong>
							<br />
							<br />
							So to help you avoid this trap, we’re introducing our Optimal Labs series. Over the
							next few weeks, we will share key insights about the hormones you need to know about
							if you want to catch critical issues in your body before they manifest as heart
							disease, cancer, diabetes, or worse.
							<br />
							<br />
							Knowledge of exactly what is going on inside your body empowers you to implement a
							science-based disease-prevention program that can add decades of healthy years to your
							life, literally.
							<br />
							<br />
							<strong>What are hormones?</strong>
							<br />
							<br />
							You've probably heard of testosterone, estrogen, cortisol, thyroid hormones, insulin,
							and melatonin. These are just a few of some 50 different hormones your body secretes
							and circulates.
							<br />
							<br />
							Every part of your body, including your brain, skin, heart, muscles, liver, etc, all
							have specific and unique jobs to carry out. Hormones are produced to tell your body
							what to do, when to do it, and for how long. They are your body’s chemical messengers.{' '}
							<br />
							<br />
							Hormones affect everything from your body’s growth and development to mood,
							metabolism, sexual function, reproduction, and so much more. <br />
							<br />
							Balanced and regulated hormones are essential to good health and well-being. <br />
							<br />
							The endocrine system includes a series of glands that produce and secrete your
							hormones. And just like there are many different types of hormones, there are also
							different glands that secrete them. Each gland produces a different hormone, likewise,
							each hormone contributes to a different function.
							<br />
							<br />
							When you understand the scope of your hormones, and how they affect your body, you can
							truly begin to optimize your health. <br />
							<br />
							<strong>Hormones to know</strong>
							<br />
							<br />
							Since there are over 50 hormones in your body, we’re going to focus on a few key
							hormones including:
							<br />
							<br />
							<ul>
								<li>
									<strong>Leptin</strong> - Known as the master hormone, it controls your appetite
									and sends signals to your brain when you’re “full." It also plays a significant
									role in fertility, immunity and brain function.
								</li>
								<li>
									<strong>Ghrelin</strong> - Appetite-regulating hormone that stimulates feelings of
									hunger.
								</li>
								<li>
									<strong>Thyroid</strong> - Responsible for regulating your body’s metabolic rate,
									heart and digestive function, muscle control, mood, and bone maintenance.
								</li>
								<li>
									<strong>Adrenaline</strong> - A stress hormone that contributes to the “fight or
									flight” response, increasing blood flow, heart rate, pupil dilation, and blood
									sugar levels.
								</li>
								<li>
									<strong>Cortisol</strong> - Considered the stress hormone, it’s in charge of our
									“fight or flight” response, increases heart rate, blood pressure, respiration, and
									muscle tension, while shutting down other body systems.
								</li>
								<li>
									<strong>Insulin</strong> - Helps to regulate metabolic processes and blood sugar
									levels in the body.
								</li>
								<li>
									<strong>Vitamin D</strong> - Known as the immunity steroid and sunshine hormone,
									it controls levels of calcium, phosphorus, mineralization of bone, and so much
									more.
								</li>
								<li>
									<strong>Melatonin</strong> - First produced by the eye where ocular melatonin
									helps liberate melatonin in your pineal gland in response to the absence of light.
									It controls your internal body clock and is directly related to sleep quality.
								</li>
								<li>
									<strong>DHEA</strong> - An important precursor hormone, which converts into other
									hormones, including testosterone and estrogen.
								</li>
								<li>
									<strong>Growth Hormone</strong> - This peptide hormone stimulates bone and muscle
									growth, cell reproduction and regeneration, and influences your body composition
									health.
								</li>
								<li>
									<strong>Progesterone</strong> - An important female sex hormone produced mainly in
									the ovaries following ovulation each month. It’s a crucial part of the menstrual
									cycle and maintenance of pregnancy.
								</li>
								<li>
									<strong>Estrogen</strong> - Contributes to sex drive in both men and women.
								</li>
								<li>
									<strong>Testosterone</strong> - A male and female sex hormones that influences sex
									drive, motivation, and muscle growth.
								</li>
							</ul>
							<br />
							Hormone imbalances happen when there is too little or too much of any of the hormones
							listed above. Even the slightest imbalance could be the reason you're not seeing the
							progress you think you should be. <br />
							<br />
							So get ready to know your body and transform your life, from the inside out. Over the
							next few weeks, we'll be taking a deeper dive into each of these hormones and learning
							how we can avoid the imbalances that negatively impact our health and body
							composition.
							<br />
							<br />
							Stay tuned!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
