import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec292015 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>
							A More Convenient & Enjoyable Way to Measure Body Fat
						</div>
						<br />
						<div className='blog-date-page'>Dec 29, 2015</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							<strong>What to expect when you visit DexaFit...</strong>
							<br />
							<br />
							You’ll discover new ways to explore and improve your health and body composition.
							You’ll never have more fun constantly investigating and critically rethinking
							everything you assumed to be true about health, nutrition, training, and overall
							wellness.
							<br />
							<br />
							And most importantly, you’ll stay updated on the latest developments in the research,
							science and medical communities—learning how to distill complex theory into actionable
							steps that you can follow to reach your goals sooner.
							<br />
							<br />
							No matter what your personal situation is—improving sport performance, building mass,
							losing fat, monitoring your risk for fracture—you’ll discover how to bridge the gap
							between your body’s limiting factors and your performance goals.
							<br />
							<br />
							<strong>How to reach your goals sooner...</strong>
							<br />
							<br />
							Improving your health, body composition, and overall performance requires a lot of
							time, effort, discipline, and money.
							<br />
							<br />
							But with medical costs increasing and health problems like obesity, diabetes, and
							heart disease on the rise, taking personal responsibility for your own health has
							never been more important.
							<br />
							<br />
							Accountability and ‘feedback focused’ nutrition and exercise programs are paramount to
							a improving your health and overall enjoyment of life. You need to know if what you’re
							actually doing is working.
							<br />
							<br />
							But how can you know?
							<br />
							<br />
							Weight on a scale only measures weight loss and weight gain—a poor indicator of
							progress.
							<br />
							<br />
							Mirrors and photos only provide an educated guess of your program’s effectiveness.
							<br />
							<br />
							These measurements and countless others are biased feedback tools we use to blindly
							try and improve our fitness and health.
							<br />
							<br />
							These biases affect our emotions and frame how we see ourselves.
							<br />
							<br />
							Medical science has long been aware of the inherent bias that occurs in collecting and
							analyzing data. This is why double blind, randomized controlled studies and other
							protocols were designed to nullify its influence.
							<br />
							<br />
							But we don’t live in a laboratory, so bias can’t be completely eliminated when
							measuring a fat loss diet and nutrition program.
							<br />
							<br />
							Or can it?
							<br />
							<br />
							<strong>Objective feedback that keeps you on track...</strong>
							<br />
							<br />
							Information only has importance in proportion to its relevance and meaning. Its
							ultimate value is how we use it to make decisions and put it in a framework of
							knowledge.
							<br />
							<br />
							In the realm of health, fitness, and nutrition, information needs to provide honest
							and outcome-based feedback to anyone pursuing a successful health and body
							transformation.
							<br />
							<br />
							And guess what…
							<br />
							<br />
							With technology innovation and more people connecting with each other to solve this
							problem, previous hurdles are now getting leaped at an exponential rate!
							<br />
							<br />
							Traditionally reserved for clinical and research settings only, our primary
							technology, DXA, objectively and consistently measures the primary variables that
							influence your health, body composition, and performance.
							<br />
							<br />
							Knowing how your body is changing at this granular level with DXA is POWERFUL. It’s
							like using a scope on a Navy Seal’s sniper rifle to target the problem areas of your
							body (unhealthy fat mass) and avoiding any friendly-fire (lean mass fallen soldiers).
							<br />
							<br />
							The benefits of DXA are countless (outlined in previous and forthcoming posts). So
							stay tuned for more insights showing you how many of our clients empower themselves
							with our technologies to know their bodies better and transform their lives!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
