import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store1 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'DNA Analysis Kit',
			price: 199.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>DNA Analysis Kit</div>
							<br />
							<br />
							<div>$199.00</div> <br />
							<br />
							<strong>Fitness Genes DNA Analysis Kit</strong> <br />
							<br />
							<div>
								With just a small amount of your saliva, we analyze over 600,000 of your gene
								variants using state-of-the-art microarray technology. You’ll receive a report on 42
								of your most important gene results, including those that impact appetite, eating
								behavior, VO2 max, fat burning, sleep cycle, nutrient metabolism, muscle strength,
								insulin function, post-workout recovery, muscle fiber composition and much more.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<br />
					<div className='product-title text-center'>Your genes are the starting point.</div>
					<br />
					<div className='product-blue-text'>
						Discover more than 40 genes related to fitness, health, and nutrition. We analyze and
						combine your DNA traits and lifestyle survey data to get a clear view of how your body
						responds to diet and exercise.
					</div>
					<br />
					<div>
						<strong>DNA Analysis</strong>
					</div>
					<br />
					<div>
						We unlock your genetic code and give you detailed descriptions of what your DNA says
						about you.
					</div>
					<br />
					<div>
						<strong>Workout Plans</strong>
					</div>
					<br />
					<div>Remove the guesswork from training with our genetically-tailored workout plans.</div>
					<br />
					<div>
						<strong>Nutrition Guide</strong>
					</div>
					<br />
					<div>
						Eat according to your unique genetic make-up, lifestyle and fitness goals with our
						personalized nutrition guide.
					</div>
					<br />
					<div>
						<strong>Insights & Actions</strong>
					</div>
					<br />
					<div>
						Find out how you respond to diet and exercise and get actionable advice on how to
						optimize your nutrition and performance.
					</div>
					<br />
					<br />
					<div className='product-title text-center'>How does it work?</div>
					<br />
					<div className='product-blue-text'>
						With just a small amount of your saliva, we analyze over 600,000 of your gene variants
						using state-of-the-art microarray technology
					</div>
					<br />
					<div className='text-center'>
						You’ll receive a report on 42 of your most important gene results, including those that
						impact appetite, eating behavior, VO2 max, fat burning, sleep cycle, nutrient
						metabolism, muscle strength, insulin function, post-workout recovery, muscle fiber
						composition and much more.
					</div>
					<br />
					<div>
						<strong>DNA ANALYSIS</strong>
					</div>
					<br />
					<ul>
						<li>Free 1-week Genetic</li>
						<br />
						<li>Workout Plan</li>
						<br />
						<li>Discover your traits on truefeed®</li>
						<br />
						<li>React to & save your personalized actions</li>
						<br />
						<li>Adapt your lifestyle to reach the best version of you</li>
						<br />
						<li>DNA Results</li>
						<br />
						<li>Nutrition Guide</li>
						<br />
						<li>Recommended Recipes</li>
						<br />
					</ul>
					<br />
					<div>
						<strong>MEMBERS AREA</strong>
					</div>
					<br />
					<ul>
						<li>Online members portal</li>
						<br />
						<li>Training action blueprint</li>
						<br />
						<li>Nutrition action blueprint with calculator</li>
						<br />
						<li>FitnessGenes eBooks</li>
						<br />
						<li>Exercise library</li>
						<br />
						<li>Glossary</li>
						<br />
						<li>Ongoing Gene And Scientific Updates</li>
						<br />
					</ul>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
