import React, { Component } from 'react';
import { Container } from 'reactstrap';

import Dexa1 from './slides/Dexa-1';
import Dexa2 from './slides/Dexa-2';
import Dexa3 from './slides/Dexa-3';
import Dexa4 from './slides/Dexa-4';
//import Dexa5 from './slides/Dexa-5';
import Dexa6 from './slides/Dexa-6';
import Dexa7 from './slides/Dexa-7';

import Vo21 from './slides/Vo2-1';
import Vo22 from './slides/Vo2-2';
import Vo23 from './slides/Vo2-3';
import Vo24 from './slides/Vo2-4';
//import Vo25 from './slides/Vo2-5';
import Vo26 from './slides/Vo2-6';
import Vo27 from './slides/Vo2-7';

import RmR1 from './slides/Rmr-1';
import RmR2 from './slides/Rmr-2';
import RmR3 from './slides/Rmr-3';
import RmR4 from './slides/Rmr-4';
//import RmR5 from './slides/Rmr-5';
import RmR6 from './slides/Rmr-6';
import RmR7 from './slides/Rmr-7';

import ThreeD1 from './slides/ThreeD-1';
import ThreeD2 from './slides/ThreeD-2';
import ThreeD3 from './slides/ThreeD-3';
import ThreeD4 from './slides/ThreeD-4';
//import ThreeD5 from './slides/ThreeD-5';
import ThreeD6 from './slides/ThreeD-6';
import ThreeD7 from './slides/ThreeD-7';

import Lab1 from './slides/Lab-1';
import Lab2 from './slides/Lab-2';
import Lab3 from './slides/Lab-3';
import Lab4 from './slides/Lab-4';
//import Lab5 from './slides/Lab-5';
import Lab6 from './slides/Lab-6';
import Lab7 from './slides/Lab-7';

import Home21 from './slides/Home2-1';
import Fit2 from './slides/Fit-2';
import Fit3 from './slides/Fit-3';
import Fit4 from './slides/Fit-4';
import Fit6 from './slides/Fit-6';
import Home5 from './slides/Home-5';

// import Fit1 from './slides/Fit-1';
// import Fit2 from './slides/Fit-2';
// import Fit3 from './slides/Fit-3';
// import Fit4 from './slides/Fit-4';
// //import Fit5 from './slides/Fit-5';
// import Fit6 from './slides/Fit-6';
// import Fit8 from './slides/Fit-8';
// import Fit9 from './slides/Fit-9';

import Footer from '../Footer';

export default class Services extends Component {
	constructor(props) {
		super(props);
		this.fitRef = React.createRef();
		this.fitRef2 = React.createRef();
	}
	state = {
		page: 'fit',
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	scrollButton = () => {
		this.fitRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
	};

	scrollButton2 = () => {
		this.fitRef2.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
	};

	renderPage() {
		switch (this.state.page) {
			case 'dexa':
				return (
					<div>
						<Dexa1
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<Dexa2 />
						<Dexa3 />
						<Dexa4 history={this.props.history} />
						{/* <Dexa5 /> */}
						<Dexa6 />
						<Dexa7 />
					</div>
				);
			case 'vo2':
				return (
					<div>
						<Vo21
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<Vo22 />
						<Vo23 />
						<Vo24 history={this.props.history} />
						{/* <Vo25 /> */}
						<Vo26 />
						<Vo27 />
					</div>
				);
			case 'rmr':
				return (
					<div>
						<RmR1
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<RmR2 />
						<RmR3 />
						<RmR4 history={this.props.history} />
						{/* <RmR5 /> */}
						<RmR6 />
						<RmR7 />
					</div>
				);
			case '3d':
				return (
					<div>
						<ThreeD1
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<ThreeD2 />
						<ThreeD3 />
						<ThreeD4 history={this.props.history} />
						{/* <ThreeD5 /> */}
						<ThreeD6 />
						<ThreeD7 />
					</div>
				);
			case 'lab':
				return (
					<div>
						<Lab1
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<Lab2 />
						<Lab3 />
						<Lab4 history={this.props.history} />
						{/* <Lab5 /> */}
						<Lab6 />
						<Lab7 />
					</div>
				);
			case 'fit':
				return (
					<div>
						{/* <Fit1
							scrollStyle={this.props.scrollStyle}
							history={this.props.history}
							statePass={this.state}
							callBack={this.myCallback}
						/>
						<Fit2 />
						<Fit3 />
						<Fit4 history={this.props.history} />
						<Fit5 />
						<Fit6 history={this.props.history} />
						<Fit8 history={this.props.history} />
						<Fit9 history={this.props.history} /> */}
						<Home21
							scrollButton={this.scrollButton}
							scrollButton2={this.scrollButton2}
							callBack={this.myCallback}
							history={this.props.history}
						/>
						<div ref={this.fitRef2}>
							<Fit2 callBack={this.myCallback} history={this.props.history} />
						</div>
						<Fit3 callBack={this.myCallback} history={this.props.history} />
						<Fit6 callBack={this.myCallback} history={this.props.history} />
						<div ref={this.fitRef}>
							<Fit4 callBack={this.myCallback} history={this.props.history} />
						</div>
						<Home5 callBack={this.myCallback} />
					</div>
				);
			default:
		}
	}

	myCallback = (dataFromChild) => {
		this.setState({
			page: dataFromChild,
		});
	};
	render() {
		return (
			<Container fluid>
				{this.renderPage()}
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
