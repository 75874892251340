import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Feb282020 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>3 Ways to Improve your Self-Care Routine</div>
						<br />
						<div className='blog-date-page'>Feb 28, 2020</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							There’s no better time to update your self-care routine than now. Everyone has their
							own unique routine that is geared toward what is most important to them. <br />
							<br />
							Popular self-care routines include a workout schedule, a sleep pattern and a
							nutritious diet, all of which can easily be disrupted after a while and can benefit
							from a little tune up from time to time.
							<br />
							<br />
							<strong>Create a workout schedule </strong> <br />
							<br />
							Staying on top of workout schedules can be difficult because your body is always
							changing and your schedule needs to keep up with it. It’s easy to make a workout plan
							for the week or the month but you also need to listen to your body day-to-day. <br />
							<br />
							One of the most important aspects of a workout routine is determining when, where and
							why you workout. Finding a time that works best for your schedule, and a place that
							motivates you is crucial to sticking with your exercise routine. <br />
							<br />
							If you’re an early bird, maybe working out before heading to work is the best option
							for you. Or perhaps you fall on the other end of the spectrum and you’re a night owl,
							evening workouts may be best. Once you determine which time of day is right for you,
							you need to decide which place is right for you. Are you most productive at the gym
							alone or with a trainer, in a class with an instructor or on your own outside or at
							home? Find a place where you feel you can be the most productive and motivated. <br />
							<br />
							Understanding your ‘why’ for working out is going to help keep you accountable to your
							routine. Before you decide when and where to exercise,{' '}
							<a href='https://www.nerdfitness.com/blog/how-to-build-your-own-workout-routine/'>
								determine what you’re trying to accomplish
							</a>
							. Are you trying to lose body fat, gain lean muscle, or maybe maintain your current
							physical condition? All of these actions require different workout styles and diets.
							To decide what you’re looking to get from your fitness routine, do some research and
							make a plan for each day. Going into your workout with a set plan of what you’re going
							to accomplish will be much more productive than “winging it.” After a few weeks of
							training, modify your workout with more weight, more reps or further distance, to keep
							seeing results. <br />
							<br />
							<strong>Sleep Patterns</strong>
							<br />
							<br />
							Consistent sleep patterns are critical to your overall well-being. Sleep affects your
							mood, energy, stress, anxiety and depression levels.{' '}
							<a href='https://health.gov/myhealthfinder/topics/everyday-healthy-living/mental-health-and-relationships/get-enough-sleep'>
								Your sleep patterns can easily be disrupted
							</a>{' '}
							by medications, caffeine, poor sleep environment and too much exposure to blue light.{' '}
							<br />
							<br />
							Stimulants like caffeine, alcohol, nicotine or prescription medication before bed can
							disrupt your quality of sleep so try to avoid interaction with these substances at
							least 1-2 hours before bed. In addition to what you consume prior to sleep, where you
							sleep is just as important. Sleeping in a room that’s too hot, cold, loud or bright
							will surely keep you awake. Try to keep your bedroom cool, and limit use of the TV and
							lights near bedtime. <br />
							<br />
							Blue light exposure is a huge factor to disturbed sleep patterns. As you increase the
							use of digital devices in your everyday life, you’re also increasing your exposure to
							blue light wavelengths. Blue light wavelengths are given off by the sun, which you’re
							naturally exposed to throughout the day; these wavelengths stimulate your eyes and
							mind causing you to feel more awake. They’re also given off by your device’s screens,
							like such smartphones, laptops and televisions. This negatively affects your natural
							sleep pattern because after the sun goes down, you’re still being stimulated by the
							blue light that is radiating off of your devices, which is confusing your brain. In
							order to prevent disrupted sleep patterns from digital devices, there are a few action
							steps you can take to prevent sleep disruption from blue light:
							<br />
							<br />
							<ul>
								<li>
									buy a pair of{' '}
									<a href='https://www.warbyparker.com/blue-light'>blue light protection glasses</a>
								</li>
								<li>use Night Shift mode on your devices</li>
								<li>stop using devices about an hour before bed</li>
							</ul>
							<br />
							<br />
							<strong>Healthy Diet</strong>
							<br />
							<br />
							Making changes to your diet doesn’t have to be a big haul! In fact, a gradual
							transition to a nutrient dense diet is what’s best for sustaining your long-term goal.
							Instead of cutting out all of your favorite foods, switching to only fruits and
							vegetables, and falling into the latest diet-fad, try switching out less nutritious
							foods for ones that are packed with nutrients. Each week aim to make a healthier swap.
							With this gradual method, you can wean yourself off of foods that are keeping you from
							achieve your goals, to the ones that are. <br />
							<br />
							Remember, slow and steady wins the race. Taking small steps toward a healthier
							lifestyle is an effective method that helps achieve sustainable results. By
							determining the right workout for you, maintaining a proper sleep schedule and
							practicing a nutritious diet, you will see positive results in your overall health
							that will compound over time.
							<br />
							<br />
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
