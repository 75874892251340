import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import LocNav from './IconNav/LocNav';
import ServNav from './IconNav/ServNav';
import PriceNav from './IconNav/PriceNav';
import BookNav from './IconNav/BookNav';
import FaqNav from './IconNav/FaqNav';
import ContNav from './IconNav/ContNav';
import './Service.css';

export default class Service1 extends Component {
	state = {
		loc: true,
		ser: false,
		price: false,
		book: false,
		faq: false,
		cont: false
	};

	componentDidMount() {
		this.stateHandler();
	}

	componentDidUpdate(prevProps, prevState) {
		this.stateHandler(prevProps, prevState);
	}
	stateHandler(value) {
		switch (value) {
			case 'loc':
				this.setState({
					loc: true,
					ser: false,
					price: false,
					book: false,
					faq: false,
					cont: false
				});
				break;
			case 'ser':
				this.setState({
					loc: false,
					ser: true,
					price: false,
					book: false,
					faq: false,
					cont: false
				});
				break;
			case 'book':
				this.setState({
					loc: false,
					ser: false,
					price: false,
					book: true,
					faq: false,
					cont: false
				});
				break;
			case 'faq':
				this.setState({
					loc: false,
					ser: false,
					price: false,
					book: false,
					faq: true,
					cont: false
				});
				break;
			case 'cont':
				this.setState({
					loc: false,
					ser: false,
					price: false,
					book: false,
					faq: false,
					cont: true
				});
				break;
			case 'price':
				this.setState({
					loc: false,
					ser: false,
					price: true,
					book: false,
					faq: false,
					cont: false
				});
				break;
			default:
		}
	}

	classReturn = statecheck => {
		switch (statecheck) {
			case 'loc':
				if (this.state.loc === true) {
					return 'on';
				} else {
					return '';
				}
			case 'ser':
				if (this.state.ser === true) {
					return 'on';
				} else {
					return '';
				}
			case 'book':
				if (this.state.book === true) {
					return 'on';
				} else {
					return '';
				}
			case 'faq':
				if (this.state.faq === true) {
					return 'on';
				} else {
					return '';
				}
			case 'cont':
				if (this.state.cont === true) {
					return 'on';
				} else {
					return '';
				}
			case 'price':
				if (this.state.price === true) {
					return 'on';
				} else {
					return '';
				}
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col lg='8'>
					<div className='scroll-bar'>
						<LocNav
							click={() => {
								this.stateHandler('loc');
								this.props.scrollButton('book1');
							}}
							on={this.classReturn('loc')}
						/>
						<ServNav
							click={() => {
								this.stateHandler('ser');
								this.props.scrollButton('book3');
							}}
							on={this.classReturn('ser')}
						/>
						<PriceNav
							click={() => {
								this.stateHandler('price');
								this.props.scrollButton('price2');
							}}
							on={this.classReturn('price')}
						/>
						<BookNav
							click={() => {
								this.stateHandler('book');
								this.props.scrollButton('book4');
							}}
							on={this.classReturn('book')}
						/>
						<FaqNav
							click={() => {
								this.stateHandler('faq');
								this.props.scrollButton('book6');
							}}
							on={this.classReturn('faq')}
						/>
						<ContNav
							click={() => {
								this.stateHandler('cont');
								this.props.scrollButton('book2');
							}}
							on={this.classReturn('cont')}
						/>
					</div>
				</Col>
			</Row>
		);
	}
}
