import React, { Component } from 'react';
import FAQ from '../../assets/img/checklist.png';
import '../Book.css';

export default class FaqNav extends Component {
	render() {
		return (
			<div className='scroll-icon location-scroll-icon'>
				<div onClick={this.props.click} className={`circle-border2 ${this.props.on}`}>
					<img id='faq-icon-img' className='small-button-icons2' src={FAQ} alt='check list icon' />
				</div>
				<h6 className='grey-label'>FAQs</h6>
			</div>
		);
	}
}
