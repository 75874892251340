import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/dexa-table-1.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class Dexa6 extends Component {
	render() {
		return (
			<Row className='off-color2'>
				<Col lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl on the dexa scan table' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col
					className='spacer-17'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					xl={{ size: 4, offset: 1 }}>
					<Fade right>
						<h2 className='big-label'>Why DexaFit</h2>
						<br />
						<p className='small-label-3'>
							Common alternative methods, such as BMI, Bioimpedance, Bod Pod, and hydrostatic
							weighing, are often used incorrectly as a surrogate for body composition health. But
							they can be dangerously misleading. <br />
							<br />
							For example, if you have a BMI of 25 kg/m2, you may have a body fat percentage
							anywhere from 10-35 percent. If you use other alternative methods, you may see a
							margin of error range from 5 to 15%. <br />
							<br />
							Whereas as a DEXA scan’s accuracy and precision ranges from a 1 to 2 percent margin of
							error, it also provides additional information that BMI and alternative testing
							methods cannot, including:
						</p>
					</Fade>
				</Col>
			</Row>
		);
	}
}
