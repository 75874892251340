import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import Phone from '../../assets/img/phone@3x.png';
import '../Service.css';

export default class Lab2 extends Component {
	render() {
		return (
			<Row>
				<Col
					className='spacer-1'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					lg={{ size: 7, offset: 1 }}>
					<Row>
						<Fade left>
							<Col className='spacer-7' md='12'>
								<h2 className='big-label'>Modern Lab Testing At Your Fingertips</h2>
								<h4 className='small-lable'>
									It has never been this easy to take control of your health.
								</h4>
							</Col>
						</Fade>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Know Thy Blood</h4>
								<p className='grey-p'>
									Get straightforward answers and easy-to-read results. See exactly what your body
									wants you to know about hormones, food sensitivities, and much more.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='Target icon' />
								</div>
								<h4 className='small-label-2'>Heal Thy Gut</h4>
								<p className='grey-p'>
									Track your progress and discover your personal gut health with Sun Genomics.
									Gutbuster™ technology analyzes trillions of microbes inside your gut. Meet your
									microbiome to boost your wellness and stay fit.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='Internet icon' />
								</div>
								<h4 className='small-label-2'>Know Thy DNA</h4>
								<p className='grey-p'>
									Get fit and stay healthy with a genetically tailored workout system. Know the
									genetic cards nature dealt you. FitnessGenes shows you an efficient way to keep
									fit.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col className='d-flex align-items-end' md='6' lg='4'>
					<Fade right>
						<img className='phone' src={Phone} alt='phone app' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
