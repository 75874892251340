import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledCarousel } from 'reactstrap';
import service1 from '../../assets/img/1.png';
import service2 from '../../assets/img/2.png';
import service3 from '../../assets/img/3.png';
import service4 from '../../assets/img/4.png';
import service6 from '../../assets/img/6.png';
import service7 from '../../assets/img/7.png';
import service8 from '../../assets/img/8.png';
import service10 from '../../assets/img/10.png';
//import Fade from 'react-reveal/Fade';
import '../Book.css';

export default class Book3 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		const items = [
			{
				src: service1,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service2,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service3,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service4,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service6,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service7,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service8,
				altText: '',
				caption: '',
				header: '',
			},
			{
				src: service10,
				altText: '',
				caption: '',
				header: '',
			},
		];

		return (
			<Row className='off-color-5'>
				<Col lg='7'>
					<UncontrolledCarousel className='carousel-book' items={items} />
				</Col>
				<Col lg='5' id='book-3-center'>
					<div id='services-we-provide'>Services We Provide</div>
					<div id='DEXA-Body-Com'>
						DEXA Body Composition Analysis <br />
						<br />
						3D Body Scanning <br />
						<br />
						RMR (Resting Metabolic Rate) Testing <br />
						<br />
						VO2max Cardiovascular Fitness Testing <br />
						<br />
						DexaFit Preventative Care Plans <br />
						<br />
						At-Home Lab Testing for Food Sensitivities, Hormones, DNA, Microbiome, and more
					</div>
					<Button onClick={(e) => this.handlePageChange('/Services')} id='learn-more-button'>
						Learn More
					</Button>
				</Col>
				<div id='blue-block-book-2'></div>
			</Row>
		);
	}
}
