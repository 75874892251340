import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import Phone from '../../assets/img/phoneBack.png';
import '../Service.css';

export default class ThreeD2 extends Component {
	render() {
		return (
			<Row>
				<Col
					className='spacer-1'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					lg={{ size: 7, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<Fade left>
								<h2 className='big-label'>A 3D Image Of You In Only 40 Seconds</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Precise measurements</h4>
								<p className='grey-p'>
									Get 400+ measurements and .Insights of key areas of your body, including
									circumferences, lengths, widths, and volumes.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Actionable insights</h4>
								<p className='grey-p'>
									Reveal imbalances in muscle symmetry, strength, flexibility, and joint mechanics.
									These can hinder training and cause pain.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Trusted by the best</h4>
								<p className='grey-p'>
									Research, medicine, and elite sports teams use 3D body analysis to track body
									measurements.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col className='d-flex align-items-end' md='6' lg='4'>
					<Fade right>
						<img className='phone' src={Phone} alt='phone app' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
