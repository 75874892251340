import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class Fit7 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false
	};

	clickToggle = item => {
		switch (item) {
			case '1':
				this.setState(prevState => ({
					item1: !prevState.item1
				}));
				break;
			case '2':
				this.setState(prevState => ({
					item2: !prevState.item2
				}));
				break;
			case '3':
				this.setState(prevState => ({
					item3: !prevState.item3
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<Fade left>
								<h2 className='big-label spacer-5'>Learn more about Dexa</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											Do I need DexaFit testing results to use Fit?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<div>
													This plan is suitable for the beginner IT specialist and will allow you to
													familiarize yourself with all the main functions of the service.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											How is my Longevity Score calculated?
										</div>
										{this.state.item2 ? (
											<Fade top>
												<div>
													This plan is suitable for the beginner IT specialist and will allow you to
													familiarize yourself with all the main functions of the service.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											What is the advantage of the Body Score over body fat %?
										</div>
										{this.state.item3 ? (
											<Fade top>
												<div>
													This plan is suitable for the beginner IT specialist and will allow you to
													familiarize yourself with all the main functions of the service.
												</div>
											</Fade>
										) : null}
									</div>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
