import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Jun212018 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Does Metabolic Testing Benefit Athletes?</div>
						<br />
						<div className='blog-date-page'>Jun 21, 2018</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							As an athlete, your health and wellness are a major part of your livelihood. Keeping
							your body safe, strong, and able is of the utmost importance to keep your passion
							alive and well. Most athletes know that to keep themselves in top shape, they must eat
							well, sleep well, and exercise regularly. <br />
							<br />
							However, these efforts might not always be enough. What happens when you hit a
							plateau? What do you do when the fat you're trying to lose is stubborn or your lean
							muscle isn't increasing at all?
							<br />
							<br />
							To be sure, eating a balanced diet, getting enough sleep, and having a regular workout
							routine is important, but there is also more you can do for yourself and your health.
							<br />
							<br />
							One way to really up your fitness game is to partner with DexaFit and undergo
							metabolic testing.
							<br />
							<br />
							Metabolic testing allows you to understand your body on a deeper level, offering you
							insight into what's happening on the inside on a molecular level. This means that when
							looking to gain muscle and lose fat, metabolic testing can tell you why your body is
							(or is not) losing fat and gaining muscle the way you want it to.
							<br />
							<br />
							The test can reveal different factors that may be hindering the kind of development
							you want to see, putting you at a serious advantage over your circumstances.
							<br />
							<br />
							<div className='text-center'>
								<strong>Understanding Metabolic Testing: A Quick Breakdown</strong>
								<br />
								<br />
								<strong>What is Metabolism? Metabolic Health and RMR</strong>
								<br />
								<br />
							</div>
							It's no secret that your metabolism is the driving force behind weight loss and
							regulation. Metabolism, or the metabolic system, is the process of chemical reactions
							within the body responsible for keeping the body alive and nourished. Metabolism is
							fueled by proper nutrition, but other factors can affect it as well, such as genetics.
							<br />
							<br />
							In addition to understanding metabolism, it is also important to understand your RMR
							or resting metabolic rate. Resting metabolic rate refers to how much energy it takes
							(aka the number of calories you burn) to keep your body alive and working well while
							at rest. In other words, the amount of energy it takes to eat, sleep, breathe, think,
							and simply sit on the couch is covered by your resting metabolic rate.
							<br />
							<br />
							DexaFit offers an RMR test that is a non-invasive way of measuring your resting
							metabolism. It analyzes your breath and determines how much oxygen you inhale
							normally, which in turn measures the number of calories burned while resting. <br />
							<br />
							<strong>
								Metabolism and Body Composition: How These Affect Weight Loss and Overall Health
							</strong>{' '}
							<br />
							<br />
							Another important factor in metabolism and metabolic testing is your natural body
							composition. While understanding and paying attention to body composition is important
							for everyone, it is especially important for athletes.
							<br />
							<br />
							Why? For one, research has shown that a balanced body composition, meaning the right
							portion of muscle mass to body fat, can increase strength, power, and agility for an
							athlete.
							<br />
							<br />
							Measuring body composition is an effective way to learn not only your body fat
							percentage, but also gain a detailed analysis of your body's general makeup. This
							means muscle mass, fat percentage, weak spots in muscle and bone, strong and refined
							areas, and areas that can use a bit more training.
							<br />
							<br />
							The information returned from a body composition test provides athletes with data that
							enables them to learn better ways of caring for, training, and strengthening their
							bodies. Many sports teams, college sports labs, and more use and swear by body
							composition testing.
							<br />
							<br />
							Learning about individual body composition leads to better training programs, diets,
							and an overall understanding of athletic health. You can learn about your body
							composition and fat percentage by taking a Dexa (DXA) Scan.
							<br />
							<br />
							<strong>How Metabolic Testing Benefits Athletes</strong> <br />
							<br /> So, how exactly does metabolic testing benefit athletes? We've already talked
							about a better understanding of one's body mass, fat percentage, and metabolism, but
							this can go a bit further. Thanks to metabolic testing, athletes can:
							<br />
							<br />
							<strong>1. Craft Their Perfect, Individualized Diet Plan:</strong> Sure, you know
							about the food pyramid and the food groups that everyone should stick to, but you also
							know that not everyone is the same. Some meals might be super healthy for one
							individual, and a harmful to another.
							<br />
							<br />
							Metabolic testing helps athletes to know which foods are right for them, and which
							foods to avoid due to dietary issues, genetic disposition, and other factors. This
							means you can create a diet plan with your very best personalized fitness in mind
							because it includes everything your body needs and wants and nothing it doesn't.
							<br />
							<br />
							Since food is the fuel of your metabolism, it makes perfect sense to want to create a
							diet that caters directly to what will boost your metabolism and make it stronger.
							Something as simple as creating a special diet perfect for you can help boost your
							training prowess considerably.
							<br />
							<br />
							Not only will you be eating well, you'll be seeing the results you want too.
							<br />
							<br />
							<strong>2. Create a Workout Routine Just for Them:</strong> Different athletes need
							different workouts to train different parts of their bodies. For example, swimmers and
							runners might find that they have similar training routines, but there will always be
							differences in what each athlete does to build strength. This is because different
							types of exercise are more useful for different types of athletes.
							<br />
							<br />
							A runner will have a different workout from a swimmer, the same way a swimmer will
							have a different workout from a tennis player. Like the food pyramid and the basic
							food groups, there are some exercises (walking, jogging, etc) that are useful no
							matter your sport, and others that are better for certain sports.
							<br />
							<br />
							With metabolic testing, you can learn your body's weaknesses and strengths, alerting
							you to exercises that might do more harm than good, and those that will benefit your
							body better than another could.
							<br />
							<br />
							<strong>3. Learn About Their Personal Risk for Disease:</strong> While your genetics
							(paired with your focus, ambition, and drive) might have something to do with your
							athletic prowess, they also have something to do with the health problems you're most
							at risk for.
							<br />
							<br />
							Metabolic testing and learning your body composition through a Dexa Scan can return
							data on what you're at risk for. You may have a family history of diabetes or
							hypertension, or you may have an increased risk for fractures or sprains due to weak
							spots in your bones and muscles.
							<br />
							<br />
							Whatever health risks you may be facing in the future, metabolic testing and body
							composition testing can alert you to them now. With this information, you'll be able
							to adjust your diet and exercise plans to lower risk and increase strength.
							<br />
							<br />
							Learning about disease risk now also gives you a chance to talk to your primary doctor
							and trainer now to make necessary changes for later.
							<br />
							<br />
							<strong>4. Make Moves to Attain the Body They Want, Inside and Out:</strong> Finally,
							metabolic testing can give you the athletic body you've always wanted, but with a
							little twist. Some athletes want a strong body that looks good on the outside, and
							they may compromise internal health to meet this goal.
							<br />
							<br />
							When you work with DexaFit for metabolic testing and more, you're making moves to
							attain a healthy body on the inside and out. This means you can look as ripped and
							toned as you desire while also resting assured that your insides are healthy,
							functioning well, looking good, and thriving.
							<br />
							<br />
							<strong>
								So, there you have it: metabolic testing comes with a ton of benefits for athletes.
							</strong>
							<br />
							<br />
							Not only will you learn how to take care of yourself better, but you'll also get to
							understand your body on a much deeper level. This will help you pave the road to
							lifelong health and wellness, giving your athletic career some longevity, and your
							overall health some serious staying power.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
