import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Review from '../../assets/img/Dexafit_Review.png';
import '../Service.css';

export default class Lab4 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row>
				<Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }}>
					<Row className='spacer-2'>
						<Col xs='12' sm='7' md='7' xl='5'>
							<Fade left>
								<h2 className='big-label'>How it works</h2>
							</Fade>
						</Col>
						<Col
							xs='11'
							sm={{ size: 5, offset: 0 }}
							md={{ size: 3, offset: 1 }}
							xl={{ size: 3, offset: 4 }}>
							<Fade right>
								<Button
									className='GetStarted-button longer spacer-9'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' className='d-none picture-switch-on'>
							<Fade right>
								<img className='img-3 small-space' src={Review} alt='treadmill vo2 max running' />
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2'>
							<Fade left>
								<div className='number-cir'>1</div>
								<h5 className='steps-label'>Choose your tests</h5>
								<p className='grey-text-2'>Choose your test. Own your health from home</p>
							</Fade>
						</Col>
						<Col className='picture-switch-off' xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade right>
								<img className='img-3' src={Review} alt='treadmill vo2 max running' />
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade left>
								<img
									className='img-3'
									src={Review}
									alt='two people sitting down and reviewing data'
								/>
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2' id='card-2-left'>
							<Fade right>
								<div className='number-cir' id='number-cir-right'>
									2
								</div>
								<h5 className='steps-label' id='steps-label-right'>
									Sample and send
								</h5>
								<p className='grey-text-2'>
									Take a sample in the comfort of home. Mail it to the lab. Or visit a local Quest.
								</p>
							</Fade>
						</Col>
						<Col md='12' className='d-none picture-switch-on'>
							<Fade right>
								<img
									className='img-3'
									src={Review}
									alt='woman standing infront of mirror holding pant line and showing how it has srunk'
								/>
							</Fade>
						</Col>
						<Col xl='6' lg='6' md='6' sm='6' xs='12' className='card-2'>
							<Fade left>
								<div className='number-cir'>3</div>
								<h5 className='steps-label'>Discover</h5>
								<p className='grey-text-2'>Get simple to understand results and recommendations.</p>
							</Fade>
						</Col>
						<Col className='picture-switch-off' xl='6' lg='6' md='6' sm='6' xs='12'>
							<Fade right>
								<img
									className='img-3'
									src={Review}
									alt='woman standing infront of mirror holding pant line and showing how it has srunk'
								/>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
