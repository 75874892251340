import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Home.css';

export default class Home6 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row className='home-6-spacer'>
				<Col xl='4'>
					<Fade left>
						<div className='home-6-side-text-big'>Start your healthier future today.</div>
						<div className='home-6-side-text-small'>
							Choose the plan that's right for you. you can cancel anytime.
						</div>
					</Fade>
				</Col>
				<Col xl='8'>
					<Row>
						<Col md='6'>
							<Fade right>
								<div className='home-6-card'>
									<div className='home-6-card-title'>DexaFit Basic</div>
									<div className='home-6-card-price'>Free</div>
									<div className='home-6-card-text'>Dip a toe in the water.</div>
									<br />
									<ul className='card-checkmark'>
										<li>
											Personalized health insights from DexaFit testing, including Longevity Score,
											Body Score, and more
										</li>
										<br />
										<li>
											DexaFit Marketplace membership to access health products, services, and
											professional support
										</li>
										<br />
										<li>
											Daily analytics of any wearable data you collect via Apple Health, Samsung
											Health, or Google Fit
										</li>
										<br />
										<li>Leaderboards and education</li>
									</ul>
									<br />

									<Button
										className='home-6-card-button'
										onClick={(e) => this.handlePageChange('/Map')}>
										Get Started
									</Button>
								</div>
							</Fade>
						</Col>
						<Col md='6'>
							<Fade right>
								<div className='home-6-card' id='home-6-card-right'>
									<div className='home-6-card-title'>DexaFit+</div>
									<div className='home-6-card-price'>$7.99/month</div>
									<div className='home-6-card-text'>I'm all in baby!</div>
									<br />
									<ul className='card-checkmark'>
										<li>Everything in DexaFit Basic</li>
										<br />
										<li>
											15% discount off any marketplace purchase + free shipping on any at-home
											testing kits
										</li>
										<br />
										<li>AI Workout Engine to optimize cardiorespiratory fitness and longevity</li>
										<br />
										<li>AI Digital Health Coach</li>
									</ul>
									<br />

									<Button
										className='home-6-card-button'
										id='home-6-card-button-right'
										onClick={(e) => this.handlePageChange('/Map')}>
										Get Started
									</Button>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
