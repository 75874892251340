import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import AppImg2 from '../../assets/img/App-images-2.png';
import AppImg from '../../assets/img/App-images.png';
import '../Service.css';

export default class Fit3 extends Component {
	render() {
		return (
			<Row className='off-color'>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 5, offset: 0 }}
					xl={{ size: 4, offset: 1 }}>
					<Row>
						<Fade right>
							<Col className='off-color' sm='12' md='12'>
								<h2>
									<strong>Optimize your health.</strong>
								</h2>
								<div>
									<p>
										Integrate all of your digital health data to get predictive insights about your
										health and actionable guidance to improve it.
									</p>
									<p>
										<strong>Longevity Score</strong>
									</p>
									<p>
										Discover your expected longevity and chronic disease risks using the leading
										indicators of all-cause mortality.
									</p>
									<p>
										<strong>Sleep & Readiness Score</strong>
									</p>
									<p>
										Get personalized sleep insights and a summary of your body's recovery and
										readiness for activity.
									</p>
									<p>
										<strong>Mo' Score</strong>
									</p>
									<p>
										Pay attention to your momentum to see if your progress is stalling and what to
										do to make sure you reach your goal.
									</p>
									<p>
										<strong>AI Digital Health Coach</strong>
									</p>
									<p>
										Get personalized cardio workouts and lifestyle recommendations to optimize your
										health and longevity
									</p>
								</div>
								<br />
								<Button
									className='GetStarted-button-3'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				<Col
					className='Multi-phone-holder'
					sm={{ size: 12, offset: 0 }}
					md={{ size: 12, offset: 0 }}
					lg={{ size: 4, offset: 3 }}
					xl={{ size: 4, offset: 3 }}>
					<Fade left>
						<div id='blue-block-2'></div>
						<img
							className='belly-fat-phone'
							src={AppImg2}
							alt='App with graphs of BPM and other metrics'
						/>
						<img className='lean-mass-phone' src={AppImg} alt='explore page on the app' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
