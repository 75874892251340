import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Belly from '../../assets/img/BellyFat.png';
import Lean from '../../assets/img/LeanMassPhone.png';
import '../Service.css';

export default class Dexa3 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder' xs='12' sm='12' md='8' lg='8' xl='6'>
					<Fade left>
						<div id='blue-block'></div>
						<img className='belly-fat-phone' src={Belly} alt='dexafit multi shot of app' />
						<img className='lean-mass-phone' src={Lean} alt='woman with dumbells' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 4, offset: 0 }}
					lg={{ size: 4, offset: 0 }}
					xl={{ size: 3, offset: 2 }}>
					<Row>
						<Col className='spacer-16' sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>Know with absolute certainty where you are</h3>
								<br />
								<p className='small-label-3'>
									See exactly where you're storing fat, if you're increasing lean mass, and how
									healthy your bones are.
								</p>
							</Fade>
						</Col>
						<Col sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>Make sure you're going the right direction</h3>
								<br />
								<p className='small-label-3'>
									Get a clearer understanding of your body composition health with our proprietary
									body score metric.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
