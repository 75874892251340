import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Number1 from '../../assets/img/1-number.png';
import Number2 from '../../assets/img/2-number.png';
import Number3 from '../../assets/img/3-number.png';
import '../HowItWorks.css';

export default class Medicine7 extends Component {
	render() {
		return (
			<Row>
				<Col md='12'>
					<Row className='off-color middle-row'>
						<Col className='text-center' md='12'>
							<Fade left>
								<div className='big-text'>Make Smarter Decisions</div>
								<div>
									Whether for your practice, your lab, or your business, we gather comprehensive
									data and turn it into actionable insights for you.
								</div>
								<br />
								<br />
							</Fade>
						</Col>
						<Col className='padding-left' md={{ size: 3, offset: 2 }}>
							<Fade left>
								<img
									className='number-container-img'
									src={Number1}
									alt='icon with number 1 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Tell Us Your Goals</div>
								<div className='grey-label-2'>
									We collect the data you need for better decisions.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number2}
									alt='icon with number 2 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Comprehensive Baseline</div>
								<div className='grey-label-2'>
									We handle the logistics of scheduling, testing, and protocol design.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number3}
									alt='icon with number 3 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Turn Data Into Insights</div>
								<div className='grey-label-2'>
									Get the information you need to forge a deeper collaboration and create a
									healthier future.
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col md='12'>
					<Row id='bottom-row'>
						<Col md='12'>
							<Fade left>
								<div className='big-text text-center' id='medicine-page-big-text'>
									Start Customizing
									<br /> your care with DexaFit
								</div>
								<br />
								<br />
							</Fade>
						</Col>
						<Col id='padding-seperator-1' className='text-sm-center text-md-right' md='6'>
							<Fade left>
								<div>
									Learn more about working with existing DexaFit <br /> centers across the country
								</div>
								<br />
								<Button id='left-learn-button-2' className='learn-button'>
									Learn More
								</Button>
								<br />
							</Fade>
						</Col>
						<Col id='padding-seperator-2' className='text-sm-center text-md-left' md='6'>
							<Fade right>
								<div>
									Become a DexaFit Partner and offer DexaFit testing <br /> within your business or
									clinic.
								</div>
								<br />
								<Button id='right-learn-button-2' className='learn-button'>
									Learn More
								</Button>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
