import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store25 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'PinnerTest Food Intolerance Kit',
			price: 299.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>PinnerTest Food Intolerance Kit</div>
							<br />
							<br />
							<div>
								$299.00 <strike> $330.00</strike>
							</div>{' '}
							<br />
							<br />
							<div>
								Some of us can’t digest certain food types. This is problematic. <br />
								<br />
								This maldigestion causes inflammation in our gut system and unwanted particles enter
								our blood stream. <br />
								<br />
								<strong>
									The opposition between our immune system and these unwanted particles is called
									“Food Intolerance”
								</strong>{' '}
								and it leads to Discomfort and Disease that lasts for a lifetime. <br />
								<br />
								Discover your food intolerances with DexaFit and PinnerTest.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<strong>How Food Intolerance Makes You Sick and Causes Weight Gain</strong> <br />
					<br />
					<div>
						The Serotonin Hormone is what makes us feel full and responsible for our good mood.
						<br />
						<br />
						The small intestine produces 95% of the Serotonin Hormone in our body. If you have an
						inflammation in your intestines, caused by Food Intolerance, Serotonin production stops
						and you won’t feel full. You will start craving sugar and carbs.
						<br />
						<br />
						Even a supposedly “Healthy Known” vegetable such as lettuce or parsley can be the reason
						for weight gain and many other diseases, if you are intolerant.
					</div>
					<br />
					<br />
					<strong>
						Today, with Pinnertest, it is Possible to Test Food Intolerances with Accuracy and
						Precision.
					</strong>
					<br />
					<br />
					<div>
						In the past, it was impossible to identify specific foods that cause intolerance with a
						precise lab test and those who suffered from food intolerance tried to identify their
						intolerances through the process of elimination diets or unreliable test methods.
						<br />
						<br />
						Today, with Pinnertest, it is Possible to Test Food Intolerances with Accuracy and
						Precision.
					</div>
					<br />
					<br />
					<strong>The Report is Very Easy-To-Understand and Super Insightful</strong>
					<br />
					<br />
					<div>
						We test for <strong>200 food types</strong> and after we receive your blood sample,
						within 10 days, you will receive your Pinnertest results via email.
						<br />
						<br />
						The results that you will receive are very easy to understand. The foods in the red
						column represent the foods that you can’t digest, that are your Pinnertest Food
						Intolerances.
						<br />
						<br />
						Our food intolerance specialists will be more than happy to answer all your questions
						regarding your Pinnertest results after you receive them.
					</div>
					<br />
					<br />
					<strong>Foods Test: </strong>
					<br />
					<br />
					<div>
						<strong>Grains:</strong> Barley, Buckwheat, Corn, Einkorn (Virgin Wheat), Kamut, Millet,
						Oat, Quinoa, Rice, Rye, Spelt, Wheat
					</div>
					<br />
					<br />
					<div>
						<strong>Legumes:</strong> Black bean, Chick Pea, Fava Bean, Kidney Bean, Lentils,
						Peanut, Pinto Bean Soybean
					</div>
					<br />
					<br />
					<div>
						<strong>Dairy & Eggs:</strong> Cow’s Milk, Egg White, Egg Yolk, Goat’s Milk, Sheep’s
						Milk{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Meat & Poultry:</strong> Beef, Chicken, Duck, Lamb, Pork, Rabbit, Quail, Turkey{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Fish/Seafood:</strong> Anchovy, Bluefish, Clam, Cod, Crab, Eel, Haddock,
						Herring, Lobster, Mackerel, Mussel, Octopus, Oyster, Salmon, Red Snapper, Sardine, Sole,
						Squid, Swordfish, Shrimp, Tuna, Tilapia, Sea Bass
					</div>
					<br />
					<br />
					<div>
						<strong>Vegetables:</strong> Artichoke, Arugula, Asparagus, Beet, Bell Pepper, Broccoli,
						Brussel Sprouts, Cabbage, Carrot, Celery, Chard, Cucumber, Eggplant, Endive, Fennel,
						Grape Leaf, Kale, Leek, Lettuce, Mushroom, Okra, Onion, Parsley, Pea, Potato, Pumpkin,
						Radicchio, Radish, Rutabaga, Spinach, Butternut Squash, Sweet Potato, String Bean,
						Cauliflower, Tomato, Turnip, Watercress, Zucchini{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Fruits: </strong> Acai Berry, Apple, Apricot, Avocado, Banana, Blackberry,
						Blueberry, Cantaloupe, Cherry, Cranberry, Currant,Date, Fig, Grape, Grapefruit, Guava,
						Kiwi, Lime, Lychee, Lemon, Mango, Nectarine, Olive, Orange, Papaya, Peach, Pear,
						Pineapple, Plum, Pomegranate, Raspberry, Rhubarb, Strawberry, Watermelon{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Seeds & Nuts:</strong> Almond, Brazil Nut, Cashew, Chestnut, Coconut, Flaxseed,
						Hazelnut, Hemp, Macadamia, Pine Nut, Pistachio, Sesame Seed, Sunflower Seed, Walnut{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Spices & Herbs:</strong> Allspice, Anise Seed, Basil, Capers, Cardamom, Carob,
						Chili Pepper, Chives, Cilantro, Cinnamon, Clove, Coriander, Cumin, Dill, Garlic, Ginger,
						Mustard Seed, Nutmeg, Oregano, Paprika, Peppercorn, Poppy Seed, Rosemary, Saffron, Sage,
						Tarragon, Thyme,Turmeric, Vanilla Drinks: Black Tea, Chamomile, Chicory, Cocoa Bean,
						Coffee, Cola Nut, Ginseng, Hibiscus, Hops, Jasmine, Rooibos{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Others:</strong> Agar, Agave, Aloe Vera, Amaranth, Aspartame, Cane Sugar,
						Fructose, Gluten (Gliadin), Honey, Maple Syrup, Pectin, Tapioca, Brewer’s Yeast, Baker’s
						Yeast
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
