import React, { Component } from 'react';
import { Container } from 'reactstrap';
import * as locationData from '../assets/data/locations.json';
import Book1 from './slides/Book1';
import Book2 from './slides/Book2';
import Book3 from './slides/Book3';
import Pricing2Book from './slides/Pricing-2-book';
import Book4 from './slides/Book4';
import Book5 from './slides/Book5';
import Book6 from './slides/Book6';
import Footer from '../Footer';

export default class Book extends Component {
	scrollButton = (ref) => {
		switch (ref) {
			case 'book1':
				this.refs.book1.scrollIntoView({ block: 'end', behavior: 'smooth' });
				break;
			case 'book2':
				this.refs.book2.scrollIntoView({ block: 'center', behavior: 'smooth' });
				break;
			case 'book3':
				this.refs.book3.scrollIntoView({ block: 'center', behavior: 'smooth' });
				break;
			case 'price2':
				this.refs.price2.scrollIntoView({ block: 'start', behavior: 'smooth' });
				break;
			case 'book4':
				this.refs.book4.scrollIntoView({ block: 'start', behavior: 'smooth' });
				break;
			case 'book5':
				this.refs.book5.scrollIntoView({ block: 'end', behavior: 'smooth' });
				break;
			case 'book6':
				this.refs.book6.scrollIntoView({ block: 'end', behavior: 'smooth' });
				break;

			default:
				break;
		}
	};
	priceReset = (ref) => {
		this.refs.price2.scrollIntoView({ block: 'start', behavior: 'smooth' });
	};

	render() {
		let display = <div>404</div>;
		locationData.default.map((location) => {
			if (location.name === this.props.match.params.place) {
				display = (
					<div>
						<div ref='book1'>
							<Book1
								scrollbutton1={this.scrollButton}
								name={this.props.match.params.place}
								location={location.coordinates}
							/>
						</div>
						<div ref='book2'>
							<Book2
								name={location.name}
								address={location.address}
								phone={location.phone}
								email={location.email}
							/>
						</div>
						<div ref='book3'>
							<Book3 history={this.props.history} />
						</div>
						<div ref='price2'>
							<Pricing2Book
								prices={location.prices}
								scroll={this.priceReset}
								scrollbutton1={this.scrollButton}
							/>
						</div>
						<div ref='book4'>
							<Book4
								scroll={this.scrollButton}
								widget={location.widget}
								phone={location.phone}
								altLink={location.altLink}
							/>
						</div>
						<div ref='book5'>
							<Book5 />
						</div>
						<div ref='book6'>
							<Book6 />
						</div>
					</div>
				);
			}
			return display;
		});
		return (
			<Container fluid>
				{display}
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
