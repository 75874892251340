import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Mar272019 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>BMR vs. RMR: Everything You Need to Know</div>
						<br />
						<div className='blog-date-page'>Oct 5, 2017</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							For many people, BMR (Basal Metabolic Rate) and RMR (Resting Metabolic Rate) are
							synonymous. Both titles refer to roughly the same concept, and so have very little
							difference in one's mind. The truth, however, is that BMR and RMR actually have
							differences that make a difference when it comes to watching your weight and counting
							your calories. Understanding the difference between these two titles may not be the
							first step to understanding your body, but it is an important one nevertheless.
							Fortunately for you, we have compiled everything you need to know about your BMR and
							RMR as well as the differences between them.
							<br />
							<br />
							<strong>The Similarities</strong>
							<br />
							<br />
							Basal Metabolic Rate and Resting Metabolic Rate are used to estimate and ultimately
							measure the amount of calories one burns if they are at rest for 24 hours. In short,
							these rates are used to figure out the minimum amount of energy necessary for a
							person's body to keep functioning at a healthy{' '}
							<a href='https://www.scientificamerican.com/article/what-is-homeostasis/'>
								homeostasis
							</a>{' '}
							: lungs breathing, heart beating, brain working, and a normal body temperature
							maintained. The rates are figured through a selfsame process, but this is where the
							similarities of BMR and RMR end.
							<br />
							<br />
							Both BMR and RMR are measured using gas analysis through one of two ways: either{' '}
							<a href='http://www.differencebetween.net/science/nature/difference-between-direct-calorimetry-and-indirect-calorimetry/'>
								direct or indirect calorimetry
							</a>
							. RMR can also be estimated using a certain equation known as the{' '}
							<a href='https://www.scientificpsychic.com/health/cron1.html'>
								Mifflin St. Jeor Equation
							</a>{' '}
							. This equation takes sex, age, height, and weight into account.
							<br />
							<br />
							Though they measure roughly the same thing, BMR is measured using more restrictive
							conditions, while RMR is measured under looser conditions. What's more, there are
							certain requirements that must be observed before taking a person's Basal Metabolic
							Rate. With their Resting Metabolic Rate, no such requirements apply. The differences
							in the final measurements affect the final reading, giving way to the differences
							between the rates.
							<br />
							<br />
							<strong>What is BMR?</strong>
							<br />
							<br />
							One's Basal Metabolic Rate is the rate at which a living being (typically a human, but
							most all mammals apply) gives off heat while at complete rest. It is measured while
							the individual is awake, but completely at rest. In most cases and for the best
							results, the test is conducted in a darkened room after the person has just awakened
							from at least eight hours of sleep. This is to ensure that the individual is at their
							most calm and rested. To ensure that the test subject has indeed achieved eight hours
							of rest, most individuals are required to spend the night in the testing facility.
							<br />
							<br />
							In order to get accurate results and estimations, it is imperative that the individual
							does not exert any extra energy while they are undergoing their test. Any type of
							unnecessary exertion can ruin the readings, resulting in the test needing to be
							redone. Most testing facilities have the individual recline so that they are resting
							completely. In addition, they are required to fast for 12 hours before they take the
							test. This is to ensure that not even their digestive system is working during the
							test. As the goal of the BMR is to find out how much energy is needed to keep the body
							functioning while at complete rest, the only energy that should be released during the
							test is what is needed to keep vital organs and functions going. As a reminder, these
							functions are: maintaining a normal temperature, maintaining a regular heartbeat, and
							breathing normally.
							<br />
							<br />
							<strong>What is RMR?</strong>
							<br />
							<br />
							Resting Metabolic Rate is also known as Resting Energy Expenditure (REE). As the name
							implies, this test measures how much energy is required to keep the body in perfect
							homeostasis while asleep or resting. Because this test is conducted while the
							individual is asleep, it is much less restrictive than the BMR test. It does not
							require the individual to sleep over at the testing facility, nor do they have to get
							at least eight hours of sleep before the test is conducted.
							<br />
							<br />
							Like the BMR test, the RMR test requires the individual to rest while reclining for
							ultimate relaxation. The test can be conducted at any time of day and is usually done
							so after three or four hours of fasting. Like the BMR test, the RMR test is designed
							to measure the amount of energy you burn in 24 hours while maintaining basic bodily
							functions such as breathing, heartbeats, and maintaining a normal temperature. The
							difference, however, is that the RMR reading also includes the number of calories
							burned while eating and doing light activities such as stretching, walking, going to
							the bathroom, etc. Consider your around the house activities while having some time
							off or on a weekend and factor these into your RMR results. These are the small
							activities that the RMR takes into account.
							<br />
							<br />
							<strong>The Difference in Calorie Estimation and How it Impacts Your Results</strong>
							<br />
							<br />
							Once the tests have been conducted and the results have arrived, the final and biggest
							difference is revealed: how the calories are estimated. When the results are compared,
							RMR readings are usually a little higher than BMR readings. However, according to the
							National Research Council, the difference is almost always less than 10 percent.
							<br />
							<br />
							It is most important to note, however, that most doctors find that your BMR is more
							accurate than your RMR. This is largely due to the restrictive procedure required to
							get a reading. Since the test is so controlled, there is no interference from other
							factors. In short, the BMR test is just a purer reading and estimation of calories
							burned.
							<br />
							<br />
							<strong>Factors That Affect Your RMR</strong>
							<br />
							<br />
							Of course, there are many factors that can affect your RMR. These factors do not
							drastically change your metabolic rate, but they can alter the results and readings.
							According to <a href='https://www.bodybuilding.com/fun/calrmr.htm'> Body Building</a>,
							these factors include:
							<br />
							<br />
							<ul>
								<li>
									Muscle Mass: The more muscles you have, the higher your Resting Metabolic Rate is.
									This is because you are always exerting more energy because you are likely using
									muscles that, on average, don't get much use.
								</li>
								<br />
								<br />
								<li>
									Age: How old you are can affect your RMR. As you get older, your metabolism
									naturally slows down, making it harder to burn fat and calories. Due to this, your
									metabolism isn't working as hard even while at rest, resulting in a decreased RMR.
								</li>
								<br />
								<br />
								<li>
									Genetics: Like all things, your genes can affect your metabolic rates. In the case
									of your RMR, your genetics can actually reduce the rate.
								</li>
								<br />
								<br />
								<li>
									Weather: That's right, even the weather can affect your RMR. Most readings find
									that individuals living in a cold environment have an increased RMR. The cold is a
									major factor because those it causes you to exert more energy while doing simple,
									daily activities. Just moving around in the cold winter takes a lot of effort.
									When it is warmer, this problem is nonexistent since it takes a lot less effort to
									get going during the spring and summer.
								</li>
							</ul>
							<br />
							<br />
							Other factors may come into play for different people such as pregnancy, taking
							supplements, and even intense dieting. Bottom line, your RMR can be tweaked and
							changed by a number of factors, reinforcing the accuracy of your BMR. Since so many
							things can ultimately affect your RMR reading, it is important to take the test
							results with a grain of salt or to seek another test a few times a month.
							<br />
							<br />
							<strong>Takeaways</strong>
							<br />
							<br />
							Whether you are looking to increase our fitness level or just learn about your body,
							knowing the difference between BMR and RMR is important and useful. These rates help
							you understand just how much energy your body is using and the number of calories
							being burned even when you are at your most rested. Many individuals can get caught up
							in calorie burning during exercise, but the body is always working to keep itself
							healthy and stable. With this knowledge, you can hopefully face both exercise and
							downtime with a new perspective. <br />
							<br />
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
