import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Pushups from '../../assets/img/pushups2.png';
import Phone from '../../assets/img/leaderboard.png';
import '../HowItWorks.css';

export default class Athletes2 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade left>
						<div className='how-text'>
							<h2 className='how-titles'>
								<span className='span-color'>Eliminate</span> Noise
							</h2>
							<div className='how-p'>
								Collect qualitative and quantitative data with medical-grade diagnostic testing
								proven to correlate with injury and performance.
								<br /> <br />
								<ul>
									<li>DEXA and 3D scanning</li>
									<li>VO2max and RMR testing</li>
									<li>Blood panel testing</li>
									<li>Blood panel testing</li>
									<li>And more</li>
								</ul>
							</div>
						</div>
					</Fade>
				</Col>
				<Col lg='7'>
					<Fade right>
						<img id='Pushups-pic' src={Pushups} alt='people doing pushups' />
						<img
							id='Phone-pic-1'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
