import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store10 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Optimal Male Panel',
			price: 529.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>Optimal Male Panel</div>
							<br />
							<br />
							<div>$529.00</div> <strike>$599.00</strike> <br />
							<br />
							<div>
								An exclusive panel of men’s health-specific biomarkers critical for any athlete,
								exercise enthusiast, or any man who cares about how he looks, feels, and performs.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						This at-home test looks at four hormones that can affect mood, weight balance, sex
						drive, muscle mass, and energy levels.
					</div>
					<br />
					<br />
					<div>
						<strong>Adrenal Health: </strong> E2, T, PSA, SHBG, DS, C, TSH, fT3, fT4, TPOab,{' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Heart, Metabolism, and Inflammation: </strong> In, hsCRP, HbA1c, TG, CH, HDL,
						LDL, VLDL, Vit D (D-25-OH){' '}
					</div>
					<br />
					<br />
					<div>
						<strong>Heavy Metals: </strong>Hg, Cd, Pb, Zn, Cu, Se, Mg
					</div>
					<br />
					<br />
					<div className='text-center'>
						<strong>Related Symptoms</strong>
					</div>
					<br />
					<br />
					<Row>
						<Col md={{ size: 4, offset: 2 }}>
							<ul>
								<li>Fatigue or Feeling Tired </li>
								<br />
								<li>Depression</li>
								<br />
								<li>Weakness </li>
								<br />
								<li>Joint Pain </li>
								<br />
								<li>Muscle Pain </li>
								<br />
								<li>Skin Issues </li>
								<br />
								<li>Bloating </li>
								<br />
								<li>Abdominal Pain </li>
								<br />
								<li>Changes in Bowel Habits </li>
								<br />
								<li>Elevated Blood Sugar</li>
								<br />
								<li>Elevated Blood Pressure </li>
								<br />
							</ul>
						</Col>
						<Col md={{ size: 4, offset: 2 }}>
							<ul>
								<li>Allergies </li>
								<br />
								<li>Asthma </li>
								<br />
								<li>Weight Loss </li>
								<br />
								<li>Sleep Disturbance </li>
								<br />
								<li>Skin Issues </li>
								<br />
								<li>Headaches or Migraines</li>
								<br />
								<li>Hair Loss </li>
								<br />
								<li>Weight Gain </li>
								<br />
								<li>Increased Body Fat</li>
								<br />
								<li>Decreased Muscle</li>
								<br />
							</ul>
						</Col>
					</Row>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
