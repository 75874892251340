import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import IconNav from '../IconNav';
import CardRotation from '../CardRotation/CardRotation.jsx';
import Img1 from '../../assets/img/animation-graphics/1.png';
import Img2 from '../../assets/img/animation-graphics/7.png';
import Img3 from '../../assets/img/animation-graphics/8.png';
import Img4 from '../../assets/img/animation-graphics/9.png';
import '../Service.css';

export default class Dexa1 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Row className='spacer-20'>
				<Col sm={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 1 }}>
					<IconNav
						scrollStyle={this.props.scrollStyle}
						statePass2={this.props.statePass}
						callBack2={this.secondCallBack}
					/>
					<Row>
						<Fade left>
							<Col className='card-7' xs='11' md='12'>
								<h2 className='big-label'>
									DEXA Speaks <br /> Louder Than Scales.
								</h2>
								<br />
								<h4 className='mid-label'>The Gold Standard For Body Composition Analysis</h4>
								<h5 className='small-label'>
									Sure your weight might be changing. But is it fat, muscle, or bone? Find out with
									DexaFit’s DEXA scan.
								</h5>
								<br />
								<br />
								<Button
									className='GetStarted-button-3 spacer-13'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				<CardRotation
					Size={6}
					BackgroundLayer={true}
					Img1={Img1}
					Img2={Img2}
					Img3={Img3}
					Img4={Img4}
					Alt1='cartoon of a dexascan'
					Alt2='screenshot of a body scan from the dexafit app'
					Alt3='screenshot of two arms flexing in the dexafit app'
					Alt4='screenshot of optimized page of dexafit app'
				/>
			</Row>
		);
	}
}
