import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer';
import './Partner.css';

export default class Partner extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handlePageChange = page => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Container fluid>
				<Row>
					<Fade left></Fade>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<Fade left>
							<div id='how-to-partner'>How to Partner with DexaFit</div>
							<br />
							<br />
						</Fade>
					</Col>
					<Col className='text-center' md={{ size: 10, offset: 1 }}>
						<Fade left>
							<div id='which-option'>
								<strong>Which option</strong> best describes you?
							</div>
							<br />
							<br />
						</Fade>
					</Col>
					<Col className='text-center' md={{ size: 5, offset: 1 }}>
						<Fade left>
							<div>
								"I want to learn more about <strong>opening my own</strong> DexaFit location."
							</div>
							<br />
						</Fade>
						<Button className='Partner-button' onClick={e => this.handlePageChange('/OpenMyOwn')}>
							Learn More
						</Button>
						<br />
						<br />
						<br />
					</Col>
					<Col className='text-center' md='5'>
						<Fade left>
							<div>
								"I want to learn more about{' '}
								<strong> offering DexaFit within my current business</strong>."
							</div>
							<br />
						</Fade>
						<Button
							className='Partner-button'
							onClick={e => this.handlePageChange('/OfferDexaFit')}>
							Learn More
						</Button>
						<br />
						<br />
						<br />
					</Col>
				</Row>
				<br />
				<br />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
