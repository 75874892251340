import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/victory.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class ThreeD6 extends Component {
	render() {
		return (
			<Row className='off-color2'>
				<Col md='12' lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl set up in the rmr machine' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col
					className='spacer-17'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					xl={{ size: 4, offset: 1 }}>
					<Fade right>
						<h2 className='big-label'>Why 3D?</h2>
						<br />
						<p className='small-label-3'>
							Top medical research universities use the 3D body scanning to investigate several
							important areas of health, including morbidity, nutrition, and posture. <br />
							<br />
							The technology uses a special type of camera to project infrared light onto your body.
							This light looks like a web of dots. It's imperceptible to the human eye, but clear as
							day to the camera’s processor! <br />
							<br />
							This processor determines measurements from the displacement of the dots on your body.{' '}
							<br />
							<br />
							These measurements are so precise that there is only half an inch (max) of variance
							between scans.
						</p>
					</Fade>
				</Col>
			</Row>
		);
	}
}
