import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class Dexa2 extends Component {
	render() {
		return (
			<Row>
				<Col
					className='spacer-1'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					lg={{ size: 7, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<Fade left>
								<h2 className='big-label'>Get answers. Not estimates.</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>FDA-Approved</h4>
								<p className='grey-p'>
									DXA is FDA-approved and quantifies your lean mass, fat mass, and bone mass in each
									region of your body, including organ fat .
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Small margin of Error</h4>
								<p className='grey-p'>
									It is significantly more precise than other methods including BMI, InBody, BodPod,
									and hydrostatic weighing
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Trusted By The Best</h4>
								<p className='grey-p'>
									Research, medicine, and elite sports labs trust DEXA as the most accurate body
									composition measurement available
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col className='d-flex align-items-end' md='6' lg='4'>
					<Fade right>
						<img className='phone' src={Phone} alt='phone app' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
