import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Phone from '../../assets/img/phone@3x.png';
import '../HowItWorks.css';

export default class Medicine4 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade left>
						<div className='how-text'>
							<h2 className='how-titles how-titles-3'>
								<span className='span-color'>Optimize</span> <br /> their fitness
							</h2>
							<div className='how-p'>
								With VO2max and RMR Testing <br />
								<br />
								<ul>
									<li>
										Find the precise level of exercise intensity to burn fat, maximize recovery, and
										optimize performance.
									</li>
									<li>
										Optimize metabolic and heart health using the single best predictor of all cause
										mortality.
									</li>
								</ul>
							</div>
						</div>
					</Fade>
				</Col>
				<Col lg='7'>
					<Fade right>
						<img
							id='Phone-pic-3'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
