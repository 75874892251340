import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Phone1 from '../../assets/img/phone-1@3x.png';
import Phone2 from '../../assets/img/phone-2@3x.png';
import Phone3 from '../../assets/img/phone-3@3x.png';
import '../Pricing.css';

export default class Pricing1 extends Component {
	scrollbutton2() {
		this.props.scrollbutton1();
	}
	render() {
		return (
			<Row className='spacer-20'>
				<Col
					xs={{ size: 12, offset: 0 }}
					sm={{ size: 12, offset: 0 }}
					md={{ size: 12, offset: 0 }}
					lg={{ size: 12, offset: 0 }}
					xl={{ size: 11, offset: 1 }}>
					<Row>
						<Col className='five-percent' id='Pricing-slide-1-space' xs='11' md='11' lg='4' xl='4'>
							<Fade left>
								<h2 className='big-label-2'>Let's conquer your goals.</h2>
								<p className='small-label-4'>
									We offer three plans designed to fit a range of health and fitness needs—from
									dipping your toe into the water to maximizing your potential with every tool we
									offer.{' '}
								</p>
								<Button
									className='get-started-button-4'
									color='primary'
									onClick={() => {
										this.scrollbutton2();
									}}>
									Get Started
								</Button>
							</Fade>
						</Col>
						<Col
							className='hide d-flex text-right align-items-center'
							xs='12'
							md='12'
							lg='8'
							xl='8'>
							<Fade right>
								<img
									className='tri-phone'
									id='phone-1'
									src={Phone1}
									alt='first phone of three, with a twisting graph in the center ploting out gas exchange threshold'
								/>
								<img
									className='tri-phone'
									src={Phone2}
									alt='second phone showing scale of optimized to at risk with a smiley face that is twoards optimized'
								/>
								<img
									className='tri-phone'
									id='phone-3'
									src={Phone3}
									alt='third phone with lbs of lean mass and another graph'
								/>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
