import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Feb62020 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>It Starts With Your Heart</div>
						<br />
						<div className='blog-date-page'>FEB 6, 2020</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							Did you know your heart beats 100,000 times a day? Responsible for circulating blood
							and oxygen through your body, your heart <strong>is</strong> the center of everything
							that happens in your body. <br />
							<br />
							When you don’t properly care and nurture your heart, you put yourself at risk for
							complications and disease. Some of these include arrhythmias, coronary artery disease,
							myocardial infraction, heart failure, and other types of chronic{' '}
							<a href='https://www.mayoclinic.org/diseases-conditions/heart-disease/symptoms-causes/syc-20353118'>
								heart disease
							</a>{' '}
							, according to Mayo Clinic. <br />
							<br />
							As your risk of heart disease increases with age,{' '}
							<a href='https://www.medicalnewstoday.com/articles/320205#Extra-monitoring-for-heart-disease-needed'>
								Medical News Today
							</a>{' '}
							reports that symptoms like{' '}
							<a href='https://www.forhims.com/medical-conditions/male-pattern-baldness'>
								male pattern baldness
							</a>
							, chest pain, high blood pressure and high cholesterol, can be early warning signs you
							may be experiencing bigger health issues.
							<br />
							<br />
							In honor of American Heart Month, there’s no better time than now to identify ways to
							improve your overall health and understand the role your heart plays. Here’s a few
							tips on how to kickstart your path to a healthy heart for a fulfilling and
							wellness-focused life!
							<br />
							<br />
							<strong>Exercise</strong>
							<br />
							<br />
							Physical exercise offers you several benefits. For starters it’s the foundation for
							health weight loss and lean muscle growth, not to mention the feel-good hormones known
							as endorphins, that are released when you exercise. <br />
							<br />
							A sweat-inducing workout is proven to help make your heart stronger, too. Exercising
							helps pump more blood through your heart and delivers more oxygen through your body.
							This process, in turn, helps lower your blood pressure (which can become high from
							inactivity), and bad cholesterol, both of which can put you at extreme risk of heart
							disease. <br />
							<br />
							Research shows that just 30 minutes of low to moderately intense aerobic exercise,
							performed three times per week, can improve your cardiovascular health. <br />
							<br />
							<strong>Nutrition</strong>
							<br />
							<br />
							If you’ve made some changes to your nutritional habits before, you know the effects
							that eating a nutrient dense diet can have. What you may not have known, though, are
							the several benefits eating nutritious foods has on your heart as well. A healthy diet
							and lifestyle are the best ways to prevent cardiovascular disease. <br />
							<br />
							Because your heart pumps nutrient-rich blood through your body, it’s vital to fuel
							your body with nutrient-rich foods. Start by replacing some nutrient-poor, processed
							foods with whole foods like fresh fruits, vegetables and lean meats. Not only will you
							start feeling better over time, but you will also notice a change in your energy
							levels. Knowing what to eat is the first step to leading a heart-healthy diet. <br />
							<br />
							<strong>Sleep</strong> <br />
							<br />
							Quality sleep isn’t just important for your mood and energy levels, but it’s vital for
							heart health. People who don’t get enough sleep are at higher risk for cardiovascular
							and coronary heart disease, regardless of age, weight, and smoking and exercise
							habits. <br />
							<br />
							One reason researchers believe lack of sleep is detrimental to heart health is because
							it causes disruptions in underlying health conditions and biological processes like
							glucose metabolism, inflammation and most importantly, blood pressure. <br />
							<br />
							During normal sleep your blood pressure goes down, returning to your body’s natural
							homeostasis. Having irregular sleep means your blood pressure stays higher for longer
							periods of time. High blood pressure is a leading cause for heart disease and stroke,
							according to the Center for Disease Control. Getting adequate sleep each night can
							lower your chances of contracting heart disease. If you find yourself struggling to
							get to sleep, or to get quality sleep, try one of these methods:
							<br />
							<br />
							<ol>
								<li>
									Create an evening routine that signals your body that it’s time to start winding
									down
								</li>
								<li>Stick to a sleep schedule, even on weekends</li>
								<li>Check your environment for temperature, sound and light</li>
								<li>Avoid caffeine after 1pm</li>
								<li>Limit exposure to bluelight</li>
							</ol>
							<br />
							<br />
							If you’re looking to see a change in your well-being, it’s smart to start with your
							heart. Every other function in your body stems from having a health heart, so before
							jumping into anything, ask yourself, “Does this benefit my heart?” When you keep that
							in the forefront of your mind, you are well on your way to a healthy, happy life.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
