import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import CardRotation from '../CardRotation/CardRotation.jsx';
import Img1 from '../../assets/img/animation-graphics/3.png';
import Img2 from '../../assets/img/animation-graphics/13.png';
import Img3 from '../../assets/img/animation-graphics/15.png';
import Img4 from '../../assets/img/animation-graphics/14.png';
import IconNav from '../IconNav';
import '../Service.css';

export default class Vo21 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Row className='spacer-20'>
				<Col sm={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 1 }} md={{ size: 10, offset: 1 }}>
					<IconNav
						scrollStyle={this.props.scrollStyle}
						statePass2={this.props.statePass}
						callBack2={this.secondCallBack}
					/>
					<Row>
						<Fade left>
							<Col className='card-7' xs='11' md='12'>
								<h2 className='big-label'>
									Discover The <br /> Right Intensity
								</h2>
								<br />
								<h4 className='mid-label'>The Gold Standard For Cardiovascular Health</h4>
								<h5 className='small-label'>
									No more crude estimates. Find your precise level of exercise intensity to optimize
									performance, recovery, and longevity.
								</h5>
								<br />
								<br />
								<Button
									className='GetStarted-button-3 spacer-13'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				<CardRotation
					Size={6}
					BackgroundLayer={true}
					Img1={Img1}
					Img2={Img2}
					Img3={Img3}
					Img4={Img4}
					Alt1='cartoon of a guy running on a treadmill'
					Alt2='a speedometer that says 67 and reads superior underneath'
					Alt3='graph of vo2 and co2 exchange threshold'
					Alt4='workout to heart rate and calorie conversion table'
				/>
			</Row>
		);
	}
}
