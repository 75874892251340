import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/victory.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class Lab6 extends Component {
	render() {
		return (
			<Row className='off-color2'>
				<Col md='12' lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl set up in the rmr machine' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col
					className='spacer-17'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					xl={{ size: 5, offset: 1 }}>
					<Fade right>
						<h2 className='big-label' id='lab-big-label-3'>
							Why Lab Testing?
						</h2>
						<br />
						<div className='small-label-3' id='lab-small-label-3'>
							Catch critical issues in your body before they manifest as heart disease, cancer,
							diabetes, or worse. Blood testing is the most important step you can take to identify
							and prevent life-threatening diseases before they happen to you. <br />
							<br />
							Knowledge of exactly what is going on inside your body empowers you to implement a
							science-based disease-prevention program that can literally add decades of healthy
							years to your life. <br />
							<br />
							And if your goal is to not only stop disease, but to also perform at your peak
							physical and cognitive capacity, blood testing is absolutely crucial for identifying
							which diet, supplements and lifestyle habits optimize your specific and unique health
							parameters. <br />
							<br />
							Gauge the levels of key chemicals in your body <br />
							<ul>
								<li>
									These include micronutrients, hormones, and heavy metals. All biomarkers appear on
									a scale as either at, above, or below optimal levels.
								</li>
							</ul>
							<br />
							Track progress. <br />
							<ul>
								<li>
									Your biomarkers are like snapshots that help you track progress and measure the
									effectiveness of your habits over time.
								</li>
							</ul>
							<br />
							Paint a clearer picture of your health <br />
							<ul>
								<li>
									Track your progress with a cutting-edge platform helps you track your progress
									over time and measure the effectiveness of your plan
								</li>
							</ul>
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
