import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import CardRotation from '../CardRotation/CardRotation.jsx';
import Img1 from '../../assets/img/animation-graphics/2.png';
import Img2 from '../../assets/img/animation-graphics/11.png';
import Img3 from '../../assets/img/animation-graphics/12.png';
import Img4 from '../../assets/img/animation-graphics/10.png';
import IconNav from '../IconNav';
import '../Service.css';

export default class RmR1 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Row className='spacer-20'>
				<Col sm={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 1 }} md={{ size: 10, offset: 1 }}>
					<IconNav
						scrollStyle={this.props.scrollStyle}
						statePass2={this.props.statePass}
						callBack2={this.secondCallBack}
					/>
					<Row>
						<Fade left>
							<Col className='card-7' xs='11' md='12'>
								<h2 className='big-label'>
									Tune Into Your <br /> Metabolism.
								</h2>
								<br />
								<h4 className='mid-label'>The Gold Standard For Metabolic Health</h4>
								<h5 className='small-label'>
									How much fuel does your body need? Does it like to burn fat or sugar? DexaFit RMR
									Metabolic test shows you.
								</h5>
								<br />
								<br />
								<Button
									className='GetStarted-button-3 spacer-13'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				<CardRotation
					Size={6}
					BackgroundLayer={true}
					Img1={Img1}
					Img2={Img2}
					Img3={Img3}
					Img4={Img4}
					Alt1='cartoon of a rmr test'
					Alt2='a comparison between sugar and fat burn that is 20%fat 80% sugar'
					Alt3='resting bpm with a arrow that circles around a cartoon heart'
					Alt4='a speedometer that is for callories burned'
				/>
			</Row>
		);
	}
}
