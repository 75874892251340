import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
//import Fade from 'react-reveal/Fade';
import Adam from '../assets/img/adam-kadela.jpg';
import Barton from '../assets/img/barton-wells.jpg';
import Brandon from '../assets/img/brandon-dragos.jpg';
import Amy from '../assets/img/bug-only.jpg';
import Ben from '../assets/img/image-asset.jpeg';
import Sandra from '../assets/img/Sandra.jpeg';
import Sajad from '../assets/img/Sajad.jpg';
import Chad from '../assets/img/chad-cook.jpg';
import Footer from '../Footer';

export default class MeetTheTeam extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handlePageChange = page => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Container fluid>
				<Row>
					<Col className='text-center'>
						<h1 className='Privacy-policy-title'>
							<strong>Meet</strong> The Team
						</h1>
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col lg='6'>
						<img className='headshot-left' src={Adam} alt='head shot of cpo adam kadela' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Adam Kadela</strong> - Founder, CPO/COO
						</div>
						<br />
						<div className='team-text'>
							As Founder and CPO/COO, Adam creates DexaFit's long-term growth vision, product road
							map, and strategy. As part of this process he partners with medical and fitness
							industry leaders to launch innovative technology platforms that provide clients with
							data, inspiration and guidance to improve their health, achieve their fitness goals
							and transform their lives. <br />
							<br />
							In a quest to improve his own health, Adam opened the first DexaFit in Chicago in 2011
							with his former Northwestern teammate and NFL alum, Matt Ulrich. The company has since
							expanded to over two dozen centers across the country with plans to open in Hong Kong,
							Kuala Lumpur, and Singapore this year. <br />
							<br />
							Adam developed his expertise in technology as head of foreign exchange at Eagle Seven,
							a global financial trading and technology firm based in Chicago. Among other
							activities, he designed proprietary algorithmic trading strategies for arbitrage,
							market making and discovering hidden liquidity in the market. <br />
							<br />A life-long athlete, Adam was starting middle linebacker and captain of the
							Northwestern University football team, where he first learned how to optimize
							performance using cutting edge technology and resources.
						</div>
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col className='d-lg-none' lg='6'>
						<img className='headshot-left' src={Barton} alt='head shot of ceo Barton Wells' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Barton Wells</strong> - CEO/CTO
						</div>
						<br />
						<div className='team-text'>
							As CEO and CTO of DexaFit, Barton oversees the corporate direction and strategy of the
							company. He brings over 30 years of experience in Silicon Valley, including
							specialities in engineering, sports science, and artificial intelligence. He joined
							DexaFit to make access to science-based insights more accessible and affordable.{' '}
							<br />
							<br />
							He’s helping the company do this by making medical-grade body composition and
							metabolic health data available at scale, and designing software solutions that use AI
							to create personalized health insights, actionable guidance, and individualized
							programs for achieving better health and longevity. <br />
							<br />A Stanford alum, he has over 30 years experience of software development, data
							science, and executive experience at start-up companies, including two that were
							acquired by Dropbox and Google. He is a former elite swimmer and current master
							swimmer with accomplishments that include three Olympic Trials and three NCAA swimming
							national championships.
						</div>
					</Col>
					<Col className='d-none d-lg-block d-xl-block' lg='6'>
						<img className='headshot-right' src={Barton} alt='head shot of ceo Barton Wells' />
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col lg='6'>
						<img className='headshot-left' src={Brandon} alt='head shot of cfo Brandon Dragos' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Brandon Dragos</strong> - CFO/CBDO
						</div>
						<br />
						<div className='team-text'>
							As CFO and CBDO of DexaFit, Brandon’s main responsibility is identifying strategic
							partnership opportunities for the company. He brings over eight years of experience,
							which includes a stint in equity research, equity trading, and investment banking. He
							joined DexaFit in 2015 when he opened the first DexaFit in Minnesota with his wife,
							Birgen. <br />
							<br />
							His goal at DexaFit is to do for health and wellness what he saw happen in finance
							when technology disrupted traditional financial methods. Wearables present a unique
							opportunity to collect activity data about what people are doing to improve their
							health, but they currently lack reliable insights into what “effect” specific
							activities have on health. <br />
							<br />
							Bridging the gap between someone’s health history and wearable data with medical-grade
							data from DexaFit’s technologies will create a unique opportunity to offer
							scientifically-validated health solutions at scale.
						</div>
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col className='d-lg-none' lg='6'>
						<img className='headshot-left' src={Amy} alt='head shot of advisor Amy Stanbery' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Amy Stanbery</strong> - Advisor
						</div>
						<br />
						<div className='team-text'>
							Amy Stanbery works closely with providers of DexaFit technologies and systems,
							strategic partners, and distributors. <br />
							<br />
							She is also responsible for establishing clinical protocols for operators of DexaFit
							testing centers to ensure the absolute integrity of all data collected. <br />
							<br />
							Amy brings 25 years of healthcare experience to DexaFit. Previously, she was clinical
							director of the Bone Density and Body Composition Center at Sequoia Hospital near San
							Francisco. She launched the clinic in 1996 and turned it into one of the most
							respected bone density and body composition centers on the West Coast attracting many
							well-known fitness experts as clients such as author Tim Ferris.
						</div>
					</Col>
					<Col className='d-none d-lg-block d-xl-block' lg='6'>
						<img className='headshot-right' src={Amy} alt='head shot of advisor Amy Stanbery' />
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col lg='6'>
						<img
							className='headshot-left'
							src={Ben}
							alt='head shot of Science Advisor Dr. Ben Peterson'
						/>
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Dr. Ben Peterson</strong> - Science Advisor
						</div>
						<br />
						<div className='team-text'>
							Dr. Ben is DexaFit's Science Advisor in charge of all metabolic and cardiovascular
							fitness testing protocols and training. He currently works as the Head of Player
							Health and Performance for the San Francisco 49ers where he leads a team of medical
							doctors, athletic trainers, and strength and performance specialists to optimize
							training regiments and player health. <br />
							<br />
							His experience includes working with performance coaches of elite teams worldwide and
							helping them identify and evaluate contextual analytics by measuring training loads –
							biomechanical and physiological load quantification’s – to improve performance
							outcomes and reduce soft tissue injuries of athletes. <br />
							<br />
							Ben completed his undergraduate degree at Northwestern University and his PhD in
							Exercise Physiology at the University of Minnesota.
						</div>
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col className='d-lg-none' lg='6'>
						<img className='headshot-left' src={Sandra} alt='head shot of ceo adam kadela' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Dr. Sandra Bender</strong> - Medical Director
						</div>
						<br />
						<div className='team-text'>
							Dr. Bender is DexaFit's National Medical Director and oversees the DexaFit Medical
							Advisory Team. She is Board Certified in Internal Medicine, Pediatrics, and Sports
							Medicine and currently owns her own practice in Palm Springs, California. <br />
							<br />
							Dr. Bender joined DexaFit at its original location in Wrigleyville, Chicago and has
							since expanded her role with the company to include oversight of all DexaFit locations
							and its Medical Advisers. <br />
							<br />
							She is the former team physician for Northwestern University's Athletic Department,
							where she first started using advanced medical-grade technology to help her athletes
							and patients optimize their health, fitness, and performance.
						</div>
					</Col>
					<Col className='d-none d-lg-block d-xl-block' lg='6'>
						<img className='headshot-right' src={Sandra} alt='head shot of ceo adam kadela' />
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col lg='6'>
						<img className='headshot-left' src={Sajad} alt='head shot of ceo adam kadela' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Dr. Sajad Zalzala</strong> - Medical Director
						</div>
						<br />
						<div className='team-text'>
							Board Certified in Family Medicine, Dr. Sajad Zalzala joined DexaFit to be at the
							forefront of the technological innovation driving the movement toward more
							personalized health, fitness, and medicine. <br />
							<br />
							With special interests in Holistic and Functional Medicine and a background in
							Computer Science, his philosophy towards health focuses on identifying structural
							imbalances that influence disease, and defining the underlying causes of poor
							health—not just the sum of the symptoms. <br />
							<br />
							Combining his experience and practice with DexaFit's data-driven focus, he believes
							quantifying the healthcare experience can lead to quicker and more lasting change.
						</div>
					</Col>
				</Row>
				<Row className='team-member-row'>
					<Col className='d-lg-none' lg='6'>
						<img className='headshot-left' src={Chad} alt='head shot of ceo adam kadela' />
					</Col>
					<Col lg='6'>
						<div className='team-title'>
							<strong>Dr. Chad Cook</strong> - Science Advisor
						</div>
						<br />
						<div className='team-text'>
							Dr. Chad Cook brings over 15+ years of experience using DexaFit technology. His
							background includes Exercise Physiology, Human Nutrition & Exercise Physiology, and
							he's a PhD in Nutrition Science. <br />
							<br />
							For the past 15 years he's overseen the design and conduct of human dietary
							intervention studies and clinical trials to evaluate food/supplement,
							biopharmaceutical, and medical device products in the areas of clinical nutrition,
							immunology and cardiometabolic health.{' '}
						</div>
					</Col>
					<Col className='d-none d-lg-block d-xl-block' lg='6'>
						<img className='headshot-right' src={Chad} alt='head shot of ceo adam kadela' />
					</Col>
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
