import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Phone from '../../assets/img/phone@3x.png';
import '../HowItWorks.css';

export default class Athletes4 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade left>
						<div className='how-text'>
							<h2 className='how-titles how-titles-3'>
								<span className='span-color'>Take</span> <br /> Action
							</h2>
							<div className='how-p'>
								Make sure you get more out of your athletes.
								<br />
								<br />
								<ul>
									<li>Improve player evaluations</li>
									<li>Optimize their performance</li>
									<li>Keep them out of the training room</li>
								</ul>
							</div>
						</div>
					</Fade>
				</Col>
				<Col lg='7'>
					<Fade right>
						<img
							id='Phone-pic-3'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
