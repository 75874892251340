import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
//import PhoneCenter from '../../assets/img/phone-2@3x.png';
import SadMan from '../../assets/img/dexafit_sad_man.png';
import WeightLoss from '../../assets/img/Dexafit_Weight_Loss.png';
import GirlWorkingOut from '../../assets/img/Dexafit_Working_Out_v2.jpg';
import Arrow from '../../assets/img/left@3x-2.png';
import '../Service.css';

export default class Fit4 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show1: true,
			show2: false,
			show3: false,
			slide: 1,
			way: true,
		};
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	handleSlideChangeForward = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 2,
					show2: true,
					show1: false,
					way: false,
				});
				break;
			case 2:
				this.setState({
					slide: 3,
					show3: true,
					show2: false,
					way: false,
				});
				break;
			case 3:
				this.setState({
					slide: 1,
					show1: true,
					show3: false,
					way: false,
				});
				break;
			default:
		}
	};
	handleSlideChangeBackwards = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 3,
					show3: true,
					show1: false,
					way: true,
				});
				break;
			case 2:
				this.setState({
					slide: 1,
					show2: false,
					show1: true,
					way: true,
				});
				break;
			case 3:
				this.setState({
					slide: 2,
					show2: true,
					show3: false,
					way: true,
				});
				break;
			default:
		}
	};
	render() {
		let direction = this.state.way;
		let left;
		let right;
		if (direction) {
			left = true;
			right = false;
		} else {
			left = false;
			right = true;
			direction = true;
		}
		return (
			<Row>
				<Col xs='12' sm='12' md='12'>
					<div className='Dexa-api-slide-title-2'>
						<strong>Find top-rated health and wellness pros</strong>
					</div>
					<div onClick={(e) => this.handlePageChange('/Map')} className='Dexa-api-slide-link'>
						View all pros
					</div>
				</Col>
				<Col className='slide-container slide-container-2'>
					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={WeightLoss}
									alt='Cartoon of a girl in pants that are double her size'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Weight Loss</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Find health and wellness professionals specializing in helping you achieve your
									weight loss goals.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={GirlWorkingOut}
									alt='Cartoon of a girl working out with dumbells'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Personal Training</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Get the best of 1-on-1 coaching with cutting-edge technology for the most personal
									training experience ever.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={SadMan}
									alt='Cartoon of a bigger man sitting in a chair eating pizza '
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Nutrition Support</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Find resources that can help you optimize your diet and eating habits for whatever
									your goal may be.
								</div>
							</div>
						</Fade>
					</div>

					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<img
								src={GirlWorkingOut}
								alt='Cartoon of a girl working out with dumbells'
								className='service-slide-border'></img>

							<h3 className='service-slide-text-bottom'>
								<strong>Personal Training</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								Get the best of 1-on-1 coaching with cutting-edge technology for the most personal
								training experience ever.
							</div>
						</Fade>
						<div className='service-slide-arrow-holder d-md-block d-lg-none '>
							<strong>{this.state.slide}/3</strong>
							<img
								onClick={this.handleSlideChangeBackwards}
								className='service-slide-arrow service-slide-right-arrow'
								src={Arrow}
								alt='Left Arrow'
							/>
							<img
								onClick={this.handleSlideChangeForward}
								className='service-slide-arrow service-slide-left-arrow'
								src={Arrow}
								alt='Right Arrow'
							/>
						</div>
					</div>
					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<img
								src={SadMan}
								alt='Cartoon of a bigger man sitting in a chair eating pizza '
								className='service-slide-border'></img>
							<h3 className='service-slide-text-bottom'>
								<strong>Nutrition Support</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								Find resources that can help you optimize your diet and eating habits for whatever
								your goal may be.
							</div>
						</Fade>
					</div>
					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<img
								src={WeightLoss}
								alt='Cartoon of a girl in pants that are double her size'
								className='service-slide-border'></img>
							<h3 className='service-slide-text-bottom'>
								<strong>Weight Loss</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								Find health and wellness professionals specializing in helping you achieve your
								weight loss goals.
							</div>
						</Fade>
					</div>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={SadMan}
									alt='Cartoon of a bigger man sitting in a chair eating pizza '
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Nutrition Support</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Find resources that can help you optimize your diet and eating habits for whatever
									your goal may be.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={WeightLoss}
									alt='Cartoon of a girl in pants that are double her size'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Weight Loss</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Find health and wellness professionals specializing in helping you achieve your
									weight loss goals.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={GirlWorkingOut}
									alt='Cartoon of a girl working out with dumbells'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>Personal Training</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Get the best of 1-on-1 coaching with cutting-edge technology for the most personal
									training experience ever.
								</div>
							</div>
						</Fade>
					</div>
				</Col>
				<Col className='Arrow-holder-hider' xs='12' sm='12' md='12'>
					<div style={{ height: 100 }}>
						<div className='service-slide-arrow-holder'>
							<strong>{this.state.slide}/3</strong>
							<img
								onClick={this.handleSlideChangeBackwards}
								className='service-slide-arrow service-slide-right-arrow'
								src={Arrow}
								alt='Left Arrow'
							/>
							<img
								onClick={this.handleSlideChangeForward}
								className='service-slide-arrow service-slide-left-arrow'
								src={Arrow}
								alt='Right Arrow'
							/>
						</div>
					</div>
				</Col>

				{/* <Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }}>
					<Row className='spacer-2'>
						<Col xs='12' sm='7' md='7' xl='5'>
							<Fade left>
								<h2 className='big-label'>Features</h2>
							</Fade>
						</Col>
						<Col
							xs='11'
							sm={{ size: 5, offset: 0 }}
							md={{ size: 3, offset: 1 }}
							xl={{ size: 3, offset: 4 }}>
							<Fade right>
								<Button
									className='GetStarted-button longer spacer-9'
									id='fit-page-getstarted-btn'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='4'>
							<Row>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='card-2 top-div div-switch-off'>
									<Fade left>
										<div className='number-cir'>1</div>
										<h5 className='steps-label steps-label-2'>
											Invest today for a longer tomorrow.
										</h5>
										<p className='grey-text-2'>
											Know exactly how your habits now affect your estimated longevity
										</p>
									</Fade>
								</Col>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='card-2 bottom-div div-switch-off'>
									<Fade left>
										<div className='number-cir'>2</div>
										<h5 className='steps-label steps-label-2'>Eat and move, smarter.</h5>
										<p className='grey-text-2'>
											Discover better ways to eat, move, and live using AI insights from your
											DexaFit results.
										</p>
									</Fade>
								</Col>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='d-none div-switch-on card-2 card-2-left-class bottom-div'>
									<Fade right>
										<div className='number-cir' id='number-cir-right'>
											1
										</div>
										<h5 className='steps-label steps-label-2'>
											Invest today for a longer tomorrow..
										</h5>
										<p className='grey-text-2'>
											Know exactly how your habits now affect your estimated longevity
										</p>
									</Fade>
								</Col>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='d-none div-switch-on card-2 card-2-left-class bottom-div'>
									<Fade right>
										<div className='number-cir' id='number-cir-right'>
											2
										</div>
										<h5 className='steps-label steps-label-2'>Eat and move, smarter.</h5>
										<p className='grey-text-2'>
											Discover better ways to eat, move, and live using AI insights from your
											DexaFit results.
										</p>
									</Fade>
								</Col>
							</Row>
						</Col>
						<Col md='4'>
							<img
								src={PhoneCenter}
								className='centered-phone'
								alt='this shows a phone with the dexafit app on it and it is showing the body fat score page'
							/>
						</Col>
						<Col md='4'>
							<Row>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='card-2 card-2-left-class top-div'>
									<Fade right>
										<div className='number-cir' id='number-cir-right'>
											3
										</div>
										<h5 className='steps-label steps-label-2' id='steps-label-right'>
											Optimize your health, automatically.
										</h5>
										<p className='grey-text-2'>
											Fit AI notifications and alerts keep you accountable and motivated.
										</p>
									</Fade>
								</Col>
								<Col
									xl='12'
									lg='12'
									md='12'
									sm='12'
									xs='12'
									className='card-2 card-2-left-class bottom-div'>
									<Fade right>
										<div className='number-cir' id='number-cir-right'>
											4
										</div>
										<h5 className='steps-label steps-label-2' id='steps-label-right'>
											Learn the ropes from pros.
										</h5>
										<p className='grey-text-2'>
											The Fit AI Marketplace of Pros and Fit Newsfeed teach you how to achieve your
											goals.
										</p>
									</Fade>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col> */}
			</Row>
		);
	}
}
