import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import DexafitTable from '../../assets/img/dexa-table-1.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../HowItWorks.css';

export default class HowItWorks3 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg='7'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic' src={DexafitTable} alt='a girl on the dexa scan table' />
						<img
							id='Phone-pic-2'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade right>
						<div className='how-text'>
							<h2 className='how-titles'>
								<span className='span-color'>Show</span> Them
							</h2>
							<p className='how-p'>
								Benchmark and personalize planning with DEXA and 3D scans <br /> <br />•
								FDA-approved technology used to quantify their lean, fat, and bone mass, including
								visceral fat <br />• Reveals imbalances in muscle symmetry and strength,
								flexibility, and joint mechanics
							</p>
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
