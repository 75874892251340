import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import DexaTable from '../../assets/img/dexafit-table-2.png';
import Phone from '../../assets/img/how-phone-3.png';
import '../HowItWorks.css';

export default class HowItWorks5 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg='7'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic' src={DexaTable} alt='A man on the dexa scan table' />
						<img
							id='Phone-pic-2'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade right>
						<div className='how-text'>
							<h2 className='how-titles how-titles-3'>
								<span className='span-color'>Simplify</span> <br /> it for them
							</h2>
							<p className='how-p'>
								Place the lab testing process in their hands, so they learn more
								<br />
								<br />
								• Blood panel testing kits show them their hormonal health, food sensitivities, and
								more <br />• Microbiome and DNA screening kits let them explore their gut and
								genetic health
							</p>
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
