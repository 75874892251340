import React, { Component } from 'react';
import Footer from '../Footer';

export default class NoMatch extends Component {
	render() {
		return (
			<div>
				<br />
				<br />
				<br />
				<h1 className='text-center'>404 Page Not Found</h1>
				<Footer />
			</div>
		);
	}
}
