import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class ThreeD7 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false,
	};

	clickToggle = (item) => {
		switch (item) {
			case '1':
				this.setState((prevState) => ({
					item1: !prevState.item1,
				}));
				break;
			case '2':
				this.setState((prevState) => ({
					item2: !prevState.item2,
				}));
				break;
			case '3':
				this.setState((prevState) => ({
					item3: !prevState.item3,
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<Fade left>
								<h2 className='big-label spacer-5'>Learn more about 3D body scanning</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											How long does a 3D scan take?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<div>
													The 3D body scan is incredibly simple and fast. You will stand on a
													turntable and spin around once (about 40 seconds). It takes about two
													minutes total from the time you enter the room and leave. Your results are
													available immediately too.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											What do I have to do before my visit?
										</div>
										{this.state.item2 ? (
											<Fade top>
												<div>
													The most important thing to note is we’re appointment only, so please make
													sure you schedule online or via our mobile app!
													<br />
													<br />
													On the day of your visit, remember to wear compression shorts or tight
													boxers (guys) or tight leggings and a sports bra (ladies). Please also
													remember to download our mobile app prior to your visit.
												</div>
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											How is the 3D scan different than the DXA scan?
										</div>
										{this.state.item3 ? (
											<Fade top>
												<div>
													Professionals consider 3D scanning one of the most precise methods for
													measuring aesthetic change. It is a consistent way to track changes in
													your appearance and posture over time.
													<br />
													<br />
													But body composition (i.e. lean mass, fat mass, visceral fat, and bone
													mass) is best measured with DXA because it shows you exactly what’s
													happening under the surface and is considered the gold standard due to its
													accuracy.
												</div>
											</Fade>
										) : null}
									</div>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
