import React, { Component } from 'react';
//import { Link } from 'react';
import { Container } from 'reactstrap';
import Business1 from './slides/Business1.jsx';
import Business2 from './slides/Business2.jsx';
import Business3 from './slides/Business3.jsx';
import Business4 from './slides/Business4.jsx';
import Business5 from './slides/Business5.jsx';
import Footer from '../Footer';

export default class BusinessHome extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Container fluid>
				<Business1 history={this.props.history} />
				<Business2 history={this.props.history} />
				<Business3 history={this.props.history} />
				<Business4 history={this.props.history} />
				<Business5 history={this.props.history} />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
