import React, { Component } from 'react';
import CONT from '../../assets/img/contact.png';
import '../Book.css';

export default class FaqNav extends Component {
	render() {
		return (
			<div className='scroll-icon location-scroll-icon'>
				<div
					id='contatct-circle'
					onClick={this.props.click}
					className={`circle-border2 ${this.props.on}`}>
					<img
						id='contact-icon-img'
						className='small-button-icons2'
						src={CONT}
						alt='head in a bubble icon'
					/>
				</div>
				<h6 className='grey-label'>Contact</h6>
			</div>
		);
	}
}
