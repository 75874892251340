import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/parts/Home';
import BusinessHome from './components/parts/BusinessHome';
import CustomerTypeChoice from './components/parts/CustomerTypeChoice';
import Services from './components/parts/Service';
import Pricing from './components/parts/Pricing';
import Book from './components/parts/Book';
import Solutions from './components/parts/Solutions';
import Map from './components/parts/Map';
import Top from './components/Navbar';
import NoMatch from './components/parts/NoMatch';
import Privacy from './components/parts/Privacy';
import Terms from './components/parts/Terms';
import FAQ from './components/parts/FAQ';
import Jobs from './components/parts/Jobs';
import Partner from './components/parts/Partner';
import Partner2 from './components/parts/Partner2';
import Partner3 from './components/parts/Partner3';
import MeetTheTeam from './components/parts/MeetTheTeam';
import Blog from './components/parts/Blog';
import './App.css';
import '@csstools/normalize.css';

let defaultE;

class App extends Component {
	constructor(props) {
		super(props);
		this.handleScroll = this.handleScroll.bind(this);
	}
	state = {
		TopDrawerOpen: false,
		location: {
			value: 'All',
			country: 'All',
			label: 'Location',
			lat: 40,
			lon: -95,
			defaultZoom: 4,
		},
		scrollTop: true,
	};

	componentDidMount() {
		document.querySelector('.Navbar-color').ontouchmove = (e) => {
			defaultE = e;
		};
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll, { passive: true });
	}
	locationSelectPass = (lat, lon, country, label) => {
		let Value = country;
		if (country === 'US') {
			Value = 'All';
		}

		this.setState({
			location: {
				value: Value,
				country: country,
				label: label,
				lat: lat,
				lon: lon,
				defaultZoom: 4,
			},
		});
	};
	locationSelectPass2 = (lat, lon, state, label, country) => {
		this.setState({
			location: {
				value: state,
				country: country,
				label: label,
				lat: lat,
				lon: lon,
				defaultZoom: 6,
			},
		});
	};

	drawerToggleClickHandler = () => {
		this.setState((prevState) => {
			if (!prevState.TopDrawerOpen) {
				document.querySelector('body').style.overflowY = 'hidden';
				document.querySelector('.Navbar-color').ontouchmove = (e) => {
					return false;
				};
			} else {
				document.querySelector('body').style.overflowY = 'auto';
				document.querySelector('.Navbar-color').ontouchmove = (e) => {
					e = defaultE;
				};
			}
			return { TopDrawerOpen: !prevState.TopDrawerOpen };
		});
	};

	backdropClickHandler = () => {
		document.querySelector('body').style.overflowY = 'auto';
		document.querySelector('.Navbar-color').ontouchmove = (e) => {
			e = defaultE;
		};
		document.querySelector('.backdrop').ontouchmove = (e) => {
			e = defaultE;
		};
		this.setState({ TopDrawerOpen: false });
	};
	handleScroll(event) {
		let page = window.location.href.split('/');
		if (page[page.length - 1] === 'Map') {
			if (window.scrollY === 0) {
				this.setState((prevState) => {
					if (prevState.scrollTop === true) {
						return { scrollTop: false };
					}
				});
			}
		} else {
			if (window.scrollY === 0) {
				this.setState({ scrollTop: true });
			} else {
				this.setState((prevState) => {
					if (prevState.scrollTop === true) {
						return { scrollTop: false };
					}
				});
			}
		}
	}
	render() {
		let animateClass = 'hamburger hamburger--slider';
		if (this.state.TopDrawerOpen) {
			animateClass = 'hamburger hamburger--slider is-active';
		}
		let navStyle = '';
		if (this.state.scrollTop === false) {
			navStyle = 'box-shadow-navbar';
		}
		let buttonStyle = '';
		if (this.state.scrollTop === false) {
			buttonStyle = 'scroll-nav-color';
		}
		let CustomerToggle = sessionStorage.getItem("Dexa-Customer-Type");
		let routerHomeSwitch;
		switch (CustomerToggle) {
			case "Customer":
				routerHomeSwitch = <Route
				exact
				path='/'
				render={(routerProps) => <Home {...routerProps} {...this.state} />}
			/>
				break;
			case "Business":
					routerHomeSwitch = <Route
					exact
					path='/'
					render={(routerProps) => <BusinessHome {...routerProps} {...this.state} />}
			/>
				break;
			case 'Choices':
						routerHomeSwitch = <Route
						exact
						path='/'
						render={(routerProps) => <CustomerTypeChoice {...routerProps} {...this.state} ChoicesSlide={CustomerToggle} />}
			/>
				break;
			case null:
						routerHomeSwitch = <Route
						exact
						path='/'
						render={(routerProps) => <CustomerTypeChoice {...routerProps} {...this.state} ChoicesSlide={CustomerToggle} />}
			/>
				break;
			default:
				break;
		}
		return (
			<Router>
				<div onScroll={this.handleScroll} style={{ height: '100%' }}>
					<Top
						drawerClickHandler={this.drawerToggleClickHandler}
						animate={animateClass}
						CurrentLocation={this.state.location}
						LocationPass={this.locationSelectPass}
						LocationPass2={this.locationSelectPass2}
						click={this.backdropClickHandler}
						show={this.state.TopDrawerOpen}
						scrollStyle={navStyle}
						ChoicesSlide={CustomerToggle}
					/>
					<Switch>
						{routerHomeSwitch}
						<Route
							exact
							path='/Solutions'
							render={(routerProps) => (
								<Solutions {...routerProps} {...this.state} scrollStyle={buttonStyle} />
							)}
						/>
						<Route
							exact
							path='/Services'
							render={(routerProps) => (
								<Services {...routerProps} {...this.state} scrollStyle={buttonStyle} />
							)}
						/>
						<Route
							exact
							path='/Pricing/:place'
							render={(routerProps) => <Pricing {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/Map'
							render={(routerProps) => (
								<Map currentLocation={this.state.location} {...routerProps} {...this.state} />
							)}
						/>
						<Route
							exact
							path='/Privacy'
							render={(routerProps) => <Privacy {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/Terms'
							render={(routerProps) => <Terms {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/FAQ'
							render={(routerProps) => <FAQ {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/Jobs'
							render={(routerProps) => <Jobs {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/Partner'
							render={(routerProps) => <Partner {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/OpenMyOwn'
							render={(routerProps) => <Partner2 {...routerProps} {...this.state} />}
						/>
						<Route
							exact
							path='/OfferDexaFit'
							render={(routerProps) => <Partner3 {...routerProps} {...this.state} />}
						/>
						<Route
							path='/Book/:place'
							render={(routerProps) => <Book {...routerProps} {...this.state} />}
						/>
						<Route
							path='/MeetTheTeam'
							render={(routerProps) => <MeetTheTeam {...routerProps} {...this.state} />}
						/>
						<Route
							path='/Blog/:blogName'
							render={(routerProps) => <Blog {...routerProps} {...this.state} />}
						/>
						<Route component={NoMatch} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
