import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store19 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Team DANG Fitness & Nutrition',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Team DANG Fitness & Nutrition</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>
								Hi, my name is John Dang, owner of Team Dang Fitness and Nutrition. I specialize In
								decreasing your body fat with personalized nutrition plans tailored to your goals
								and lifestyle.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						Thank you for considering me to be your fitness & nutrition coach. <br />
						<br />
						You have inquired at the right time as I've been on top of my game. I'm ecstatic to help
						you on your mission to achieve a more improved physique, whether just to look better,
						photo shoot, or compete in bodybuilding competitions. Your package includes the
						following:
					</div>
					<br />
					<br />
					<ul>
						<li>Customized Diet Program</li>
						<br />
						<li>Cardio Advisory</li>
						<br />
						<li>Supplement Advisory</li>
						<br />
						<li>Bi-weekly Monitoring</li>
						<br />
					</ul>
					<div>
						For contest diets, it'll include your peak week protocol (critical week to dry you out
						and fill your muscles to its fullest potential). You will be required to submit progress
						pics on a biweekly basis, front and side selfies, and current weight. After submission
						of your initial pics, we can project a goal/show date. Ready to get started? Complete
						and submit the Team DANG Fitness Questionnaire. You will receive your first working diet
						in 24-48 hours.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
