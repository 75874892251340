import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../../Pricing.css';

export default class CardOne extends Component {
	renderComponent() {
		switch (this.props.page) {
			case '3D Scan':
				return (
					<div>
						<h2 className='small-card-title'>12 Pack</h2>
						<h3 className='flavor-text'>TWELVE 3D Scans</h3>
						<h4 className='prices'>{this.props.prices.threeDpack}</h4>
					</div>
				);
			default:
				return;
		}
	}
	renderComponent2() {
		switch (this.props.page) {
			case '3D Scan':
				return (
					<div>
						<h5 className='included-title'>3D Body Scan</h5>
						<h6 className='included-items'>
							TWELVE 3D Scans (${Math.round((this.props.prices.threeDpack / 12) * 100) / 100} per
							Scan)
						</h6>
						<h6 className='included-items'>
							Map the surface of your body and get more than 400 results about your body in three
							dimensions
						</h6>
						<h6 className='included-items'>
							Reveal imbalances in muscle symmetry and strength, flexibility, and joint mechanics
						</h6>
						<h6 className='included-items'>
							Insights of key areas of your body, including circumferences, lengths, widths, and
							volumes
						</h6>
					</div>
				);
			default:
				return;
		}
	}
	render() {
		return (
			<div>
				{this.renderComponent()}
				<div className='small-card-wraper'>
					{this.renderComponent2()}
					<h5 className='included-title'>DexaFit ai</h5>
					<h6 className='included-items'>
						All testing results and health data on your iOS or Android phone
					</h6>
					<h6 className='included-items'>Apple Health and Google Fit integration</h6>
					<h6 className='included-items'>AI-powered personal health and fitness insights</h6>
					<h6 className='included-items'>Longevity Score & Body Score</h6>
					<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
					<h6 className='included-items'>Leaderboards and more!</h6>
				</div>

				<Button
					id='selected-button'
					className='get-started-button-white align-self-end'
					color='primary'>
					Get Started
				</Button>
			</div>
		);
	}
}
