import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store16 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Immunity & Stress Panel',
			price: 199.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Immunity & Stress Panel</div>
							<br />
							<br />
							<div>$199.00</div> <br />
							<br />
							<div>
								Uncover potential causes of poor sleep and chronic stress in your life affecting how
								you look, feel, and perform.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						This at-home test can alert you to adrenal stress and a Vitamin D deficiency, which can
						contribute to an overall state of inflammation and immune issues.
					</div>
					<br />
					<br />
					<div>
						<strong>Adrenal Stress: </strong>Saliva: DS, Cx4
					</div>
					<br />
					<div>Vitamin D</div>
					<br />
					<br />
					<strong>Related Symptoms:</strong> <br /> <br />
					<ul>
						<li>Fatigue or Feeling Tired Weight Gain </li>
						<br />
						<li>Depression </li>
						<br />
						<li>Sleep Disturbance </li>
						<br />
						<li>Mental Fatigue </li>
						<br />
						<li>Lack of Concentration </li>
						<br />
						<li>Increased Appetite </li>
						<br />
						<li>Sugar Cravings</li>
						<br />
						<li>Irritability </li>
						<br />
					</ul>
				</Col>
			</Row>
		);
	}
}
