import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store23 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Clinical Health Prevention Program - The Champ Plan',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>
								Clinical Health Prevention Program - The Champ Plan
							</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>The world's first Personalized Clinical Health Prevention Program.</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						Much more than corporate wellness program! What you'll achieve by signing your company
						on:
						<br />
						<br />
						<ol>
							<li>Healthier employees </li>
							<br />
							<li>Additional health insurance benefits above your companies health plan</li>
							<br />
							<li>
								No cost to employee or employer utilizing IRS Section 125 tax code (give us a call
								because it's better---the employer and employee actually save money).
							</li>
							<br />
						</ol>
						Schedule a free consultation to learn more!
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
