import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
//import PhoneCenter from '../../assets/img/phone-2@3x.png';
import DexaScan from '../../assets/img/Dexafit_DXA_Scan.png';
import Treadmill from '../../assets/img/Dexafit_Treadmill.png';
import RMR from '../../assets/img/Dexafit_RMR_Test.png';
import Arrow from '../../assets/img/left@3x-2.png';
import '../Service.css';

export default class Fit6 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show1: true,
			show2: false,
			show3: false,
			slide: 1,
			way: true,
		};
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	handleSlideChangeForward = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 2,
					show2: true,
					show1: false,
					way: false,
				});
				break;
			case 2:
				this.setState({
					slide: 3,
					show3: true,
					show2: false,
					way: false,
				});
				break;
			case 3:
				this.setState({
					slide: 1,
					show1: true,
					show3: false,
					way: false,
				});
				break;
			default:
		}
	};
	handleSlideChangeBackwards = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 3,
					show3: true,
					show1: false,
					way: true,
				});
				break;
			case 2:
				this.setState({
					slide: 1,
					show2: false,
					show1: true,
					way: true,
				});
				break;
			case 3:
				this.setState({
					slide: 2,
					show2: true,
					show3: false,
					way: true,
				});
				break;
			default:
		}
	};
	render() {
		let direction = this.state.way;
		let left;
		let right;
		if (direction) {
			left = true;
			right = false;
		} else {
			left = false;
			right = true;
			direction = true;
		}
		return (
			<Row>
				<Col xs='12' sm='12' md='12'>
					<h2 className='Dexa-api-slide-title'>
						<strong>Transform your life.</strong>
					</h2>
					<div className='Dexa-api-slide-text'>
						Join a community of health seekers, biohackers, athletes, and wellness enthusiasts using
						the DexaFit Marketplace to discover breakthrough innovations in the world of health and
						fitness.
					</div>
					<div className='Dexa-api-slide-title-2'>
						<strong>Shop popular products and services</strong>
					</div>
					<div onClick={(e) => this.handlePageChange('/Map')} className='Dexa-api-slide-link'>
						View all products and services
					</div>
				</Col>
				<Col className='slide-container slide-container-2'>
					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={RMR}
									alt='Cartoon of a girl relaxing back and a tube measuring her Resting metabolic rate'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>RMR Analysis</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									How much fuel does your body need? Does it like to burn fat or sugar? DexaFit RMR
									Metabolic test shows you.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={DexaScan}
									alt='Cartoon of someone laying on a dexa scan'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>DXA Body Composition</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Sure your weight might be changing. But is it fat, Muscle, or bone? Find out with
									DexaFit's DEXA scan.
								</div>
							</div>
						</Fade>

						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left-2'>
								<img
									src={Treadmill}
									alt='Cartoon of someone running on a treadmill'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>VO2 Max Fitness Test</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									No more crude estimates. Find your precise level of exercise intensity to optimize
									performance, recovery, and longevity.
								</div>
							</div>
						</Fade>
					</div>

					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<img
								src={DexaScan}
								alt='Cartoon of someone laying on a dexa scan'
								className='service-slide-border'></img>
							<h3 className='service-slide-text-bottom'>
								<strong>DXA Body Composition</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								Sure your weight might be changing. But is it fat, Muscle, or bone? Find out with
								DexaFit's DEXA scan.
							</div>
						</Fade>
						<div className='service-slide-arrow-holder d-md-block d-lg-none '>
							<strong>{this.state.slide}/3</strong>
							<img
								onClick={this.handleSlideChangeBackwards}
								className='service-slide-arrow service-slide-right-arrow'
								src={Arrow}
								alt='Left Arrow'
							/>
							<img
								onClick={this.handleSlideChangeForward}
								className='service-slide-arrow service-slide-left-arrow'
								src={Arrow}
								alt='Right Arrow'
							/>
						</div>
					</div>
					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<img
								src={Treadmill}
								alt='Cartoon of someone running on a treadmill'
								className='service-slide-border'></img>
							<h3 className='service-slide-text-bottom'>
								<strong>VO2 Max Fitness Test</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								No more crude estimates. Find your precise level of exercise intensity to optimize
								performance, recovery, and longevity.
							</div>
						</Fade>
					</div>
					<div className='slide-position-2'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<img
								src={RMR}
								alt='Cartoon of a girl relaxing back and a tube measuring her Resting metabolic rate'
								className='service-slide-border'></img>

							<h3 className='service-slide-text-bottom'>
								<strong>RMR Analysis</strong>
							</h3>
							<div className='service-slide-text-bottom'>
								How much fuel does your body need? Does it like to burn fat or sugar? DexaFit RMR
								Metabolic test shows you.
							</div>
						</Fade>
					</div>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={RMR}
									alt='Cartoon of a girl relaxing back and a tube measuring her Resting metabolic rate'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>RMR Analysis</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									How much fuel does your body need? Does it like to burn fat or sugar? DexaFit RMR
									Metabolic test shows you.
								</div>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={Treadmill}
									alt='Cartoon of someone running on a treadmill'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>VO2 Max Fitness Test</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									No more crude estimates. Find your precise level of exercise intensity to optimize
									performance, recovery, and longevity.
								</div>
							</div>
						</Fade>

						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right-2'>
								<img
									src={DexaScan}
									alt='Cartoon of someone laying on a dexa scan'
									className='service-slide-border'></img>
								<h3 className='service-slide-text-bottom'>
									<strong>DXA Body Composition</strong>
								</h3>
								<div className='service-slide-text-bottom'>
									Sure your weight might be changing. But is it fat, Muscle, or bone? Find out with
									DexaFit's DEXA scan.
								</div>
							</div>
						</Fade>
					</div>
				</Col>
				<Col className='Arrow-holder-hider' xs='12' sm='12' md='12'>
					<div style={{ height: 100 }}>
						<div className='service-slide-arrow-holder'>
							<strong>{this.state.slide}/3</strong>
							<img
								onClick={this.handleSlideChangeBackwards}
								className='service-slide-arrow service-slide-right-arrow'
								src={Arrow}
								alt='Left Arrow'
							/>
							<img
								onClick={this.handleSlideChangeForward}
								className='service-slide-arrow service-slide-left-arrow'
								src={Arrow}
								alt='Right Arrow'
							/>
						</div>
					</div>
				</Col>
			</Row>
		);
	}
}
