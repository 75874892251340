import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store13 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Heart Health & Inflammation Panel',
			price: 220.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Heart Health & Inflammation Panel</div>
							<br />
							<br />
							<div>$220.00</div> <br />
							<br />
							<div>
								Take a comprehensive look at your heart health and the overall state of inflammation
								in your body.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						This at-home test is for anyone interested in a comprehensive look at their heart health
						and inflammation status.
					</div>
					<br />
					<br />
					<div>
						<strong>Heart, Metabolic, and Inflammatory Health: </strong> In, hsCRP, HbA1c, TG, CH,
						HDL, LDL, VLDL{' '}
					</div>
					<br />
					<br />
					<div className='text-center'>
						<strong>Related Symptoms</strong>
					</div>
					<br />
					<br />

					<Row>
						<Col md={{ size: 4, offset: 2 }}>
							<ul>
								<li>Fatigue or Feeling Tired Depression </li>
								<br />
								<li>Weakness </li>
								<br />
								<li>Joint Pain </li>
								<br />
								<li>Muscle Pain </li>
								<br />
								<li>Skin Issues </li>
								<br />
								<li>Bloating </li>
								<br />
							</ul>
						</Col>
						<Col md={{ size: 4, offset: 2 }}>
							<ul>
								<li>Changes in Bowel </li>
								<br />
								<li>Habits Elevated </li>
								<br />
								<li>Blood Sugar </li>
								<br />
								<li>Elevated Blood Pressure </li>
								<br />
								<li>Allergies </li>
								<br />
								<li>Asthma </li>
								<br />
								<li>Abdominal Pain</li>
								<br />
							</ul>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
