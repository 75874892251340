import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store15 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Joel Yakowitz',
			price: 0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Joel Yakowitz</div>
							<br />
							<br />
							<div>$0.00</div> <br />
							<br />
							<div>
								Joel builds programs for the busy professional looking to improve their health using
								science based methods.{' '}
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>Build skills for a lifetime of health </div>
					<br />
					<br />
					<Row>
						<Col md={{ size: 4, offset: 2 }}>
							<strong>Program includes: </strong>
							<br />
							<br />
							<ul>
								<li>Daily accountability Bi-Weekly program adjustments </li>
								<br />
								<li>Nutrition</li>
								<br />
								<li>Exercise</li>
								<br />
								<li>Recovery</li>
								<br />
								<li>Habits and skills to build your health arsenal</li>
								<br />
								<li>Supplements </li>
								<br />
								<li>Breathing techniques</li>
								<br />
								<li>Reflexive Performance Reset</li>
								<br />
								<li>Heart rate variability </li>
								<br />
								<li>Genetics</li>
								<br />
							</ul>
						</Col>
						<Col md={{ size: 4, offset: 1 }}>
							<strong>Results include:</strong>
							<br />
							<br />
							<ul>
								<li>Improved Body composition </li>
								<br />
								<li>Improved strength </li>
								<br />
								<li>Improved performance</li>
								<br />
								<li>Improved energy </li>
								<br />
								<li>Improved sleep </li>
								<br />
								<li>Improved mobility </li>
								<br />
								<li>Decrease inflammation </li>
								<br />
								<li>Decrease back and joint pain </li>
								<br />
								<li>Decrease stress and anxiety</li>
								<br />
							</ul>
						</Col>
					</Row>
					<br />
					<div className='text-center'>
						Your program also includes 24/7 access to Joel for any questions or modifications to
						your program.{' '}
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
