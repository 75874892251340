import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../Footer';

export default class Privacy extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={{ size: 10, offset: 1 }}>
						<h1 id='Privacy-policy-title'>
							<strong>DexaFit </strong>Privacy Policy
						</h1>
						<div className='Privacy-policy'>
							Last modified on November 27, 2018. <br /> <br />
							1. Introduction <br /> <br />
							Welcome to DexaFit and Fit by DexaFit (the "App"), a website and app operated by
							DexaFit ("DexaFit", "we", "us", or "our"). We operate a wellness service enabling
							members ("Members") to purchase laboratory diagnostics, report their health history,
							review health results and body scans, and engage healthcare providers ("Authorized
							Providers") to obtain advice regarding the results of such diagnostics and other
							wellness-oriented healthcare advice (the "Service"). Our App provides further
							information about our Service. We know you are concerned about your privacy, so we
							developed this privacy policy ("Privacy Policy") to explain how we collect, use, and
							disclose information from and/or about you when you use the App or the Services,
							whether you are a Member, Authorized Provider or simply visiting our App ("Visitor").
							<br />
							<br />
							Certain Definitions. So we are clear about the terms we are using, when we use the
							term "Personal Information" in this Privacy Policy, we mean information about you that
							is personally identifiable to you, such as your contact information (e.g., name,
							address, email address, or telephone number), and any other non-public information
							that is associated with such information (collectively, "Personal Information"). Also,
							when we use the term "De-Identified Information", we mean information that is neither
							used nor intended to be used to personally identify an individual. Questions. If you
							have questions or concerns about our Privacy Policy, please contact us by sending an
							email to support@dexafit.com.
							<br />
							<br />
							NOTE TO BETA USERS: If you are a beta user of the Service the beta agreement between
							you and DexaFit has additional provisions regarding your personal information. In the
							event of a conflict between a provision in the beta agreement and this privacy policy,
							the provision in the beta agreement will prevail.
							<br />
							<br />
							2. How We Collect Your Information
							<br />
							<br />
							Member Information. We receive information related to a Member ("Member Information")
							when:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									You Provide Us Information, including when you register as a Member, schedule an
									appointment, or otherwise communicate with us through our App. This information
									may include:
								</li>
								<li className='legal-list'>
									Health Information (e.g. medical records, body scans, age, gender, health
									background, health status, prescribed and over-the-counter medications, Authorized
									Provider notes and diagnoses, medical ID number, driver's license number,
									laboratory testing results, insurance information);
								</li>
								<li className='legal-list'>Personal Information;</li>
								<li className='legal-list'>
									Demographic Information (e.g., ethnicity, marital status, sexual behavior, sexual
									orientation); and
								</li>
								<li className='legal-list'>
									Lifestyle Information (e.g., salary and level of education information).
								</li>
								<li className='legal-list'>
									Your Authorized Providers and Testing Laboratories Provide Information to Us (upon
									proper authorization from you);
								</li>
								<li className='legal-list'>
									We Create De-Identified Information. We do this by removing the information that
									personally identifies you from the Member Information otherwise collected.
								</li>
							</ul>
							<br />
							Authorized Provider Information. We receive information related to an Authorized
							Provider ("Authorized Provider Information") when:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									You Provide Us Information, including when you register as an Authorized Provider,
									communicate with us through our App, provide Authorized Provider notes on specific
									Members, send us an email or participate in a survey. This information may include
									Personal Information of the Authorized Provider.
								</li>
								<li className='legal-list'>We Verify Your Credentials</li>
							</ul>
							<br />
							Visitor Information. We receive information related to a Visitor ("Visitor
							Information") when:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									You Provide Us Information, including when you communicate with us through our App
									or send us an email. This information may include, without limitation, Personal
									Information of Visitor.
								</li>
							</ul>
							<br />
							Information We Collect When You Interact with the App. As you use the App or the
							Service, certain information may be passively collected, including:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									App Activity Information. We keep track of some of the actions you take on the
									App, such as the content of searches you perform on the App.
								</li>
								<li className='legal-list'>
									The information we collect when you interact with the App may be Personal
									Information (e.g., if you are logged in) or Anonymous Information.
								</li>
							</ul>
							<br />
							3. How We Use Your Information
							<br />
							<br />
							Member Information. We will use your Member Information to:
							<br />
							<br />
							<ul>
								<li className='legal-list'>Administer your account;</li>
								<li className='legal-list'>Process your payments and fulfill your orders;</li>
								<li className='legal-list'>Verify your identity as a Member;</li>
								<li className='legal-list'>Provide you with the Service and customer support;</li>
								<li className='legal-list'>
									Tailor the features, performance and support of the App or the Service to you;
								</li>
								<li className='legal-list'>
									Market our Service and those of third parties we believe may be of interest to
									you;
								</li>
								<li className='legal-list'>
									Respond to your requests, resolve disputes and/or troubleshoot problems;
								</li>
								<li className='legal-list'>Improve the quality of the App and the Service; and</li>
								<li className='legal-list'>Communicate with you about the App and the Service.</li>
								<li className='legal-list'>
									Provide personalized advice or suggestions based on information about you that we
									collect and/or calculate.
								</li>
								<li className='legal-list'>
									Provide personalized text in the app or contextual information such as how your
									metrics compare to similar users on the App and the population.
								</li>
								<li className='legal-list'>
									Collect information such as your height, weight, gender, race/ethnicity, and age
									in order to accurately calculate your body measurements.
								</li>
							</ul>
							<br />
							In addition, we may use your Member Information or De-Identified Information to
							conduct research or allow others to do so, but only if you consent to the use of
							De-Identified Information for this purpose and, in the case of Personal Information,
							if you provide an additional consent to such use.
							<br />
							<br />
							You can choose not to provide us with certain information, but this may limit the
							features of the App or the Service you are able to use. You can also opt out of
							receiving certain optional communications from the App such as our newsletters and
							advertisements by emailing us at support@dexafit.com. Please note that even if you
							opt-out, we may still send you certain App and Service related communications.
							<br />
							<br />
							Authorized Provider Information. We will use your Authorized Provider Information to:
							<br />
							<br />
							<ul>
								<li className='legal-list'>Administer your Account;</li>
								<li className='legal-list'>Verify your identity as a Authorized Provider;</li>
								<li className='legal-list'>Provide the Service to Members;</li>
								<li className='legal-list'>
									Respond to your requests, resolve disputes and/or troubleshoot problems;
								</li>
								<li className='legal-list'>Improve the quality of the App and the Service; and</li>
								<li className='legal-list'>Communicate with you about the App and the Service;</li>
							</ul>
							<br />
							Visitor Information. We will use your Visitor Information to:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									Market our Service and those of third parties we believe may be of interest to
									you;
								</li>
								<li className='legal-list'>
									Respond to your requests, resolve disputes and/or troubleshoot problems;
								</li>
								<li className='legal-list'>Improve the quality of the App and the Service; and</li>
								<li className='legal-list'>Communicate with you about the App and the Service.</li>
							</ul>
							<br />
							Anonymous Information & De-Identified Information. We may use your Anonymous
							Information and De-Identified Information created by us without restriction. For
							example, we may keep you logged into our App, track your session history, and tailor
							content to you. We may use search content or Anonymous Information to analyze usage
							patterns so that we may enhance the App or the Service and improve our internal
							operations and the content of our software, notify Visitors about updates to our App
							or Service, and improve the efficacy of our content and advertisements that appear on
							the App.
							<br />
							<br />
							4. How We Share Your Information
							<br />
							<br />
							We share your information with third parties when we believe the sharing is permitted
							by you, reasonably necessary to offer our services, or when legally required to do so.
							For example, we may disclose certain Member Information, Health Provider Information
							and Visitor Information:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									To third party vendors who help us provide the Service or the App or who provide
									additional goods and services through the App, including without limitation,
									testing laboratories, phlebotomists, billing providers and benefits
									administrators;
								</li>
								<li className='legal-list'>
									To third parties to whom you ask us to send such information;
								</li>
								<li className='legal-list'>
									To a third party such as a third party administrator, benefits provider or other
									organization in accordance with the terms of the program to which the Member is
									subject;
								</li>
								<li className='legal-list'>
									To a parent company, any subsidiaries, joint ventures, or other companies under a
									common control (collectively, "Affiliates"), in the event we have such Affiliates
									in the future, in which case we will require our Affiliates to honor this Privacy
									Policy;
								</li>
								<li className='legal-list'>
									To a company that merges with us, acquires us, or purchases our assets, in which
									case such company may continue to process the Member Information and Personal
									Information of Authorized Providers and Visitors as set forth in this Privacy
									Policy; or
								</li>
								<li className='legal-list'>
									If we believe in good faith that such disclosure is necessary to (i) resolve
									disputes, investigate problems, or enforce our Terms of Service; (ii) comply with
									relevant laws or to respond to requests from law enforcement or other government
									officials relating to investigations or alleged illegal activity, in which case we
									can (and you authorize us to) disclose Personal Information without subpoenas or
									warrants served on us; or (iii) protect and defend our rights or property or the
									rights and property of you or third parties.
								</li>
							</ul>
							<br />
							Additionally, we will disclose Member Information to such Member's Authorized Provider
							in order to assist the Authorized Provider provide wellness-oriented healthcare advice
							to such Member. We also may disclose Anonymous Information and De-Identified
							Information publicly without restriction.
							<br />
							<br />
							5. Information You Share With Third Parties
							<br />
							<br />
							This Privacy Policy addresses our use and disclosure of information we collect from
							and/or about you on the App or through the Service. Information made available through
							the Service to Authorized Providers and testing laboratories is required to be used by
							such entities in accordance with all applicable laws, including the Health Insurance
							Portability and Accountability Act ("HIPAA"). If you disclose information to others,
							including, for example, a partner providing goods and services, different rules may
							apply to their use or disclosure of the information you disclose to them. The use and
							disclosure restrictions contained in this Privacy Policy will not apply to any third
							party. We do not control the privacy policies of third parties, and you are subject to
							the privacy policies of those third parties where applicable.
							<br />
							<br />
							When you click on links on the App you may leave our App. We are not responsible for
							the privacy practices of other Apps, and we encourage you to read their privacy
							statements.
							<br />
							<br />
							6. How You Can Change or Remove Your Information
							<br />
							<br />
							Modification of Information by DexaFit. DexaFit cannot verify, modify or otherwise
							alter any of the Visitor Information, and will not verify, modify or otherwise alter
							any of the Member Information or Authorized Provider Information without the consent
							of the applicable Member or Authorized Provider, but DexaFit may correct any errors or
							modify any immaterial information at any time. Modification of Information by Members
							and Authorized Providers. When such functionality is available through the Service,
							Members and Authorized Providers will be able to update some of their information
							through the App. In the meantime, requests to modify any information should be
							submitted directly to support@dexafit.com.
							<br />
							<br />
							Limitations on Deletion of Information. You may request deletion of your Personal
							Information by us, but please note that we may be required (by law or otherwise) to
							keep this information and not delete it (or to keep this information for a certain
							time, in which case we will comply with your deletion request only after we have
							fulfilled such requirements). When we delete Personal Information, it will be deleted
							from the active database, but may remain in our archives and we may also retain
							Anonymous Information about your use of our Service. Once we disclose some of your
							Personal Information to third parties, we cannot access that Personal Information any
							longer and cannot force the deletion or modification of any such information by the
							parties to whom we have made those disclosures. After we delete Personal Information,
							we will retain De-Identified Data and will continue to use De-Identified Data as
							permitted under this Privacy Policy.
							<br />
							<br />
							7. How We Protect Your Information
							<br />
							<br />
							Steps we take to keep your information secure. We employ reasonable physical,
							electronic and managerial security methods to help protect against unauthorized access
							to Personal Information.
							<br />
							<br />
							Risks inherent in sharing information. Please be aware that no data transmission over
							the Internet or data storage facility can be guaranteed to be perfectly secure. As a
							result, while we try to protect your Personal Information, we cannot ensure or
							guarantee the security of any information you transmit to us, and you do so at your
							own risk. Report Violations. You should report any security violations to us by
							sending an email to support@dexafit.com.
							<br />
							<br />
							8. Our Policy Toward Children Our Products and Services are intended for use by
							individuals eighteen (18) years of age and older. Individuals seventeen (17) years of
							age and younger may only use our Products and Services with parent permission which
							may be obtained by contacting legal@dexafit.com
							<br />
							<br />
							9. Children’s Online Privacy Protection Act
							<br />
							<br />
							Our Service is not directed to children under the age of 13, if you are not 13 years
							or older, do not use our Service without parental consent. We do not knowingly collect
							Personal Information from children under the age of 13. If we learn that Personal
							Information of persons less than 13 years-of-age has been collected through our
							Service without parent consent, we will take the appropriate steps to delete this
							information. If you are a parent or guardian and discover that your child or a minor
							under the age of 13 has posted, submitted or otherwise communicated
							<br />
							<br />
							Personal Information to our Service without your consent, then you may alert us at
							legal@dexafit.com so that we may take appropriate action to remove the minor’s
							Personal Information from our systems.
							<br />
							<br />
							All minors under the age of 18 are required to submit parental consent in order to use
							the Services. Parents or guardians wishing to provide consent for a minor to use the
							App may contact us at legal@dexafit.com to request the necessary paperwork.
							<br />
							<br />
							10. California Privacy Rights
							<br />
							<br />
							California residents may request and obtain from us, once a year, free of charge, a
							list of third parties, if any, to which we disclosed their Personal Information for
							direct marketing purposes during the preceding calendar year and the categories of
							Personal Information shared with those third parties. If you are a California resident
							and wish to obtain that information, please submit your request by sending us an email
							at legal@dexafit.com with “California Privacy Rights” in the subject line.
							<br />
							<br />
							12. European Privacy Rights
							<br />
							<br />
							If you are based in the EU, in certain circumstances you have rights under data
							protection laws in relation to Personal Information we hold about you.
							<br />
							<br />
							Specifically:
							<br />
							<br />
							<ul>
								<li className='legal-list'>
									Request access to your Personal Information. You may have the right to request
									access to any Personal Information we hold about you as well as related
									information, including the purposes for processing the Personal Information, the
									recipients or categories of recipients with whom the Personal Information has been
									shared, where possible, the period for which the Personal Information will be
									stored, the source of the Personal Information, and the existence of any automated
									decision making.
								</li>
								<li className='legal-list'>
									Request correction of your Personal Information. You may have the right to obtain
									without undue delay the rectification of any inaccurate Personal Information we
									hold about you.
								</li>
								<li className='legal-list'>
									Request erasure of your Personal Information. You may have the right to request
									that
								</li>
								<li className='legal-list'>Personal Information held about you is deleted.</li>
								<li className='legal-list'>
									Request restriction of processing your Personal Information. You may have the
									right to prevent or restrict processing of your Personal Information.
								</li>
								<li className='legal-list'>
									Request transfer of your Personal Information. You may have the right to request
									transfer of Personal Information directly to a third party where this is
									technically feasible.
								</li>
							</ul>
							<br />
							Where you believe that we have not complied with our obligation under this Privacy
							Policy or European data protection law, you have the right to make a complaint to an
							EU Data Protection Authority.
							<br />
							<br />
							13. Changes to this Privacy Policy
							<br />
							<br />
							We may change this Privacy Policy from time to time in the future. We will post any
							revised version of the Privacy Policy on this page. If we make any material changes to
							it, we may also send you notice to the last email address you have provided to us.
							Continued use of our Service following notice of such changes will indicate your
							acknowledgement of such changes and agreement to be bound by the terms and conditions
							of such changes.
							<br />
							<br />
							14. Privacy Inquiries
							<br />
							<br />
							If you have any questions or concerns about this Privacy Policy or our privacy
							practices, please contact us as follows:
							<br />
							<br />
							By emailing the Privacy Department: legal@dexafit.com
							<br />
							<br />
							By mail post:
							<br />
							<br />
							Privacy Department <br /> DexaFit <br /> 3601 Minnesota Drive Ste 180 <br /> Edina, MN
							55435
							<br />
							<br />
						</div>
					</Col>
				</Row>
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
