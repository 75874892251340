import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Feb142020 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Top 20 Health and Fitness Blogs of 2020</div>
						<br />
						<div className='blog-date-page'>FEB 14, 2020</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							The first month of the year has already come and gone, can you believe it? Do you feel
							like you’re already falling behind on your health and fitness goals for 2020? Or
							maybe, you haven’t even gotten started. <br />
							<br />
							Don’t worry, you’re not alone. <br />
							<br />
							To make it easier for you to stay on top of your game and keep up to date with the
							latest, we removed the guesswork and compiled a list of the top 20 health and fitness
							blogs to get you started, or keep you on track, this year.
							<br />
							<br />
							1. <a href='https://blog.myfitnesspal.com/'> MyFitnessPal</a>
							<br />
							<br />
							Run by one of the biggest global fitness brands, Under Armour Connected Fitness, it
							should come as no surprise that it features an abundance of health and fitness
							resources. Here, you’ll find everything from recipe ideas to the newest workout trends
							to fitness product reviews, so make sure to add this blog to your bookmarks.
							<br />
							<br />
							2.{' '}
							<a href='https://www.acefitness.org/education-and-resources/lifestyle/blog/'> ACE</a>
							<br />
							<br />
							Looking for expert-level advice? Look no further. American Council of Exercise relies
							heavily on scientific data to support their claims on training programs and design,
							nutrition, and overall health and wellbeing. All the information featured is produced
							by renowned professionals in the industry, you never have to worry about whether you
							can trust the content. <br />
							<br />
							3. <a href='https://breakingmuscle.com/'> Breaking Muscle</a>
							<br />
							<br />
							Breaking Muscle is the fitness industry’s top source for credible information on
							exercise, fitness, health and nutrition. Comprised of a team of experienced and
							passionate professionals, you can be reassured that the content you find is not only
							accurate, but is a comprehensive guide to get you to your health and fitness goals.{' '}
							<br />
							<br />
							4. <a href='https://jessikneeland.com/'> Jessi Kneeland</a>
							<br />
							<br />
							Body image struggles tend to come hand-in-hand with health and fitness goals, but
							Jessi Kneeland is devoted to helping individuals challenge negative mental habits and
							build self confidence while improving their physical fitness. This blog is dedicated
							to improving both mental and physical health, so you'll find a diverse assortment of
							empowering diet, fitness, and mental health pieces with a body-positive influence.
							<br />
							<br />
							5. <a href='https://www.blogilates.com/'> Blogilates</a>
							<br />
							<br />
							Looking for personable and relatable stories, combined with simple fitness tips and
							tricks? Meet Cassy Ho, award-winning fitness instructor and creator of POP Pilates.
							Cassey’s blog consists of lighthearted and down to earth posts about her fitness
							journey, and tips you can implement to help you with yours. <br />
							<br />
							6. <a href='https://www.mindbodygreen.com/'> mindbodygreen</a>
							<br />
							<br />
							When we're focused on a specific goal or aspect of our health and fitness journey, we
							often find ourselves with a case of tunnel vision and let other components of our
							health fall to the wayside. This blog is just the cure for our one-track minds:
							mindbodygreen is a genuinely comprehensive resource that advocates holistic wellness,
							including emotional and spiritual health along with physical health.
							<br />
							<br />
							7. <a href='http://fitting-it-all-in.com/'> Fitting It All In</a>
							<br />
							<br />
							Clare, the author behind Fitting It All In, started her blog amid feelings of
							isolation and confusion shortly after recovering from an eating disorder. Through the
							blog, she was able to share her story and provide support to others struggling with
							mental and physical health issues. Years later, she's a fourth-year medical student
							with minimal free time— her blog is packed with tips and advice for squeezing health
							and fitness into our whirlwind lifestyles while staying healthy mentally, too.
							<br />
							<br />
							8. <a href='https://fitmencook.com/'> Fit Men Cook</a>
							<br />
							<br />
							Everyone has their “ah-ha” moment, that one point in time when you suddenly realize
							you need to take control of your health.. Now. For Kevin Curry that moment happened
							when he saw a photo of himself on a friend’s FaceBook page. With Kevin, healthy eating
							doesn’t need to be boring or bland. On this blog you’ll find delicious, healthy, and
							cost effective meals, and training tips, to help you on your lifelong fitness journey.{' '}
							<br />
							<br />
							9. <a href='http://www.badyogi.com/blog/'> Bad Yogi</a>
							<br />
							<br />
							Ready to redefine “yogi”? Erin started the blog after jokingly referring to themselves
							as “bad yogi’s” because they didn't fit in with the stereotypical yoga-goer. Erin
							wanted to create an anti-clique, judgement free yoga community. Her transparent and
							relatable writing style allows you to get to know the real Erin, while educating you
							on mental health, nutrition and of course, yoga.
							<br />
							<br />
							10. <a href='http://mentalitywod.com/'> Mentality WOD</a>
							<br />
							<br />
							Learning how to deal with stress and pressure are integral to progressing toward a
							healthier overall self. Mentality WOD is dedicated to helping you improve not only
							your <a href='https://www.crossfit.com/'> CrossFit performance</a> but also your
							success in your daily life outside of the gym. If you feel like you've hit a plateau
							in your fitness progress, mental training may be the piece you need to reach that next
							level. <br />
							<br />
							11. <a href='https://lovesweatfitness.com/'> Love, Sweat, and Fitness</a>
							<br />
							<br />
							Sometimes the prospect of learning and making changes to our lifestyle can seem like a
							monumental task. Just like it was for blogger Katie Dunlop. Her journey started after
							college when she found herself 45 pounds overweight, tired of the ups and downs, and
							not sure where to turn next. She took her life in her own hands and found her passion
							helping other women do the same. Now a certified trainer and group fitness instructor,
							Katie’s blog is loaded with motivation articles, fitness and nutrition tips. <br />
							<br />
							12. <a href='https://carrotsncake.com/'> Carrots N Cake</a>
							<br />
							<br />
							Looking for your one-stop-shop for all things fitness related? Look no further. Tina
							started Carrots N Cake back in 2008 when she made the decision to ‘shape-up’ for her
							wedding day, knowing it would mean a true lifestyle change to keep the weight off.
							Over time, her blog has developed into a place for fitness enthusiasts alike to use as
							a resource for recipes, workouts, beauty and fashion, momlife and living with a
							chronic illness. <br />
							<br />
							13. <a href='http://fitnessinthecity.com.au/'> Fitness In The City</a>
							<br />
							<br />
							Healthy living in an urban environment comes with its own set of unique challenges.
							Fitness In The City is targeted at working professionals in urban settings seeking an
							escape through their diet and fitness pursuits. The recipe and fitness resources are
							fantastic, but the gorgeous food photography alone will have you hooked!
							<br />
							<br />
							14. <a href='https://www.runtastic.com/blog/en/'> Runtastic</a>
							<br />
							<br />
							Whether you’re just starting to implement running into your exercise regime, or have a
							few marathons under your belt, Adidas blog Runtastic has everything you need to know
							about running. The blog includes articles like how to make running a daily habit,
							nutritional advice to support running, and testimonials of how running has changed
							people’s lives. <br />
							<br />
							15. <a href='http://www.avstim.com/obesity-and-diet-research'> MindSpa</a>
							<br />
							<br />
							If you like data and research, this blog is for you. MindSpa uses neuro-technology to
							help individuals get better sleep. But their blog goes beyond sleep, and is filled
							with scientific research articles broken down into several different categories like
							circadian rhythms, female wellness, adolescent research, meditation, exercise and
							athletic performance, and more. <br />
							<br />
							16. <a href='https://dailyburn.com/life/'> Life</a>
							<br />
							<br />
							Life by Daily Burn is dedicated to helping you live a healthier, happier and more
							active lifestyle. The blog goes the extra mile to empower you to take control over
							physical, mental, and emotional lives. If your goal is to lose weight, gain strength,
							reduce stress, or make healthier food choices, Life provides reliable and insightful
							articles to help you achieve your personal goals.
							<br />
							<br />
							17. <a href='https://thebalancedlifeonline.com/'> The Balanced Life</a>
							<br />
							<br />
							Robin Long is a mother to four children and a fitness instructor, so she is intimately
							familiar with the struggles women face trying to find the time to work out somewhere
							in their hectic schedules. Her blog is full of free barre and Pilates workouts,
							lifestyle tips, and nourishing recipes striving to make health and fitness goals more
							attainable for busy women.
							<br />
							<br />
							18. <a href='https://www.eatingbirdfood.com/'> Eating Bird Food</a>
							<br />
							<br />
							Making changes to your eating habits can be challenging. That’s exactly why Brittany
							started her blog Eating Bird Food. Her passion is to help others live happier and
							healthier lives providing simple ways to implement diet changes to allow you to become
							your best self. The blog consists of diet specific recipes, including gluten free,
							diary free, vegan, paleo, and low carb, tips on how to change eating habits, and her
							own personal health stories. <br />
							<br />
							19. <a href='https://www.wellandgood.com/'> Well+Good</a>
							<br />
							<br />
							The health and fitness industry is continually evolving, and if you love to stay on
							top of the latest fitness trends and healthy living tips, Well+Good is the blog for
							you. Articles feature the newest developments in all facets of wellness, ranging from
							sustainable travel tips to natural energy supplements. <br />
							<br />
							20. <a href='https://mellowed.com/'> Mellowed</a>
							<br />
							<br />
							Life can be difficult, confusing and well, stressful. Now try to make lifestyle
							changes and it becomes even more complicated. That’s where Mellow comes in. The blog
							is designed to help you cope with the stress that life throws at you each day, and
							live a healthier, more mellow life. Mellow focuses on mental health, stress
							management, anxiety, wellness and everything in between that plays a role in helping
							you enjoy life, not stress over it.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
