import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store14 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Essential Thyroid',
			price: 220.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Essential Thyroid</div>
							<br />
							<br />
							<div>$220.00</div> <br />
							<br />
							<div>
								Understand how your thyroid is working and find out the source of your fatigue,
								sensitivity to cold, or trouble losing weight.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						Easily measure your Vitamin D status and levels for the 3 main thyroid hormones (TSH,
						T3, and T4) plus thyroid antibodies with this comprehensive test—all from the comfort of
						your own home.
						<br />
						<br />
						TSH, fT3, fT4, TPOa Vitamin D{' '}
					</div>
					<br />
					<br />
					<Row>
						<Col md='6'>
							<div className='product-title'>Symptoms of Hypothyroidism:</div>
							<br />
							<br />
							<ul>
								<li>Sluggishness and Fatigue or Feeling Tired</li>
								<br />
								<li>Weight Gain</li>
								<br />
								<li>Muscle Weakness, Aches, Tenderness</li>
								<br />
								<li>Depression</li>
								<br />
								<li>Cold Sensitivity</li>
								<br />
								<li>Mental Fogginess</li>
								<br />
								<li>Irregular or Heavier Than Normal Periods</li>
								<br />
								<li>Constipation</li>
								<br />
								<li>Dry Skin and Thinning Hair</li>
								<br />
								<li>High Blood Sugar</li>
								<br />
								<li>High Cholesterol</li>
								<br />
							</ul>
						</Col>
						<Col md='6'>
							<div className='product-title'>Symptoms of Hyperthyroidism:</div>
							<br />
							<br />
							<ul>
								<li>Unexpected or Unusual Weight Loss</li>
								<br />
								<li>Rapid Heartbeat</li>
								<br />
								<li>Increased Appetite</li>
								<br />
								<li>Nervousness and Anxiousness</li>
								<br />
								<li>Shaking or Trembling</li>
								<br />
								<li>Frequent Sweating</li>
								<br />
								<li>Heat Sensitivity</li>
								<br />
								<li>More Frequent Bowel Movements</li>
								<br />
								<li>Swelling in the Neck from Enlarged Thyroid</li>
								<br />
								<li>Sleep Difficulty</li>
								<br />
							</ul>
						</Col>
					</Row>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
