import React, { Component } from 'react';
import LIT from '../../assets/img/fat_mass(1).png';
import '../Book.css';

export default class PriceNav extends Component {
	render() {
		return (
			<div className='scroll-icon location-scroll-icon'>
				<div
					id='Service-circle'
					onClick={this.props.click}
					className={`circle-border2 ${this.props.on}`}>
					<img className='small-button-icons2' src={LIT} alt='piggy pank with coin going in' />
				</div>
				<h6 className='grey-label'>Prices</h6>
			</div>
		);
	}
}
