import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Belly from '../../assets/img/how-phone-3.png';
import Lean from '../../assets/img/how-phone-1.png';
import '../Service.css';

export default class Vo23 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder' xs='12' sm='12' md='8' lg='8' xl='6'>
					<Fade left>
						<div id='blue-block'></div>
						<img className='belly-fat-phone' src={Belly} alt='dexafit multi shot of app' />
						<img className='lean-mass-phone' src={Lean} alt='woman with dumbells' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 4, offset: 0 }}
					lg={{ size: 4, offset: 0 }}
					xl={{ size: 3, offset: 2 }}>
					<Row>
						<Col className='spacer-16' sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>
									Trusted by the Mayo Clinic and top sports scientists
								</h3>
								<br />
								<p className='small-label-3'>
									Along with your VO2max, discover your Aerobic Base, Anaerobic Threshold, and
									personalized Heart Rate zones.
								</p>
							</Fade>
						</Col>
						<Col sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>VO2max is the leading predictor of premature death.</h3>
								<br />
								<p className='small-label-3'>
									Get a clearer understanding of your all cause mortality risks and track how your
									longevity changes with new habits.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
