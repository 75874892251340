import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Dumbbell from '../../assets/img/Dexafit_Working_Out_v2.jpg';
import ThreePhones from '../../assets/img/3-phones-home.png';
import '../Home.css';

export default class Home1 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 4, offset: 1 }}>
					<Fade left>
						<h2 className='Know-your-body'>Know your body.</h2>
						<h2 className='Transform-your-life'>Transform your life</h2>
						<p className='Join-thousands-of-ot'>
							Get a comprehensive snapshot of your health with DexaFit’s AI-powered DEXA scans,
							VO2max testing, RMR analysis, and more.
						</p>
						<br /> <br />
						<Button
							className='GetStarted-button'
							onClick={(e) => this.handlePageChange('/Map')}
							color='primary'>
							Get Started
						</Button>
					</Fade>
				</Col>
				<Col
					className='Multi-image-holder'
					md={{ size: 7, offset: 0 }}
					lg={{ size: 6, offset: 1 }}
					xl={{ size: 6, offset: 1 }}>
					<Fade right>
						<img className='Sharp' src={ThreePhones} alt='dexafit multi shot of app' />
						<img className='Dumbbell' src={Dumbbell} alt='woman with dumbells' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
