import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store8 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Longevity Panel',
			price: 249.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>Longevity Panel</div>
							<br />
							<br />
							<div>$249.00</div>
							<strike>$299.00</strike> <br />
							<br />
							<strong>
								The Longevity Panel is your map to know how the environment is impacting your health
								and longevity.{' '}
							</strong>{' '}
							<br />
							<br />
							<div>
								You will see exactly how well you're hydrated, your hormonal health, and how your
								lifestyle and environment affects your optimal state of well-being. <br />
								<br />
								*Please note: this test is not yet available if you’re in MD, NJ, NY, RI, ND, SD, or
								HI. We recommend trying one of our at-home testing kits.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title'>
						How is your zip code influencing your genetic code and longevity?
					</div>
					<br />
					<br />
					<div>
						The emerging field of epi-genetics is teaching us how the environment around us
						influences the way genes express themselves and determines how we look, feel, and
						perform.
						<br />
						<br />
						What we eat and drink... our exposure to non-native EMF, artificial light...
						environmental toxins, parasites, and mold... all of these things influence health in
						ways doctors and researchers never imagined until recent years.
						<br />
						<br />
						The sweet spot of optimal health is when you
						<strong>
							{' '}
							find balance between your gut health, your cortisol hormones, your steroid hormones
							and your thyroid.
						</strong>{' '}
						These must work synchronization to achieve your best.
						<strong>
							{' '}
							How you get there is by understanding how your environment and hydration
						</strong>{' '}
						affect your sleep and the parasympathetic nervous system for autophagy, regeneration,
						and healing.
						<br />
						<br />
						The Longevity Panel is{' '}
						<strong>
							your map to know how the environment is impacting your health and longevity.
						</strong>
						<br />
						<br />
						Your results will show you exactly how well your cells are hydrated, your hormonal
						health, and how your lifestyle and environment affects your state of well-being.
						<br />
						<br />
						This panel is best to monitor 2-4 times throughout the year, depending on your personal
						circumstances.{' '}
					</div>
					<br />
					<br />
					<div className='product-title'>Everything included:</div>
					<br />
					<ul>
						<li>25-Hyroxy Vitamin D </li>
						<br />
						<li>HS-CRP </li>
						<br />
						<li>ALT </li>
						<br />
						<li>Bun/Creatine Ratio </li>
						<br />
						<li>DHEA </li>
						<br />
						<li>Total Testosterone</li>
						<br />
						<li>Free Testosterone </li>
						<br />
						<li>Free T3 </li>
						<br />
						<li>TSH </li>
						<br />
						<li>DHEA-S </li>
						<br />
						<li>Cortisol </li>
						<br />
						<li>Glucose </li>
						<br />
						<li>A1C</li>
						<br />
						<li>Progesterone Lipid Panel </li>
						<br />
						<li>Cortisol </li>
						<br />
						<li>Homocysteine</li>
						<br />
						<li>E2</li>
						<br />
					</ul>
				</Col>
			</Row>
		);
	}
}
