import React, { Component } from 'react';
import LAB from '../../assets/img/LabResults(1).png';
import '../Service.css';

export default class MedNav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img className='small-button-icons' src={LAB} alt='Meds icon' />
				</div>
				<h6 className='grey-label'>Medicine</h6>
			</div>
		);
	}
}
