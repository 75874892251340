import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store3 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'FitnessGenes Muscle Build System',
			price: 289.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>FitnessGenes Muscle Build System</div>
							<br />
							<br />
							<div>$289.00</div> <br />
							<br />
							<strong>FitnessGenes DNA Analysis + Muscle Build System</strong> <br />
							<br />
							<div>
								Build muscle and gain strength. This DNA analysis and 12-week Muscle Building Plan
								gives you unique insights into your fitness related genes and physiology. Your
								personalized plan will pave the way to increasing your muscle mass and strength
								through periodization and progressive overload.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>Fitness Genes Muscle Build System</div>
					<br />
					<br />
					<div className='text-center'>
						Genetically enhanced workout system designed to help you build muscle.
					</div>
					<br />
					<br />
					<strong>TESTOSTERONE LEVELS</strong>
					<br />
					<br />
					<div>
						Testosterone is affected by your genetics and lifestyle. Learn if you can make some
						changes to increase your testosterone levels.
					</div>
					<br />
					<br />
					<strong>ENDURANCE VS STRENGTH</strong>
					<br />
					<br />
					<div>
						Learn if your genetics are linked with fast-twitch or slow-twitch muscle fibers and find
						out if you’re built for strength or endurance.
					</div>
					<br />
					<br />
					<strong>VO2 MAX</strong>
					<br />
					<br />
					<div>
						Find out if you have the VO2 Max capacity of an endurance or strength athlete. Learn if
						you can maximize your VO2 Max capacity.
					</div>
					<br />
					<br />
					<strong>OPTIMIZE MUSCLE RECOVERY</strong>
					<br />
					<br />
					<div>
						How well can your body clear lactic acid and prolong the onset of muscle fatigue. You
						may be able to prolong the onset of muscle fatigue.{' '}
					</div>
					<br />
					<br />
					<strong>CAFFEINE METABOLISM</strong>
					<br />
					<br />
					<div>
						Caffeine can play an important part in weight training. Learn when you should take
						caffeine to increase performance and sleep.
					</div>
					<br />
					<br />
					<strong>MUSCLE GROWTH</strong>
					<br />
					<br />
					<div>
						There is a rare version of the MSTN gene, which is linked to low myostatin levels and
						mass muscle growth. Do you have this gene?
					</div>
					<br />
					<br />
					<div className='product-title text-center'>How does it work?</div>
					<br />
					<br />
					<div className='text-center'>
						Personalized plans, refined by genetics, simplify how you build muscle and increase
						strength.
					</div>
					<br />
					<br />
					<div className='product-title'>DNA ANALYSIS</div>
					<br />
					<ul>
						<li>DNA Test Kit</li>
						<br />
						<li>DNA Analysis</li>
						<br />
						<li>40+ Gene Results</li>
						<br />
						<li>Detailed FitnessGenes Gene report</li>
						<br />
					</ul>
					<br />
					<div className='product-title'>MEMBERS AREA</div>
					<br />
					<ul>
						<li>Online members portal</li>
						<br />
						<li>Training action blueprint</li>
						<br />
						<li>Nutrition action blueprint with calculator</li>
						<br />
						<li>FitnessGenes eBooks</li>
						<br />
						<li>Exercise library</li>
						<br />
						<li>Glossary</li>
						<br />
						<li>Ongoing Gene and Scientific Updates</li>
						<br />
					</ul>
					<br />
					<div className='product-title'>FITNESSGENES MUSCLE-BUILDING SYSTEM</div>
					<br />
					<ul>
						<li>12-Week Periodized Muscle-Building Plan</li>
						<br />
						<li>Exercise descriptions, photos, and videos</li>
						<br />
						<li>Macronutrient Plan</li>
						<br />
						<li>Online Support</li>
						<br />
						<li>Free HIIT Workouts</li>
						<br />
						<li>Free Abs and Core Workouts</li>
						<br />
					</ul>
					<br />
				</Col>
			</Row>
		);
	}
}
