import React, { Component } from 'react';
import ACT from '../../assets/img/Activity(1).png';
import '../Service.css';

export default class AthNav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img id='ath-icon-img' className='small-button-icons' src={ACT} alt='Athletics icon' />
				</div>
				<h6 className='grey-label'>Athletics</h6>
			</div>
		);
	}
}
