import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledCarousel } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Scan from '../../assets/img/Dexafit_DXA_Scan.png';
import ThreeD from '../../assets/img/Dexafit_Styku_3D.png';
import Rmr from '../../assets/img/Dexafit_RMR_Test.png';
import Vo2 from '../../assets/img/Dexafit_Treadmill.png';
import Lean from '../../assets/img/lean_mass(1).png';
import RMR from '../../assets/img/RMR(2).png';
import Lab from '../../assets/img/LabResults(1).png';
import Active from '../../assets/img/Activity(1).png';
import '../BusinessHome.css';

export default class Business2 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		const items = [
			{
				src: Scan,
				altText: '',
				caption: '',
			},
			{
				src: ThreeD,
				altText: '',
				caption: '',
			},
			{
				src: Rmr,
				altText: '',
				caption: '',
			},
			{
				src: Vo2,
				altText: '',
				caption: '',
			},
		];
		return (
			<Row>
				<Col>
					<Row>
						<Col>
							<h4 className='Business-slide-2-top-text-1'>Featured Technologies</h4>
							<p className='Business-slide-2-top-text-2'>
								Leverage AI-powered diagnostic testing considered the gold standard by medicine and
								science
							</p>
						</Col>
					</Row>
					<Row>
						<Col lg='5'>
							<Fade left>
								<UncontrolledCarousel items={items} />
							</Fade>
						</Col>
						<Col className='Business-slide-2-right-spacing' lg='7'>
							<Row>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-2-right-img-holder' xs='3'>
											<img
												className='Business-slide-2-right-img'
												src={Lean}
												alt='a cartoon arm flexing'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-2-right-text-top'>Body Composition</div>
											<div className='Business-slide-2-right-text-bottom'>
												DEXA body composition and 3D body scanning
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-2-right-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-2-right-img-holder' xs='3'>
											<img
												className='Business-slide-2-right-img'
												src={Active}
												alt='a cartoon red sneaker running'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-2-right-text-top'>Cardio Fitness</div>
											<div className='Business-slide-2-right-text-bottom'>
												VO2max cardiorespiratory fitness testing
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-2-right-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-2-right-img-holder' xs='3'>
											<img
												className='Business-slide-2-right-img'
												src={RMR}
												alt='a cartoon campfire'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-2-right-text-top'>Metabolism</div>
											<div className='Business-slide-2-right-text-bottom'>
												Metabolic health analysis, including RMR and more
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-2-right-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
								<Col lg='6'>
									<Row>
										<Col className='Business-slide-2-right-img-holder' xs='3'>
											<img
												className='Business-slide-2-right-img'
												src={Lab}
												alt='a cartoon test vial with a cross on it'
											/>
										</Col>
										<Col xs='9'>
											<div className='Business-slide-2-right-text-top'>Biomarkers</div>
											<div className='Business-slide-2-right-text-bottom'>
												Lab testing for Blood, DNA, Bicrobiome, and more
											</div>
											<Button
												onClick={(e) => this.handlePageChange('/Services')}
												className='Business-slide-2-right-button'>
												Learn More
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
