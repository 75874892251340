import React, { Component } from 'react';
//import { Link } from 'react';
import { Col, Row, Button } from 'reactstrap';
import NormalLogo from '../../assets/img/Dexafit-tag.png';
import WhiteLogo from '../../assets/img/DexaFit-White-Logo.png';
import CustomerPhone from '../../assets/img/New-Home-View.png';
import BusinessPhone from '../../assets/img/ChoiceBuis.png';

export default class ChoiceSlide extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	ButtonPress = (choice) => {
		switch (choice) {
			case 'Customer':
				this.props.PaddingSwitch();
				sessionStorage.setItem('Dexa-Customer-Type', 'Customer');
				window.location.reload();
				break;
			case 'Business':
				this.props.PaddingSwitch();
				sessionStorage.setItem('Dexa-Customer-Type', 'Business');
				window.location.reload();
				break;
			default:
				break;
		}
	};

	render() {
		return (
			<div>
				<Row>
					<Col md='6' className='Choice-off-color'>
						<Row>
							<Col lg='6'>
								<img
									className='Choice-logo'
									id='Choice-logo-left'
									src={WhiteLogo}
									alt='white dexafit Icon'
								/>
							</Col>
							<Col lg='6'>
								<div className='Choice-get-started'>Get Started</div>
							</Col>
						</Row>
						<Row>
							<Col md='8'>
								<div className='Choice-For'>For Everyone</div>
								<div className='Choice-text'>
									Find everything you need to achieve optimal health and fitness
								</div>
								<Button
									onClick={() => {
										this.ButtonPress('Customer');
									}}
									className='Choice-slide-button'>
									Get Started Now
								</Button>
							</Col>
							<Col className='Choice-phone-holder' md='4'>
								<img
									className='Choice-phone'
									src={CustomerPhone}
									alt='phone app with body scores on them'
								/>
							</Col>
						</Row>
					</Col>
					<Col className='Choice-off-color-2' md='6'>
						<Row>
							<Col lg='6'>
								<img
									id='Choice-logo-right'
									className='Choice-logo'
									src={NormalLogo}
									alt='white dexafit Icon'
								/>
								<div id='Choice-business-text'>Business</div>
							</Col>
							<Col lg='6'>
								<div className='Choice-get-started'>Learn More</div>
							</Col>
						</Row>
						<Row>
							<Col md='8'>
								<div className='Choice-For'>For Healthcare, Business & Pros</div>
								<div className='Choice-text'>
									Transform lives with AI-driven software and health technologies
								</div>
								<Button
									onClick={() => {
										this.ButtonPress('Business');
									}}
									id='Choice-right-button'
									className='Choice-slide-button'>
									Learn More
								</Button>
							</Col>
							<Col className='Choice-phone-holder' md='4'>
								<img
									className='Choice-phone'
									src={BusinessPhone}
									alt='phone app with body scores on them'
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}
