import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Button } from 'reactstrap';
import AppleDown from '../assets/img/AppleDownload.png';
import GoogleDown from '../assets/img/GoogleDownload.png';

export default function Phone(props) {
	const [value, setValue] = useState();
	return (
		<div className='app-download-wraper'>
			<div>
				<a href='https://apps.apple.com/us/app/fit-by-dexafit/id1444806342'>
					<img className='app-download-button' src={AppleDown} alt='Apple store download link' />
				</a>
				<a href='https://play.google.com/store/apps/details?id=com.dexafit&hl=en_US'>
					<img className='app-download-button' src={GoogleDown} alt='Google store download link' />
				</a>
				<br />
				<br />
			</div>
			<h5 className='Download-the-App'>Or get a link to download:</h5>
			<div className='phone-number-center-div'>
				<PhoneInput
					className='input-number'
					country={'us'}
					placeholder='Enter phone number'
					value={value}
					onChange={(newValue) => setValue(newValue)}
				/>
			</div>
			<Button onClick={() => props.handleSubmit(value)} className='input-button'>
				Send
			</Button>
			<br />
			<br />
		</div>
	);
}
