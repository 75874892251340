import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Sleeping from '../assets/img/SleepingStock.jpeg';
import Meditate from '../assets/img/Meditatestock.jpeg';
import Stretch from '../assets/img/StretchStock.jpeg';
import Heart from '../assets/img/HeartStock.jpeg';
import Meet from '../assets/img/MeetStock.jpeg';
import Stalling from '../assets/img/StallingStock.jpeg';
import Mar112020 from './slides/BlogPages/Mar112020.jsx';
import Feb282020 from './slides/BlogPages/Feb282020.jsx';
import Feb142020 from './slides/BlogPages/Feb142020.jsx';
import Feb62020 from './slides/BlogPages/Feb62020.jsx';
import Jan72020 from './slides/BlogPages/Jan72020.jsx';
import Dec122019 from './slides/BlogPages/Dec122019.jsx';
import Mar272019 from './slides/BlogPages/Mar272019.jsx';
import Sep172019 from './slides/BlogPages/Sep172019.jsx';
import Jun212018 from './slides/BlogPages/Jun212018.jsx';
import May102018 from './slides/BlogPages/May102018.jsx';
import Jan172018 from './slides/BlogPages/Jan172018.jsx';
import Nov82017 from './slides/BlogPages/Nov82017.jsx';
import Oct52017 from './slides/BlogPages/Oct52017.jsx';
import May222017 from './slides/BlogPages/May222017.jsx';
import Dec122016 from './slides/BlogPages/Dec122016.jsx';
import Dec282015 from './slides/BlogPages/Dec282015.jsx';
import Dec292015 from './slides/BlogPages/Dec292015.jsx';
import Dec202015 from './slides/BlogPages/Dec202015.jsx';

import Footer from '../Footer';

export default class Blog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			title: this.props.match.params.blogName.replace('%20', ' '),
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	handleBlogChange = (blog) => {
		this.setState({
			title: blog,
		});
		window.scrollTo(0, 0);
		//let statedata = null;
		// window.history.pushState(statedata, '', blog);
		this.props.history.push(blog);
	};
	handleReload = () => {
		this.setState({
			title: 'home',
		});
		this.props.history.push('home');
		window.scrollTo(0, 0);
	};
	handleNewPage = (page) => {
		this.setState({
			page: page,
		});
		window.scrollTo(0, 0);
	};
	handleRight = () => {
		let currentPage = this.state.page;
		let newPage = currentPage + 1;
		if (newPage > 3) {
			newPage = 1;
		}
		this.setState({
			page: newPage,
		});
		window.scrollTo(0, 0);
	};
	handleLeft = () => {
		let currentPage = this.state.page;
		let newPage = currentPage - 1;
		if (newPage < 1) {
			newPage = 3;
		}
		this.setState({
			page: newPage,
		});
		window.scrollTo(0, 0);
	};
	render() {
		const blogPosts = [
			{
				title: 'Are Your Hormones Impacting Your Sleep',
				date: 'MAR 11, 2020',
				text:
					'Not only do your hormones affect your mood, metabolic function, production, and more, they also play a huge role in the amount of sleep you manage to get each night. Balancing your hormones is vital to getting the sleep your body needs each night,',
				img: Sleeping,
				blog: <Mar112020 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: '3 Ways to Improve your Self-Care Routine',
				date: 'FEB 28, 2020',
				text:
					'There’s no better time to update your self-care routine than now. Everyone has their own unique routine that is geared toward what is most important to them. Modifying your routine will help keep you on track to achieve your goals.',
				img: Meditate,
				blog: <Feb282020 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: 'Top 20 Health and Fitness Blogs of 2020',
				date: 'FEB 14, 2020',
				text:
					'To make it easier for you to stay on top of your game and keep up to date with the latest health and fitness trends, we removed the guesswork and compiled a list of the top 20 health and fitness blogs to get you started, or keep you on track, this year.',
				img: Stretch,
				blog: <Feb142020 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: 'It Starts With Your Heart',
				date: 'FEB 6, 2020',
				text:
					'Did you know your heart beats 100,000 times a day? Responsible for circulating blood and oxygen through your body, your heart is the center of everything that happens in your body. When you don’t properly care and nurture your heart, you put yourself at risk for complications and disease.',
				img: Heart,
				blog: <Feb62020 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: 'Meet Your Master Hormone: Leptin',
				date: 'JAN 7, 2020',
				text:
					'All hormones play a crucial role in your body, but there is one that holds the skeleton key and is considered the master: Leptin.  You may have heard of leptin called the "satiety hormone" or "starvation hormone." It controls all energy metabolism in your body, and plays a significant role in fertility, immunity and brain function.',
				img: Meet,
				blog: <Jan72020 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: 'Are your hormones stalling your progress',
				date: 'DEC 12, 2019',
				text:
					'You committed to an exercise program. You’re following a diet that a friend or coach recommended. And you’re doing everything conventional wisdom claims to do to lose body fat. Yet still... no change. But have you checked your hormones? If your hormones are out of whack, your metabolism will get wonky and weight gain, disease, and frustration will follow.',
				img: Stalling,
				blog: <Dec122019 handleReload={this.handleReload} />,
				page: 1,
			},
			{
				title: 'How One Simple Number Ignites the Change You Need',
				date: 'Sep 17, 2019',
				text:
					'Have you ever stopped and wondered how long you might live? We did. It’s why we created a solution that helps people get a leg up on optimizing their longevity. Aging affects us all, and we all want to know how to live a longer, more optimal life. ',
				img: Meditate,
				blog: <Sep172019 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'Harness the Power of VO2 Max Testing',
				date: 'Mar 27, 2019',
				text:
					'If you track your carbs, time your laps and rarely forget your FitBit at home, you already understand the importance of collecting health data. But a test once utilized only in labs for elite athletes is now bringing more powerful fitness data to anyone, whether you’re a badminton buff or serial marathoner. ',
				img: Stretch,
				blog: <Mar272019 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'Does Metabolic Testing Benefit Athletes',
				date: 'Jun 21, 2018',
				text:
					'Most athletes know that to keep themselves in top shape, they must eat well, sleep well, and exercise regularly.  Find out how athletes are utilizing metabolic testing in their training regimen to gain benefits.',
				img: Meet,
				blog: <Jun212018 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'DexaFit Services: 3D Body Scanner + Metabolic Testing',
				date: 'May 10, 2018',
				text: `Health and wellness are an important part of life, and everyone is always trying to figure out how to reach peak health through fitness plans, diets, workout routines and more. While there are many ways to stay in shape and maintain both physical and emotional wellness, you usually cannot do it all alone.`,
				img: Heart,
				blog: <May102018 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'The Battle of Body Fat Testing Methods',
				date: 'Jan 17, 2018',
				text: `Before addressing more specific areas of health, training, and nutrition—i.e. the things you want to do to actually improve your body composition and health rather than just measure it—let's first address some common questions we get after first time scans. `,
				img: Stalling,
				blog: <Jan172018 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'Why Do I Need A DEXA Scan',
				date: 'May 22, 2017',
				text: `When it comes to full body composition scanning, there is no better type of scan other than the DEXA scan (or DXA Scan). Offered by DexaFit, this full body scan is incredibly convenient, easy, and accurate. It’s a great way to gather information about your body and take charge of your health. The DEXA scan continues to revolutionize the medical world and will continue to benefit many people for years to come.`,
				img: Meditate,
				blog: <May222017 handleReload={this.handleReload} />,
				page: 2,
			},
			{
				title: 'BMR vs. RMR: Everything You Need to Know',
				date: 'Oct 5, 2017',
				text: `For many people, BMR (Basal Metabolic Rate) and RMR (Resting Metabolic Rate) are synonymous. Both titles refer to roughly the same concept, and so have very little difference in one's mind. The truth, however, is that BMR and RMR actually have differences that make a difference when it comes to watching your weight and counting your calories.`,
				img: Meet,
				blog: <Oct52017 handleReload={this.handleReload} />,
				page: 3,
			},
			{
				title: 'What is Mitochondrial Fitness',
				date: 'Nov 8, 2017',
				text: `You've likely heard mitochondria are the power stations of your cells. But what exactly does this mean for you? Mitochondria give your body 95 percent of the total energy it needs. They use oxygen to burn fat, glucose, and amino acids to create ATP. `,
				img: Heart,
				blog: <Nov82017 handleReload={this.handleReload} />,
				page: 3,
			},
			{
				title: 'Benefits of a Body Composition Analysis',
				date: 'Dec 12, 2016',
				text: `A BCA (body composition analysis, for anyone unfamiliar with the term) is the “process of examining the human body to determine the percentage of body fat compared to muscle tissue.”`,
				img: Stretch,
				blog: <Dec122016 handleReload={this.handleReload} />,
				page: 3,
			},
			{
				title: 'A More Convenient & Enjoyable Way to Measure Body Fat',
				date: 'Dec 29, 2015',
				text: `You’ll discover new ways to explore and improve your health and body composition. You’ll never have more fun constantly investigating and critically rethinking everything you assumed to be true about health, nutrition, training, and overall wellness.”`,
				img: Stalling,
				blog: <Dec292015 handleReload={this.handleReload} />,
				page: 3,
			},
			{
				title: 'Athletes and Body Composition, important for EVERYONE',
				date: 'Dec 28, 2015',
				text: `Athletes are always looking for the perfect body fat to muscle ratio—that sweet spot of optimal weight, strength, speed, and endurance. Their potential is largely dependent on the ability to sustain power both anaerobically and aerobically, and to overcome heavy resistance.”`,
				img: Meditate,
				blog: <Dec282015 handleReload={this.handleReload} />,
				page: 3,
			},
			{
				title: 'Types of Body Fat and the Dangers of Visceral Fat',
				date: 'Dec 20, 2015',
				text: `Anyone trying to lose weight knows it's all about burning off the unhealthy excess fat. But as it turns out, not all fat is created equal—and not all people who need to lose fat even know about it. `,
				img: Meet,
				blog: <Dec202015 handleReload={this.handleReload} />,
				page: 3,
			},
		];
		let slideDot1;
		let slideDot2;
		let slideDot3;
		switch (this.state.page) {
			case 1:
				slideDot1 = 'circle-on';
				break;
			case 2:
				slideDot2 = 'circle-on';
				break;
			case 3:
				slideDot3 = 'circle-on';
				break;

			default:
				break;
		}
		return (
			<Container fluid>
				{this.state.title === 'home' || !this.state.title ? (
					<div>
						<Row>
							<Col className='text-center'>
								<div className='Privacy-policy-title'>DexaFit Blog</div> <br />
								<br />
								<br />
							</Col>
						</Row>
						<Row>
							<Col md={{ size: 10, offset: 1 }}>
								<Row>
									{blogPosts.map((post) =>
										this.state.page === post.page ? (
											<Col key={post.date} md='6'>
												<Fade left>
													<div className='text-center card-blog'>
														<img
															className='blog-thumbnail'
															src={post.img}
															alt='blog post thumbnail'
															onClick={(e) => this.handleBlogChange(post.title)}
														/>
														<div
															className='blog-title'
															onClick={(e) => this.handleBlogChange(post.title)}>
															{post.title}
														</div>
														<div
															className='blog-date'
															onClick={(e) => this.handleBlogChange(post.title)}>
															{post.date}
														</div>
														<div
															className='blog-text'
															onClick={(e) => this.handleBlogChange(post.title)}>
															{post.text}
														</div>
														<div
															className='blog-read'
															onClick={(e) => this.handleBlogChange(post.title)}>
															Read more
														</div>
													</div>
												</Fade>
											</Col>
										) : null
									)}
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className='text-center'>
								<div id='dot-holder2'>
									<div
										onClick={(e) => {
											this.handleLeft();
										}}
										className={`slide-circle2 left-arrow-3 `}>
										{'<'}
									</div>
									<div
										onClick={(e) => {
											this.handleNewPage(1);
										}}
										className={`slide-circle2 ${slideDot1}`}></div>
									<div
										onClick={(e) => {
											this.handleNewPage(2);
										}}
										className={`slide-circle2 ${slideDot2}`}></div>
									<div
										onClick={(e) => {
											this.handleNewPage(3);
										}}
										className={`slide-circle2 ${slideDot3}`}></div>
									<div
										onClick={(e) => {
											this.handleRight();
										}}
										className={`slide-circle2 last-slide-circle2 right-arrow-3 `}>
										{'>'}
									</div>
								</div>
							</Col>
						</Row>
					</div>
				) : (
					<div>
						{blogPosts.map((post) =>
							this.state.title === post.title ? <div key={post.date}>{post.blog}</div> : null
						)}
					</div>
				)}

				<Footer history={this.props.history} />
			</Container>
		);
	}
}
