import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Jan172018 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>The Battle of Body Fat Testing Methods</div>
						<br />
						<div className='blog-date-page'>Jan 17, 2018</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							DXA vs Bod Pod, "Dunk Tank," & Everyone Else <br />
							<br />
							Before addressing more specific areas of health, training, and nutrition—i.e. the
							things you want to do to actually improve your body composition and health rather than
							just measure it—let's first address some common questions we get after first time
							scans.
							<br />
							<br />
							One specific 800-pound gorilla in the scanning room after each test:
							<br />
							<br />
							“Why is my DXA body fat percentage different than other tests I’ve done in the past?”
							<br />
							<br />
							For most first-timers, they’re shocked to learn they have a higher body fat percentage
							than they previously believed. Especially if they’re accustomed to other common
							methods of body fat testing found in most places that don’t have access to DXA.
							<br />
							<br />
							But comparing DXA to other methods for <a href='/Services'>body fat measurement</a> is
							like comparing apples to oranges. DXA is in a league of its own.
							<br />
							<br />
							So maybe apples to Oysters Rockefeller.
							<br />
							<br />
							The biggest difference you ask?
							<br />
							<br />
							The main reason (and why DXA is considered the gold standard) is that DXA is the most
							accurate measurement of body composition, offers the best precision between tests, and
							uses a three-compartment model vs two.
							<br />
							<br />
							And remember...the alligator eats 3 not 2.
							<br />
							<br />
							Three compartment model means you get a measurement of not only your lean and fat mass
							(the two compartment model), but also your bone mass (very important for knowing your
							risk of osteoporosis, osteopenia, and other low bone mass issues).
							<br />
							<br />A <a href='/Service'>DEXA scan</a> also shows you the fat and lean mass
							distribution throughout your entire body! This means you can see how each region of
							your body compares to each other (left arm fat vs right arm fat... right leg muscle vs
							left leg… belly fat, including your visceral fat specifically -- i.e., the ‘scary fat’
							if it’s too high). Other popular methods using the two-compartment model like
							Hydrostatic Weighing, Bod Pod, and Calipers only measure fat and fat-free mass and
							can’t report your fat and lean mass distribution.
							<br />
							<br />
							These methods are also calculated measurements of body fat, influenced by
							uncontrollable environmental variables, human error, less precision, and a high margin
							of error.
							<br />
							<br />
							For example, let’s examine the underlying premises and methods of Underwater Weighing
							(aka Hydrostatic Weighing)—a calculated measurement that is the previous ‘gold
							standard’ of body fat testing.
							<br />
							<br />
							With Underwater Weighing, the underlying assumption is that densities of fat mass and
							fat-free mass are constant. The densities of bone and muscles are higher than water,
							and fat is less dense than water.
							<br />
							<br />
							So if you have more bone and muscle you will weigh more in water than a person with
							less bone and muscle. This means you have a higher body density and lower percentage
							of body fat.
							<br />
							<br />
							After an Underwater Weighing test, the volume of your body is calculated and your body
							density is determined by using standard formulas. Then, your body fat percentage is
							calculated from your body density using standard equations (either Siri or Brozek).
							<br />
							<br />
							This is sound theory, but in practice, it’s limited by the density assumption.
							<br />
							<br />
							It’s missing a few important and potentially conflicting variables that strongly
							influence the equations used to calculate your body density:
							<br />
							<br />
							<strong>+ Athletes:</strong> Athletes typically have denser bones and muscles than
							non-athletes; thus, their measurements often underestimate body fat percentage;
							<br />
							<br />
							<strong>+ Elderly patients:</strong> Conversely, the body fat of elderly folks --
							especially those with osteoporosis -- may be overestimated due to these body density
							assumptions;
							<br />
							<br />
							<strong>+ One-size-fits-all formulas:</strong> To date, specific equations that
							accommodate these different population groups have not been developed;
							<br />
							<br />
							DXA, on the other hand, can directly measure your bone density (its primary purpose in
							clinical settings) and distinguish it from your fat tissue and lean tissue.
							<br />
							<br />
							This significantly reduces the margin of error because DXA doesn’t calculate body fat
							percentage from body density equations or require extra calculations that use
							non-specific equations—it’s a direct measurement.
							<br />
							<br />
							One other important variable to consider with the Underwater Weighing method is the
							amount of air left in your lungs after breathing out.
							<br />
							<br />
							Few of us can blow all of the air out of our lungs before dunking our head underwater.
							In most cases, some air will be left. This is pretty important because gas floats and
							air in the lungs will cause us to be 'lighter' underwater.
							<br />
							<br />
							And in reality, it's actually impossible to get all the air out of the lungs. Humans
							simply can't do it. Thus, researchers use other methods to estimate how much air is
							left!!!! (Introducing yet another calculation and potential source of error).
							<br />
							<br />
							Other calculated measurements have similar limitations. When we have clients that
							question why there’s a spread between their DXA results and past measurements from
							Underwater Weighing, Calipers, or the Bod Pod, we explain how the subjective variables
							like the ones discussed above are inherent to calculated measurements of body fat.
							<br />
							<br />
							These greatly influence the consistency and accuracy of tests and any discrepancy
							between DXA and a calculated method is not due to an overestimate of the DXA.
							<br />
							<br />
							One last issue to consider when evaluating calculated measurements of body fat—and the
							reason why comparing DXA to other methods is like comparing apples to oysters—is the
							fact that calculated measurements of body fat have historically relied on Underwater
							Weighing as a benchmark for determining the accuracy of other calculated methods.
							<br />
							<br />
							What this means is these other methods were calibrated to Underwater Weighing, since
							it’s been the gold standard for reference (until DXA).
							<br />
							<br />
							For example, researchers would underwater weigh samples of people, measure their body
							composition using another method (e.g. calipers), then develop equations that
							correlated body fat % calculations from the latter method with the body fat %
							calculated by Underwater Weighing.
							<br />
							<br />
							This is why other methods typically cluster around each other but not DXA.
							<br />
							<br />
							But in any case, whether or not your DXA result is higher or lower than other tests
							isn't important.
							<br />
							<br />
							The greatest benefits with DXA is its consistent reliability from scan to scan, and
							its ability objectively detect subtle changes in your body composition.
							<br />
							<br />
							Couple these benefits with its three-compartment analysis and ability to display fat
							distribution by region, and no other method can compete as a better benchmark for
							tracking progress and evaluating your overall health.
							<br />
							<br />
							When your numbers go down on a DXA scan, you can be absolutely certain you’ve lost fat
							and/or gained muscle because it’s not the consequence of a confounding subjective
							variable or human error outside of your control.
							<br />
							<br />
							If you're interested in seeing how others are transforming their lives sooner using
							DXA data with our other tests and feedback, read a few DexaFit success stories here...
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
