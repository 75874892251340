import React, { Component } from 'react';
import { Row, Col, Button, InputGroup, Input, InputGroupText, InputGroupAddon } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import CardRotation from '../CardRotation/CardRotation.jsx';
import Img1 from '../../assets/img/animation-graphics/17.png';
import Img2 from '../../assets/img/animation-graphics/7.png';
import Img3 from '../../assets/img/animation-graphics/8.png';
import Img4 from '../../assets/img/animation-graphics/14.png';
import Icon from '../../assets/img/Icons/Dexafit_Icons_Measure.png';
import '../Service.css';

export default class Home21 extends Component {
	state = {
		search: '',
		activeOption: 0,
		showOptions: false,
		options: [
			'Dexa Scan',
			'Dexa Scan Near Me',
			'VO2 Max',
			'RMR',
			'Dexa AI',
			'AI',
			'3D',
			'Lab Testing',
			'Business',
			'Medicine',
			'Athletics',
			'Map',
			'Blog',
			'Blogs',
			'Locations',
		],
		filteredOptions: [],
	};

	onChange = (e) => {
		const filteredOptions = this.state.options.filter(
			(optionName) => optionName.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
		);

		this.setState({
			search: e.target.value,
			activeOption: 0,
			filteredOptions,
			showOptions: true,
		});
	};

	changePage = (search) => {
		switch (search) {
			case 'Dexa Scan':
				this.props.callBack('dexa');
				break;
			case 'Dexa Scan Near Me':
				this.handlePageChange('/Map');
				break;
			case 'VO2 Max':
				this.props.callBack('vo2');
				break;
			case 'RMR':
				this.props.callBack('rmr');
				break;
			case 'AI':
				this.props.scrollButton2();
				break;
			case 'Dexa AI':
				this.props.scrollButton2();
				break;
			case '3D':
				this.props.callBack('3d');
				break;
			case 'Lab Testing':
				this.props.callBack('lab');
				break;
			case 'Business':
				this.handlePageChange('/Solutions');
				break;
			case 'Medicine':
				this.handlePageChange('/Solutions');
				break;
			case 'Athletics':
				this.handlePageChange('/Solutions');
				break;
			case 'Map':
				this.handlePageChange('/Map');
				break;
			case 'Blog':
				this.handlePageChange('/Blog/home');
				break;
			case 'Blogs':
				this.handlePageChange('/Blog/home');
				break;
			case 'Locations':
				this.handlePageChange('/Map');
				break;
			default:
				break;
		}
	};

	onClick = (e) => {
		// this.setState({
		// 	activeOption: 0,
		// 	filteredOptions: [],
		// 	showOptions: false,
		// 	search: e.currentTarget.innerText,
		// });
		this.changePage(e.currentTarget.innerText);
	};
	rotateArrayUp = () => {
		this.state.filteredOptions.unshift(this.state.filteredOptions.pop());
		this.setState({ search: this.state.filteredOptions[0] });
	};
	rotateArrayDown = () => {
		this.state.filteredOptions.push(this.state.filteredOptions.shift());
		this.setState({ search: this.state.filteredOptions[0] });
	};

	onKeyDown = (e) => {
		if (e.keyCode === 13) {
			// this.setState({
			// 	activeOption: 0,
			// 	showOptions: false,
			// 	search: this.state.filteredOptions[this.state.activeOption],
			// });
			this.changePage(this.state.filteredOptions[0]);
		} else if (e.keyCode === 38) {
			// if (this.state.activeOption === 0) {
			// 	return;
			// }
			this.rotateArrayUp();
			//this.setState({ activeOption: this.state.activeOption - 1 });
		} else if (e.keyCode === 40) {
			// if (this.state.activeOption === this.state.filteredOptions.length - 1) {
			// 	return;
			// }
			this.rotateArrayDown();
			//this.setState({ activeOption: this.state.activeOption + 1 });
		}
	};

	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		let optionList;
		if (this.state.showOptions && this.state.search) {
			if (this.state.filteredOptions.length) {
				optionList = (
					<ul className='search-option'>
						{this.state.filteredOptions.map((optionName, index) => {
							let className;
							if (index === this.state.activeOption) {
								className = 'search-option-active';
							}
							return (
								<li className={className} key={optionName} onClick={this.onClick}>
									{optionName}
								</li>
							);
						})}
					</ul>
				);
			} else {
				optionList = (
					<div className='no-options'>
						<em>No Option!</em>
					</div>
				);
			}
		}
		return (
			<Row>
				<Col className='background-holder'>
					<Row className='spacer-home-2-20'>
						<Col
							sm={{ size: 10, offset: 1 }}
							lg={{ size: 5, offset: 1 }}
							md={{ size: 10, offset: 1 }}>
							<Row>
								<Fade left>
									<Col className='card-7' xs='11' md='12'>
										<h2 className='Home-2-top-label'>Do something epic with your body.</h2>
										<br />
										{/* <h4 className='mid-label'>The Gold Standard For Cardiovascular Health</h4> */}
										<h5 className='Home-2-bottom-label'>
											Get immediate, personalized insights about your body, fitness, and health with
											DexaFit.
										</h5>
										<InputGroup>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText className='Home-2-input-img-holder'>
													<img className='Home-2-input-img' src={Icon} alt='Magnify glass' />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												value={this.state.search}
												autoComplete='off'
												onKeyDown={this.onKeyDown}
												onChange={this.onChange}
												className='Home-2-input-bar'
												placeholder='Try "DEXA scan near me"'
											/>
											<InputGroupAddon addonType='append'>
												<Button
													className='Home-2-input-button'
													onClick={(e) => {
														this.changePage(this.state.filteredOptions[0]);
													}}
													color='primary'>
													Search
												</Button>
											</InputGroupAddon>
										</InputGroup>
										{optionList}
										<Row className='home-2-button-group-seperator'>
											<div className='home-2-button-group-popular'>Popular:</div>
											<div>
												<Button
													onClick={(e) => {
														this.props.callBack('dexa');
													}}
													className='Home-2-popular-buttons'>
													DEXA Scan
												</Button>

												<Button
													onClick={(e) => {
														this.props.callBack('rmr');
													}}
													className='Home-2-popular-buttons'>
													RMR
												</Button>

												<Button
													onClick={(e) => {
														this.props.callBack('lab');
													}}
													className='Home-2-popular-buttons'>
													Labs
												</Button>

												<Button
													onClick={(e) => {
														this.props.scrollButton();
													}}
													className='Home-2-popular-buttons'>
													Coaching
												</Button>
											</div>
										</Row>
										{/* <Button
										className='GetStarted-button-3 spacer-13'
										onClick={(e) => this.handlePageChange('/Map')}
										color='primary'>
										Get Started
									</Button> */}
									</Col>
								</Fade>
							</Row>
						</Col>
						<CardRotation
							Size={6}
							BackgroundLayer={false}
							Img1={Img1}
							Img2={Img2}
							Img3={Img3}
							Img4={Img4}
							Alt1='a circle progress bar with a smily face marking how far it has gottten and it reads the number 78, with the title Longevity score on top'
							Alt2='screenshot of a body scan from the dexafit app'
							Alt3='screenshot of two arms flexing in the dexafit app'
							Alt4='workout to heart rate and calorie conversion table'
						/>
					</Row>
				</Col>
			</Row>
		);
	}
}
