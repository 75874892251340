import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Dexa7 from './slides/Dexa-7';
import Vo27 from './slides/Vo2-7';
import RmR7 from './slides/Rmr-7';
import ThreeD7 from './slides/ThreeD-7';
import Lab7 from './slides/Lab-7';
import Fit7 from './slides/Fit-7';
import Price3 from './slides/Pricing-3';
import Footer from '../Footer';

export default class FAQ extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Container fluid>
				<h1 className='text-center'>Frequently Asked Questions</h1>
				<Dexa7 />
				<Vo27 />
				<RmR7 />
				<ThreeD7 />
				<Lab7 />
				<Fit7 />
				<Price3 />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
