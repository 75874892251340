import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import CardRotation from '../CardRotation/CardRotation.jsx';
import Img1 from '../../assets/img/animation-graphics/24.png';
import Img2 from '../../assets/img/animation-graphics/26.png';
import Img3 from '../../assets/img/animation-graphics/25.png';
import Img4 from '../../assets/img/animation-graphics/23.png';
import IconNav from '../IconNav';
import '../Service.css';

export default class Lab1 extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Row className='spacer-20'>
				<Col sm={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 1 }} md={{ size: 10, offset: 1 }}>
					<IconNav
						scrollStyle={this.props.scrollStyle}
						statePass2={this.props.statePass}
						callBack2={this.secondCallBack}
					/>
					<Row>
						<Fade left>
							<Col className='card-7' xs='11' md='12'>
								<h2 className='big-label'>
									Explore Beyond <br /> The Surface.
								</h2>
								<br />
								<h4 className='mid-label'>Simple and convenient at-home lab testing</h4>
								<h5 className='small-label'>
									Discover more about your blood, gut, and DNA with our convenient at-home lab
									testing kits.
								</h5>
								<br />
								<br />
								<Button
									className='GetStarted-button-3 spacer-13'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				<CardRotation
					Size={6}
					BackgroundLayer={true}
					Img1={Img1}
					Img2={Img2}
					Img3={Img3}
					Img4={Img4}
					Alt1='lab results that reads free testosterone is 650 and TSH is 6.4'
					Alt2='cartoons of differnt dexafit testing kits'
					Alt3='a graph that says free testosterone and is rising'
					Alt4='a scale that says Biomarkers at a glance and is a 76% optimzied'
				/>
			</Row>
		);
	}
}
