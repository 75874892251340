import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Belly from '../../assets/img/how-phone-3.png';
import Lean from '../../assets/img/phone@3x.png';
import '../Service.css';

export default class RmR3 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder' xs='12' sm='12' md='8' lg='8' xl='6'>
					<Fade left>
						<div id='blue-block'></div>
						<img className='belly-fat-phone' src={Belly} alt='dexafit multi shot of app' />
						<img className='lean-mass-phone' src={Lean} alt='woman with dumbells' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 4, offset: 0 }}
					lg={{ size: 4, offset: 0 }}
					xl={{ size: 3, offset: 2 }}>
					<Row>
						<Fade right>
							<Col className='spacer-16' sm='12' md='12'>
								<h3 className='mid-label-2'>Feed your body what it needs to thrive. </h3>
								<br />
								<p className='small-label-3'>
									Pinpointing the precise number of calories you burn at rest is crucial if you're
									trying to lose weight or build lean mass.
								</p>
							</Col>
							<Col sm='12' md='12'>
								<h3 className='mid-label-2'>Optimize your body, metabolism, and longevity.</h3>
								<br />
								<p className='small-label-3'>
									Understand how your metabolism and resting heart rate influence your body
									composition and longevity.
								</p>
							</Col>
						</Fade>
					</Row>
				</Col>
			</Row>
		);
	}
}
