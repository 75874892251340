import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import IconNav3 from '../IconNav-3';
import '../Book.css';

export default class Book1 extends Component {
	scrollbutton2 = ref => {
		this.props.scrollbutton1(ref);
	};

	render() {
		return (
			<Row>
				<Col className='centering-class' lg={{ size: 5, offset: 1 }}>
					<Fade left>
						<IconNav3 scrollButton={this.scrollbutton2} />
						<div className='location-name'>{this.props.name}</div>
						<div className='New-to-DexaFit'>New to DexaFit?</div>
						<div className='deal-text'>
							Get ONE DexaFit test for only $79 (almost 50% off), Offer includes ONE free 3D scan
							too.
						</div>
						<Form>
							<FormGroup>
								<Input type='name' name='First' id='FirstName' placeholder='First Name' />
								<Input type='name' name='Last' id='LastName' placeholder='Last Name' />
							</FormGroup>
							<FormGroup>
								<Input type='tel' name='Phone' id='PhoneNumber' placeholder='Phone Number' />
							</FormGroup>
							<FormGroup>
								<Input type='email' name='email' id='email' placeholder='Email Address' />
							</FormGroup>
						</Form>
						<Button
							onClick={() => {
								this.scrollbutton2('book4');
							}}
							id='Book-button'>
							Book Now
						</Button>
					</Fade>
				</Col>
				<Col className='padding-zero' lg='6'>
					<Fade right>
						<div id='blue-block-book'></div>
						<img
							src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.props.location[0]},${this.props.location[1]}&zoom=12&size=580x500&markers=color:blue|${this.props.location[0]},${this.props.location[1]}&style=element:geometry|color:0xf5f5f5&style=element:labels.icon|visibility:off&style=element:labels.text.fill|color:0x616161&style=element:labels.text.stroke|color:0xf5f5f5&style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&style=feature:poi|element:geometry|color:0xeeeeee&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0xe5e5e5&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&style=feature:road|element:geometry|color:0xffffff&style=feature:road.arterial|element:labels.text.fill|color:0x757575&style=feature:road.highway|element:geometry|color:0xdadada&style=feature:road.highway|element:labels.text.fill|color:0x616161&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&style=feature:transit.line|element:geometry|color:0xe5e5e5&style=feature:transit.station|element:geometry|color:0xeeeeee&style=feature:water|element:geometry|color:0xc9c9c9&style=feature:water|element:labels.text.fill|color:0x9e9e9e&key=${process.env.REACT_APP_GOOGLE_KEY}`}
							alt='map of currently selected dexafit location'
							id='location-specific-map'
						/>
					</Fade>
				</Col>
			</Row>
		);
	}
}
