import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Wrench from '../../assets/img/container@2x.png';
import Target from '../../assets/img/container@3x.png';
import Internet from '../../assets/img/container.png';
import Phone from '../../assets/img/how-phone-1.png';
import '../Service.css';

export default class Vo22 extends Component {
	render() {
		return (
			<Row>
				<Col
					className='spacer-1'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					lg={{ size: 7, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<Fade left>
								<h2 className='big-label'>Exercise Smarter. Not Harder</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Increase energy levels</h4>
								<p className='grey-p'>
									Optimizing your VO2max improves your body’s power grid. It strengthens your heart,
									improves blood flow and metabolism, and makes your muscles more efficient.{' '}
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='Target icon' />
								</div>
								<h4 className='small-label-2'>Prevent overexertion</h4>
								<p className='grey-p'>
									Overexertion is counterproductive to good health and fitness, and it can also
									cause injury, prolong recovery, and stress your immune system.
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='Internet icon' />
								</div>
								<h4 className='small-label-2'>Optimize longevity</h4>
								<p className='grey-p'>
									VO2max is a more reliable longevity indicator than factors like tobacco use, blood
									pressure, and cholesterol. A lower VO2max means lower energy levels and higher
									disease risks.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col className='d-flex align-items-end' md='6' lg='4'>
					<Fade right>
						<img className='phone' src={Phone} alt='phone app' />
					</Fade>
				</Col>
			</Row>
		);
	}
}
