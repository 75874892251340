import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Medicine1 from './slides/Medicine1';
import Medicine2 from './slides/Medicine2';
import Medicine3 from './slides/Medicine3';
import Medicine4 from './slides/Medicine4';
import Medicine5 from './slides/Medicine5';
import Medicine7 from './slides/Medicine7';
import Footer from '../Footer';

export default class Medicine extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Container fluid>
				<Medicine1
					scrollStyle={this.props.scrollStyle}
					statePass2={this.props.statePass}
					callBack2={this.secondCallBack}
				/>
				<Medicine2 />
				<Medicine3 />
				<Medicine4 />
				<Medicine5 />
				<Medicine7 />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
