import React, { Component } from 'react';
//import { Link } from 'react';
import * as locationData from '../assets/data/locations.json';
import { Container } from 'reactstrap';
import Pricing1 from './slides/Pricing-1';
import Pricing2 from './slides/Pricing-2';
import Pricing3 from './slides/Pricing-3';
import Footer from '../Footer';

export default class Home extends Component {
	constructor(props) {
		super(props);
		this.scrollbutton = this.scrollbutton.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	scrollbutton() {
		this.refs.pricing2.scrollIntoView({ block: 'start', behavior: 'smooth' });
	}

	render() {
		let priceDisplay = <div>404</div>;
		locationData.default.map((location) => {
			if (location.name === this.props.match.params.place) {
				priceDisplay = (
					<div>
						<Pricing1 scrollbutton1={this.scrollbutton} />
						<div ref='pricing2'>
							<Pricing2
								prices={location.prices}
								scroll={this.scrollbutton}
								history={this.props.history}
							/>
						</div>
						<Pricing3 />
					</div>
				);
			}
			return priceDisplay;
		});
		return (
			<Container fluid>
				{priceDisplay}

				<Footer history={this.props.history} />
			</Container>
		);
	}
}
