import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class Vo27 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false,
		item4: false,
	};

	clickToggle = (item) => {
		switch (item) {
			case '1':
				this.setState((prevState) => ({
					item1: !prevState.item1,
				}));
				break;
			case '2':
				this.setState((prevState) => ({
					item2: !prevState.item2,
				}));
				break;
			case '3':
				this.setState((prevState) => ({
					item3: !prevState.item3,
				}));
				break;
			case '4':
				this.setState((prevState) => ({
					item4: !prevState.item4,
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<h2 className='big-label spacer-5'>Learn more about VO2max</h2>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											What do I have to do before my visit?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<br />
												<div>
													The most important thing to note is we’re appointment only, so please make
													sure you schedule online or via our mobile app!
													<br />
													<br />
													On the day of your visit, remember to wear workout clothes and tennis
													shoes. Also make sure you’re fasted for at least four hours (water only).
													Refrain from taking any caffeine or stimulates the day of your test too.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											How long does a VO2max take?
										</div>
										{this.state.item2 ? (
											<Fade top>
												<br />
												<div>
													A typical test only takes about 15 minutes. We will start by warming you
													up with a leisurely walk then slowly ramp up the intensity until you hit
													your peak. Once the test is complete, your results will be available
													immediately.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											What is an optimal VO2max level for me?
										</div>
										{this.state.item3 ? (
											<Fade top>
												<br />
												<div>
													The average sedentary male will achieve a VO2 max of approximately 35 to
													40 mL/kg/min. The average sedentary female will score a VO2 max of between
													27 an 30 mL/kg/min.
													<br />
													<br />
													Tour de France winner Miguel Indurain's VO2 max was reported at 78
													mL/kg/min during the peak of his conditioning, while cross-country skier
													Bjørn Dæhlie reportedly achieved a VO2 max of 96 mL/kg/min.
													<br />
													<br />
													These scores can improve with training. Higher VO2 max scores are
													associated with increased longevity and performance.
												</div>
												<br />
											</Fade>
										) : null}
									</div>
									{/* <div>
										{this.state.item4 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('4')}
											className={`switch ${this.state.item4 ? 'switch-select' : null}`}>
											What can I do to improve my VO2max?
										</div>
										{this.state.item4 ? (
											<Fade top>
												<br />
												<div>
													This plan is suitable for the beginner IT specialist and will allow you to
													familiarize yourself with all the main functions of the service.
												</div>
												<br />
											</Fade>
										) : null}
									</div> */}
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
