import React, { Component } from 'react';
import Energy from '../../assets/img/VO2Max.png';
import '../Service.css';

export default class Vo2Nav extends Component {
	render() {
		return (
			<div className='scroll-icon'>
				<div onClick={this.props.click} className={`circle-border ${this.props.on}`}>
					<img className='small-button-icons' src={Energy} alt='vo2 icon' />
				</div>
				<h6 className='grey-label'>VO2max</h6>
			</div>
		);
	}
}
