import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Athletes1 from './slides/Athletes1';
import Athletes2 from './slides/Athletes2';
import Athletes3 from './slides/Athletes3';
import Athletes4 from './slides/Athletes4';
import Athletes6 from './slides/Athletes6';
import Athletes7 from './slides/Athletes7';
import Footer from '../Footer';

export default class Athletes extends Component {
	secondCallBack = (childData) => {
		this.props.callBack(childData);
	};
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<Container fluid>
				<Athletes1
					scrollStyle={this.props.scrollStyle}
					statePass2={this.props.statePass}
					callBack2={this.secondCallBack}
				/>
				<Athletes2 />
				<Athletes3 />
				<Athletes4 />
				<Athletes6 />
				<Athletes7 history={this.props.history} />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
