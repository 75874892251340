import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store6 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Performance Panel',
			price: 349.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>Performance Panel</div>
							<br />
							<br />
							<div>$349.00</div> <strike>$449.00</strike> <br />
							<br />
							<div>
								An extensive package of biomarkers at a lower cost than specialty clinics. <br />
								<br />
								Critical markers to test if you care about hormone imbalances and nutrient
								deficiencies that affect sleep, energy, longevity, and mood. <br />
								<br />
								*Please note: this test is not yet available if you’re in MD, NJ, NY, RI, ND, SD, or
								HI. We recommend trying one of our at-home testing kits.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>Why Blood Testing?</div>
					<br />
					<br />
					<div>
						Blood testing is the most important step you can take to identify and prevent
						life-threatening diseases before they happen to you. With your blood test results in
						hand, you can catch critical issues in your body before they manifest as heart disease,
						cancer, diabetes, or worse. Knowledge of exactly what is going on inside your body
						empowers you to implement a science-based disease-prevention program that can literally
						add decades of healthy years to your life.
						<br />
						<br />
						And if your goal is to not only stop disease, but to also perform at your peak physical
						and cognitive capacity, blood testing is absolutely crucial for identifying which diet,
						which supplements and which lifestyle steps you should take to optimize your specific
						and unique health parameters.
						<br />
						<br />
						But sadly, most annual medical check-ups that the average physician orders are simply
						routine, old-school blood tests that don’t even test for the most important markers of
						disease risk, and that are simply designed to make sure you’re “not dying”. They aren’t
						designed to optimize longevity or to ensure your body is completely primed to perform at
						peak capacity.
						<br />
						<br />
						What most people don’t realize is that you can bypass your physician and take complete
						ownership in the entire process for getting your blood work done yourself.
						<br />
						<br />
						If you want to test everything that affects your organs, your energy, your hormones,
						your health and your longevity, then this panel is your answer. We partnered with Quest
						labs, America’s top laboratory for blood testing and online access to all your blood
						testing results, and developed the “DexaFit Performance Panel," which is the most
						complete blood testing package money can buy.
						<br />
						<br />
						We designed it for the high performer, biohacker, anti-aging, or general health
						enthusiast who wants access to the same type of executive health panel and screening
						that would cost tens of thousands of dollars at a longevity institute.
					</div>
					<br />
					<br />
					<div className='product-title text-center'>What's Included?</div>
					<br />
					<br />
					<div>
						The panel dives deep into overall metabolic functioning for optimal long-term health and
						longevity, and much, much more, including:
					</div>
					<br />
					<br />
					<ul>
						<li>25-Hyroxy Vitamin D </li>
						<br />
						<li>HS-CRP </li>
						<br />
						<li>CO2</li>
						<br />
						<li>SHBG</li>
						<br />
						<li>E2</li>
						<br />
						<li>IGF-1</li>
						<br />
						<li>Free T3</li>
						<br />
						<li>Free T4</li>
						<br />
						<li>Total Testosterone + Free Testosterone </li>
						<br />
						<li>TSH </li>
						<br />
						<li>Progesterone </li>
						<br />
						<li>DHEA </li>
						<br />
						<li>Cortisol </li>
						<br />
						<li>Glucose </li>
						<br />
						<li>A1C </li>
						<br />
						<li>Thyroid Peroxidase AB </li>
						<br />
						<li>Reverse T3 </li>
						<br />
						<li>Lipid Panel </li>
						<br />
						<li>Estradiol</li>
						<br />
						<li>Bun/Creatine Ratio </li>
						<br />
						<li>Thyroglobulin Antibodies</li>
						<br />
					</ul>
					<br />
					<div>
						We held nothing back and covered all bases with this customized blood panel. You will
						not find a test this comprehensive anywhere else, guaranteed. From identifying disease
						to optimizing longevity and anti-aging to maximizing performance, this test gives you
						absolutely everything you need to thrive.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
