import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Number1 from '../../assets/img/1-number.png';
import Number2 from '../../assets/img/2-number.png';
import Number3 from '../../assets/img/3-number.png';
import '../HowItWorks.css';

export default class Athletes7 extends Component {
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row>
				<Col md='12'>
					<Row id='small-top-row'>
						<Col className='padding-left' md={{ size: 7, offset: 1 }}>
							<Fade left>
								<div className='big-text' id='athlete-page-title-competitive'>
									Get A Competitive Edge
								</div>
								<div>
									With more than a decade of experience helping professional, collegiate and
									recreational athletes optimize their performance, we make sure you achieve more.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md={{ size: 3, offset: 1 }}>
							<Fade right>
								<Button
									onClick={(e) => this.handlePageChange('/Services')}
									className='learn-button'>
									Learn More
								</Button>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col md='12'>
					<Row className='off-color middle-row'>
						<Col className='text-center' md='12'>
							<Fade left>
								<div className='big-text'>Make Smarter Decisions</div>
								<div>
									Use DexaFit to get the same level of insights used by leading sports scientists
									across the country.
								</div>
								<br />
								<br />
							</Fade>
						</Col>
						<Col className='padding-left' md={{ size: 3, offset: 2 }}>
							<Fade left>
								<img
									className='number-container-img'
									src={Number1}
									alt='icon with number 1 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Tell Us Your Goals</div>
								<div className='grey-label-2'>
									We collaborate with your existing talent, including strength coaches, trainers,
									nutrition, and medical specialists.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number2}
									alt='icon with number 2 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Comprehensive Baseline</div>
								<div className='grey-label-2'>
									We collect qualitative and quantitative data with medical-grade diagnostic testing
									proven to correlate with injury and performance.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number3}
									alt='icon with number 3 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Turn Data Into Insights</div>
								<div className='grey-label-2'>
									We translate your performance data into actionable insights for the practice
									field, weight room, or anywhere your sport takes you.
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col md='12'>
					<Row id='bottom-row'>
						<Col md='12'>
							<Fade left>
								<div className='big-text text-center'>
									Play An Entirely Different Game
									<br /> Altogether
								</div>
							</Fade>
						</Col>
						<Col id='padding-seperator-1' className='text-sm-center text-md-right' md='6'>
							<Fade left>
								<div className='bold-text'>Not So Long Ago...</div>
								<div>
									Strength coaches and trainers used one-size-fits-all, "cookie-cutter" programs to
									prepare athletes for competition. Scales and stopwatches were used to measure
									"results."{' '}
								</div>
								<br />
							</Fade>
						</Col>
						<Col id='padding-seperator-2' className='text-sm-center text-md-left' md='6'>
							<Fade right>
								<div className='bold-text'>Happening Now...</div>
								<div>
									Wearables, bloodwork, nutrition tracking, and screening technologies are now being
									used to tailor every aspect of an athlete's physiology and environment.
								</div>
								<br />
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
