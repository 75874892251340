import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import DexafitTable from '../../assets/img/dexa-table-1.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../HowItWorks.css';

export default class Medicine3 extends Component {
	render() {
		return (
			<Row id='row-how-2'>
				<Col lg='7'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic' src={DexafitTable} alt='a girl on the dexa scan table' />
						<img
							id='Phone-pic-2'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col lg={{ size: 4, offset: 1 }}>
					<Fade right>
						<div className='how-text'>
							<h2 className='how-titles'>
								<span className='span-color'>Know</span> their body
							</h2>
							<div className='how-p'>
								With DEXA and 3D Body Scanning <br /> <br />
								<ul>
									<li>
										FDA-approved technology used to quantify lean, fat, and bone mass, including
										visceral fat (a leading predictor of longevity)
									</li>
									<li>
										Reveal imbalances in muscle symmetry and strength, flexibility, and joint
										mechanics
									</li>
								</ul>
							</div>
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
