import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/victory.png';
import Phone from '../../assets/img/how-phone-2.png';
import Home5Slide from './Home-5-slide';
import '../Home.css';

export default class Home5 extends Component {
	render() {
		return (
			<Row className='off-color-2'>
				{/* <Col sm='6' lg='6' md='6' className='card-3 card-4 off-color-3 d-flex'>
					<Fade left>
						<img className='biker-placeholder mx-auto my-auto' src={Biker} alt='biker-2 min' />
					</Fade>
				</Col> */}
				<Col md='12' lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl on the dexa scan table' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col sm='12' md='12' lg='6' className='card-3'>
					<Fade right>
						{/* <div>
							<h2 className='Why-DexaFit'>Why DexaFit</h2>
							<br />
							<p className='The-healthcare-syste'>
								The healthcare system prioritizes treatment over prevention. Chronic disease is on
								the rise. Obesity is an epidemic. DexaFit is a practical solution to these problems.{' '}
								<br />
								<br />
								DexaFit opened seven years ago as a practical solution to this problem. We provide
								real-time data, actionable insights, and resources that empower you to achieve and
								sustain optimal health.
							</p>
						</div> */}
						{/* <div className='Border'>
							<h5 className='Our-Main-Principle'>Our Main Principle</h5>
							<p className='lorem-text'>
								Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut
								laoreet sodales nisi, quis iaculis nulla iaculis vitae. Donec sagittis faucibus
								lacus eget
							</p>
						</div> */}
						<Home5Slide />
					</Fade>
				</Col>
			</Row>
		);
	}
}
