import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Belly from '../../assets/img/BellyFat.png';
import Lean from '../../assets/img/LeanMassPhone.png';
import '../Service.css';

export default class ThreeD3 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder' xs='12' sm='12' md='8' lg='8' xl='6'>
					<Fade left>
						<div id='blue-block'></div>
						<img className='belly-fat-phone' src={Belly} alt='dexafit multi shot of app' />
						<img className='lean-mass-phone' src={Lean} alt='woman with dumbells' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 4, offset: 0 }}
					lg={{ size: 4, offset: 0 }}
					xl={{ size: 3, offset: 2 }}>
					<Row>
						<Col className='spacer-16' sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>Visually see your progress in 3D.</h3>
								<br />
								<p className='small-label-3'>
									The test itself only takes about 40 seconds. Your results are delivered in
									minutes, including a 3D avatar of your body.{' '}
								</p>
							</Fade>
						</Col>
						<Col sm='12' md='12'>
							<Fade right>
								<h3 className='mid-label-2'>Optimize your posture, balance, and mobility.</h3>
								<br />
								<p className='small-label-3'>
									DexaFit 3D also tracks your posture & balance, which is great for aesthetics,
									physical therapy, and injury prevention.
								</p>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
