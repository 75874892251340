import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
// import Wrench from '../../assets/img/container@2x.png';
// import Target from '../../assets/img/container@3x.png';
// import Internet from '../../assets/img/container.png';
// import Phone from '../../assets/img/phone@3x.png';
import AppImg2 from '../../assets/img/App-images-2.png';
import AppImg from '../../assets/img/App-images.png';
import '../Service.css';

export default class Fit2 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder-2' xs='12' sm='12' md='12' lg='5' xl='5'>
					<Fade left>
						<img
							className='belly-fat-phone-2'
							src={AppImg2}
							alt='App with graphs of BPM and other metrics'
						/>
						<img className='lean-mass-phone-2' src={AppImg} alt='explore page on the app' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 5, offset: 2 }}
					xl={{ size: 5, offset: 2 }}>
					<Row>
						<Fade right>
							<Col sm='12' md='12'>
								<h2>
									<strong>Know your body.</strong>
								</h2>
								<div>
									<p>
										Get access to our network of health centers, clinics, and labs providing the
										latest innovations in biometric health and performance testing
									</p>
									<p>
										<strong>DEXA and 3D Body Composition</strong>
									</p>
									<p>
										Measure your lean mass, fat mass, skeletal health, posture, and more with the
										gold standard.
									</p>
									<p>
										<strong>VO2 Max Cardiorespiratory Fitness</strong>
									</p>
									<p>
										Measure your cardiorespiratory fitness to learn your VO2 max, AT, and heart rate
										zones.
									</p>
									<p>
										<strong>RMR Metabolic Health</strong>
									</p>
									<p>
										Pinpoint the precise number of Calories you burn at rest and preferred fuel with
										a RMR test.
									</p>
									<p>
										<strong>Biomarker Health</strong>
									</p>
									<p>
										Reveal key insights into your hormones, microbiome, DNA, micronutrients, and
										more with lab testing.
									</p>
								</div>
								<br />
								<Button
									className='GetStarted-button-3'
									onClick={(e) => this.handlePageChange('/Map')}
									color='primary'>
									Get Started
								</Button>
							</Col>
						</Fade>
					</Row>
				</Col>
				{/* <Col
					className='spacer-1'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					lg={{ size: 7, offset: 1 }}>
					<Row>
						<Col className='spacer-7' md='12'>
							<Fade left>
								<h2 className='big-label'>Meet Fit AI.</h2>
								<h4 className='small-label'>GPS for your health.</h4>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Wrench} alt='wrench icon' />
								</div>
								<h4 className='small-label-2'>Know where you are</h4>
								<p className='grey-p'>
									Fit AI tracks your DexaFit results and combines them with your lifestyle and
									activity data to calculate your overall health and life expectancy
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Target} alt='Target icon' />
								</div>
								<h4 className='small-label-2'>Find out where you should be</h4>
								<p className='grey-p'>
									It uses machine learning and artificial intelligence to show you a personalized
									path of where you should be and how you compare to your peers
								</p>
							</Fade>
						</Col>
						<Col md='12' lg='4'>
							<Fade left>
								<div>
									<img className='small-icon' src={Internet} alt='Internet icon' />
								</div>
								<h4 className='small-label-2'>Discover how to get there</h4>
								<p className='grey-p'>
									The Fit AI marketplace provides a network of professionals, products, and
									resources that can help you achieve optimal health and longevity
								</p>
							</Fade>
						</Col>
					</Row>
				</Col> */}
				{/* <Col className='d-flex align-items-end' md='6' lg='4'>
					<Fade right>
						<img className='phone' src={Phone} alt='phone app' />
					</Fade>
				</Col> */}
			</Row>
		);
	}
}
