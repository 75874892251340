import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Nov82017 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>What is Mitochondrial Fitness?</div>
						<br />
						<div className='blog-date-page'>Nov 8, 2017</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							You've likely heard mitochondria are the power stations of your cells. But what
							exactly does this mean for you?
							<br />
							<br />
							Mitochondria give your body 95 percent of the total energy it needs. They use oxygen
							to burn fat, glucose, and amino acids to create ATP.
							<br />
							<br />
							Your body uses ATP as its primary energy currency to "fund" essential functions. These
							include muscle movement, digestion and enzyme production.
							<br />
							<br />
							But mitochondria do more than creating ATP. They give you the vitality you need to win
							your day, like your own personal cheer squad.
							<br />
							<br />
							And like a squad, your mitochondria work together. They operate as an interconnected
							network to create energy balance and maintain health. This network{' '}
							<a href='https://www.nih.gov/news-events/news-releases/high-resolution-3d-images-reveal-muscle-mitochondrial-power-grid'>
								resembles a wire grid
							</a>{' '}
							like you'd see distributing power through a city.
							<br />
							<br />
							And here's a secret: you can train your mitochondria to work better and proliferate.
							<a href='https://pubmed.ncbi.nlm.nih.gov/18580415/'> The best way</a> to do this is
							through high-intensity, aerobic endurance training.
							<br />
							<br />
							But mitochondrial fitness works like your other training. When you stress a muscle the
							right amount, it grows in size and power. But if you push it too far, you'll cause
							damage that erases your progress.
							<br />
							<br />
							The sweet spot between progress and injury is your ideal training zone. Any stress
							beyond this threshold creates an overabundance of cell-damaging chemicals.
							<br />
							<br />
							So by training within your ideal zone you’ll lessen your risks of discomfort and
							injury. You'll also learn to enjoy exercise more, while maximizing your results.
							<br />
							<br />
							One of the best ways to identify your zone is a <a href='/Service'>VO2 max test</a>.
							This test indicates the amount of oxygen your body can use at your current level of
							fitness. It also indicates the precise level of intensity that will optimize your body
							to burn fat.
							<br />
							<br />
							At your VO2 max you create an oxygen debt that will kill off any malfunctioning
							mitochondria. This also forces the healthy mitochondria to become more efficient at
							creating ATP.
							<br />
							<br />
							<a href='https://pubmed.ncbi.nlm.nih.gov/17414804/'> Consistent training</a> will
							cause your heart to grow stronger. More capillaries will also develop in your muscles
							to help you transfer more oxygen. Together, these improvements will enable you to pump
							blood with more power. Your body will also demand more energy.
							<br />
							<br />
							Like bacteria, mitochondria respond to greater energy demand by reproducing. More
							mitochondria enable better extraction of oxygen from the blood to generate ATP. This
							leads to a <a href='https://pubmed.ncbi.nlm.nih.gov/18580415/'> higher VO2 max</a> and
							higher levels of energy.
							<br />
							<br />
							For this reason, your VO2 max is also one of the best indicators of your mitochondrial
							health. <a href='https://www.pnas.org/content/pnas/86/5/1583.full.pdf'>
								Studies
							</a>{' '}
							have identified a{' '}
							<a href='https://www.mitoaction.org/blog/exercise-nutrition'>correlation</a> between
							VO2 max and{' '}
							<a href='https://books.google.com/books?id=1BG78utt6VUC&pg=PA87&lpg=PA87&dq=vo2+max+mitochondria&source=bl&ots=zTztV7UT_I&sig=psqDkgeRbmxP1JESYKAsNdDg9-8&hl=en&sa=X&ved=0ahUKEwjwlsuI9uXSAhXK4CYKHcbdDs84ChDoAQg8MAU%2523v=onepage&q=vo2%252520max%252520mitochondria&f=false#v=onepage&q=vo2%252520max%252520mitochondria&f=false'>
								whole body mitochondrial content
							</a>{' '}
							.
							<br />
							<br />
							The higher your VO2 max, the denser the mitochondria in your muscles. And the more
							powerful your power grid.
							<br />
							<br />A stronger grid will lead to{' '}
							<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1540458/'>
								increases in both your endurance and fatigue resistance
							</a>{' '}
							. So VO2 max is also great indicator of TOTAL fitness and health.
							<br />
							<br />
							Your ability to improve your VO2 max depends on your current level of training. The
							rate at which you can use oxygen has an upper limit, so improvements are significant
							in the untrained. But these will diminish over time.
							<br />
							<br />
							Your biological upper limit depends on the ability of your muscles to use oxygen.
							Several{' '}
							<a href='http://vo2maxresearch.blogspot.com/2009/02/limiting-factors-for-maximum-oxygen.html'>
								factors
							</a>{' '}
							influence this ability.
							<br />
							<br />
							For example, <a href='https://www.apple.com/'>studies</a> show variations in your
							genes may limit your muscles’ abilities to use oxygen. We call these variations
							genetic polymorphisms, or morphs.
							<br />
							<br />
							Humans all share more than 99.9 percent of the same genes. Morphs represent the 0.01
							percent that are different.{' '}
							<a href='https://www.researchgate.net/publication/14847998_Mitochondrial_DNA_sequence_polymorphism_VO2max_and_response_to_endurance_training'>
								Evidence
							</a>{' '}
							shows morphs may influence the trainability of your VO2 max.
							<br />
							<br />
							So it may be useful to test for certain genes. These include:
							<br />
							<br />
							<ol>
								<li>ACE (athletic performance and endurance)</li>
								<br />
								<li>ADRB2 (cardiovascular function)</li>
								<br />
								<li>AKT1 (muscle-building and metabolism)</li>
								<br />
								<li>AMPD1 (energy production)</li>
								<br />
								<li>CKM (muscle energy)</li>
								<br />
								<li>HIF1A (response to low oxygen)</li>
								<br />
								<li>PGC1A (aerobic capacity)</li>
								<br />
								<li>VEGFA (forming new blood vessels)</li>
							</ol>
							<br />
							Yet{' '}
							<a href='https://www.sciencedirect.com/science/article/abs/pii/S1567724909000853'>
								some research suggests
							</a>{' '}
							you may be able to reduce the influence of your genes on your VO2 max.
							<br />
							<br />
							Consistent, interval aerobic training may force your body to adapt to use more oxygen.
							This will enhance your{' '}
							<a href='http://gih.diva-portal.org/smash/get/diva2:766681/FULLTEXT01.pdf'>
								capacity for energy production
							</a>{' '}
							. More oxygen means more fuel for mitochondria to create ATP.
							<br />
							<br />
							Regardless, the best way to measure your mitochondrial fitness is to use data. At
							DexaFit, we
							<br />
							<br />
							give you the essential tools as well as the support you need to maximize your
							performance.
							<br />
							<br />
							And we'll be there for you every step of the way to provide guidance and help you
							grow.
							<br />
							<br />
							We'll design your package to accommodate your individual needs and goals. We want to
							help you learn more about your body to ensure the effectiveness of your exercise
							habits.
							<br />
							<br />
							Are you ready to reach a higher level of fitness and health?
							<br />
							<br />
							<a href='/Map'>Get started</a> today!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
