import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

function JobApplications() {
	const [modal, setModal] = useState(false);
	const [modal2, setModal2] = useState(false);
	//const [modal3, setModal3] = useState(false);
	//const [modal4, setModal4] = useState(false);

	const toggle = () => setModal(!modal);
	const toggle2 = () => setModal2(!modal2);
	//const toggle3 = () => setModal3(!modal3);
	//const toggle4 = () => setModal4(!modal4);

	return (
		<div>
			<Button className='Job-name' onClick={toggle}>
				Brand and Marketing Guru
			</Button>
			<br />
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Brand and Marketing Guru</ModalHeader>
				<ModalBody>
					<div>
						<strong>About</strong> You
					</div>
					<br />
					<ul>
						<li>
							Creative and able to generate fun and engaging content for distribution across
							multiple digital channels
						</li>
						<li>Basic graphic design skills</li>
						<li>Analyze performance of brand management strategies</li>
					</ul>
					<div>
						<strong>About</strong> this Position
					</div>
					<br />
					<div>
						You’ll work closely with a small team to manage: Sales, Brand Management, Digital
						Management, and Creative Execution. <br />
						<br />
						You will serve as both author and editor, creating content and guiding the brand
						activities of Graphic Design, Social Media Management, E-Commerce Strategies and
						Marketing Coordinator.
						<br />
						<br />
						You will be in charge of articulating the "what" and "why" of our product offerings.
						<br />
						<br />
						You will use every tool at your disposal to communicate what Seth Godin described as,
						"the set of expectations, memories, stories and relationships that, taken together,
						account for a consumer's decision to choose one product or service over another"
						<br />
						<br />
						You will inspire feelings, reactions, understanding and loyalty to the DexaFit brand
						through social media and customer outreach.
						<br />
						<br />
						If you think you’re the right person for the job, apply on this form!
					</div>
					<br />
					<div>
						<strong>Primary</strong> responsibilities
					</div>
					<br />
					<ul>
						<li>
							Help develop and drive the go-to-market strategy for each DexaFit offering and manage
							all social platforms.
						</li>
						<li>
							Generate and curate content of all forms to communicate the product and brand story –
							product pages, blog posts, articles, ads, press releases, social media posts, video
							content, product packaging, displays and more.
						</li>
						<li>
							Develop and manage the brand voice, message, look, feel and personality across every
							available communication platform, ensuring consistency across all consumer touch
							points.
						</li>
						<li>
							Collaborate with the Product Management and Creative teams to execute the brand
							strategy.
						</li>
						<li>
							Evaluate and report the impact and effectiveness of brand strategies and adjust and
							re-calibrate activities.
						</li>
						<li>Travel to local events in support of the brand</li>
					</ul>
					<br />
					<div>
						<strong>Required</strong> Qualifications: Entry Level
					</div>
					<br />
					<div>
						Experience in writing engaging, convincing, accurate, well-researched and grammatically
						correct copy and video scripts. Seriously, don’t apply unless you genuinely love writing
						and communicating and are able to do it well. <br />
						<br />
						At a minimum, you have personal or work experience in digital marketing, social media
						and PR.
						<br />
						<br />
						Strong creativity and ability to generate fun, humorous, and engaging content.
						<br />
						<br />
						An understanding of how to promote a brand with a technical component. Or at least an
						educational or personal background that demonstrates an ability to master how pretty
						quickly.
					</div>
					<br />
					<div>
						<strong>Personal </strong> Qualities
					</div>
					<br />
					<ul>
						<li>
							The ability to be persuasive, form relationships, tailor your message and deliver it
							to a variety of audiences.
						</li>
						<li>
							The capability to give honest and reasonable feedback as well as receive constructive
							critical input in a non-emotional manner.
						</li>
						<li>
							Genuine creativity, with an ability to see opportunities that may not be immediately
							evident.
						</li>
						<li>
							The capacity to work in a dynamic, casual atmosphere where you will need to both work
							independently and seek out input from busy collaborators.
						</li>
						<li>
							Desire to create order and clarity out of chaos through the development of standard
							processes, workflows and systems.
						</li>
					</ul>
					<div>
						DexaFit's work environment is informal and high-energy, and our employees' quality of
						life is important to us.
						<br />
						<br />
						This position is open now and will be filled as soon as a suitable candidate is
						identified. Please submit the form linked below along with the samples required upon
						completion
						<br />
						<br />
						(Instructions will be sent in the follow up email after you submit your form)
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' href='https://dexafit1.typeform.com/to/yvE2He'>
						Apply
					</Button>
					<Button color='secondary' onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Button className='Job-name' onClick={toggle2}>
				Business Development
			</Button>
			<br />
			<Modal isOpen={modal2} toggle={toggle2}>
				<ModalHeader toggle={toggle2}>Business Development</ModalHeader>
				<ModalBody>
					<div>
						<strong>About</strong> You
					</div>
					<br />
					<ul>
						<li>
							Identify, negotiate, and oversee partnerships that increase the awareness of DexaFit,
							generate revenue, as well as strengthen our product offering through integrations
						</li>
						<li>
							Establish the vision and goals for partnerships at DexaFit, and execute on the plan
							for growth
						</li>
						<li>Scale the partnerships function at DexaFit</li>
					</ul>
					<br />
					<div>
						<strong>About</strong> this position
					</div>
					<br />
					<div>
						You’ll work closely with DexaFit's executives and Operations Manager. <br />
						<br />
						As a Biz Dev Guru, you will identify, assess, and prioritize new business opportunities;
						evaluate and recommend partnerships; work with us to develop and manage integrations;
						and oversee the long­-term success of partnerships at DexaFit.
						<br />
						<br />
						Partnerships may include anything and everything between gym partners, physician groups,
						sports teams, celebrity personalities, social media influences, technology companies,
						and corporate wellness. <br />
						<br />
						You will be in charge of articulating the "what" and "why" of our product offerings to
						potential partners and customers.
						<br />
						<br />
						You are highly self-­motivated and able to work with significant autonomy and build a
						team that can scale.
						<br />
						<br />
						If you think you’re the right person for the job, apply on this form.
					</div>
					<br />
					<div>
						<strong>Personal</strong> Qualities
					</div>
					<br />
					<ul>
						<li>
							The ability to be persuasive, form relationships, tailor your message and deliver it
							to a variety of audiences.
						</li>
						<li>
							The capability to give honest and reasonable feedback as well as receive constructive
							critical input in a non-emotional manner.
						</li>
						<li>
							The capacity to work in a dynamic, casual atmosphere where you will need to both work
							independently and seek out input from busy collaborators.
						</li>
						<li>
							Desire to create order and clarity out of chaos through the development of standard
							processes, workflows and systems.
						</li>
					</ul>
					<br />
					<div>
						This position is open now and will be filled as soon as a suitable candidate is
						identified. <br />
						<br />
						If you want to improve your chances, please include answers to these questions when you
						submit the form link below:
					</div>
					<br />
					<ul>
						<li>
							Tell us about a time you made a decision regarding whether or not to pursue a business
							opportunity, such as an acquisition, partnership, expansion into a new market. What
							decision did you make and how did you make it?
						</li>
						<li>
							If you were hired for this role, which partnership would you pursue first and why?
						</li>
						<li>
							Tell us about an accomplishment in your life, personal or professional, of which you
							are particularly proud.
						</li>
					</ul>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' href='https://dexafit1.typeform.com/to/s0t9dn'>
						Apply
					</Button>
					<Button color='secondary' onClick={toggle2}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Button className='Job-name' href='https://dexafit1.typeform.com/to/s0t9dn'>
				Radiologic Tech
			</Button>
			<br />
			<Button className='Job-name' href='https://dexafit1.typeform.com/to/s0t9dn'>
				Assistant Manager (Atlanta)
			</Button>
		</div>
	);
}

export default JobApplications;
