import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Tablet from '../../assets/img/tablet.png';
import Phone from '../../assets/img/leaderboard-public.png';
import '../HowItWorks.css';

export default class HowItWorks6 extends Component {
	render() {
		return (
			<Row className='off-color-2'>
				<Col md='12'>
					<h2 id='Your-business' className='text-center'>
						Your Business. Our Support.
					</h2>
				</Col>
				<Col lg={{ size: 5, offset: 1 }}>
					<Fade left>
						<img id='tablet-pic' src={Tablet} alt='the app shown on a tablet' />
						<img
							id='tablet-phone'
							src={Phone}
							alt='the app shown on a phone with the RMR page up'
						/>
					</Fade>
				</Col>
				<Col className='padding-left' lg={{ size: 3, offset: 2 }} xl={{ size: 3, offset: 1 }}>
					<Fade right>
						<div className='bold-text'>We Do the Heavy Lifting</div>
						<div>You focus on your business. We take care of the testing.</div>
						<br />
						<div className='bold-text'>Full-time Cheerleaders</div>
						<div>Our team of experts are dedicated to your success. 24/7.</div>
						<br />
						<div className='bold-text'>Rally and Inspire</div>
						<div>Energize your members and clients with unique insights into their health.</div>
						<br />
						<div className='bold-text'>Boost Revenue</div>
						<div>Bundle our services into yours and host competitions that increase retention.</div>
						<br />
					</Fade>
				</Col>
				<div id='blue-bottom'></div>
			</Row>
		);
	}
}
