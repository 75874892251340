import React, { Component } from 'react';
//import { Link } from 'react';
import { Container } from 'reactstrap';
import Home1 from './slides/Home-1';
//import Home2 from './slides/Home-2';
import Home3 from './slides/Home-3';
import Home4 from './slides/Home-4';
import Home5 from './slides/Home-5';
import Home6 from './slides/Home-6';
import Footer from '../Footer';

export default class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Container fluid>
				<Home1 history={this.props.history} />
				<Home3 history={this.props.history} />
				<Home4 history={this.props.history} />
				<Home5 />
				<Home6 history={this.props.history} />
				<Footer history={this.props.history} />
			</Container>
		);
	}
}
