import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
//import Fade from 'react-reveal/Fade';
import '../Book.css';

export default class Book4 extends Component {
	render() {
		return (
			<Row>
				<Col className='text-center' lg={{ size: 10, offset: 1 }}>
					<div className='title-calender'>Ready To Get Started</div>
					<div className={`title-text-calender${this.props.widget ? '' : '-2'}`}>
						{this.props.widget ? (
							<span>Schedule below or give us a call at {this.props.phone}</span>
						) : (
							<span>Give us a call at {this.props.phone}</span>
						)}
						.{' '}
						<span
							className='blue-text'
							onClick={(e) => {
								this.props.scroll('price2');
							}}>
							Click here
						</span>{' '}
						for plans and pricing
					</div>
					{this.props.altLink ? (
						<Button
							href={this.props.altLink}
							target='_blank'
							rel='noopener noreferrer'
							className='alt-link-button'>
							Make An Appointment
						</Button>
					) : null}
					{!this.props.widget && !this.props.altLink ? (
						<div>
							The booking widget is not set up for this location, Call to place an appointment!
						</div>
					) : null}
					{/* <div id='appointment-input'>appointment type</div>
					<div id='instructor-input'>instructors</div>
					<div id='my-account-button'>My account</div> */}
				</Col>
				{this.props.widget ? (
					<Col>
						<Row>
							<Col lg={{ size: 5, offset: 1 }}>
								<div className='Book-now'>Book Now</div>
								<div className='numbered-list'>1.Select Your appointment type</div>
								<div className='numbered-list'>2.Choose the day you would like to come in</div>
								<div className='numbered-list'>
									3.Hit search and select a time that works best for you
								</div>
								<div className='warning-text'>
									*Please note that the VO2max and RMR tests require a fast. See additional testing
									requirements and commonly asked questions here.
								</div>
								<div>
									Are you a business, gym, team, doctor, or wellness pro?{' '}
									<span
										onClick={(e) => {
											this.props.scroll('book1');
										}}
										className='blue-text'>
										Find out
									</span>{' '}
									about our group rates and services. New to DexaFit?{' '}
									<span
										onClick={(e) => {
											this.props.scroll('book1');
										}}
										className='blue-text'>
										Ask us
									</span>{' '}
									about our new client specials.
								</div>
							</Col>
							<Col lg='6'>
								<healcode-widget
									data-type='appointments'
									data-widget-partner='object'
									data-widget-id={this.props.widget}
									data-widget-version='0'></healcode-widget>
							</Col>
						</Row>
					</Col>
				) : null}
			</Row>
		);
	}
}
