import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Belly from '../../assets/img/BellyFat.png';
import Lean from '../../assets/img/LeanMassPhone.png';
import '../Service.css';

export default class Lab3 extends Component {
	render() {
		return (
			<Row>
				<Col className='Multi-phone-holder' xs='12' sm='12' md='8' lg='8' xl='6'>
					<Fade left>
						<div id='blue-block'></div>
						<img className='belly-fat-phone' src={Belly} alt='dexafit multi shot of app' />
						<img className='lean-mass-phone' src={Lean} alt='woman with dumbells' />
					</Fade>
				</Col>
				<Col
					className='spacer-8'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 4, offset: 0 }}
					lg={{ size: 4, offset: 0 }}
					xl={{ size: 3, offset: 2 }}>
					<Row>
						<Fade right>
							<Col className='spacer-16' sm='12' md='12'>
								<h3 className='mid-label-2'>Old Way</h3>
								<br />
								<div className='small-label-3'>
									<ol>
										<li>Visit Doctor for lab order</li>
										<li>Visit lab</li>
										<li>Wait for results</li>
										<li>Visit Doctor to review</li>
									</ol>
								</div>
							</Col>
							<Col sm='12' md='12'>
								<h3 className='mid-label-2'>New Way</h3>
								<br />
								<div className='small-label-3'>
									<ol>
										<li>Order test</li>
										<li>Own your health from home</li>
										<li>Easy-to-understand results available online</li>
									</ol>
								</div>
							</Col>
						</Fade>
					</Row>
				</Col>
			</Row>
		);
	}
}
