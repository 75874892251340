import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import '../BusinessHome.css';
import Sajad from '../../assets/img/Sajad.jpg';
import Ben from '../../assets/img/image-asset.jpeg';

const Business5 = (props) => {
	// const handlePageChange = (page) => {
	// 	this.props.history.push(page);
	// };
	const [FirstName, setFirstName] = useState('');
	const [LastName, setLastName] = useState('');
	const [Email, setEmail] = useState('');
	const [Phone, setPhone] = useState('');
	const [City, setCity] = useState('');
	const [StateOrCountry, setStateOrCountry] = useState('');
	const [Industry, setIndustry] = useState('');
	const [About, setAbout] = useState('');

	const handleSubmit = () => {
		console.log(FirstName, LastName, Email, Phone, City, StateOrCountry, Industry, About);
	};

	return (
		<Row className='off-color-2'>
			<Col>
				<Row className='Business-slide-5-background'>
					<Col lg='6'>
						<Row>
							<Col>
								<div className='Business-slide-5-left-quote-text'>
									"We saw patient retention and compliance more than triple after adopting DexaFit."
								</div>
								<div>
									<img
										className='Business-slide-5-left-headshot'
										src={Sajad}
										alt='Headshot of Dr.Sajad Zalzala'
									/>
									<div className='Business-slide-5-left-info'>
										<div>Dr.Sajad Zalzala</div>
										<div>Root Cause Clinic & Ageless Rx</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className='Business-slide-5-left-quote-text'>
									"No better way to get science-based insights to optimize performance"
								</div>
								<div>
									<img
										className='Business-slide-5-left-headshot'
										src={Ben}
										alt='Headshot of Dr.Ben Peterson'
									/>
									<div className='Business-slide-5-left-info'>
										<div>Dr.Ben Peterson</div>
										<div>Director of Athlete Health & Performance, San Francisco 49ers</div>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
					<Col lg='5'>
						<div className='Business-slide-5-right-card'>
							<div className='Business-slide-5-right-title'>See for yourself how DexaFit works</div>
							<div className='Business-slide-5-right-text'>
								Join thousands of others using DexaFit to Change the way we take care of ourselves
							</div>
							<Row>
								<Col md='6'>
									<div className='Business-slide-5-right-input-text'>First name</div>
									<input
										onChange={(e) => setFirstName(e.target.value)}
										className='Business-slide-5-right-input'
										name='FirstName'
										type='text'
									/>
								</Col>
								<Col md='6'>
									<div className='Business-slide-5-right-input-text'>Last name</div>
									<input
										onChange={(e) => setLastName(e.target.value)}
										className='Business-slide-5-right-input'
										name='LastName'
										type='text'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='Business-slide-5-right-input-text'>Email</div>
									<input
										onChange={(e) => setEmail(e.target.value)}
										className='Business-slide-5-right-input'
										type='email'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='Business-slide-5-right-input-text'>Phone</div>
									<input
										onChange={(e) => setPhone(e.target.value)}
										className='Business-slide-5-right-input'
										type='text'
									/>
								</Col>
							</Row>
							<Row>
								<Col md='6'>
									<div className='Business-slide-5-right-input-text'>City</div>
									<input
										onChange={(e) => setCity(e.target.value)}
										className='Business-slide-5-right-input'
										type='text'
									/>
								</Col>
								<Col md='6'>
									<div className='Business-slide-5-right-input-text'>State or Country</div>
									<input
										onChange={(e) => setStateOrCountry(e.target.value)}
										className='Business-slide-5-right-input'
										type='text'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='Business-slide-5-right-input-text'>Industry</div>
									<input
										onChange={(e) => setIndustry(e.target.value)}
										className='Business-slide-5-right-input'
										type='text'
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='Business-slide-5-right-input-text'>
										Please tell us about yourself and interest in DexaFit
									</div>
									<textarea
										onChange={(e) => setAbout(e.target.value)}
										className='Business-slide-5-right-textarea'
										type='text'
									/>
								</Col>
							</Row>
							<Row>
								<Button onClick={handleSubmit} className='Business-slide-5-right-button'>
									Let's Talk
								</Button>
							</Row>
						</div>
					</Col>
					<Col lg='1'></Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Business5;
