import React, { Component } from 'react';
import { Button } from 'reactstrap';
import '../../Pricing.css';

export default class CardSix extends Component {
	renderComponent() {
		switch (this.props.page) {
			case 'DXA':
				return (
					<div>
						<h2 className='small-card-title'>Six Pack</h2>
						<h3 className='flavor-text'>SIX DEXA Scans</h3>
						<h4 className='prices'>{this.props.prices.sixpack}</h4>
					</div>
				);
			case 'Vo2max':
				return (
					<div>
						<h2 className='small-card-title'>Six Pack</h2>
						<h3 className='flavor-text'>SIX VO2 max Tests</h3>
						<h4 className='prices'>{this.props.prices.sixpack}</h4>
					</div>
				);
			case 'RMR':
				return (
					<div>
						<h2 className='small-card-title'>Six Pack</h2>
						<h3 className='flavor-text'>SIX RMR Tests</h3>
						<h4 className='prices'>{this.props.prices.sixpack}</h4>
					</div>
				);
			case 'Bone':
				return (
					<div>
						<h2 className='small-card-title'>Six Pack</h2>
						<h3 className='flavor-text'>SIX Bone Density Scans</h3>
						<h4 className='prices'>{this.props.prices.sixpack}</h4>
					</div>
				);
			default:
				return;
		}
	}
	renderComponent2() {
		switch (this.props.page) {
			case 'DXA':
				return (
					<div>
						<h5 className='included-title'>DEXA Body Comp Scan</h5>
						<h6 className='included-items'>
							SIX DEXA Scans (${Math.round((this.props.prices.sixpack / 6) * 100) / 100} per test)
						</h6>
						<h6 className='included-items'>
							Map and pinpoint your muscle gains and fat loss in specific areas of the body
						</h6>
						<h6 className='included-items'>
							Track your body's specific physiological response to your diet or fitness plan
						</h6>
						<h6 className='included-items'>
							Discover your skeletal health and risk for osteoporosis
						</h6>
						<h6 className='included-items'>
							Identify personal long-term health risks associated with visceral fat around your
							organs
						</h6>
					</div>
				);
			case 'Vo2max':
				return (
					<div>
						<h5 className='included-title'>VO2 Max Fitness Test</h5>
						<h6 className='included-items'>
							SIX VO2 max Tests (${Math.round((this.props.prices.sixpack / 6) * 100) / 100} per
							test)
						</h6>
						<h6 className='included-items'>
							Pinpoint your precise fitness level, endurance potential, and cardiorespiratory
							degeneration.
						</h6>
						<h6 className='included-items'>
							Discover your VO2 max, Aerobic Base, Anaerobic Threshold, and more
						</h6>
						<h6 className='included-items'>
							Considered the top predicator for ALL-CAUSE MORTALITY risk
						</h6>
					</div>
				);
			case 'RMR':
				return (
					<div>
						<h5 className='included-title'>Metabolic Health Analysis (RMR)</h5>
						<h6 className='included-items'>
							SIX RMR Tests ({Math.round((this.props.prices.sixpack / 6) * 100) / 100} per test)
						</h6>
						<h6 className='included-items'>Pinpoint the number of Calories you burn at rest</h6>
						<h6 className='included-items'>
							See how efficient your body is at converting food to energy and using fat or sugar as
							fuel
						</h6>
						<h6 className='included-items'>
							Learn if you have a slowed metabolism to breakthrough frustrating plateaus
						</h6>
					</div>
				);
			case 'Bone':
				return (
					<div>
						<h5 className='included-title'>Bone Density Testing</h5>
						<h6 className='included-items'>
							SIX Bone Density Scans ({Math.round((this.props.prices.sixpack / 6) * 100) / 100} per
							test)
						</h6>
					</div>
				);
			default:
				return;
		}
	}
	render() {
		return (
			<div>
				{this.renderComponent()}
				<div className='small-card-wraper'>
					{this.renderComponent2()}
					<h5 className='included-title'>DexaFit ai</h5>
					<h6 className='included-items'>
						All testing results and health data on your iOS or Android phone
					</h6>
					<h6 className='included-items'>Apple Health and Google Fit integration</h6>
					<h6 className='included-items'>AI-powered personal health and fitness insights</h6>
					<h6 className='included-items'>Longevity Score & Body Score</h6>
					<h6 className='included-items'>Health & Wellness Pro marketplace</h6>
					<h6 className='included-items'>Leaderboards and more!</h6>
				</div>

				<Button
					id='selected-button'
					className='get-started-button-white align-self-end'
					color='primary'>
					Get Started
				</Button>
			</div>
		);
	}
}
