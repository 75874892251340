import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { motion } from 'framer-motion';
import '../BusinessHome.css';
import './CardRotation.css';

const CardRotation = (props) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth > 991 ? 993 : 991);

	useEffect(() => {
		function handleResize() {
			// Set window width/height to state
			if (window.innerWidth > 991) {
				if (windowSize !== 993) {
					setWindowSize(993);
					setIsActive1({
						x: '10%',
						y: '95%',
						scale: 1,
					});
					setIsActive2({
						x: '40%',
						y: '-5%',
						scale: 0.9,
					});
					setIsActive3({
						x: '106%',
						y: '46%',
						scale: 0.83,
					});
					setIsActive4({
						x: '75%',
						y: '125%',
						scale: 0.6,
					});
				}
			} else {
				if (windowSize !== 991) {
					setWindowSize(991);
					setIsActive1({
						x: '30%',
						y: '25%',
						scale: 1,
					});
					setIsActive2({
						x: '55%',
						y: '25%',
						scale: 0.9,
					});
					setIsActive3({
						x: '30%',
						y: '25%',
						scale: 0.8,
					});
					setIsActive4({
						x: '10%',
						y: '25%',
						scale: 0.9,
					});
				}
			}
		}

		window.addEventListener('resize', handleResize);

		return (_) => {
			window.removeEventListener('resize', handleResize);
		};
	});

	useEffect(() => {
		const interval = setInterval(() => {
			handleClick();
		}, 5000);
		return () => clearInterval(interval);
	});

	// Add event listener

	let size = windowSize;
	const [isActive1, setIsActive1] = useState(
		size <= 991
			? {
					x: '30%',
					y: '25%',
					scale: 1,
			  }
			: {
					x: '10%',
					y: '95%',
					scale: 1,
			  }
	);

	const [isActive2, setIsActive2] = useState(
		size <= 991
			? {
					x: '55%',
					y: '25%',
					scale: 0.9,
			  }
			: {
					x: '40%',
					y: '-5%',
					scale: 0.9,
			  }
	);
	const [isActive3, setIsActive3] = useState(
		size <= 991
			? {
					x: '30%',
					y: '25%',
					scale: 0.8,
			  }
			: {
					x: '106%',
					y: '46%',
					scale: 0.83,
			  }
	);
	const [isActive4, setIsActive4] = useState(
		size <= 991
			? {
					x: '10%',
					y: '25%',
					scale: 0.9,
			  }
			: {
					x: '75%',
					y: '125%',
					scale: 0.6,
			  }
	);
	const [zIndex1, setzIndex1] = useState(5);
	const [zIndex2, setzIndex2] = useState(3);
	const [zIndex3, setzIndex3] = useState(4);
	const [zIndex4, setzIndex4] = useState(2);

	const handleClick = () => {
		const zIndexHolder1 = zIndex1;
		const zIndexHolder2 = zIndex2;
		const zIndexHolder3 = zIndex3;
		const zIndexHolder4 = zIndex4;
		setzIndex1(zIndexHolder4);
		setzIndex2(zIndexHolder1);
		setzIndex3(zIndexHolder2);
		setzIndex4(zIndexHolder3);

		const ActiveHolder1 = isActive1;
		const ActiveHolder2 = isActive2;
		const ActiveHolder3 = isActive3;
		const ActiveHolder4 = isActive4;
		setIsActive1(ActiveHolder4);
		setIsActive2(ActiveHolder1);
		setIsActive3(ActiveHolder2);
		setIsActive4(ActiveHolder3);
	};

	return (
		<Col className='Business-slide-1-multi-holder' lg={{ size: props.Size }}>
			{props.BackgroundLayer ? <div id='layer-1'></div> : null}
			{props.BackgroundLayer ? <div id='layer-2'></div> : null}
			{props.BackgroundLayer ? <div id='layer-3'></div> : null}
			{windowSize > 992 ? (
				<div className={props.BackgroundLayer ? 'Rotating-holder-space' : ''}>
					<motion.img
						className='Rotating-img-card'
						src={props.Img1}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex1 }}
						animate={isActive1}
						transition={{ duration: 0.1 }}
						alt={props.Alt1}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img2}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex2 }}
						animate={isActive2}
						transition={{ duration: 0.1 }}
						alt={props.Alt2}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img3}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex3 }}
						animate={isActive3}
						transition={{ duration: 0.1 }}
						alt={props.Alt3}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img4}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex4 }}
						animate={isActive4}
						transition={{ duration: 0.1 }}
						alt={props.Alt4}
					/>
				</div>
			) : (
				<div className={props.BackgroundLayer ? 'Rotating-holder-space' : ''}>
					<motion.img
						className='Rotating-img-card'
						src={props.Img1}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex1 }}
						animate={isActive1}
						transition={{ duration: 0.1 }}
						alt={props.Alt1}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img2}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex2 }}
						animate={isActive2}
						transition={{ duration: 0.1 }}
						alt={props.Alt2}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img3}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex3 }}
						animate={isActive3}
						transition={{ duration: 0.1 }}
						alt={props.Alt3}
					/>
					<motion.img
						className='Rotating-img-card'
						src={props.Img4}
						onClick={(e) => {
							handleClick();
						}}
						style={{ zIndex: zIndex4 }}
						animate={isActive4}
						transition={{ duration: 0.1 }}
						alt={props.Alt4}
					/>
				</div>
			)}
		</Col>
	);
};
export default CardRotation;
