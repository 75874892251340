import React from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Img1 from '../../assets/img/animation-graphics/1.png';
import Img2 from '../../assets/img/animation-graphics/7.png';
import Img3 from '../../assets/img/animation-graphics/8.png';
import Img4 from '../../assets/img/animation-graphics/9.png';

import CardRotation from '../CardRotation/CardRotation.jsx';

import '../BusinessHome.css';

const Business1 = (props) => {
	return (
		<Row>
			<Col className='Background-holder'>
				<Row className='Business-slide-1'>
					<Col lg={{ size: 4, offset: 1 }}>
						<Fade left>
							<h2 className='Business-slide-1-top-text'>Your life's passion, powered by ours.</h2>
							<p className='Business-slide-1-bottom-text'>
								A powerful suite of technology & software solutions that help people stay motivated
								and erase any doubt of your value to them.
							</p>
						</Fade>
					</Col>
					<CardRotation
						Size={7}
						BackgroundLayer={false}
						Img1={Img1}
						Img2={Img2}
						Img3={Img3}
						Img4={Img4}
						Alt1='cartoon of a dexascan'
						Alt2='screenshot of a body scan from the dexafit app'
						Alt3='screenshot of two arms flexing in the dexafit app'
						Alt4='screenshot of optimized page of dexafit app'
					/>
				</Row>
			</Col>
		</Row>
	);
};
export default Business1;
