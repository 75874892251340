import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../Pricing.css';

export default class RmR7 extends Component {
	state = {
		item1: false,
		item2: false,
		item3: false,
		item4: false,
	};

	clickToggle = (item) => {
		switch (item) {
			case '1':
				this.setState((prevState) => ({
					item1: !prevState.item1,
				}));
				break;
			case '2':
				this.setState((prevState) => ({
					item2: !prevState.item2,
				}));
				break;
			case '3':
				this.setState((prevState) => ({
					item3: !prevState.item3,
				}));
				break;
			case '4':
				this.setState((prevState) => ({
					item4: !prevState.item4,
				}));
				break;
			default:
		}
	};

	render() {
		return (
			<Row>
				<Col
					className='spacer-18 text-center'
					xs={{ size: 11, offset: 0 }}
					sm={{ size: 10, offset: 1 }}
					md={{ size: 10, offset: 1 }}
					lg={{ size: 10, offset: 1 }}
					xl={{ size: 10, offset: 1 }}>
					<Row>
						<Col className='text-center'>
							<Fade left>
								<h2 className='big-label spacer-5'>Learn more about RMR</h2>
							</Fade>
						</Col>
					</Row>
					<Row>
						<Col className='spacer-10 text-center'>
							<Fade left cascade>
								<div className='text-left question-list'>
									<div>
										{this.state.item1 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('1')}
											className={`switch ${this.state.item1 ? 'switch-select' : null}`}>
											How long does RMR take?
										</div>
										{this.state.item1 ? (
											<Fade top>
												<br />
												<div>The test itself only takes about 15 minutes.</div> <br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item2 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('2')}
											className={`switch ${this.state.item2 ? 'switch-select' : null}`}>
											What do I have to do before my visit?
										</div>
										{this.state.item2 ? (
											<Fade top>
												{' '}
												<br />
												<div>
													Avoid food, tobacco, alcohol and caffeine at least 6 hours prior to your
													test. Do not exercise for at least 12 hours prior to testing. Please wear
													comfortable clothing to your test.
												</div>{' '}
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item3 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('3')}
											className={`switch ${this.state.item3 ? 'switch-select' : null}`}>
											What information does RMR me tell?
										</div>
										{this.state.item3 ? (
											<Fade top>
												{' '}
												<br />
												<div>
													An RMR test tells you how many calories you burn at rest, if your
													metabolism is slowed, normal, or fast, and if you utilize sugar or fat as
													a primary source of fuel.
												</div>{' '}
												<br />
											</Fade>
										) : null}
									</div>
									<div>
										{this.state.item4 ? <div className='dot'>&#9679;</div> : null}
										<div
											onClick={() => this.clickToggle('4')}
											className={`switch ${this.state.item4 ? 'switch-select' : null}`}>
											What impacts my RMR?
										</div>
										{this.state.item4 ? (
											<Fade top>
												{' '}
												<br />
												<div>
													RMR is very sensitive, and can be affected by several factors including
													genetics, environment, hormones, efficiency of digestion, quantity of
													intake, stress and exercise.
												</div>{' '}
												<br />
											</Fade>
										) : null}
									</div>
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
