import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/rmr.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class RmR6 extends Component {
	render() {
		return (
			<Row className='off-color2'>
				<Col md='12' lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl set up in the rmr machine' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col
					className='spacer-17'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					xl={{ size: 4, offset: 1 }}>
					<Fade right>
						<h2 className='big-label'>Why RMR Testing?</h2>
						<br />
						<p className='small-label-3'>
							Your metabolism refers to all the chemical processes that your body undergoes every
							day in order to keep you alive. <br />
							<br />
							Many factors affect your metabolic rate, including weight, age, body composition,
							environment, hormone levels, and gender. <br />
							<br />
							This makes it a highly unique and important number to know, since... <br />
							<br />
						</p>
						<ul className='small-label-3'>
							<li>Your RMR accounts for about 70 percent of all energy you expend </li>
							<li>
								It represents the amount of Calories you need to maintain essential functions at
								rest (things like heartbeat and breathing!)
							</li>
							<li>
								Pinpointing the precise number of calories you burn at rest is crucial if you're
								trying to lose weight or build lean mass
							</li>
						</ul>
						<p className='small-label-3'>
							<br />
							This is why testing your RMR directly is so important vs using an equation because
							even the most “accurate” equation-based RMR estimations can vary as much as 30 to 40
							percent! <br />
							<br />
							More importantly, the DexaFit RMR tests shows you if your metabolism is slow, normal,
							or fast, and if you primarily burn sugar or fat for fuel.
						</p>
					</Fade>
				</Col>
			</Row>
		);
	}
}
