import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Biker from '../../assets/img/vo2.png';
import Phone from '../../assets/img/how-phone-2.png';
import '../Service.css';

export default class Vo26 extends Component {
	render() {
		return (
			<Row className='off-color2'>
				<Col md='12' lg='6'>
					<Fade left>
						<div className='blue-siding'></div>
						<img id='dexa-table-pic-home' src={Biker} alt='a girl on the dexa scan table' />
						<img
							id='Phone-pic-2-home'
							src={Phone}
							alt='The app on the distance page showing the recording of miles and pace'
						/>
					</Fade>
				</Col>
				<Col
					className='spacer-17'
					sm={{ size: 10, offset: 1 }}
					md={{ size: 5, offset: 1 }}
					xl={{ size: 4, offset: 1 }}>
					<Fade right>
						<h2 className='big-label'>Why VO2max?</h2>
						<br />
						<p className='small-label-3'>
							A lower VO2max is directly associated with an increased risk of multiple chronic
							illnesses, like Type 2 Diabetes. Once you test yours, you will have a powerful
							indicator of your health and physical fitness. This information helps you keep your
							heart and lungs strong, and it can help decrease your risk of contracting chronic
							illnesses. <br />
							<br />
							VO2max is also the most precise and scientific way to measure your current fitness
							level. Athletes and fitness enthusiasts alike use the data from their VO2max test to
							build workout progressions based on their training zones <br />
							<br />
							If you’re looking to improve your speed, endurance, and overall fitness, VO2max
							testing can help identify exactly how much you should train in each heart zone
							identified from your test. This means you will spend the right amount of time training
							and the right amount recovering, in order to see real, data-driven results in your
							performance. <br />
							<br />
							With the information VO2 max testing returns, you gain a serious advantage and control
							over your health and wellness.
						</p>
					</Fade>
				</Col>
			</Row>
		);
	}
}
