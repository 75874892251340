import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store9 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Care Consultation',
			price: 299.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='product-title'>Care Consultation</div>
							<br />
							<br />
							<div>$299.00</div> <br />
							<br />
							<strong>
								30 minute consultation with a DexaFit licensed practitioner and Care Coach.
							</strong>{' '}
							<br />
							<br />
							<div>
								You have your DexaFit results (DEXA, VO2max, and/or RMR), but now what? Schedule a
								Care consultation with a DexaFit licensed physician and Care Coach to get
								personalized feedback, insights, and actionable guidance for optimizing your health
								and longevity.{' '}
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<br />
					<br />
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div>
						The DexaFit Care Consultation is your opportunity to find out with a licensed doctor
						from the DexaFit network of practitioners and wellness pros.
						<br />
						<br />
						Get comprehensive insights about your body and metabolism, discover potential structural
						imbalances that influence poor health and performance, and learn how to reduce your risk
						for preventative diseases like diabetes, obesity, autoimmunity, cvd, and more.
						<br />
						<br />
						Combine the best aspects of physicians – judgment, empathy, and experience - with the
						best aspects of DexaFit technology – accuracy, precision and convenience – to get more
						in depth care and personalization.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
