import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import '../BusinessHome.css';
import Scan from '../../assets/img/Dexafit_DXA_Scan.png';
import Tablet from '../../assets/img/tablet-team.png';
import Phone from '../../assets/img/leaderboard-public.png';
import Icons from '../../assets/img/Icons/media-icon-spread.png';

export default class Business4 extends Component {
	state = {
		row1: false,
		row2: false,
		row3: false,
		row4: false,
		row5: false,
		row6: false,
	};
	handlePageChange = (page) => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row>
				<Col>
					<Row>
						<Col>
							<h4 className='Business-slide-4-top-text-1'>Powerful alone. Better together.</h4>
							<p className='Business-slide-4-top-text-2'>
								No matter your use case, we have a solution for you. Build the ideal package for
								your business or practice.
							</p>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row1: !this.state.row1 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For healthcare professionals</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row1 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row1}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row2: !this.state.row2 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For entrepreneuers & businesses</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row2 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row2}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row3: !this.state.row3 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For wellness professionals</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row3 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row3}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row4: !this.state.row4 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For sports science & research</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row4 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row4}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row5: !this.state.row5 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For imaging centers</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row5 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row5}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
					<Row className='Business-slide-4-row-container'>
						<Col>
							<Row
								className='Business-slide-4-row-click'
								onClick={(e) => {
									this.setState({ row6: !this.state.row6 });
								}}>
								<Col>
									<div className='Business-slide-4-row-title'>For insurance</div>
									<div
										className={`Business-slide-4-row-plus ${
											this.state.row6 ? 'Business-slide-4-row-plus-active' : null
										}`}>
										<span></span>
										<span></span>
									</div>
								</Col>
							</Row>
							<Fade left collapse when={this.state.row6}>
								<Row className='Business-slide-4-row-card'>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												className='Business-slide-4-row-card-img'
												src={Scan}
												alt='cartoon of dexafit scan'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>01.</div>
												<div className='Business-slide-4-row-card-text'>
													Add DexaFit testing or integrate DexaFit ai software to your existing
													systems.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-tablet'
												className='Business-slide-4-row-card-img'
												src={Tablet}
												alt='tablet with the dexafit app being dysplayed on it'
											/>
											<img
												id='Business-slide-4-row-card-img-phone'
												src={Phone}
												alt='phone with the dexafit app being displayed on it'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>02.</div>
												<div className='Business-slide-4-row-card-text'>
													Streamline your workflow and improve your patient's experience with their
													health data.
												</div>
											</div>
										</div>
									</Col>
									<Col lg='4'>
										<div className='Business-slide-4-card-section'>
											<img
												id='Business-slide-4-row-card-img-icons'
												className='Business-slide-4-row-card-img'
												src={Icons}
												alt='all the social media icons displayed in a grid'
											/>
											<div>
												<div className='Business-slide-4-row-card-number'>03.</div>
												<div className='Business-slide-4-row-card-text'>
													Grow your business or practice with DexaFit's lead generation and
													marketing.
												</div>
											</div>
										</div>
									</Col>
									<div
										onClick={(e) => {
											this.handlePageChange('/Services');
										}}
										className={`Business-slide-4-row-link`}>
										Learn more &#8594;
									</div>
								</Row>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
