import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Number1 from '../../assets/img/1-number.png';
import Number2 from '../../assets/img/2-number.png';
import Number3 from '../../assets/img/3-number.png';
import '../HowItWorks.css';

export default class HowItWorks7 extends Component {
	handlePageChange = page => {
		this.props.history.push(page);
	};
	render() {
		return (
			<Row>
				<Col md='12'>
					<Row id='small-top-row'>
						<Col className='padding-left' md={{ size: 7, offset: 1 }}>
							<Fade left>
								<div className='big-text'>Become a DexaFit Pro Today</div>
								<div>
									Join our network of DexaFit Pros, and get referrals, dicounted pricing, and more!
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md={{ size: 3, offset: 1 }}>
							<Fade right>
								<Button onClick={e => this.handlePageChange('/Services')} className='learn-button'>
									Learn More
								</Button>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col md='12'>
					<Row className='off-color middle-row'>
						<Col className='text-center' md='12'>
							<Fade left>
								<div className='big-text'>Everything Is Fully Customized</div>
								<div>
									Include our services with yours. Get preferred pricing. Let us help you market
									your new solutions with our help.
								</div>
								<br />
								<br />
							</Fade>
						</Col>
						<Col className='padding-left' md={{ size: 3, offset: 2 }}>
							<Fade left>
								<img
									className='number-container-img'
									src={Number1}
									alt='icon with number 1 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Tell Us Your Goals</div>
								<div className='grey-label-2'>
									Personalizing a program? Need help? We perform the testing.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number2}
									alt='icon with number 2 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Comprehensive Baseline</div>
								<div className='grey-label-2'>
									We handle the logistics of scheduling, testing, and collecting the key metrics to
									track.
								</div>
							</Fade>
						</Col>
						<Col className='padding-left' md='3'>
							<Fade left>
								<img
									className='number-container-img'
									src={Number3}
									alt='icon with number 3 on it'
								/>
								<br /> <br />
								<div className='bold-text'>Turn Data Into Insights</div>
								<div className='grey-label-2'>
									Get the information you need to forge a more personalized experience for your
									people.
								</div>
							</Fade>
						</Col>
					</Row>
				</Col>
				<Col md='12'>
					<Row id='bottom-row'>
						<Col md='12'>
							<Fade left>
								<div className='big-text text-center'>
									Experience The DexaFit <br /> Difference Yourself
								</div>
							</Fade>
						</Col>
						<Col id='padding-seperator-1' className='text-sm-center text-md-right' md='6'>
							<Fade left>
								<div>Work with existing DexaFit centers near you.</div>
								<br />
								<Button id='left-learn-button' className='learn-button'>
									Learn More
								</Button>
							</Fade>
						</Col>
						<Col id='padding-seperator-2' className='text-sm-center text-md-left' md='6'>
							<Fade right>
								<div>Become a DexaFit Partner location.</div>
								<br />
								<Button id='right-learn-button' className='learn-button'>
									Learn More
								</Button>
							</Fade>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
