import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Phone1 from '../../assets/img/phone-1.png';
import Phone2 from '../../assets/img/phone-2.png';
import Phone3 from '../../assets/img/phone-3.png';
import arrow from '../../assets/img/right.png';
import '../Book.css';

export default class ParterSlide extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show1: true,
			show2: false,
			show3: false,
			slide: 1,
			way: true
		};
	}
	handleSlideChangeForward = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 2,
					show2: true,
					show1: false,
					way: false
				});
				break;
			case 2:
				this.setState({
					slide: 3,
					show3: true,
					show2: false,
					way: false
				});
				break;
			case 3:
				this.setState({
					slide: 1,
					show1: true,
					show3: false,
					way: false
				});
				break;
			default:
		}
	};
	handleSlideChangeBackwards = () => {
		switch (this.state.slide) {
			case 1:
				this.setState({
					slide: 3,
					show3: true,
					show1: false,
					way: true
				});
				break;
			case 2:
				this.setState({
					slide: 1,
					show2: false,
					show1: true,
					way: true
				});
				break;
			case 3:
				this.setState({
					slide: 2,
					show2: true,
					show3: false,
					way: true
				});
				break;

			default:
		}
	};
	render() {
		let direction = this.state.way;
		let left;
		let right;
		let slideDot1 = '';
		let slideDot2 = '';
		let slideDot3 = '';
		if (this.state.show1) {
			slideDot1 = 'circle-on-2';
		}
		if (this.state.show2) {
			slideDot2 = 'circle-on-2';
		}
		if (this.state.show3) {
			slideDot3 = 'circle-on-2';
		}
		if (direction) {
			left = true;
			right = false;
		} else {
			left = false;
			right = true;
			direction = true;
		}
		return (
			<Row>
				<Col className='slide-container'>
					<img
						onClick={this.handleSlideChangeBackwards}
						className='left-arrow-2'
						src={arrow}
						alt='arrow pointing left'
					/>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<img
									className='phone-slideshow'
									src={Phone3}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<img
									className='phone-slideshow'
									src={Phone1}
									alt='shows the gas exchange threshold page of the phone app'
								/>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeBackwards} className='sidebar-left'>
								<img
									className='phone-slideshow'
									src={Phone2}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
					</div>

					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div className='text-center'>
								<img
									className='phone-slideshow'
									src={Phone1}
									alt='shows the gas exchange threshold page of the phone app'
								/>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div className='text-center'>
								<img
									className='phone-slideshow'
									src={Phone2}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
					</div>
					<div className='slide-position'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div className='text-center'>
								<img
									className='phone-slideshow'
									src={Phone3}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
					</div>

					<div className='sidebar-switch'>
						<Fade right={right} left={left} opposite={direction} when={this.state.show1}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<img
									className='phone-slideshow'
									src={Phone2}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show2}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<img
									className='phone-slideshow'
									src={Phone3}
									alt='shows the body comp page of the phone app'
								/>
							</div>
						</Fade>
						<Fade right={right} left={left} opposite={direction} when={this.state.show3}>
							<div onClick={this.handleSlideChangeForward} className='sidebar-right'>
								<img
									className='phone-slideshow'
									src={Phone1}
									alt='shows the gas exchange threshold page of the phone app'
								/>
							</div>
						</Fade>
					</div>

					<img
						onClick={this.handleSlideChangeForward}
						className='right-arrow-2'
						src={arrow}
						alt='arrow pointing right'
					/>
					<div id='dot-holder'>
						<div className={`slide-circle ${slideDot1}`}></div>
						<div className={`slide-circle ${slideDot2}`}></div>
						<div className={`slide-circle ${slideDot3}`}></div>
					</div>
				</Col>
			</Row>
		);
	}
}
