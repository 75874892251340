import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Jan72020 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Meet Your Master Hormone: Leptin</div>
						<br />
						<div className='blog-date-page'>JAN 7, 2020</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							All hormones play a crucial role in your body, but there is one that holds the
							skeleton key and is considered the master: Leptin. <br />
							<br />
							You may have heard of leptin called the "satiety hormone" or "starvation hormone." It
							controls all energy metabolism in your body, and plays a significant role in
							fertility, immunity and brain function.
							<br />
							<br />
							In fact, it influences all other hormones in the body. It is the sine qua non for
							optimizing your body composition, energy levels, and overall performance. <br />
							<br />
							<strong>How Leptin Works</strong>
							<br />
							<br />
							Leptin is released from your adipose tissues and signals your hypothalamus about your
							body's energy status. The more body fat you carry, the more leptin you produce. <br />
							<br />
							Your bloodstream carries leptin into your brain, where it sends a signal to the
							hypothalamus, which controls when and how much you eat.
							<br />
							<br />
							High levels of leptin tell your brain that you have plenty of fat stored. Low levels
							of leptin tell your brain that fat stores are low and you need to eat. <br />
							<br />
							Because of this direct connection from white fat stores to your brain, leptin
							regulates long-term food intake, energy expenditure, and helps maintain your body
							weight. <br />
							<br />
							And since your brain is responsible for receiving and delivering energy status from
							over 20 trillion cells in your body, signaling efficiency is pretty important. When
							it's poor or broken, problems arise. <br />
							<br />
							<strong>Leptin Resistance</strong>
							<br />
							<br />
							If your brain no longer recognizes the leptin signal sent to the brain, you become
							leptin resistant. This creates all sorts of problems, including disrupting thyroid
							function. In return, your body isn’t able to burn fat effectively and decreases your
							basal metabolic rate. <br />
							<br />
							If you’re overweight and carry excess body fat, there is a good chance you have some
							degree of leptin resistance. But it’s also possible to be leptin resistance If you’re
							underweight and what some call “skinny fat.” If you have or had an eating disorder,
							you’re likely suffering from a serious leptin issue too.
							<br />
							<br />
							The easiest test is to look in the mirror. The mirror does not lie and it is really
							cheap. Step in front of a full length mirror and take a look at your reflection. Do
							you see a high amount of fat or do you see skinny, but kind of fat (skinny fat)? If
							you’re either of these, there’s a good chance you might have leptin issues. <br />
							<br />
							But if you want a better way to be sure, measuring your body composition health,
							especially your visceral fat with a DEXA scan, is another good way. Monitoring
							specific blood labs like thyroid hormones, vitamin D, and HS-CRP can help identify
							your leptin status too. <br />
							<br />
							High levels of leptin are associated with:
							<br />
							<br />
							<ol>
								<li>High levels of white blood cells</li>
								<li>Low vitamin D</li>
								<li>High levels of visceral fat</li>
								<li>Prehypertension</li>
								<li>Type 2 Diabetes</li>
								<li>
									<a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4532332/'>
										Risk of osteoporosis
									</a>
								</li>
							</ol>
							Leptin resistance can also lead to liver issues. <br />
							<br />
							<strong>What happens when you eat a meal?</strong>
							<br />
							<br />
							When you eat a meal, 60% of calories go to the liver, which in turn routes them to
							tissues in the body to sustain normal energy production. The other 40% of calories go
							to peripheral tissue and muscle where insulin allows them to enter the cells. <br />
							<br />
							The remaining 40% of calories is where problems arise if your leptin sensitive or
							resistance. <br />
							<br />
							If your leptin sensitive, the peripheral tissues and muscles use all of the calories.
							Meanwhile, if your leptin resistant, the calories that are not used go back to the
							liver and are stored as fat cells. The more fat that is deposited, the higher leptin
							levels become. And a buildup of fat in the liver can cause fatty liver or metabolic
							syndrome.
							<br />
							<br />
							It can also drive up inflammatory chemicals and things like IL6 (interleukin 6, a
							pro-inflammatory signal) and TNF alpha (tumor necrosis factor alpha), which increases
							C-reactive protein and impairs insulin signaling.
							<br />
							<br />
							This biochemical cascade caused by leptin resistance can lead to preventable diseases
							like Obesity and Type 2 Diabetes. <br />
							<br />
							<strong>Ways to optimize Leptin Sensitivity</strong>
							<br />
							<br />
							If you show signs of leptin resistance, it’s time to start measuring your hormones and
							other lab markers. There are also a few action steps you can take to regain leptin
							sensitivity, but we recommend working with a professional to help guide you:
							<br />
							<br />
							<ol>
								<li>Try reducing your carbohydrate intake and increase protein intake</li>
								<li>Don't skip breakfast and avoid eating after dark</li>
								<li>Avoid snacking (it can stress the liver) </li>
								<li>Optimize your sleep (8 to 9 hours) </li>
								<li>Avoid intense exercise until you regain leptin sensitivity </li>
								<li>Reduce stress</li>
							</ol>
							<br />
							Signs you’ve become leptin sensitive again include:
							<br />
							<br />
							<ol>
								<li>Rapid weight loss</li>
								<li>Improved mood and sleep</li>
								<li>Increased libido</li>
								<li>Improved energy levels</li>
								<li>Reduced hunger and food cravings</li>
							</ol>
							<br />
							Follow a regimented schedule for at least 6-8 weeks to give yourself the best chance
							of regaining leptin sensitivity. <br />
							<br />
							That’s it for the first post in our Optimal Labs series. Stay tuned as we get ready to
							explore your thyroid next!
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
