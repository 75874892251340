import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class May222017 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>Why Do I Need A DEXA Scan?</div>
						<br />
						<div className='blog-date-page'>May 22, 2017</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							When it comes to full body composition scanning, there is no better type of scan other
							than the DEXA scan (or DXA Scan). Offered by <a href='/Service'>DexaFit</a>, this full
							body scan is incredibly convenient, easy, and accurate. It’s a great way to gather
							information about your body and take charge of your health. The DEXA scan continues to
							revolutionize the medical world and will continue to benefit many people for years to
							come.
							<br />
							<br />
							In today’s post, we’ll be taking a closer look on why you should get a DEXA scan.
							<br />
							<br />
							<strong>First off, what is a DEXA Scan?</strong>
							<br />
							<br />
							A DEXA scan (aka a DXA Scan) is a Dual X-Ray Absorptiometry scan. The DEXA scan
							requires the use of two X-ray beams, which scans the body for approximately 10-20
							minutes with minimal radiation exposure. This scan measures bone density and full body
							composition and is easy to use, convenient, and incredibly accurate. It only takes a
							few minutes and provides some of the most accurate results and data, which is why it
							has revolutionized the medical field.
							<br />
							<br />
							<strong>What does it do?</strong>
							<br />
							<br />
							The DEXA scan is used to pinpoint conditions, problems, and diseases in the body. It’s
							a quick, convenient, and easy way to check for possible diseases (like osteoporosis,
							rheumatoid arthritis, liver disease, and etc.) before they occur. DEXA scans can also
							determine weak points in your body, such as areas that are more prone to fractures or
							thinning muscle mass. By pinpointing and locating these diseases, it makes it much
							easier for doctors to diagnose, prevent, and treat them before they get a chance to
							fully develop.
							<br />
							<br />
							DEXA scans also measure body composition, which includes body fat. These scans are
							used to measure belly fat and can be used to diagnose and pinpoint diseases and health
							problems, such as: diabetes, breast cancer, and etc. These scans will also be able to
							find the excess fat between your organs, problem areas, and find problems like high
							cholesterol. DEXA scans are a great way to track and prevent issues before they
							happen, and can also help you create a plan to keep your health under control.
							<br />
							<br />
							<strong>Why do I need it?</strong>
							<br />
							<br />
							As mentioned before, DEXA scans are a great way to find out everything that is
							happening in your body. It can help you locate excess fat in the body and help you
							create a diet and exercise plan. DEXA scans can locate and check for potential
							diseases and health issues, which is great for maximizing health and lifespan. These
							scans will also indicate areas of improvement and areas that require maintenance, such
							as with blood and cholesterol levels. Getting a DEXA scan is a great way to map out
							areas to watch and things in your body that you need to be aware of.
							<br />
							<br />
							<strong>How will it improve my life?</strong>
							<br />
							<br />
							DEXA scans can potentially help to improve your quality of life tremendously. By
							detecting and finding potential issues with your body, you can take the necessary
							steps to treat and prevent these issues before they escalate. With a DEXA scan, you’ll
							be able to know all there is to know about your body, and you’ll never be left in the
							dark about your health again.
							<br />
							<br />
							At DexaFit, we’ll help you achieve better fitness results and help transform your
							life. To make an appointment for a DEXA scan, visit <a href='/Service'>DexaFit</a> and
							find a location to get started. You’ll get access to the best technology and feedback
							that will help you change your life for the better.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
