import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class Store7 extends Component {
	state = {
		quantity: 1,
	};

	resetPage = () => {
		this.props.scroll();
		this.props.reset();
	};
	addCart = () => {
		const item = {
			name: 'Elite Blood Panel',
			price: 1199.0,
			quantity: this.state.quantity,
			key: Math.floor(Math.random() * Math.floor(10000)),
		};
		this.props.addCart(item);
		this.props.scroll();
		this.props.reset();
	};
	render() {
		return (
			<Row>
				<Col md={{ size: 10, offset: 1 }}>
					<Row>
						<Col lg='6'>
							<img className='product-img' src={this.props.img} alt='product thumbnail' />
						</Col>
						<Col lg={{ size: 5, offset: 1 }}>
							<div className='card-relative'>
								<div className='card-sale'>Sale</div>
							</div>
							<div className='product-title'>Elite Blood Panel</div>
							<br />
							<br />
							<div>$1,199.00</div> <strike>$1,299.00</strike> <br />
							<br />
							<div>
								Our most comprehensive diagnostic package yet and the same one trusted by
								Professional Athletes. Take a deeper look into your thyroid and reproductive
								hormones, and other advanced testing critical to reaching peak performance. <br />
								<br /> *Please note: this test is not yet available if you’re in MD, NJ, NY, RI, ND,
								SD, or HI. We recommend trying one of our at-home testing kits.
							</div>
							<br />
							<div>Quantity:</div>
							<input
								value={this.state.quantity}
								onChange={(e) => {
									this.setState({ quantity: e.target.value });
								}}
								type='number'
								id='quantity'
								name='quantity'
								min='1'
								max='10'></input>
							<Button onClick={this.addCart} className='add-to-cart-button'>
								Add to Cart
							</Button>
						</Col>
					</Row>
					<Button className='back-button' onClick={this.resetPage}>
						Back
					</Button>
					<br />
					<br />
					<div className='product-title text-center'>The Elite Deep Dive</div>
					<br />
					<br />
					<div>
						Our most comprehensive and exclusive panel yet. Get VIP-level access to every biomarker
						top athletes and competitors track to reach their peak.
						<br />
						<br />
						DexaFit's lead Sports Scientist designed this unique and extremely comprehensive blood
						testing package as a response to the top athletes he advised in their off-season asking
						him questions like...{' '}
					</div>
					<br />
					<br />
					<div className='text-center'>
						“How can I optimize my performance and stay healthy?”
						<br />
						<br />
						“What supplements should I take for peak performance?”
						<br />
						<br />
						“What should my ideal diet look like?”
					</div>
					<br />
					<br />
					<div>
						Dr. Ben's response, "Honestly, without any data about you, I'm just guessing."
						<br />
						<br />
						Even if the good doctor's guess is better than most, unless you know what your blood
						looks like under a microscope, there is no way for you to identify with 100% confidence
						what steps you should take to eat right, take the right supplements, and perform at peak
						capacity.{' '}
					</div>
					<br />
					<br />
					<div className='product-title text-center'>Solution: Blood Panel Testing</div> <br />
					<br />
					<div>
						Blood testing is a critical step to take if you want to implement a science-based
						peak-performance program that will skyrocket your performance and add years to your
						life. Knowledge of exactly what is going on inside your body empowers you to perform at
						your peak physical and cognitive capacity.
						<br />
						<br />
						But sadly, your annual medical check-ups are simply routine, old-school blood tests that
						don’t even test for the most important markers of disease risk. Let alone the markers
						you need to track to reach optimal. They are simply designed to make sure you’re “not
						dying."
						<br />
						<br />
						But now you can skip your physician and simply manage the entire process for getting
						your blood work done yourself. And if you want to test absolutely every little thing
						that affects your organs, your energy, your hormones, your health and your longevity,
						then you’ve found the ultimate answer.
						<br />
						<br />
						Working closely with Dr. Ben, we designed a panel he trusts for his athletes, and its
						our most complete blood testing package money can buy. This is by far the most
						comprehensive blood testing package we offer. It's for the high performer, biohacker and
						anti-aging enthusiast who wants access to the same type of elite health panel and
						screening that would cost tens of thousands of dollars at a longevity institute or
						professional sports lab.
						<br />
						<br />
					</div>
					<div className='product-title text-center'>The Test</div> <br />
					<br />
					<div>
						Dive deep into your overall metabolic functioning for optimal long-term health, fitness
						and longevity. Your panel will include thyroid function, stress response, blood glucose
						regulation, sex hormone balance, heavy metals, inflammation, organs of detoxification
						(liver, kidneys, gallbladder, and lungs), proteins, electrolytes, blood oxygen and
						nutrient delivery, immune system status, vitamin D status and much, much more,
						including:
					</div>
					<br />
					<br />
					<ul>
						<li>25-Hydroxy-Vitamin D </li>
						<br />
						<li>Apolipoprotein A-1 </li>
						<br />
						<li>Apolipoprotein B </li>
						<br />
						<li>Blood Lead </li>
						<br />
						<li>Blood Mercury </li>
						<br />
						<li>Cardio IQ </li>
						<br />
						<li>Lipoprotein Fractionation/Ion Mobility </li>
						<br />
						<li>Complete Blood Count w/ differential </li>
						<br />
						<li>Complete Metabolic Panel </li>
						<br />
						<li>Copper </li>
						<br />
						<li>Cortisol </li>
						<br />
						<li>Dehydroepiandrosterone Sulfate (DHEA) </li>
						<br />
						<li>Ferritin, serum</li>
						<br />
						<li>Fibrinogen </li>
						<br />
						<li>Folate </li>
						<br />
						<li>Free Fatty Acids </li>
						<br />
						<li>Hemoglobin A1c</li>
						<br />
						<li>Homocysteine </li>
						<br />
						<li>High-sensitivity C-reactive protein </li>
						<br />
						<li>IGF-1 (Growth hormone surrogate) </li>
						<br />
						<li>Insulin </li>
						<br />
						<li>Iron, TIBC</li>
						<br />
						<li>Lipid Panel Lipoprotein (a) </li>
						<br />
						<li>Luteinizing Hormone </li>
						<br />
						<li>Omega 3 Fatty Acids </li>
						<br />
						<li>RBC </li>
						<br />
						<li>Magnesium </li>
						<br />
						<li>Selenium </li>
						<br />
						<li>SHBG (sex hormone binding globulin) </li>
						<br />
						<li>T-3 Total </li>
						<br />
						<li>T-3 Uptake </li>
						<br />
						<li>T-3 Free</li>
						<br />
						<li>T-4 Free </li>
						<br />
						<li>Testosterone + Free Testosterone </li>
						<br />
						<li>Thiamine, Vitamin B-1 </li>
						<br />
						<li>Thyroglobulin Antibodies Thyroid Peroxidase AB </li>
						<br />
						<li>TSH </li>
						<br />
						<li>Uric Acid </li>
						<br />
						<li>Vitamin A</li>
						<br />
						<li>Vitamin B12 </li>
						<br />
						<li>Zinc </li>
						<br />
						<li>Estradiol </li>
						<br />
						<li>Prolactin </li>
						<br />
						<li>Pregnenolone </li>
						<br />
						<li>Bun/Creatine Ratio </li>
						<br />
						<li>Progesterone </li>
						<br />
						<li>CO2 </li>
						<br />
						<li>ALT</li>
						<br />
					</ul>
					<div>
						Based on the size of this list of biomarkers, Ben held nothing back and covered all your
						bases. You will not find a test this comprehensive anywhere else, guaranteed. Get
						absolutely everything you need to identify disease, optimize longevity and supercharge
						your performance.
					</div>
					<br />
					<br />
				</Col>
			</Row>
		);
	}
}
