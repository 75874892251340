import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import '../../blog.css';
import Fade from 'react-reveal/Fade';

export default class Dec282015 extends Component {
	render() {
		return (
			<Row>
				<Col md={{ size: 8, offset: 2 }}>
					<Fade left>
						<div className='blog-title-page'>
							Athletes and Body Composition, important for EVERYONE
						</div>
						<br />
						<div className='blog-date-page'>Dec 28, 2015</div>
						<br />
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
						<br />
						<div className='blog-text-page'>
							Athletes are always looking for the perfect body fat to muscle ratio—that sweet spot
							of optimal weight, strength, speed, and endurance. Their potential is largely
							dependent on the ability to sustain power both anaerobically and aerobically, and to
							overcome heavy resistance.
							<br />
							<br />
							Both of these variables are highly correlated to body composition.
							<br />
							<br />
							Research shows that the correct portion of muscle mass increases strength, power, and
							agility, which is essential to optimizing athletic performance.
							<br />
							<br />
							Nutritional intake/timing and a strict physical training regimen go a long way to
							accomplishing this goal.
							<br />
							<br />
							But in order to fully maximize potential, it’s important to measure progress with
							honest and objective feedback, so a stagnating or ineffective program can be changed.
							<br />
							<br />
							Looking in the mirror or monitoring weight on a scale—while helpful—isn’t enough
							information to fully prepare the body for competition that requires optimal power,
							speed, and endurance.
							<br />
							<br />
							Throughout the duration of a training and/or nutrition program, you need to{' '}
							<strong>
								know whether the weight you’re gaining or losing is lean muscle or fat, and how
								balanced your development is.{' '}
							</strong>
							<br />
							<br />
							This is why obtaining a detailed analysis of your body composition, and not just a
							body fat percentage, is so important.
							<br />
							<br />
							A DXA (Dexa) scan’s direct measurement of your body composition provides this data and
							separates itself from other methods because of its scope of application.
							<br />
							<br />
							<a href='https://www.wsj.com/articles/SB112871260917363004'>NFL teams</a>, college
							sports labs, and other professional teams use DXA to track their athletes’ progress
							during and after the season, and the Olympic Training Center in Colorado Springs
							utilizes the technology to assess and develop training programs for their athletes
							during the off-season.
							<br />
							<br />
							Former Chicago Bears and Oakland Raiders linebacker Nick Roach is one of many
							individual athletes we work with that utilizes the data and analysis from his scan to
							more efficiently tailor and assess his training and nutrition programs while preparing
							for the long NFL season.
							<br />
							<br />
							Due to a history of leg injuries, he likes to monitor his lean mass balance, so he can
							reduce his chances of pulling a muscle or worse.
							<br />
							<br />
							Below are a few more applications adopted by athletes like Nick, and by coaches and
							trainers that refer their athletes to us for complete body composition analysis:
							<br />
							<br />
							+ Knowing your body composition—and specifically your body fat and lean mass
							distribution—helps you better determine the type of fuel mixture to use before,
							during, and after workouts and practice along with pre-competition fuel (e.g. athletes
							with lower body fat typically need more calories and carbohydrates than athletes with
							higher body fat); this is also a factor in a maintenance program in the off-season,
							when many athletes are still fueling for competition (out of habit) but working out
							significantly less or at lower intensity.
							<br />
							<br />+ <a href='/Services'>Body composition testing</a> helps formulate a training
							program that builds muscle, lose fat, or both. With information from a DXA scan, you
							can establish a baseline, compare your lean and fat mass distribution to elite
							athletes in your sport or at your position, then design a specific program that will
							help you reach your goals more optimally. Follow up scans will ensure you’re making
							the progress you need to maximize performance.
							<br />
							<br />
							+ Athletes get injured. It’s a fact in any sport. During rehabilitation, fat gain and
							muscle atrophy (loss) are common phenomena. A DXA scan helps trainers and physical
							therapists monitor these variables along with the resulting lean mass imbalance that
							results from injury. This information can then help them adapt rehab workouts, rest,
							and nutrition plans to reflect your body’s specific needs during its recovery.
							<br />
							<br />
							+ There are countless mental/emotional roadblocks to athletic success. For many female
							athletes, weight gain—even when the gain is due to muscle—can lead to obsessive
							behavior and often an eating disorder. DXA scans provide the ultimate reassurance test
							to athletes, so they know that the weight they gain is the ‘right’ kind of weight.
							<br />
							<br />
							Overall, knowing your body’s total composition is much more important than{' '}
							<a href='/Services'>measuring fat</a>. More and more athletes and teams alike are
							recognizing that identifying the exact distribution of lean mass and fat mass is more
							valuable than a body fat percentage.
							<br />
							<br />
							It’s a more effective tool for developing meal plans and other nutrition strategies;
							monitoring progress; optimizing performance; rehabilitating an injury; providing
							motivation; and discovering irregularities in behavior.
						</div>
						<div onClick={this.props.handleReload} className='blog-date-page'>
							Back
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
