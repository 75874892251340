import React, { Component } from 'react';
import { Row } from 'reactstrap';
import HowNav from './IconNav/HowNav';
import MedNav from './IconNav/MedNav';
import AthNav from './IconNav/AthNav';
import './Service.css';

export default class Service1 extends Component {
	state = {
		how: true,
		med: false,
		ath: false,
	};

	componentDidMount() {
		this.stateHandler();
	}

	componentDidUpdate(prevProps, prevState) {
		this.stateHandler(prevProps, prevState);
	}
	stateHandler(prevProps) {
		if (prevProps !== this.props) {
			switch (this.props.statePass2.page) {
				case 'how':
					this.setState({
						how: true,
						med: false,
						ath: false,
					});
					break;
				case 'med':
					this.setState({
						how: false,
						med: true,
						ath: false,
					});
					break;
				case 'ath':
					this.setState({
						how: false,
						med: false,
						ath: true,
					});
					break;
				default:
			}
		}
	}

	classReturn = (statecheck) => {
		switch (statecheck) {
			case 'how':
				if (this.state.how === true) {
					return 'on';
				} else {
					return '';
				}
			case 'med':
				if (this.state.med === true) {
					return 'on';
				} else {
					return '';
				}
			case 'ath':
				if (this.state.ath === true) {
					return 'on';
				} else {
					return '';
				}
			default:
		}
	};

	buttonClick = (value) => {
		this.props.callBack2(value);
	};

	render() {
		let switchScroll;
		if (this.props.scrollStyle === '') {
			switchScroll = 'solution-nav-bar';
		} else {
			switchScroll = this.props.scrollStyle;
		}
		return (
			<div>
				<Row className={`scroll-nav-spacer ${switchScroll}`}>
					<div className='scroll-bar'>
						<HowNav click={() => this.buttonClick('how')} on={this.classReturn('how')} />
						<MedNav click={() => this.buttonClick('med')} on={this.classReturn('med')} />
						<AthNav click={() => this.buttonClick('ath')} on={this.classReturn('ath')} />
					</div>
				</Row>
			</div>
		);
	}
}
