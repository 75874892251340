import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Badou from '../../assets/img/BadouJack.png';
import Fade from 'react-reveal/Fade';
import '../HowItWorks.css';

export default class Athletes6 extends Component {
	render() {
		return (
			<Row className='off-color-3'>
				<Col className='text-center' md='12'>
					<Fade left>
						<br />
						<br />
						<br />
						<img src={Badou} alt='Badou Jack at dexafit' />
						<div id='quote'>
							"I love DexaFit. Their scientific and accurate measurements get me insights I never
							had before and give me a significant competitive edge in the ring." <br />
							<span id='quote-badou'>— Badou Jack, WBC and WBA World Boxing Champion</span>
						</div>
					</Fade>
				</Col>
			</Row>
		);
	}
}
